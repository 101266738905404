import { ChangeDetectorRef, Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appTailwindInput]',
  standalone: true,
})
export class TailwindInputDirective {
  _error = false;

  set error(error: boolean) {
    this._error = error;
    this.cdr.markForCheck();
  }

  @HostBinding('class') get classes() {
    const baseClasses =
      'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6';
    const errorClasses = 'ring-red-400 focus:ring-red-400 focus:ring-2';
    return baseClasses + ' ' + (this._error ? errorClasses : '');
  }

  //relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-5
  //block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6
  constructor(private cdr: ChangeDetectorRef) {}
}
