<app-settings-card
  heading="Adresse"
  description="Die Adresse wird beispielsweise in den erzeugten Rechnungen verwendet."
  (save)="saveSettings()"
  [disabled]="disabled"
>
  <form [formGroup]="addressForm">
    <app-address-form class="block mt-6" [addressFormGroup]="addressForm"></app-address-form>
  </form>
</app-settings-card>
