import { inject, Injectable } from '@angular/core';
import { BaseInvoice, Document, FirestoreEntity, FirestoreInvoice, Invoice } from 'commons';

import { firstValueFrom } from 'rxjs';
import { DocumentService } from './document.service';
import { FIRESTORE_SERVICE, ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { where } from '@angular/fire/firestore';
import { LastEditorService } from './last-editor.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  private documentService = inject(DocumentService);
  private lastEditor = inject(LastEditorService);

  getAllInvoices() {
    return this.orgaFirestoreService.getDocs<Invoice>('invoice');
  }

  getInvoicesForProject(projectId: string) {
    return this.orgaFirestoreService.getDocs<Invoice>(
      'invoice',
      where('projectId', '==', projectId)
    );
  }

  getInvoice(invoiceId: string) {
    return this.orgaFirestoreService.getDoc<Invoice>(`invoice/${invoiceId}`);
  }

  async createInvoice(invoice: BaseInvoice) {
    return this.orgaFirestoreService.createDoc<BaseInvoice>(
      'invoice',
      await this.lastEditor.hydrate(invoice)
    );
  }

  async updateInvoice(invoiceId: string, invoice: Partial<Invoice>): Promise<void> {
    return this.orgaFirestoreService.updateDoc<Invoice>(
      `invoice/${invoiceId}`,
      await this.lastEditor.hydrate(invoice)
    );
  }

  async deleteInvoice(invoice: FirestoreInvoice) {
    await this.updateInvoice(invoice.id, {});
    return this.rootFirestoreService.deleteDoc(invoice.docRef.path);
  }

  async downloadInvoice(invoice: FirestoreInvoice) {
    const document: FirestoreEntity<Document> = await firstValueFrom(
      this.rootFirestoreService.getDoc<Document>(invoice.data.invoicePath)
    );
    this.documentService.downloadDocument(document);
  }
}
