import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  EffortPosition,
  FirestoreProject,
  FixedPricePosition,
  getVatRateForDropdown,
  NumberString,
  PositionEntity2,
  PositionType2,
  VatRateType,
} from 'commons';
import { NgIf } from '@angular/common';
import { SlideOverComponent } from '../../../../../components/slide-overs/slide-over/slide-over.component';
import { SlideOverHeaderComponent } from '../../../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverContentComponent } from '../../../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { SlideOverFooterComponent } from '../../../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { TailwindButtonDirective } from '../../../../../components/button/tailwind-button.directive';
import { ProjectService } from '../../../../../services/project.service';
import { DropdownComponent } from '../../../../../components/dropdown/dropdown.component';
import { Position2 } from 'commons/dist/entities/position2';
import { NumberInputComponent } from '../../../../../components/input/number-input/number-input.component';
import { TextInputComponent } from '../../../../../components/input/text-input/text-input.component';
import {
  RadioButtonGroupComponent,
  RadioButtonOptions,
} from '../../../../../components/input/radio-button/radio-button-group.component';
import { CheckboxComponent } from '../../../../../components/input/checkbox/checkbox.component';
import { DropdownButtonComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownItemComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DropdownMinimalMenuComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { WorkService } from '../../../../../services/work.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { WorkTimeInputComponent } from '../../../../../components/input/work-time-input/work-time-input.component';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

interface PositionBaseForm {
  name: FormControl<string>;
  description: FormControl<string | null>;
  billable: FormControl<boolean>;
  vatRateType: FormControl<VatRateType>;
}

interface EffortForm {
  estimatedWorkMinutes: FormControl<number | null>;
  hourlyRate: FormControl<NumberString | null>;
}

interface FixedForm {
  amount: FormControl<NumberString | null>;
}

interface PositionForm {
  type: FormControl<PositionType2>;
  base: FormGroup<PositionBaseForm>;
  specific: FormGroup<EffortForm> | FormGroup<FixedForm>;
}

function getPositionBaseFormGroup(position: PositionEntity2 | null) {
  return new FormGroup<PositionBaseForm>({
    name: new FormControl<string>(position?.name ?? '', {
      nonNullable: true,
      validators: [Validators.maxLength(240), Validators.required],
    }),
    description: new FormControl<string | null>(position?.description ?? null, [
      Validators.maxLength(1500),
    ]),
    vatRateType: new FormControl<VatRateType>(position?.vatRateType ?? 'standardRate', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    billable: new FormControl<boolean>(position?.billable ?? true, { nonNullable: true }),
  });
}

function getEffortFormGroup(position: EffortPosition | null): FormGroup<EffortForm> {
  return new FormGroup<EffortForm>({
    estimatedWorkMinutes: new FormControl<number | null>(position?.estimatedWorkMinutes ?? null),
    hourlyRate: new FormControl<NumberString | null>(position?.hourlyRate ?? null, [
      Validators.required,
    ]),
  });
}

function getFixedFormGroup(position: FixedPricePosition | null): FormGroup<FixedForm> {
  return new FormGroup<FixedForm>({
    amount: new FormControl<NumberString | null>(position?.amount ?? null, [Validators.required]),
  });
}

function getSpecificFormGroup(
  type: PositionType2,
  position: PositionEntity2 | null
): FormGroup<EffortForm> | FormGroup<FixedForm> {
  switch (type) {
    case 'effort':
      return getEffortFormGroup(position as EffortPosition);
    case 'fixed':
      return getFixedFormGroup(position as FixedPricePosition);
  }
}

function getPositionForm(position: PositionEntity2 | null): FormGroup<PositionForm> {
  return new FormGroup<PositionForm>({
    type: new FormControl<PositionType2>(position?.type ?? 'effort', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    base: getPositionBaseFormGroup(position),
    specific: getSpecificFormGroup(position?.type ?? 'effort', position),
  });
}

export interface PositionSlideOverInput {
  position?: Position2;
  project: FirestoreProject;
}

@Component({
  selector: 'app-position-slide-over',
  templateUrl: './position-slide-over.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SlideOverComponent,
    NgIf,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    SlideOverFooterComponent,
    TailwindButtonDirective,
    DropdownComponent,
    NumberInputComponent,
    TextInputComponent,
    RadioButtonGroupComponent,
    CheckboxComponent,
    DropdownButtonComponent,
    DropdownItemComponent,
    DropdownMinimalMenuComponent,
    NgIconWrapperComponent,
    WorkTimeInputComponent,
  ],
})
export class PositionSlideOverComponent implements OnInit, OnDestroy {
  positionTypeOptions: RadioButtonOptions[] = [
    {
      value: 'effort',
      description: 'Der Preis der Position wird anhand der darauf gebuchten Stunden berechnet.',
      label: 'Aufwand',
    },
    {
      value: 'fixed',
      description:
        'Die Position wird bis zum angebenen Festpreis verrechnet. Du kannst aber zur internen Kostenkontrolle auch Stunden darauf buchen.',
      label: 'Fixpreis',
    },
  ];

  vatRates = getVatRateForDropdown();

  positionForm!: FormGroup<PositionForm>;
  isDeletionAllowed = false;

  subscriptions = new Subscription();

  constructor(
    private projectService: ProjectService,
    private workService: WorkService,
    @Inject(Dialog) private dialog: Dialog,
    @Inject(DIALOG_DATA) public data: PositionSlideOverInput | null,
    @Inject(DialogRef) public dialogRef: DialogRef
  ) {}

  async ngOnInit() {
    const position = this.data?.project ?? null;

    this.positionForm = getPositionForm(this.data?.position ?? null);
    this.subscriptions.add(
      this.positionForm.controls.type.valueChanges.subscribe((type) => {
        if (type) {
          this.positionTypeChanged(type);
        }
      })
    );
    if (this.data?.position) {
      this.isDeletionAllowed =
        (await firstValueFrom(
          this.workService.countWork(this.data.project.id, this.data.position.id)
        )) === 0;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  positionTypeChanged(positionType: PositionType2) {
    this.positionForm.setControl('specific', getSpecificFormGroup(positionType, null));
  }

  closeSlideOver() {
    this.dialogRef.close();
  }

  async savePosition() {
    this.positionForm.markAllAsTouched();
    if (!this.positionForm.valid) {
      return;
    }
    const formValue = this.positionForm.getRawValue();
    const position: PositionEntity2 = {
      type: formValue.type,
      ...formValue.base,
      ...(formValue.type === 'effort'
        ? { ...formValue.specific, billedWorkMinutes: 0 }
        : formValue.specific),
      totalWorkMinutes: 0,
    } as PositionEntity2;

    if (this.data?.position) {
      this.projectService.updatePosition(this.data.project.id, this.data.position.id, position);
    } else {
      await this.projectService.createPosition(this.data?.project.id!, position);
    }
    this.closeSlideOver();
  }

  deletePosition() {
    this.projectService.deletePosition(this.data?.project.id!, this.data?.position?.id!);
    this.closeSlideOver();
  }
}
