<div [class.bg-gray-100]="!subpage">
  <div
    class="w-full flex flex-col max-w-screen-3xl mx-auto pt-16 min-h-screen"
    [ngClass]="subpage ? '' : 'bg-gray-100'"
  >
    <button
      *ngIf="subpage"
      (click)="goBack()"
      class="w-fit my-2 sm:mx-8 inline-flex items-center justify-center px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 hover:bg-gray-100"
    >
      <svg
        class="w-5 h-5 rtl:rotate-180"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
        />
      </svg>
      <span>Zurück</span>
    </button>
    <div
      class="flex-1 relative bg-gray-100"
      [ngClass]="subpage ? 'sm:mx-8 mb-8 pb-8 rounded-lg overflow-hidden shadow-lg' : ''"
    >
      <ng-content select="app-header-meta-action"></ng-content>
      <ng-container *ngIf="sidebar; else noSidebar">
        <main class="pb-10 lg:py-12 lg:px-8 print:p-4">
          <div class="lg:grid lg:grid-cols-12 lg:gap-x-5 items-start">
            <aside
              class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3 lg:sticky lg:top-[80px] print:p-0"
            >
              <ng-content select="app-sidebar-navigation"></ng-content>
            </aside>
            <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              <ng-template [ngTemplateOutlet]="content"></ng-template>
            </div>
          </div>
        </main>
      </ng-container>

      <ng-template #noSidebar>
        <div class="mx-auto px-0 sm:px-6 lg:px-8">
          <ng-template [ngTemplateOutlet]="content"></ng-template>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
