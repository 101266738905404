import { Component, Inject, OnInit } from '@angular/core';
import { firstValueFrom, Observable, shareReplay } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { FirestoreProject, mapProjectSummary } from 'commons';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { EmptyComponent } from '../../../../components/empty/empty.component';
import { PositionCardComponent } from '../positions/position-card/position-card.component';
import { ProjectService } from '../../../../services/project.service';
import { Position2 } from 'commons/dist/entities/position2';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { BadgeComponent } from '../../../../components/badge/badge.component';
import { Dialog } from '@angular/cdk/dialog';
import {
  WorkSlideOverComponent,
  WorkSlideOverInput,
} from '../../slide-overs/work-slide-over.component';
import { PositionSlideOverComponent } from '../positions/position-slide-over/position-slide-over.component';

@Component({
  selector: 'app-project-structure',
  templateUrl: './project-structure.component.html',
  standalone: true,
  imports: [
    NgIf,
    EmptyComponent,
    NgFor,
    PositionCardComponent,
    AsyncPipe,
    TailwindButtonDirective,
    BadgeComponent,
  ],
})
export class ProjectStructureComponent implements OnInit {
  project!: Observable<FirestoreProject>;
  positions!: Observable<Position2[]>;

  totalBilled!: Observable<number>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    @Inject(Dialog) private dialog: Dialog
  ) {}

  async ngOnInit() {
    this.project = this.projectService
      .getProject(this.route.parent?.snapshot.params['projectId'])
      .pipe(shareReplay(1));

    this.positions = this.project.pipe(
      map((project) => this.projectService.getPositions(project)),
      map((positions) => positions.sort((a, b) => (a.name + a.id).localeCompare(b.name + b.id)))
    );
    // this.totals$ = this.positions$.pipe(
    //   map((positions) => {
    //     const totalWorkedMinutes = positions.reduce(
    //       (prev, curr) => prev + curr.totals.totalWorkMinutes,
    //       0
    //     );
    //     const total = positions.reduce((prev, curr) => {
    //       switch (curr.positionType) {
    //         case PositionType.perPiece:
    //           return prev;
    //         case PositionType.fixedPrice:
    //           return prev + addVat(curr.fixedPrice.amount, curr.vatRate);
    //         case PositionType.timeAndMaterial:
    //           return (
    //             prev +
    //             addVat(
    //               curr.timeAndMaterial.hourlyRate * (curr.totals.totalWorkMinutes / 60),
    //               curr.vatRate
    //             )
    //           );
    //       }
    //     }, 0);
    //     const totalExclVat = positions.reduce((prev, curr) => {
    //       switch (curr.positionType) {
    //         case PositionType.perPiece:
    //           return prev;
    //         case PositionType.fixedPrice:
    //           return prev + curr.fixedPrice.amount;
    //         case PositionType.timeAndMaterial:
    //           return prev + curr.timeAndMaterial.hourlyRate * (curr.totals.totalWorkMinutes / 60);
    //       }
    //     }, 0);
    //
    //     return {
    //       totalWorkedMinutes,
    //       total,
    //       totalExclVat,
    //     };
    //   })
    // );
    this.totalBilled = this.project.pipe(map((project) => project.data.totalBilled ?? 0));
  }

  async modifyPosition(position: Position2) {
    this.dialog.open(PositionSlideOverComponent, {
      data: { project: await firstValueFrom(this.project), position },
    });
  }

  async createWork(position: Position2) {
    const project = await firstValueFrom(this.project);
    this.dialog.open<never, WorkSlideOverInput, WorkSlideOverComponent>(WorkSlideOverComponent, {
      data: {
        project: mapProjectSummary(project),
        customer: project.data.customerId
          ? { id: project.data.customerId, displayName: project.data.customerName! }
          : undefined,
        position: {
          id: position.id,
          displayName: position.name,
          billable: position.billable,
          type: position.type,
        },
      },
    });
  }

  async createPosition() {
    this.dialog.open(PositionSlideOverComponent, {
      data: { project: await firstValueFrom(this.project) },
    });
  }

  async showWork(position: Position2) {
    this.router.navigate(
      ['/project/details/', (await firstValueFrom(this.project)).id, 'activity'],
      { queryParams: { positionId: position.id } }
    );
  }
}
