<app-invoice
  *ngIf="invoice | async as invoice"
  [invoice]="invoice"
  [invoiceForm]="invoiceForm"
  [users]="users"
  [customers]="customers"
  [invoiceSummary]="(invoiceSummary | async)!"
  [invoicePositions]="(invoicePositions | async)!"
  [previewPdf]="previewPdf | async"
  [formInvalid]="formInvalidAndMarkedAsTouched"
  (openImportDialog)="openImportDialog()"
  (saveInvoice)="saveInvoice()"
  (deletedPosition)="cleanUpDeletedPosition($event)"
  (setInvoiceDraft)="setInvoiceDraft()"
  (setInvoiceReady)="setInvoiceReady()"
  (setInvoiceCanceled)="setInvoiceCanceled()"
  (setInvoicePaid)="setInvoicePaid()"
  (setInvoiceSent)="setInvoiceSent()"
  (downloadDocument)="downloadDocument()"
>
</app-invoice>
