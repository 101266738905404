import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TailwindButtonDirective } from '../../../../../components/button/tailwind-button.directive';
import { FormatWorkPipe } from '../../../../../pipes/format-work.pipe';
import { Position2 } from 'commons/dist/entities/position2';

@Component({
  selector: 'app-position-card',
  templateUrl: './position-card.component.html',
  standalone: true,
  imports: [NgIf, NgIconWrapperComponent, TailwindButtonDirective, FormatWorkPipe],
})
export class PositionCardComponent {
  @Input() position!: Position2;

  @Output() modifyPosition = new EventEmitter<Position2>();

  @Output() createWork = new EventEmitter<Position2>();

  @Output() showWork = new EventEmitter<Position2>();
}
