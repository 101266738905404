<form autocomplete="off" novalidate>
  <app-slide-over *ngIf="workForm2" [backdropBlur]="true">
    <app-slide-over-header
      (closeSlideOver)="closeSlideOver()"
      [title]="title"
      [formDirty]="workForm2.dirty"
      #headerComponent
    >
    </app-slide-over-header>

    <app-slide-over-alert
      *ngIf="billed"
      heading="Diese Stunden wurden bereits verrechnet"
      subheading="Um was zu ändern muss die Rechnung storniert werden."
    ></app-slide-over-alert>

    <app-slide-over-content>
      <div class="space-y-6 divide-y">
        <div class="space-y-4 pt-6 px-4 sm:px-6">
          <app-dropdown
            label="Mitarbeiter"
            [formControl]="workForm2.controls.orgaUser"
            [options]="orgaUsers"
          ></app-dropdown>
          <app-dropdown
            label="Projekt"
            [formControl]="workForm2.controls.project"
            [options]="projectSummaries"
          ></app-dropdown>
          <app-dropdown
            label="Position"
            [formControl]="workForm2.controls.position"
            [options]="positionSummaries"
          ></app-dropdown>
        </div>
        <div class="space-y-4 pt-6 pb-5 px-4 sm:px-6">
          <app-date-input label="Datum" [formControl]="workForm2.controls.date"></app-date-input>

          <app-text-input
            label="Beschreibung"
            [formControl]="workForm2.controls.description"
            variant="textarea"
          ></app-text-input>

          <app-work-time-input
            label="Arbeitszeit"
            [formControl]="workForm2.controls.workMinutes"
          ></app-work-time-input>
        </div>
      </div>
    </app-slide-over-content>

    <app-slide-over-footer>
      <app-dropdown-minimal-menu *ngIf="workSlideOver.getMode() === 'edit' && !billed">
        <app-dropdown-button><app-ng-icon name="heroTrash"></app-ng-icon></app-dropdown-button>
        <app-dropdown-item (menuClick)="deleteWork()"> Löschen </app-dropdown-item>
      </app-dropdown-minimal-menu>
      <span class="inline-flex rounded-md shadow-sm">
        <button
          appTailwindButton
          color="secondary"
          type="button"
          (click)="headerComponent.closeSlideOverClicked()"
        >
          Abbrechen
        </button>
      </span>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton [disabled]="billed" type="button" (click)="saveWork()">
          Speichern
        </button>
      </span>
    </app-slide-over-footer>
  </app-slide-over>
</form>
