<div class="flex flex-col gap-5" *ngIf="timer$ | async as timer">
  <div class="bg-white shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Timer</h3>
      <div class="mt-2 sm:flex sm:items-start sm:justify-between">
        <div class="max-w-xl text-sm text-gray-500">
          <p>
            Mit dem Timer kannst du deine Zeit die du arbeitest stoppen und danach als Arbeit unter
            einem Projekt erfassen. Es können mehrere Timer zur gleichen Zeit verwendet werden.
          </p>
        </div>
        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <button
            (click)="addTimer()"
            type="button"
            [disabled]="timer.length > 4"
            appTailwindButton
          >
            Timer starten
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="mx-10 flex flex-col gap-5">
    <div
      class="sm:rounded-lg border border-gray-200 relative overflow-hidden"
      [ngClass]="timerRunner.isRunning ? 'bg-white shadow-xl' : 'bg-gray-100 shadow'"
      *ngFor="let timerRunner of timer; index as i"
    >
      <div class="px-4 py-5 sm:p-6">
        <h3
          class="text-lg leading-6 font-medium text-gray-900 font-mono"
          *ngIf="timerRunner.isRunning; else durationWhenPaused"
        >
          {{ timerRunner.startTime | secondsPassed | formatSeconds }}
        </h3>
        <ng-template #durationWhenPaused>
          <h3 class="text-lg leading-6 font-medium text-gray-900 font-mono">
            {{ timerRunner.cumulatedSeconds | formatSeconds }}
          </h3>
        </ng-template>

        <p class="text-sm text-gray-600">
          {{ timerRunner.creationTime | formatTimestamp }}
        </p>

        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <app-editable-label
            class="w-full"
            [labelText]="timerRunner.description"
            (labelChanged)="updateDescription(i, $event)"
          ></app-editable-label>
          <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 flex sm:items-center gap-3 sm:gap-5">
            <button (click)="delete(i)" type="button" appTailwindButton color="white">
              Löschen
            </button>
            <button
              *ngIf="timerRunner.isRunning; else notRunning"
              (click)="pause()"
              type="button"
              appTailwindButton
            >
              Pause
            </button>
            <ng-template #notRunning>
              <button (click)="start(i)" type="button" appTailwindButton>Start</button>
              <button (click)="createWorkEntry(i)" type="button" appTailwindButton>
                Eintrag erstellen
              </button>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        *ngIf="timerRunner.isRunning"
        class="absolute top-0 bottom-0 left-0 w-1 bg-blue-600"
      ></div>
    </div>
  </div>
</div>
