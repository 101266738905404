import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { TimerWorkListComponent } from '../components/timer-work-list/timer-work-list.component';
import { combineLatest, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStateService } from '../../../services/session-state.service';
import { WorkService } from '../../../services/work.service';
import { switchMap } from 'rxjs/operators';
import { TimerService } from '../../../services/timer.service';
import { FirestoreWork, IsoDate } from 'commons';
import {
  WorkSlideOverComponent,
  WorkSlideOverInput,
} from '../../projects/slide-overs/work-slide-over.component';
import { mapFirestoreTimerToRow } from '../components/timer-work-list/rows/timer-row.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-timer-work-list-page',
  standalone: true,
  imports: [AsyncPipe, TimerWorkListComponent],
  templateUrl: './timer-work-list.page.html',
})
export class TimerWorkListPage {
  sessionState = inject(SessionStateService);
  workService = inject(WorkService);
  dialog = inject(Dialog);
  timerService = inject(TimerService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);

  selectedDate = this.activatedRoute.params.pipe(map((params) => params.selectedDate));

  works = combineLatest([this.sessionState.getOrgaUserId(), this.selectedDate]).pipe(
    switchMap(([orgaUserId, selectedDate]) =>
      this.workService.getAllWorkForUser(orgaUserId, selectedDate, selectedDate)
    )
  );

  timerRows = this.selectedDate.pipe(
    switchMap((selectedDate) => this.timerService.getTimersByDate(selectedDate)),
    map((timers) => timers.map(mapFirestoreTimerToRow))
  );

  createTimer(date: IsoDate) {
    this.timerService.createTimer(date);
  }

  showTimer(timerId: string) {
    this.router.navigate(['timer', timerId], { relativeTo: this.activatedRoute });
  }

  createWorkEntry(date: IsoDate) {
    this.dialog.open<never, WorkSlideOverInput, WorkSlideOverComponent>(WorkSlideOverComponent, {
      data: { date: date },
    });
  }

  async start(timerId: string) {
    await this.timerService.startTimer(timerId);
  }

  async stop() {
    await this.timerService.stopTimer();
  }

  editWork(work: FirestoreWork) {
    this.dialog.open<never, WorkSlideOverInput, WorkSlideOverComponent>(WorkSlideOverComponent, {
      data: { work },
    });
  }
}
