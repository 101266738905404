<app-timer-work-list
  [works]="works | async"
  [timerRows]="timerRows | async"
  [selectedDate]="selectedDate | async"
  (createTimer)="createTimer($event)"
  (showTimer)="showTimer($event)"
  (createWorkEntry)="createWorkEntry($event)"
  (start)="start($event)"
  (stop)="stop()"
  (editWork)="editWork($event)"
>
</app-timer-work-list>
