import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Orga } from 'commons';
import { NgIf } from '@angular/common';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { SettingsCardComponent } from '../settings-card/settings-card.component';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';

@Component({
  selector: 'app-orga-name-card',
  templateUrl: './orga-name-card.component.html',
  standalone: true,
  imports: [
    SettingsCardComponent,
    ReactiveFormsModule,
    InputComponent,
    TailwindInputDirective,
    NgIf,
    TextInputComponent,
  ],
})
export class OrgaNameCardComponent implements OnInit {
  @Input()
  orga!: Orga;

  @Input()
  disabled = false;

  @Output()
  nameChanged = new EventEmitter<Partial<Orga>>();

  nameForm!: FormGroup;

  selectedImageError?: Record<string, any>;

  ngOnInit(): void {
    this.nameForm = new FormGroup({
      name: new FormControl(this.orga.name, [Validators.required, Validators.maxLength(250)]),
      legalName: new FormControl(this.orga.legalName, [Validators.maxLength(250)]),
      logo64: new FormControl(this.orga.logo64),
      logoFileName: new FormControl(this.orga.logoFileName),
    });
    if (this.disabled) {
      this.nameForm.disable();
    }
  }

  onFileSelected(event: Event): void {
    this.selectedImageError = undefined;
    // @ts-ignore
    const file: File = <File>event?.target?.files[0];

    // Check file type and the file size
    if (file && file.type.match(/image\.*/) && file.size <= 100000) {
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        this.nameForm.get('logo64')?.setValue(reader.result as string);
        this.nameForm.get('logoFileName')?.setValue(file?.name);
      };
      reader.readAsDataURL(file);
    } else {
      this.selectedImageError = {
        logoFileRestrictions: 'File must be an image and size should not exceed 100KB!',
      };
    }
  }

  saveSettings() {
    if (!this.nameForm.invalid && !this.selectedImageError) {
      this.nameChanged.emit(this.nameForm.value);
    }
  }
}
