import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FirestoreUser, User } from 'commons';
import { UserService } from '../../../services/user.service';
import { AsyncPipe } from '@angular/common';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { SessionStateService } from '../../../services/session-state.service';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';

@Component({
  selector: 'app-user-onboarding',
  templateUrl: './user-onboarding.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    TailwindInputDirective,
    TailwindButtonDirective,
    RouterLink,
    AsyncPipe,
    TextInputComponent,
  ],
})
export class UserOnboardingComponent implements OnInit {
  userForm!: FormGroup;

  loggedInUserEmail$ = this.sessionState.getUser().pipe(
    filter((user): user is FirestoreUser => !!user),
    map((user) => user.data.email)
  );

  constructor(
    private sessionState: SessionStateService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.userForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    });
  }

  async saveUser(user: User) {
    const userId = await firstValueFrom(
      this.sessionState.getUser().pipe(
        filter((user): user is FirestoreUser => !!user),
        map((user) => user.id)
      )
    );
    await this.userService.update(userId, user);
    // this.sessionState.setUser(
    //   await firstValueFrom(
    //     this.userService
    //       .getUser(userId)
    //       .pipe(filter((user) => !!user.data.lastName || !!user.data.firstName))
    //   )
    // );

    await this.router.navigateByUrl('/');
  }
}
