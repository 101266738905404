import { FirestoreWork, NumberString, Position2, VatRate, Work, WorkEntity2 } from 'commons';
import { InvoicePosition2, InvoiceTotals } from 'commons/dist/entities/invoice2';
import { Big } from 'big.js';
import { compareAsc, compareDesc, parseISO } from 'date-fns';

export function sumWorkMinutesByPosition(workEntities: FirestoreWork[]): {
  [positionId: string]: number;
} {
  return workEntities.reduce(
    (acc, workEntity) => {
      const positionId = workEntity.data.position.id;
      const workMinutes = workEntity.data.workMinutes;
      acc[positionId] = (acc[positionId] || 0) + workMinutes;
      return acc;
    },
    {} as { [positionId: string]: number }
  );
}

export function sumWorkMinutes(workEntitites: WorkEntity2[]): number {
  return workEntitites.reduce((acc, workEntity) => acc + workEntity.workMinutes, 0);
}

export function groupWorkByPosition(
  workEntities: FirestoreWork[]
): Record<string, { works: Work[]; totalWorkMinutes: number }> {
  return workEntities.reduce(
    (acc, workEntity) => {
      const positionId = workEntity.data.position.id;
      const workMinutes = workEntity.data.workMinutes;

      if (!acc[positionId]) {
        acc[positionId] = {
          works: [{ ...workEntity.data, id: workEntity.id }],
          totalWorkMinutes: workMinutes,
        };
      } else {
        acc[positionId].totalWorkMinutes = acc[positionId].totalWorkMinutes + workMinutes;
        acc[positionId].works = [
          ...acc[positionId].works,
          { ...workEntity.data, id: workEntity.id },
        ];
      }

      return acc;
    },
    {} as Record<string, { works: Work[]; totalWorkMinutes: number }>
  );
}

export function calculateTotals(positions: InvoicePosition2[]): InvoiceTotals {
  const netAmount = positions.reduce(
    (acc, cur) => new Big(acc).add(cur.totalPrice ?? 0).toFixed(2),
    '0'
  );
  const vatAcc = positions.reduce(
    (acc, cur) => {
      acc[cur.vatRate] = new Big(acc[cur.vatRate] || 0)
        .add(new Big(cur.totalPrice).mul(new Big(cur.vatRate)))
        .toFixed(2);
      return acc;
    },
    {} as { [vatRate: NumberString]: NumberString }
  );
  const vat = Object.entries(vatAcc).map(([rate, amount]) => ({ rate, amount }));
  const totalVatSum = vat
    .reduce((prev, cur) => prev.add(new Big(cur.amount)), new Big(0))
    .toFixed(2);
  return {
    netAmount,
    vat,
    totalAmount: new Big(netAmount).add(totalVatSum).toFixed(2),
  };
}

function getQuantity(totalWorkMinutes: number) {
  return new Big(totalWorkMinutes ?? 0).div(new Big(60)).toFixed(2).toString();
}

function getTotalPrice(totalWorkMinutes: number, hourlyRate: NumberString) {
  return new Big(totalWorkMinutes ?? 0)
    .div(new Big(60))
    .mul(new Big(hourlyRate ?? 0) ?? 0)
    .toFixed(2)
    .toString();
}

export function mapInvoicePositions(
  positions: Position2[],
  groupedWork: Record<string, { works: Work[]; totalWorkMinutes: number }>,
  vatRates: VatRate
): InvoicePosition2[] {
  return positions.map((position) => ({
    positionId: position.id,
    displayName: position.name,
    unit: position.type === 'fixed' ? 'fixedPrice' : position.type === 'effort' ? 'hours' : 'piece',
    quantity: getQuantity(groupedWork[position.id]?.totalWorkMinutes),
    totalPrice:
      position.type === 'effort'
        ? getTotalPrice(groupedWork[position.id]?.totalWorkMinutes, position.hourlyRate)
        : '0',
    unitPrice: position.type === 'effort' ? position.hourlyRate : null,
    vatRate: vatRates[position.vatRateType] ?? '0.00',
    works: groupedWork[position.id]?.works ?? [],
  }));
}

export function calculateInvoicePosition(
  position: InvoicePosition2,
  works: Work[],
  totalWorkMinutes: number
): InvoicePosition2 {
  return {
    ...position,
    quantity: getQuantity(totalWorkMinutes),
    totalPrice: getTotalPrice(totalWorkMinutes, position.unitPrice ?? '0'),
    works,
  };
}

export function getAllWork(positions: InvoicePosition2[]): Work[] {
  return positions.flatMap((position) => position.works);
}

export function getDeliveryPeriod(positions: InvoicePosition2[]) {
  return {
    from:
      positions
        .map((position) => position.works)
        .flat()
        .sort((a, b) => compareAsc(parseISO(a.date), parseISO(b.date)))[0]?.date ?? null,
    to:
      positions
        .map((position) => position.works)
        .flat()
        .sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)))[0]?.date ?? null,
  };
}
