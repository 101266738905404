import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { OrgaUserService } from '../../../../services/orga-user.service';
import { FirestoreOrga, OrgaUser } from 'commons';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { SlideOverFooterComponent } from '../../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { TailwindInputDirective } from '../../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../../components/input/input.component';
import { SlideOverContentComponent } from '../../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { SlideOverHeaderComponent } from '../../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverComponent } from '../../../../components/slide-overs/slide-over/slide-over.component';
import { SessionStateService } from '../../../../services/session-state.service';
import { DialogComponent } from '../../../../components/dialog/dialog.component';
import { TextInputComponent } from '../../../../components/input/text-input/text-input.component';

@Component({
  selector: 'app-orga-user-slide-over',
  templateUrl: './orga-user-slide-over.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SlideOverComponent,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    InputComponent,
    TailwindInputDirective,
    NgIf,
    SlideOverFooterComponent,
    TailwindButtonDirective,
    AsyncPipe,
    DialogComponent,
    TextInputComponent,
  ],
})
export class OrgaUserSlideOverComponent implements OnInit {
  orgaUserForm!: FormGroup;

  orga$ = this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga));
  orgaUsers$ = this.orgaUserService.getAllOrgaUsers();

  showEmployeeRole!: Observable<boolean>;
  showAccountantRole!: Observable<boolean>;

  @Output()
  cancel = new EventEmitter();
  showConfirmation = false;

  constructor(
    private orgaUserService: OrgaUserService,
    private sessionState: SessionStateService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.showAccountantRole = combineLatest([this.orgaUsers$, this.orga$]).pipe(
      map(([orgaUsers, orga]) => {
        const subscription = orga.data.subscription;
        return (
          !!subscription &&
          ((subscription.plan === 'business' &&
            orgaUsers.filter((orgaUser) => orgaUser.data.role === 'accountant').length < 1) ||
            (subscription.plan === 'enterprise' &&
              orgaUsers.filter((orgaUser) => orgaUser.data.role === 'accountant').length < 3))
        );
      })
    );

    this.showEmployeeRole = combineLatest([this.orgaUsers$, this.orga$]).pipe(
      map(([orgaUsers, orga]) => {
        const subscription = orga.data.subscription;
        return (
          !!subscription &&
          orgaUsers.filter((orgaUser) => orgaUser.data.role !== 'accountant').length <
            subscription.seats
        );
      })
    );
  }

  initForm(): void {
    this.orgaUserForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', [Validators.required]),
    });
  }

  closeSlideOver() {
    this.cancel.emit();
  }

  createOrgaUser(orgaUser: OrgaUser) {
    this.orgaUserService.createOrgaUser(orgaUser).then(() => {
      this.showConfirmation = true;
    });
  }
}
