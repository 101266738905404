<app-settings-card heading="Mehrwertsteuersätze" [showSaveButton]="false">
  <div class="mt-4 rounded-md shadow-sm ring-gray-300 ring-1 bg-white overflow-hidden">
    <div class="relative overflow-auto">
      <table class="min-w-full table-fixed divide-y divide-gray-300 border-b">
        <thead>
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Gültig von
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Gültig bis
            </th>
            <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
              Normalsatz
            </th>
            <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
              Reduziert
            </th>
            <th scope="col" class="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
              Sondersatz
            </th>
          </tr>
        </thead>
        <tbody class="divide-y bg-white">
          <tr *ngFor="let vatRate of vatRates; index as i">
            <td
              class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6"
              [class.font-bold]="i === activeRateIndex"
            >
              {{ vatRate.validFrom | date: 'dd.MM.yyy' }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-900"
              [class.font-bold]="i === activeRateIndex"
            >
              {{ vatRate.validTo | date: 'dd.MM.yyy' }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"
              [class.font-bold]="i === activeRateIndex"
            >
              {{ vatRate.standardRate | formatPercent }}%
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"
              [class.font-bold]="i === activeRateIndex"
            >
              {{ vatRate.reducedRate | formatPercent }}%
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"
              [class.font-bold]="i === activeRateIndex"
            >
              {{ vatRate.specialRate | formatPercent }}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-settings-card>
