import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InvoiceListEntryComponent } from './invoice-list-entry/invoice-list-entry.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FirestoreInvoice } from 'commons/dist/entities/invoice';
import { EmptyStateComponent } from '../../../components/empty/empty-state.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  standalone: true,
  imports: [NgIf, NgFor, InvoiceListEntryComponent, NgClass, EmptyStateComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesComponent {
  @Input() invoices: FirestoreInvoice[] = [];
  @Output() editInvoice = new EventEmitter<FirestoreInvoice>();
}
