import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { NgIconWrapperComponent } from '../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'li[app-pricing-list-item]',
  templateUrl: './pricing-list-item.component.html',
  standalone: true,
  imports: [NgIconWrapperComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingListItemComponent {
  @HostBinding('attr.class')
  hostClass = 'flex gap-x-1 items-center';
}
