import { Component, Input } from '@angular/core';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-position-type-badge',
  templateUrl: './position-type-badge.component.html',
  standalone: true,
  imports: [NgSwitch, NgSwitchCase],
})
export class PositionTypeBadgeComponent {
  @Input()
  positionType!: 'fixed' | 'effort' | 'material';
}
