import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BankConnection, Orga } from 'commons';
import { SettingsCardComponent } from '../settings-card/settings-card.component';
import {
  BankConnectionForm,
  BankConnectionFormComponent,
  getBankConnectionForm,
} from '../../../components/forms/bank-connection-form/bank-connection-form.component';

@Component({
  selector: 'app-orga-banking-card',
  templateUrl: './orga-banking-card.component.html',
  standalone: true,
  imports: [SettingsCardComponent, ReactiveFormsModule, BankConnectionFormComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgaBankingCardComponent implements OnInit {
  @Input()
  orga!: Orga;

  @Input()
  disabled = false;

  @Output()
  bankDetailsChanged = new EventEmitter<Partial<Orga>>();

  bankDetailForm!: FormGroup<BankConnectionForm>;

  ngOnInit(): void {
    this.bankDetailForm = getBankConnectionForm(this.orga.bankDetails);
  }

  saveSettings() {
    if (!this.bankDetailForm.invalid) {
      this.bankDetailsChanged.emit({
        bankDetails: this.bankDetailForm.value as BankConnection,
      });
    }
  }
}
