<ng-container *ngIf="documents | async as documents; else noComments">
  <app-document-card
    *ngIf="documents.length > 0; else noComments"
    cardTitle="Dokumente"
    [disableFilter]="true"
    [disableSearch]="true"
    [documents]="documents"
    (editDocument)="editDocument($event)"
    (deleteDocument)="deleteDocument($event)"
  ></app-document-card>
</ng-container>

<ng-template #noComments>
  <app-empty-state class="flex justify-center mt-[100px]"></app-empty-state>
</ng-template>
