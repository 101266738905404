import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { VatRateDecimal } from 'commons';
import { Big } from 'big.js';

@Pipe({
  name: 'formatPercent',
  standalone: true,
})
export class FormatPercent implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(vatRateDecimal: VatRateDecimal | null, ...args: unknown[]): string {
    if (!vatRateDecimal) {
      return '';
    }
    return formatNumber(
      new Big(vatRateDecimal).mul(new Big('100')).toNumber(),
      this.locale,
      '1.1-1'
    );
  }
}
