<span
  [ngClass]="{
    'rounded-full': style === 'pill',
    'rounded-md': style === 'badge',
    'bg-gray-50 text-gray-600 ring-gray-500/10': color === 'grey',
    'bg-red-50 text-red-700 ring-red-600/10': color === 'red',
    'bg-yellow-50 text-yellow-800 ring-yellow-600/20': color === 'yellow',
    'bg-green-50 text-green-700 ring-green-600/20': color === 'green',
    'bg-blue-50 text-blue-700 ring-blue-700/10': color === 'blue',
    'bg-indigo-50 text-indigo-700 ring-indigo-700/10': color === 'indigo',
    'bg-purple-50 text-purple-700 ring-purple-700/10': color === 'purple',
    'bg-pink-50 text-pink-700 ring-pink-700/10': color === 'pink',
    'px-2 py-1 text-xs': size === 'small',
    'px-3 py-2 text-sm': size === 'large'
  }"
  class="inline-flex items-center font-medium ring-1 ring-inset whitespace-nowrap">
  <ng-content></ng-content>
</span>
