import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { ArchiveFolder, FirestoreArchive } from 'commons';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { DropdownItemComponent } from '../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownButtonComponent } from '../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../../../components/input/input.component';
import { NgFor, NgIf } from '@angular/common';
import { SettingsCardComponent } from '../settings-card/settings-card.component';

@Component({
  selector: 'app-document-folder-card',
  templateUrl: './document-folder-card.component.html',
  standalone: true,
  imports: [
    SettingsCardComponent,
    NgIf,
    CdkDropList,
    NgFor,
    CdkDrag,
    InputComponent,
    ReactiveFormsModule,
    TailwindInputDirective,
    FormsModule,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    NgIconWrapperComponent,
    DropdownItemComponent,
    TailwindButtonDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentFolderCardComponent {
  @Input()
  archive!: FirestoreArchive;

  @Input()
  disabled = false;

  @Output()
  updateArchiveFolders = new EventEmitter<{
    folders: ArchiveFolder[];
    folderSort: string[];
  }>();

  folders!: ArchiveFolder[];
  folderSort!: string[];
  editId: string | null = null;

  saveSettings() {
    this.updateArchiveFolders.emit({
      folders: this.folders,
      folderSort: this.folderSort,
    });
  }

  addNewFolder() {
    const id = crypto.randomUUID();
    this.folders.push({
      name: 'neuer Ordner',
      active: true,
      id,
      documentCount: 0,
      sharedDocumentCount: 0,
    });
    this.folderSort.unshift(id);
    this.sort();
    this.saveSettings();
  }

  drop(dragDrop: CdkDragDrop<any>) {
    moveItemInArray(this.folderSort, dragDrop.previousIndex, dragDrop.currentIndex);
    this.sort();
    this.saveSettings();
  }

  private sort() {
    this.folders.sort((a, b) => this.folderSort.indexOf(a.id) - this.folderSort.indexOf(b.id));
  }

  deleteFolder(id: string) {
    const index = this.folders.findIndex((value) => value.id === id);
    if (index > -1) {
      this.folders.splice(index, 1);
    }
    const sortIndex = this.folderSort.indexOf(id);
    if (index > -1) {
      this.folderSort.splice(sortIndex, 1);
    }
    this.sort();
    this.saveSettings();
  }

  ngOnChanges() {
    this.folders = this.archive.data.folders ? Object.values(this.archive.data.folders) : [];
    this.folderSort = this.archive.data.folderSort ?? [];
    this.sort();
  }

  editFolder(id: string | null) {
    this.editId = id;
    if (id === null) {
      this.saveSettings();
    }
  }

  folderState(id: string, active: boolean) {
    const folder = this.folders.find((folder) => folder.id === id);
    if (folder) {
      folder.active = active;
    }
    this.saveSettings();
  }
}
