<div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
  <app-text-input
    class="sm:col-span-6"
    label="Name der Bank"
    [formControl]="bankConnectionFormGroup.controls.name"
  >
  </app-text-input>

  <app-text-input
    class="sm:col-span-2"
    label="Plz"
    [formControl]="bankConnectionFormGroup.controls.zipCode"
  >
  </app-text-input>
  <app-text-input
    class="sm:col-span-4"
    label="Ort"
    [formControl]="bankConnectionFormGroup.controls.city"
  >
  </app-text-input>
  <app-text-input
    class="sm:col-span-6"
    label="BIC (SWIFT)"
    [formControl]="bankConnectionFormGroup.controls.bic"
  >
  </app-text-input>
  <app-text-input
    class="sm:col-span-6"
    label="IBAN Nummer"
    [formControl]="bankConnectionFormGroup.controls.iban"
  >
  </app-text-input>
  <app-text-input
    class="sm:col-span-6"
    label="QR IBAN Nummer"
    [formControl]="bankConnectionFormGroup.controls.qrIban"
  >
  </app-text-input>
</div>
