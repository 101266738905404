import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { ExpenseDocument, FirestoreDocument, FirestoreEntity, RevenueDocument } from 'commons';
import { BehaviorSubject, interval, Observable, startWith, switchMap } from 'rxjs';
import { DocumentService } from '../../../services/document.service';
import { FinanceRow, FinanceTotal } from '../expense-revenue/expense-revenue.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { DropdownItemComponent } from '../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DropdownButtonComponent } from '../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { AsyncPipe, DatePipe, DecimalPipe, NgFor, NgIf } from '@angular/common';
import { orderBy, where } from '@angular/fire/firestore';
import { InvoiceService } from '../../../services/invoice.service';
import { Dialog } from '@angular/cdk/dialog';
import { DocumentSlideOverComponent } from '../../document/document-slide-over/document-slide-over.component';

@Component({
  selector: 'app-outstanding-payments',
  templateUrl: './outstanding-payments.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgIconWrapperComponent,
    NgFor,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    DropdownItemComponent,
    EmptyComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutstandingPaymentsComponent {
  private documents$: Observable<FirestoreDocument[]>;
  total$: Observable<FinanceTotal>;
  rows$: Observable<FinanceRow[]>;
  currentDate$: Observable<Date>;
  sortDate = new BehaviorSubject<'asc' | 'desc'>('desc');

  constructor(
    private documentService: DocumentService,
    private invoiceService: InvoiceService,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.documents$ = this.sortDate.pipe(
      switchMap((sortDate) =>
        this.documentService.getAllDocuments(
          where('paid', '==', false),
          orderBy('valueDate', sortDate)
        )
      )
    );

    this.rows$ = this.documents$.pipe(
      switchMap((documents) => this.currentDate$.pipe(map(() => documents))),
      map((documents) =>
        documents
          .filter(
            (document): document is FirestoreEntity<RevenueDocument | ExpenseDocument> =>
              document.data.type === 'expense' || document.data.type === 'revenue'
          )
          .map((doc) => {
            const document = doc.data;
            return {
              document: doc,
              valueDate: (document.valueDate && new Date(document.valueDate)) || undefined,
              legalEntityDisplayName:
                document.linkedEntities &&
                Object.values(document.linkedEntities)
                  .filter((entitites) =>
                    ['customer-company', 'customer-person'].includes(entitites.entity)
                  )
                  .reduce((prev, cur) => prev + cur.name + ' ', ''),
              description: document.name,
              revenue: document.type === 'revenue' ? document.amountInCents / 100 : undefined,
              expense: document.type === 'expense' ? document.amountInCents / 100 : undefined,
              overdue: document.valueDate < new Date().toISOString(),
            };
          })
      )
    );

    this.total$ = this.documents$.pipe(
      map((documents) =>
        documents.reduce(
          (prev, cur) => {
            prev.revenue += cur.data.type === 'revenue' ? cur.data.amountInCents : (undefined ?? 0);
            prev.expense += cur.data.type === 'expense' ? cur.data.amountInCents : (undefined ?? 0);
            return prev;
          },
          {
            revenue: 0,
            expense: 0,
          }
        )
      ),
      map((totals) => ({ revenue: totals.revenue / 100, expense: totals.expense / 100 }))
    );

    this.currentDate$ = interval(60000).pipe(
      map(() => new Date()),
      startWith(new Date())
    );
  }

  edit(document: FirestoreDocument) {
    this.dialog.open(DocumentSlideOverComponent, { data: { documents: [document] } });
  }

  sortDateToggle() {
    if (this.sortDate.value === 'desc') {
      this.sortDate.next('asc');
    } else {
      this.sortDate.next('desc');
    }
  }

  async markAsPaid(document: FirestoreDocument) {
    await this.documentService.updateDocument(document.id, { paid: true });
    const invoice = document.data.linkedEntities
      ? Object.values(document.data.linkedEntities).filter(
          (entitites) => entitites.entity === 'invoice'
        )[0]
      : null;
    if (invoice) {
      await this.invoiceService.updateInvoice(invoice.id, { invoiceState: 'paid' });
    }
  }
}
