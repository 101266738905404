<div class="-my-2 md:-mx-6 lg:-mx-8">
  <div class="py-8 align-middle inline-block min-w-full md:px-6 lg:px-8">
    <div class="shadow">
      <table class="min-w-full divide-y divide-gray-200 table-auto">
        <thead>
          <tr>
            <th
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              Datum
            </th>
            <th
              class="md:hidden px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            ></th>
            <th
              *ngIf="columns.includes('project')"
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              Projekt
            </th>
            <th
              *ngIf="columns.includes('position')"
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              Position
            </th>
            <th
              *ngIf="columns.includes('user')"
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              Person
            </th>
            <th
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              Beschreibung
            </th>
            <th
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            >
              Stunden
            </th>
            <th colspan="3" class="hidden md:table-cell bg-gray-50"></th>
            <th colspan="2" class="md:hidden bg-gray-50"></th>
          </tr>
        </thead>
        <tfoot class="sticky bottom-0 z-10">
          <tr>
            <th
              class="px-6 py-3 bg-gray-50 text-left text-sm leading-4 font-bold text-gray-900 uppercase tracking-wider"
            >
              Total
            </th>
            <th
              *ngIf="columns.includes('project')"
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            ></th>
            <th
              *ngIf="columns.includes('position')"
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            ></th>
            <th
              *ngIf="columns.includes('user')"
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            ></th>
            <th
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            ></th>
            <th
              class="hidden md:table-cell px-6 py-3 bg-gray-50 text-right text-sm leading-4 font-bold text-gray-900 uppercase tracking-wider"
            >
              {{ sum | formatWork }}
            </th>
            <th
              class="hidden md:px-3 md:py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
            ></th>
            <th
              class="px-6 md:py-3 bg-gray-50 text-right text-sm leading-4 font-bold text-gray-900 uppercase tracking-wider"
            >
              <span class="md:hidden">{{ sum | formatWork }}</span>
            </th>
            <th class="bg-gray-50"></th>
          </tr>
        </tfoot>
        <tbody class="bg-white divide-y divide-gray-200 align-top">
          <tr *ngFor="let work of works">
            <td
              class="hidden md:table-cell px-6 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900"
            >
              {{ work.data.date | date }}
            </td>
            <td class="md:hidden px-6 py-2 text-sm leading-5 text-gray-600">
              <ul>
                <li class="font-medium text-gray-900">
                  {{ work.data.date | date }}
                  <span
                    *ngIf="work.data.invoice"
                    class="ml-4 inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
                    >Verrechnet #{{ work.data.invoice.invoiceNumber }}</span
                  >
                </li>
                <li *ngIf="columns.includes('project')">{{ work.data.project.displayName }}</li>
                <li *ngIf="columns.includes('position')">{{ work.data.position.displayName }}</li>
                <li class="text-xs">{{ work.data.description }}</li>
                <li class="flex mt-2 items-center">
                  <app-ng-icon name="heroUserMini"></app-ng-icon
                  >{{ work.data.orgaUser.displayName }}
                </li>
              </ul>
            </td>
            <td
              *ngIf="columns.includes('project')"
              class="hidden md:table-cell px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500"
            >
              {{ work.data.project.displayName }}
            </td>
            <td
              class="hidden md:table-cell px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500"
            >
              {{ work.data.position.displayName }}
            </td>
            <td
              *ngIf="columns.includes('user')"
              class="hidden md:table-cell px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500"
            >
              {{ work.data.orgaUser.displayName }}
            </td>
            <td class="hidden md:table-cell px-6 py-4 text-sm leading-5 text-gray-500">
              {{ work.data.description }}
            </td>
            <td
              class="table-cell px-6 py-4 whitespace-nowrap text-sm text-right leading-5 text-gray-500 align-middle md:align-top"
            >
              {{ work.data.workMinutes | formatWork }}
            </td>
            <td
              class="hidden md:table-cell px-6 py-3 whitespace-nowrap text-sm text-center leading-5 text-gray-500"
            >
              <span
                *ngIf="work.data.invoice"
                class="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700"
                >Verrechnet #{{ work.data.invoice.invoiceNumber }}</span
              >
            </td>

            <td class="align-middle">
              <app-dropdown-minimal-menu class="justify-end">
                <app-dropdown-button>
                  <app-ng-icon
                    name="heroEllipsisVerticalSolid"
                    class="text-3xl"
                    strokeWidth="3"
                  ></app-ng-icon>
                </app-dropdown-button>
                <app-dropdown-item (menuClick)="modifyWork.emit(work)">
                  Bearbeiten
                </app-dropdown-item>
                <app-dropdown-item *ngIf="!work.data.invoice" (menuClick)="deleteWork.emit(work)">
                  Löschen</app-dropdown-item
                >
              </app-dropdown-minimal-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
