<app-container *ngIf="project | async as project" [subpage]="true">
  <app-header-meta-action
    [title]="project.data.name"
    [subTitle]="project.data.customerName"
    [subTitleLink]="'/customer/' + project.data.customerId"
  >
    <div class="flex items-center gap-4">
      <app-favorite
        [favorite]="project.data.favorite"
        (stateChange)="favoriteChanged($event)"
      ></app-favorite>
      <button appTailwindButton color="secondary" (click)="editProject()">
        Projekt bearbeiten
      </button>
      <button appTailwindButton color="secondary" (click)="createInvoice()">
        Rechnung erstellen
      </button>
      <button appTailwindButton (click)="createWork()">Stunden erfassen</button>
    </div>
  </app-header-meta-action>

  <div class="mt-4">
    <app-routed-tab-group>
      <a
        appRoutedTab
        [active]="structure.isActive"
        [routerLink]="['structure']"
        [replaceUrl]="true"
        routerLinkActive
        #structure="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        >Projektstruktur</a
      >
      <a
        appRoutedTab
        [active]="activity.isActive"
        [routerLink]="['activity']"
        [replaceUrl]="true"
        routerLinkActive
        #activity="routerLinkActive"
        >Stunden</a
      >
      <a
        appRoutedTab
        [active]="invoice.isActive"
        [routerLink]="['invoice']"
        [replaceUrl]="true"
        routerLinkActive
        #invoice="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        >Rechnungen</a
      >
      <!--      <a-->
      <!--        appRoutedTab-->
      <!--        [active]="document.isActive"-->
      <!--        [routerLink]="['documents']"-->
      <!--        [replaceUrl]="true"-->
      <!--        routerLinkActive-->
      <!--        #document="routerLinkActive"-->
      <!--        [routerLinkActiveOptions]="{ exact: true }"-->
      <!--        >Dokumente <app-badge class="ml-2" color="green">Coming Soon</app-badge></a-->
      <!--      -->
      <a
        appRoutedTab
        [active]="comments.isActive"
        [routerLink]="['comments']"
        [replaceUrl]="true"
        routerLinkActive
        #comments="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        *appHideWhenNotInPlan="['business', 'enterprise']"
        >Kommentare <small>({{ project.data.comments?.count ?? 0 }})</small></a
      ><a
        appRoutedTab
        [active]="history.isActive"
        [routerLink]="['history']"
        [replaceUrl]="true"
        routerLinkActive
        #history="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        *appHideWhenNotInPlan="['business', 'enterprise']"
        >Änderungen</a
      >
    </app-routed-tab-group>
    <div class="pt-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</app-container>
