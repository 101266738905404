<app-container [subpage]="true">
  <app-header-meta-action
    [title]="'Rechnung Nr. ' + invoice.data.invoiceNumber"
    [subTitle]="invoice.data.project.displayName"
  >
    <app-invoice-state-badge [invoiceState]="invoice.data.invoiceState"></app-invoice-state-badge>
    <button
      *ngIf="invoiceForm.controls.invoiceState.value !== 'canceled'"
      appTailwindButton
      color="secondary"
      (click)="setInvoiceCanceled.emit()"
    >
      Rechnung stornieren
    </button>
    <button
      *ngIf="invoiceForm.controls.invoiceState.value === 'draft'"
      appTailwindButton
      (click)="setInvoiceReady.emit()"
      class="relative"
    >
      Fertigstellen
    </button>
    <button
      *ngIf="invoiceForm.controls.invoiceState.value === 'ready'"
      appTailwindButton
      color="secondary"
      (click)="setInvoiceDraft.emit()"
    >
      Editieren
    </button>
    <button
      *ngIf="invoiceForm.controls.invoiceState.value === 'ready'"
      appTailwindButton
      color="primary"
      (click)="setInvoiceSent.emit()"
    >
      Rechnung versendet
    </button>

    <button
      *ngIf="invoiceForm.controls.invoiceState.value === 'sent'"
      appTailwindButton
      color="primary"
      (click)="setInvoicePaid.emit()"
    >
      Rechnung bezahlt
    </button>
    <button
      type="button"
      *ngIf="invoiceForm.controls.invoiceState.value !== 'draft'"
      class="text-blue-600 hover:text-blue-900 transition ease-in-out duration-150"
      (click)="downloadDocument.emit()"
    >
      <app-ng-icon class="text-3xl" name="heroDocumentArrowDown"> </app-ng-icon>
    </button>
  </app-header-meta-action>

  <app-alert
    *ngIf="false"
    class="md:col-span-6 w-full"
    heading="Eine oder mehrere Buchungen wurden bereits fakturiert"
    subheading="Lösche die betreffende Position und erstelle sie neu. Damit werden diese betroffenen Buchungen weggefiltert."
  ></app-alert>

  <app-tab-group (tabChanged)="saveInvoice.emit()">
    <ng-template appTab="Rechnungsinhalt">
      <app-invoice-content
        [invoiceForm]="invoiceForm"
        [users]="users"
        [customers]="customers"
        [invoicePositions]="invoicePositions"
        [invoiceSummary]="invoiceSummary"
        (importPositions)="openImportDialog.emit()"
        (deletedPosition)="deletedPosition.emit($event)"
        [isDisabled]="this.invoiceForm.controls.invoiceState.value !== 'draft'"
      ></app-invoice-content>
    </ng-template>
    <!--    <ng-template appTab="Stundenrapport">-->
    <!--      <app-badge class="mt-4" color="green">Coming Soon</app-badge>-->

    <!--      <app-work-table [columns]="['position', 'user']" [works]="[]"></app-work-table>-->
    <!--    </ng-template>-->
    <ng-template appTab="Vorschau" *ngIf="invoiceForm.controls.invoiceState.value === 'draft'">
      <app-invoice-preview
        *ngIf="previewPdf as previewPdf"
        [src]="previewPdf"
      ></app-invoice-preview>
    </ng-template>
    <ng-template appTab="Rechnung" *ngIf="invoiceForm.controls.invoiceState.value !== 'draft'">
      <app-invoice-preview
        *ngIf="previewPdf as previewPdf"
        [src]="previewPdf"
      ></app-invoice-preview>
    </ng-template>
    <ng-template appTab="Kommentare <small>({{ invoice.data.comments?.count ?? 0 }})</small>">
      <div class="flex w-full justify-center">
        <app-comment-section
          *ngIf="invoice as invoice"
          class="w-full"
          [parentEntity]="invoice"
        ></app-comment-section>
      </div>
    </ng-template>
  </app-tab-group>
</app-container>
