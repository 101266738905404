import { Component, ContentChild, HostListener, Input, OnInit } from '@angular/core';
import { SlideOverHeaderComponent } from './slide-over-header/slide-over-header.component';
import { NgIconWrapperComponent } from '../../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';
import { CdkTrapFocus } from '@angular/cdk/a11y';

@Component({
  selector: 'app-slide-over',
  templateUrl: './slide-over.component.html',
  standalone: true,
  imports: [NgIf, NgIconWrapperComponent, CdkTrapFocus],
})
export class SlideOverComponent implements OnInit {
  @Input()
  backdropBlur = false;

  @Input()
  collabsibleSlideover = false;

  @ContentChild(SlideOverHeaderComponent)
  header!: SlideOverHeaderComponent;

  // just a hack to a problem with 100vh in mobile safari https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
  innerHeight = 0;
  slideOverVisible = true;

  @HostListener('window:resize')
  resize() {
    this.innerHeight = window.innerHeight;
  }

  // @HostListener('document:keydown.escape', ['$event'])
  // onEscKeydownHandler() {
  //   if (this.header) {
  //     this.header.closeSlideOverClicked();
  //   }
  // }

  ngOnInit() {
    this.innerHeight = window.innerHeight;
    this.slideOverVisible = this.collabsibleSlideover ? window.innerWidth > 450 : true;
  }

  toggle() {
    this.slideOverVisible = !this.slideOverVisible;
    // setTimeout(() => this.pdfViewerComponent?.updateSize(), 10);
  }
}
