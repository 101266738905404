import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { FormatWorkPipe } from '../../../../../pipes/format-work.pipe';
import { NgIf } from '@angular/common';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { FirestoreTimer, IsoDate } from 'commons';
import { FormatSecondsPipe } from '../../../../../pipes/format-seconds.pipe';
import { SecondsPassedPipe } from '../../../../../pipes/seconds-passed.pipe';
import { isToday, parseISO } from 'date-fns';
import { TippyDirective } from '@ngneat/helipopper';

@Component({
  selector: 'tr[app-timer-row]',
  standalone: true,
  imports: [
    FormatWorkPipe,
    NgIconWrapperComponent,
    NgIf,
    FormatSecondsPipe,
    SecondsPassedPipe,
    TippyDirective,
  ],
  templateUrl: './timer-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host td:not(first-child) {
      @apply cursor-pointer;
    }
  `,
})
export class TimerRowComponent {
  @Input({ required: true }) timer!: TimerRowEntity;
  @Output() showTimer = new EventEmitter<string>();
  @Output() start = new EventEmitter<string>();
  @Output() stop = new EventEmitter();

  @HostListener('click', ['$event']) onClick(): void {
    this.showTimer.emit(this.timer.timerId);
  }
}

export interface TimerRowEntity {
  timerId: string;
  timerState: 'active' | 'inactive';
  startTime?: IsoDate;
  outdated: boolean;
  projectDisplayName?: string;
  positionDisplayName?: string;
  description?: string;
  workMinutes: number;
}

export function mapFirestoreTimerToRow(fsTimer: FirestoreTimer): TimerRowEntity {
  return {
    timerId: fsTimer.id,
    startTime: fsTimer.data.timerState === 'active' ? fsTimer.data.startTime : undefined,
    outdated: !isToday(parseISO(fsTimer.data.date)),
    timerState: fsTimer.data.timerState,
    projectDisplayName:
      fsTimer.data.workItemState === 'embedded'
        ? fsTimer.data.work.project?.displayName
        : undefined,
    positionDisplayName:
      fsTimer.data.workItemState === 'embedded'
        ? fsTimer.data.work.position?.displayName
        : undefined,
    description:
      fsTimer.data.workItemState === 'embedded' ? fsTimer.data.work.description : undefined,
    workMinutes: fsTimer.data.workItemState === 'embedded' ? fsTimer.data.work.workMinutes ?? 0 : 0,
  };
}
