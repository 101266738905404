import { Injectable } from '@angular/core';
import { TDocumentDefinitions, TFontDictionary } from 'pdfmake/interfaces';
import pdfMake from 'pdfmake';

export const FONTS: TFontDictionary = {
  Inter: {
    normal: `${window.location.origin}/assets/fonts/Inter_18pt-Regular.ttf`,
    bold: `${window.location.origin}/assets/fonts/Inter_18pt-Medium.ttf`,
    italics: `${window.location.origin}/assets/fonts/Inter_18pt-Italic.ttf`,
    bolditalics: `${window.location.origin}/assets/fonts/Inter_18pt-MediumItalic.ttf`,
  },
};

@Injectable({ providedIn: 'root' })
export class PDFService {
  private pdfMake!: pdfMake;

  constructor() {}

  private async loadPDFMaker() {
    // if (!this.pdfMake) {
    //   this.pdfMake = await import('pdfmake/build/pdfmake');
    // }
  }

  async createDoc(def: TDocumentDefinitions) {
    await this.loadPDFMaker();

    // @ts-ignore
    const pdfGenerator = pdfMake.createPdf(def, undefined, FONTS);
    const pdfKitDocument: PDFKit.PDFDocument = await new Promise((resolve, reject) => {
      // @ts-ignore
      pdfGenerator.getStream({}, (document) => {
        resolve(document);
      });
    });
    return pdfKitDocument;
  }

  async createUint8Array(pdfKitDocument: PDFKit.PDFDocument) {
    const chunks = await new Promise<Uint8Array[]>((resolve, reject) => {
      const chunks: Uint8Array[] = [];
      pdfKitDocument.on('data', (chunk) => chunks.push(chunk));
      pdfKitDocument.on('end', () => {
        resolve(chunks);
      });
      pdfKitDocument.end();
    });
    // fixme samuel, der umweg über blob ist wohl nicht nötig
    return new Uint8Array(await new Blob(chunks, { type: 'application/pdf' }).arrayBuffer());
  }

  downloadPdf(blob: Blob) {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'generated_pdf.pdf';
    link.click();
    URL.revokeObjectURL(url);
  }
}
