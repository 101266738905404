import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { ToggleButtonGroupComponent } from '../toggle-button-group.component';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ToggleButtonComponent {
  @Input()
  value!: string;

  disabled = false;

  @HostBinding('class.checked')
  isChecked = false;

  constructor(
    @Optional() @Inject(ToggleButtonGroupComponent) private toggleGroup: ToggleButtonGroupComponent,
    private cdr: ChangeDetectorRef
  ) {}

  buttonClick() {
    if (this.disabled) {
      return;
    }
    this.toggleGroup.setValue(this.value);
  }

  setDisabled(isDisabled: boolean) {
    this.disabled = isDisabled;
    //this.cdr.markForCheck();
  }

  markForCheck() {
    this.cdr.markForCheck();
  }
}
