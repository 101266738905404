import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appRoutedTab]',
  standalone: true,
})
export class TabDirective {
  @HostBinding('class') get classes() {
    return this.active
      ? 'border-blue-500 text-blue-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm';
  }

  @Input()
  active = false;
}
