import { Component } from '@angular/core';
import { WorkViewComponent } from '../components/work/work-view.component';

@Component({
  selector: 'app-work',
  templateUrl: './work.page.html',
  standalone: true,
  imports: [WorkViewComponent],
})
export class WorkPage {}
