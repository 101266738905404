<div class="flex flex-col md:flex-row items-end md:justify-end gap-4 flex-wrap">
  <app-simple-pill
    *ngIf="showFilter.includes('project')"
    label="Projekt"
    [filter]="filter.project"
    [filterValues]="projectFilterValues"
    (filterChanged)="projectChanged($event)"
  ></app-simple-pill>
  <app-simple-pill
    *ngIf="showFilter.includes('position') && filter.project"
    label="Position"
    [filter]="filter.position"
    [filterValues]="positionFilterValues"
    (filterChanged)="positionChanged($event)"
  ></app-simple-pill>
  <app-simple-pill
    *ngIf="showFilter.includes('user')"
    label="Mitarbeiter"
    [filter]="filter.user"
    [filterValues]="userFilterValues"
    (filterChanged)="userChanged($event)"
  ></app-simple-pill>
  <app-date-filter-pill
    [filter]="filter.date"
    (filterChanged)="dateChanged($event)"
  ></app-date-filter-pill>
</div>
