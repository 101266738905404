import { Component } from '@angular/core';
import { MenuEntry } from '../components/shell/menuEntry';
import { map, Observable } from 'rxjs';

import { RouterOutlet } from '@angular/router';
import {
  createUserDisplayName,
  createUserInitials,
  FirestoreOrga,
  FirestoreUser,
  IsoDate,
} from 'commons';
import { filter } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { DarkNavShellComponent } from '../components/shell/dark-nav-shell.component';
import { SessionStateService } from '../services/session-state.service';
import { TimerService } from '../services/timer.service';

@Component({
  selector: 'app-navigation-shell',
  templateUrl: './navigation-shell.component.html',
  standalone: true,
  imports: [DarkNavShellComponent, RouterOutlet, AsyncPipe],
})
export class NavigationShellComponent {
  navMenuEntries: MenuEntry[] = [
    {
      routerLink: '',
      displayName: 'Home',
    },
    {
      routerLink: '/project',
      displayName: 'Projekte',
    },
    {
      routerLink: '/customer',
      displayName: 'Firmen und Personen',
    },
    {
      routerLink: '/finance',
      displayName: 'Finanzen',
    },
    {
      routerLink: '/document',
      displayName: 'Dokumente',
    },
  ];
  profileMenuEntries: MenuEntry[] = [
    {
      routerLink: '/settings',
      displayName: 'Einstellungen',
    },
    {
      routerLink: '/user/orga',
      displayName: 'Organisation wechseln',
    },
    {
      routerLink: '/user/logout',
      displayName: 'Ausloggen',
    },
  ];
  navMenuAccountantEntries: MenuEntry[] = [
    {
      routerLink: '/document',
      displayName: 'Dokumente',
    },
  ];
  profileMenuAccountantEntries: MenuEntry[] = [
    {
      routerLink: '/user/orga',
      displayName: 'Organisation wechseln',
    },
    {
      routerLink: '/user/logout',
      displayName: 'Ausloggen',
    },
  ];

  userEmailAddress$: Observable<string> | undefined;
  userDisplayName$: Observable<string> | undefined;
  userInitials$: Observable<string> | undefined;
  navMenu$: Observable<MenuEntry[]> | undefined;
  profileMenu$: Observable<MenuEntry[]> | undefined;
  orgaName$: Observable<string> | undefined;
  orgaId$: Observable<string> | undefined;
  schemaVersion$: Observable<number | undefined>;
  timerStartTime: Observable<IsoDate | null>;
  timerUrl: Observable<string | null>;

  constructor(
    private sessionState: SessionStateService,
    private timerService: TimerService
  ) {
    this.timerStartTime = this.timerService
      .getActiveTimer()
      .pipe(
        map((timer) => (timer && timer.data.timerState === 'active' ? timer.data.startTime : null))
      );
    this.timerUrl = this.timerService
      .getActiveTimer()
      .pipe(
        map((timer) =>
          timer && timer.data.timerState === 'active'
            ? `calendar/${timer.data.date}/timer/${timer.id}`
            : null
        )
      );

    const user = this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user));

    this.userDisplayName$ = user.pipe(
      map((fsUser) => {
        if (fsUser.data.firstName && fsUser.data.lastName) {
          return createUserDisplayName(fsUser.data.firstName, fsUser.data.lastName);
        } else {
          return 'Bitte setze deinen Namen in den Einstellungen';
        }
      })
    );

    this.userInitials$ = user.pipe(
      filter((fsUser) => !!fsUser.data.firstName && !!fsUser.data.lastName),
      map((settings) => createUserInitials(settings.data.firstName, settings.data.lastName))
    );

    this.navMenu$ = this.sessionState.getOrgaUserRole().pipe(
      map((role) => {
        if (role === 'accountant') {
          return this.navMenuAccountantEntries;
        } else {
          return this.navMenuEntries;
        }
      })
    );

    this.profileMenu$ = this.sessionState.getOrgaUserRole().pipe(
      map((role) => {
        if (role === 'accountant') {
          return this.profileMenuAccountantEntries;
        } else {
          return this.profileMenuEntries;
        }
      })
    );

    this.userEmailAddress$ = user.pipe(map((user) => user.data.email));
    const orga = this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga));
    this.orgaName$ = orga.pipe(map((orga) => orga.data?.name));
    this.schemaVersion$ = orga.pipe(map((orga) => orga.data?.schemaVersion));
    this.orgaId$ = this.sessionState.getOrgaId();
  }
}
