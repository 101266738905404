import { Component, OnInit } from '@angular/core';
import { filter, first, switchMap } from 'rxjs/operators';
import { SystemService } from '../../services/system.service';
import { OrgaService } from '../../services/orga.service';
import { Router } from '@angular/router';
import { Observable, shareReplay } from 'rxjs';
import { FirestoreOrga } from 'commons';
import { LastEditorService } from '../../services/last-editor.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../services/session-state.service';

@Component({
  selector: 'app-update-schema',
  templateUrl: './update-schema.component.html',
  standalone: true,
  imports: [NgIf, AsyncPipe],
})
export class UpdateSchemaComponent implements OnInit {
  orga!: Observable<FirestoreOrga>;

  constructor(
    private sessionState: SessionStateService,
    private router: Router,
    private orgaService: OrgaService,
    private systemService: SystemService,
    private lastEditor: LastEditorService
  ) {}

  ngOnInit() {
    this.orga = this.sessionState.getOrga().pipe(
      filter((orga): orga is FirestoreOrga => !!orga),
      switchMap((orga) => this.orgaService.getOrga(orga.id))
    );
    const isSchemaVersionCorrect = this.orga.pipe(
      switchMap((orga) =>
        this.systemService.checkIfSchemaVersionIsCorrect(orga.data.schemaVersion)
      ),
      shareReplay(1)
    );

    isSchemaVersionCorrect
      .pipe(
        filter((isCorrect) => isCorrect),
        first()
      )
      .subscribe(() => this.router.navigateByUrl('/'));

    isSchemaVersionCorrect
      .pipe(
        filter((isCorrect) => !isCorrect),
        first(),
        switchMap(() => this.orga),
        filter((orga) => !!orga && (!orga.data.migration || orga.data.migration === 'done')),
        first()
      )
      .subscribe(async (orga) => {
        await this.orgaService.update(
          orga.id,
          await this.lastEditor.hydrate({ migration: 'requested' })
        );
      });
  }
}
