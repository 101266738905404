import { inject, Injectable } from '@angular/core';
import {
  Auth,
  getIdTokenResult,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut,
  User,
  user,
  UserCredential,
} from '@angular/fire/auth';
import { actionCodeSettings } from '../../environments/environment';
import { FirebaseError } from 'firebase/app';
import { BehaviorSubject, combineLatest, firstValueFrom, takeUntil, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FirestoreUser, Permission } from 'commons';
import { isEqual } from 'lodash';
import { SignInResults } from './session.service';
import { SessionStateService } from './session-state.service';
import { UserService } from './user.service';
import { LOCAL_STORAGE } from 'ngx-webstorage-service';

const SESSION_ORGANISATION_ID = 'SESSION_ORGANISATION_ID';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth = inject(Auth);
  private sessionStateService = inject(SessionStateService);
  private userService = inject(UserService);
  private localStore = inject(LOCAL_STORAGE);

  async bootstrapAlreadyLoggedInUser() {
    const currentUser = await firstValueFrom(user(this.auth));
    if (currentUser) {
      await this.setupUser(currentUser.uid);
    }
  }

  async signInEmail(email: string, sendEmailLink: boolean = false) {
    if (sendEmailLink) {
      await sendSignInLinkToEmail(this.auth, email, { ...actionCodeSettings });
    }
    window.localStorage.setItem('emailForSignIn', email);
  }

  async signInWithUrl(url: string): Promise<SignInResults> {
    const signInEmail = window.localStorage.getItem('emailForSignIn');

    if (!signInEmail) {
      return 'NoEmail';
    }
    if (!(await isSignInWithEmailLink(this.auth, url))) {
      return 'NoValidSignInUrl';
    }

    let userCredential: UserCredential;
    try {
      userCredential = await signInWithEmailLink(this.auth, signInEmail, url);
    } catch (error) {
      const code = error instanceof FirebaseError ? error.code : 'unknown error';
      switch (code) {
        case 'auth/expired-action-code':
          return 'ExpiredLink';
        case 'auth/invalid-email':
          return 'InvalidEmail';
        case 'auth/user-disabled':
          return 'UserDisabled';
      }
      console.error('unknown error code while signInWithEmailLink', error);
      throw error;
    }

    if (userCredential.user?.uid) {
      await this.setupUser(userCredential.user.uid);
    } else {
      throw new Error('Something bad happend while sign in');
    }
    return 'SignInDone';
  }

  private async setupUser(authUserUid: string) {
    window.localStorage.removeItem('emailForSignIn');

    const authUser = await firstValueFrom(
      user(this.auth).pipe(filter((user): user is User => !!user))
    );

    const firestoreUser = await firstValueFrom(
      this.sessionStateService.getUser().pipe(filter((user): user is FirestoreUser => !!user))
    );
    const storedPermissions = this.userService.getPermission(firestoreUser.id);

    let claimPermissions = new BehaviorSubject<Permission | null>(null);

    const permissionsLoadedAndChecked = combineLatest([storedPermissions, claimPermissions]).pipe(
      filter(([stored, claim]) => isEqual(stored, claim))
    );

    timer(500, 2000)
      .pipe(takeUntil(permissionsLoadedAndChecked))
      .subscribe(async () => {
        const idToken = await getIdTokenResult(authUser, true);
        claimPermissions.next(idToken.claims.permission as Permission);
      });

    // wait until permission (the document permission) in firebase are identical with the ones in the claim
    await firstValueFrom(permissionsLoadedAndChecked);

    // only one orga -> direct set orga (no choosing dialog)
    const orgas = await firstValueFrom(this.sessionStateService.getOrganisations());
    if (
      orgas &&
      Object.keys(orgas).length === 1 &&
      Object.values(orgas)[0].role !== 'not authorized by jessie'
    ) {
      await this.setLoggedInOrgaId(Object.keys(orgas)[0]);
    } else {
      //check if an orga is set in localStore
      const savedOrga = this.localStore.get(SESSION_ORGANISATION_ID);
      if (savedOrga && orgas && orgas[savedOrga]) {
        await this.setLoggedInOrgaId(savedOrga);
      }
    }
  }

  async signOut() {
    await signOut(this.auth);
    this.sessionStateService.signOut();
  }

  async setLoggedInOrgaId(orgaId: string) {
    this.localStore.set(SESSION_ORGANISATION_ID, orgaId);
    this.sessionStateService.setOrgaId(orgaId);
  }

  getLogggedInUser() {
    return user(this.auth);
  }
}
