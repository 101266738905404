import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DecimalPipe, NgIf } from '@angular/common';
import { InvoiceEntity } from 'commons/dist/entities/invoice';
import { FirestoreEntity } from 'commons';
import { InvoiceStateBadgeComponent } from '../../../invoice/components/invoice-state-badge/invoice-state-badge.component';
import { BadgeComponent } from '../../../../components/badge/badge.component';

@Component({
  selector: 'li[app-invoice-list-entry]',
  templateUrl: './invoice-list-entry.component.html',
  standalone: true,
  imports: [NgIf, DecimalPipe, InvoiceStateBadgeComponent, BadgeComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceListEntryComponent {
  @Input()
  invoice!: FirestoreEntity<Partial<InvoiceEntity>>;

  @Output()
  editInvoice = new EventEmitter<FirestoreEntity<Partial<InvoiceEntity>>>();

  @Output()
  deleteInvoice = new EventEmitter<FirestoreEntity<Partial<InvoiceEntity>>>();

  @Output()
  downloadInvoice = new EventEmitter<FirestoreEntity<Partial<InvoiceEntity>>>();
}
