<app-form-input-wrapper [control]="formControl">
  <div
    class="rounded-md shadow-sm ring-gray-300 ring-1 bg-white overflow-hidden"
    [class.!bg-gray-50]="isDisabled"
    [ngClass]="{ 'ring-red-400 focus:ring-red-400 focus:ring-2': errors | async }"
  >
    <div class="relative overflow-auto">
      <table class="min-w-full table-fixed divide-y divide-gray-300 border-b">
        <thead>
          <tr>
            <th *ngIf="mode === 'selecting'" scope="col" class="relative px-7 sm:w-12 sm:px-6"></th>
            <th
              scope="col"
              class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
              [class.pl-3]="mode === 'sorting'"
            >
              Position
            </th>
            <th scope="col" class="px-3 py-3.5 text-sm text-right font-semibold text-gray-900">
              Menge
            </th>
            <th scope="col" class="px-3 py-3.5 text-sm text-left font-semibold text-gray-900">
              Einheit
            </th>
            <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right">
              Preis/Einheit
            </th>
            <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right">
              MwSt.
            </th>
            <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right">
              Summe
            </th>
            <th
              *ngIf="mode === 'sorting'"
              scope="col"
              class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right w-8"
            ></th>
          </tr>
        </thead>
        <tbody class="divide-y">
          <ng-container *ngFor="let position of _invoicePositions">
            <ng-container [ngSwitch]="position.unit">
              <tr
                app-time-material-row
                [showCheckboxes]="mode === 'selecting'"
                *ngSwitchCase="'hours'"
                [position]="position"
                [isChecked]="value?.positionsSort?.includes(position.positionId) ?? false"
                [isDisabled]="isDisabled"
                (positionSelected)="positionSelected(position, $event)"
                (deletePosition)="deleteRow($event)"
              ></tr>
              <tr
                app-fixed-price-row
                *ngSwitchCase="'fixedPrice'"
                [position]="position"
                [isDisabled]="isDisabled"
                [showCheckboxes]="mode === 'selecting'"
                (deletePosition)="deleteRow($event)"
              ></tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
      <table
        class="table-fixed mt-8 ml-auto"
        *ngIf="invoiceSummary"
        [class.mr-[32px]]="mode === 'sorting'"
      >
        <tbody>
          <tr>
            <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-700 tabular-nums">
              Zwischentotal
            </td>
            <td
              class="whitespace-nowrap px-3 font-medium text-sm text-gray-700 text-right tabular-nums"
            >
              {{ invoiceSummary.netAmount | formatAmount }}
            </td>
          </tr>
          <tr *ngFor="let vat of invoiceSummary.vat">
            <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-500 tabular-nums">
              {{ vat.rate | formatPercent }}% MwSt
            </td>
            <td
              class="whitespace-nowrap px-3 text-sm font-medium text-gray-500 text-right tabular-nums"
            >
              {{ vat.amount | formatAmount }}
            </td>
          </tr>
          <tr>
            <td class="whitespace-nowrap px-3 text-sm font-medium text-gray-900 tabular-nums">
              Total inkl. MwSt
            </td>
            <td
              class="whitespace-nowrap px-3 py-2 text-sm font-medium text-gray-900 text-right tabular-nums"
            >
              {{ invoiceSummary.totalAmount | formatAmount }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-form-input-wrapper>
