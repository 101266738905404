import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgIf } from '@angular/common';
import { NgIconWrapperComponent } from '../../../icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'app-slide-over-header',
  templateUrl: './slide-over-header.component.html',
  styles: [
    `
      :host {
        @apply sticky top-0 z-10;
      }
    `,
  ],
  standalone: true,
  imports: [NgIconWrapperComponent, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideOverHeaderComponent {
  @Input()
  title!: string;
  @Input()
  leadText!: string;
  @Input()
  formDirty = false;

  @Output()
  closeSlideOver = new EventEmitter();

  @ViewChild('headerTitle', { read: ElementRef }) headerTitle!: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  showConfirmation = false;

  closeSlideOverClicked() {
    console.log('hans');
    if (this.formDirty) {
      this.showConfirmation = true;
      this.cdr.markForCheck();
    } else {
      this.closeSlideOver.emit();
    }
  }
}
