import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TailwindInputDirective } from '../../../../../components/input/tailwind-input.directive';
import { BadgeComponent } from '../../../../../components/badge/badge.component';
import { NgIf } from '@angular/common';
import { PositionTypeBadgeComponent } from '../../../../projects/components/positions/position-type-badge/position-type-badge.component';
import { InvoicePosition2 } from 'commons/dist/entities/invoice2';
import { NumberInputComponent } from '../../../../../components/input/number-input/number-input.component';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'tr[app-fixed-price-row]',
  standalone: true,
  imports: [
    TailwindInputDirective,
    BadgeComponent,
    NgIf,
    PositionTypeBadgeComponent,
    NumberInputComponent,
    NgIconWrapperComponent,
  ],
  templateUrl: './fixed-price-row.component.html',
})
export class FixedPriceRowComponent {
  @Input() position!: InvoicePosition2;
  @Input() showCheckboxes = false;
  @Input() isDisabled = false;

  @Output() deletePosition = new EventEmitter<InvoicePosition2>();

  @HostBinding('class.bg-gray-50') clazz = true;
}
