import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormInputWrapperComponent } from '../form-input-wrapper/form-input-wrapper.component';
import { NgForOf } from '@angular/common';

export interface RadioButtonOptions {
  value: string;
  label: string;
  description: string;
}

@Component({
  selector: 'app-radio-button-group',
  standalone: true,
  imports: [FormsModule, FormInputWrapperComponent, NgForOf, ReactiveFormsModule],
  templateUrl: './radio-button-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonGroupComponent),
      multi: true,
    },
  ],
  styles: `
    :host {
      @apply block;
    }
  `,
})
export class RadioButtonGroupComponent implements ControlValueAccessor {
  @Input() label?: string;
  @Input() options!: RadioButtonOptions[];

  @Input() formControl!: FormControl;
  @Input() isDisabled = false;

  value: string | null = null;

  onChange: (value: string | null) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: string | null) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  // Handle the radio change event
  selectValue(value: any) {
    if (!this.isDisabled) {
      this.value = value;
      this.onChange(value); // Notify Angular forms of the change
      this.onTouched(); // Mark the control as touched
    }
  }

  // Check if a radio button should be selected
  isSelected(value: any) {
    return this.value === value;
  }
}
