<div class="min-h-screen flex flex-col">
  <div class="flex-grow">
    <nav
      class="bg-gray-800 print:hidden fixed inset-x-0 top-0 z-50 shadow-gray-500 transition-all"
      [class.shadow-md]="isScrolled"
    >
      <div class="max-w-screen-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <a class="relative flex-shrink-0" [routerLink]="['/']" [relativeTo]="null">
              <img class="h-8" src="../../../assets/jessie-logo.png" alt="Jessie logo" />
            </a>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline gap-1">
                <a
                  [routerLink]="menuEntry.routerLink"
                  class="px-3 py-2 rounded-md text-sm text-gray-300 font-medium focus:outline-none focus:text-white focus:bg-gray-700"
                  routerLinkActive
                  #rla="routerLinkActive"
                  [ngClass]="rla.isActive ? 'text-white bg-gray-600' : 'text-gray-300'"
                  *ngFor="let menuEntry of navMenuEntries"
                  >{{ menuEntry.displayName }}</a
                >
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <a [routerLink]="'/home/timer'" class="text-gray-300 text-xs font-mono">
                {{ timerRunningTime }}
              </a>

              <button
                *ngIf="false"
                class="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
                aria-label="Notifications"
              >
                <app-ng-icon name="heroBell" class="text-xl"></app-ng-icon>
              </button>

              <div class="ml-3 relative">
                <div>
                  <button
                    class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    (click)="toggleProfileMenu()"
                  >
                    <span
                      *ngIf="userInitials; else noInitials"
                      class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500"
                    >
                      <span class="font-medium leading-none text-white uppercase">{{
                        userInitials
                      }}</span>
                    </span>
                    <ng-template #noInitials>
                      <span class="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                        <app-ng-icon
                          class="text-gray-600 text-[32px]"
                          name="heroQuestionMarkCircle"
                        ></app-ng-icon>
                      </span>
                    </ng-template>
                  </button>
                </div>
                <div
                  *ngIf="profileMenuOpen"
                  class="fixed inset-0 z-50"
                  (click)="toggleProfileMenu()"
                ></div>

                <div
                  *ngIf="profileMenuOpen"
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white z-50"
                >
                  <div class="px-4 py-3" role="none">
                    <p class="text-sm text-gray-900 truncate" role="none">
                      {{ orgaName }}
                    </p>
                    <p class="text-xs text-gray-400 truncate" role="none">
                      {{ orgaId }}
                    </p>
                    <p class="text-xs text-gray-400 truncate" role="none">v{{ schemaVersion }}.0</p>
                  </div>
                  <div
                    class="py-1 rounded-md ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                  >
                    <a
                      [routerLink]="menuEntry.routerLink"
                      (click)="profileMenuOpen = !profileMenuOpen"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      *ngFor="let menuEntry of profileMenuEntries"
                      >{{ menuEntry.displayName }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <button
              (click)="toggleMobileMenu()"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            >
              <app-ng-icon
                class="text-2xl"
                [ngClass]="mobileMenuOpen ? 'hidden' : 'block'"
                name="heroBars3"
              ></app-ng-icon>
              <!-- Menu open: "block", Menu closed: "hidden" -->
              <app-ng-icon
                class="text-2xl"
                [ngClass]="mobileMenuOpen ? 'block' : 'hidden'"
                name="heroXMark"
              ></app-ng-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="md:hidden" [ngClass]="mobileMenuOpen ? 'block' : 'hidden'">
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <a
            [routerLink]="menuEntry.routerLink"
            (click)="mobileMenuOpen = !mobileMenuOpen"
            class="block px-3 py-2 rounded-md text-base font-medium focus:outline-none focus:text-white focus:bg-gray-700"
            routerLinkActive
            #rla="routerLinkActive"
            [ngClass]="rla.isActive ? 'text-white bg-gray-900' : 'text-gray-300'"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngFor="let menuEntry of navMenuEntries"
            >{{ menuEntry.displayName }}</a
          >
        </div>
        <div class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <span
                *ngIf="userInitials; else noInitials"
                class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500"
              >
                <span class="font-medium leading-none text-white uppercase">{{
                  userInitials
                }}</span>
              </span>
              <ng-template #noInitials>
                <span class="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                  <app-ng-icon
                    class="text-gray-600 text-[40px]"
                    name="heroQuestionMarkCircle"
                  ></app-ng-icon>
                </span>
              </ng-template>
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">
                {{ userDisplayName }}
              </div>
              <div class="mt-1 text-sm font-medium leading-none text-gray-400">
                {{ userEmailAddress }}
              </div>
              <div class="mt-1 text-sm leading-none text-gray-400">
                {{ orgaName }}
              </div>
            </div>
          </div>
          <div class="mt-3 px-2">
            <a
              [routerLink]="menuEntry.routerLink"
              (click)="mobileMenuOpen = !mobileMenuOpen"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              *ngFor="let menuEntry of profileMenuEntries"
              >{{ menuEntry.displayName }}</a
            >
          </div>
          <div class="text-center text-[9px] text-gray-400">{{ orgaId }}</div>
        </div>
      </div>
    </nav>

    <ng-content></ng-content>
  </div>
</div>
