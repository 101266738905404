<div class="shadow sm:rounded-lg flex flex-col bg-white divide-y divide-gray-200">
  <div class="flex flex-row self-center">
    <app-month-calendar
      [month]="months[0]"
      [work]="work"
      (selectedDateChange)="selectedDateChange.emit($event)"
      [selectedDate]="selectedDate"
      [warningDays]="warningDays"
      class="hidden 2xl:block"
    ></app-month-calendar>
    <app-month-calendar
      [month]="months[1]"
      [work]="work"
      (selectedDateChange)="selectedDateChange.emit($event)"
      [selectedDate]="selectedDate"
      [warningDays]="warningDays"
      class="hidden md:block"
    ></app-month-calendar>
    <app-month-calendar
      [month]="months[2]"
      [work]="work"
      (selectedDateChange)="selectedDateChange.emit($event)"
      [selectedDate]="selectedDate"
      [warningDays]="warningDays"
    ></app-month-calendar>
  </div>

  <div class="sm:rounded-b-lg">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="absolute top-0 right-0 m-8 grid grid-cols-2 gap-2">
  <button
    aria-label="calendar backward"
    (click)="previousMonth()"
    class="hover:text-gray-400 text-gray-800 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    <app-ng-icon class="text-xl" name="heroChevronLeft"></app-ng-icon>
  </button>

  <button
    aria-label="calendar forward"
    (click)="nextMonth()"
    class="hover:text-gray-400 text-gray-800 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    <app-ng-icon class="text-xl" name="heroChevronRight"></app-ng-icon>
  </button>
</div>
