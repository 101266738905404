<app-document-card
  *ngIf="documents$ | async as documents"
  cardTitle="Inbox"
  [documents]="documents"
  [updateAccountingRelevantEnabled]="true"
  (searchValueChanged)="searchValue.next($event)"
  (typeFilterChanged)="typeFilter.next($event)"
  [typeFilter]="typeFilter | async"
  [enableShowAll]="limitRows.value && documents.length >= 10"
  (showAll)="limitRows.next(false)"
  (editDocument)="editDocument($event)"
  (deleteDocument)="deleteDocument($event)"
></app-document-card>
<div class="flex justify-center text-xs mt-2 text-gray-500">
  <span class="flex items-center gap-1">
    <app-ng-icon class="text-lg" name="heroLightBulb"></app-ng-icon>
    <p>Dokumente können auch via drag and drop hinzugefügt werden.</p></span
  >
</div>
