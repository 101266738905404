import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { SessionStateService } from '../../../services/session-state.service';
import { WorkViewComponent } from '../../projects/components/work/work-view.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-my-work-page',
  standalone: true,
  imports: [WorkViewComponent, AsyncPipe],
  templateUrl: './my-work.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyWorkPage {
  orgaUserId = this.sessionState.getOrgaUserId();
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private sessionState: SessionStateService
  ) {}

  async ngOnInit() {}
}
