import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';
import { SessionStateService } from '../../services/session-state.service';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styles: [
    `
      :host {
        @apply flex;
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, NgIconWrapperComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteComponent implements OnInit, OnChanges {
  @Input({ required: true }) favorite: Record<string, boolean> = {};
  // todo do not use userId for favorites - instead orgaUserId -> migration necessary
  @Input({ required: true }) userId!: string;
  @Output() stateChange = new EventEmitter<boolean>();

  activated = false;

  constructor(private sessionState: SessionStateService) {}

  async ngOnInit() {
    this.setCurrentValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setCurrentValue();
  }

  setCurrentValue() {
    this.activated = !!this.favorite && this.userId in this.favorite && this.favorite[this.userId];
  }

  click() {
    this.stateChange.emit(!this.activated);
  }
}
