<div
  class="flex w-60 rounded-full border bg-white py-1 pr-1 text-gray-400 relative justify-between h-[34px]"
  [ngClass]="filter ? 'border-green-600 text-gray-600' : 'border-gray-300'"
>
  <div *ngIf="filter?.displayName" class="ml-4 text-gray-600 pr-4 truncate">
    {{ filter?.displayName }}
  </div>
  <div *ngIf="!filter?.displayName" class="ml-4 font-light">{{ label }}</div>
  <button (click)="resetFilter()" *ngIf="filter; else noFilter">
    <app-ng-icon class="text-2xl" strokeWidth="1.5" name="heroXCircle"></app-ng-icon>
  </button>
  <ng-template #noFilter>
    <app-dropdown-minimal-menu class="mr-1">
      <app-dropdown-button>
        <app-ng-icon class="text-xl" strokeWidth="1.5" name="heroFunnel"></app-ng-icon>
      </app-dropdown-button>
      <app-dropdown-item *ngFor="let val of filterValues" (menuClick)="enableFilter(val)"
        >{{ val.displayName }}
      </app-dropdown-item>
    </app-dropdown-minimal-menu>
  </ng-template>
</div>
