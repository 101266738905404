<div
  class="fixed inset-0 overflow-hidden z-50 flex justify-end bg-red"
  cdkTrapFocus="true"
  cdkTrapFocusAutoCapture="true"
  [class.backdrop-blur-sm]="backdropBlur"
>
  <!--  (keydown.escape)="toggle(); $event.stopPropagation()"-->
  <div class="relative flex-1" *ngIf="collabsibleSlideover">
    <ng-content select="app-center-content"></ng-content>
    <button
      class="absolute bottom-0 right-0 h-[70px] w-10 inline-flex items-center justify-center border border-transparent font-medium shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      (click)="toggle(); $event.preventDefault(); $event.stopPropagation()"
      *ngIf="!slideOverVisible"
    >
      <app-ng-icon class="text-2xl" name="heroChevronLeft"></app-ng-icon>
    </button>
  </div>
  <div
    class="relative w-screen md:w-96 xl:w-1/3 2xl:w-1/4 3xl:w-1/5"
    [style.height.px]="innerHeight"
    *ngIf="slideOverVisible"
  >
    <div class="inset-0 h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div class="flex-1 flex flex-col min-h-0">
        <ng-content select="app-slide-over-header"></ng-content>
        <ng-content select="app-slide-over-alert"></ng-content>
        <div class="relative flex-1 min-h-0">
          <ng-content select="app-slide-over-content"></ng-content>
        </div>
      </div>
      <ng-content select="app-slide-over-footer"></ng-content>
    </div>
    <button
      class="absolute bottom-0 left-0 h-[70px] w-10 inline-flex items-center justify-center border border-transparent font-medium shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      (click)="toggle(); $event.preventDefault(); $event.stopPropagation()"
      *ngIf="collabsibleSlideover"
    >
      <app-ng-icon class="text-2xl" name="heroChevronRight"></app-ng-icon>
    </button>
  </div>
</div>
