import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { detailedDiff } from 'deep-object-diff';

import { saveAs } from 'file-saver';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { format } from 'date-fns';
import {
  Archive,
  ArchiveFolder,
  Document,
  DocumentType,
  FirestoreArchive,
  FirestoreDocument,
  formatFileName,
} from 'commons';
import { sanitize } from '../util/file-sanitize';
import { LastEditorService } from './last-editor.service';
import { FIRESTORE_SERVICE, ORGA_FIRESTORE_SERVICE } from './firestore.service';
import {
  deleteField,
  doc,
  Firestore,
  limit,
  orderBy,
  QueryConstraint,
  runTransaction,
  where,
} from '@angular/fire/firestore';
import { flattenObject } from './helper';
import { Storage } from '@angular/fire/storage';
import JSZip from 'jszip';
import { SessionStateService } from './session-state.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);

  private lastEditor = inject(LastEditorService);
  private http = inject(HttpClient);

  firestore = inject(Firestore);
  storage = inject(Storage);

  sessionState = inject(SessionStateService);

  getArchive(): Observable<FirestoreArchive> {
    return this.orgaFirestoreService.getDoc<Archive>('archive/archive');
  }

  getFolders() {
    return this.getArchive().pipe(
      map((firestoreArchive) =>
        Object.values(firestoreArchive.data.folders)
          .filter((folder) => folder.active)
          .sort(
            (a, b) =>
              firestoreArchive.data.folderSort.indexOf(a.id) -
              firestoreArchive.data.folderSort.indexOf(b.id)
          )
      )
    );
  }

  async updateArchiveFolders(folderStuff: { folders: ArchiveFolder[]; folderSort: string[] }) {
    const orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    const archiveRef = doc(this.firestore, `orga/${orgaId}/archive/archive`);
    const localFolders = folderStuff.folders.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr }),
      {}
    );

    await runTransaction(this.firestore, async (transaction) => {
      const archive = await transaction.get(archiveRef);
      const serverFolders = archive.data()?.['folders'];
      if (serverFolders) {
        // @ts-ignore
        const { added, deleted, updated } = detailedDiff(serverFolders, localFolders);
        Object.keys(deleted).forEach((k) => {
          if (serverFolders[k].documentCount > 0) {
            throw new Error('deleted folder has a document count on server');
          }
        });
        const updatedFolders = {
          ...flattenObject(added, 'folders'),
          ...flattenObject(updated, 'folders'),
          ...Object.fromEntries(
            Object.entries(flattenObject(deleted, 'folders')).map(([key]) => [key, deleteField()])
          ),
        };
        transaction.update(
          archiveRef,
          await this.lastEditor.hydrate({
            ...updatedFolders,
            folderSort: folderStuff.folderSort,
          })
        );
      } else {
        transaction.update(
          archiveRef,
          await this.lastEditor.hydrate({
            folders: localFolders,
            folderSort: folderStuff.folderSort,
          })
        );
      }

      transaction.update(archiveRef, { as: 'sdf' });
    });
  }

  getAllDocuments(...queryConstraints: QueryConstraint[]): Observable<FirestoreDocument[]> {
    return this.orgaFirestoreService.getDocs<Document>(
      'archive/archive/document',
      ...queryConstraints
    );
  }

  getDocument(documentId: string): Observable<FirestoreDocument> {
    return this.orgaFirestoreService.getDoc(`archive/archive/document/${documentId}`);
  }

  getDocumentsInArchive(
    archiveId: string | null,
    accountingRelevantOnly: boolean,
    typeFilter: DocumentType[],
    limitRows: number = 0
  ): Observable<FirestoreDocument[]> {
    const queryConstraints: QueryConstraint[] = [];
    queryConstraints.push(
      archiveId ? where('folder.id', '==', archiveId) : where('folder', '==', null)
    );
    if (limitRows) {
      queryConstraints.push(limit(limitRows));
    }
    if (accountingRelevantOnly) {
      queryConstraints.push(where('accounting.relevant', '==', true));
    }
    if (typeFilter.length > 0) {
      queryConstraints.push(where('type', 'in', typeFilter));
    } else {
      queryConstraints.push(where('type', 'in', ['sentinel']));
    }
    queryConstraints.push(orderBy('documentDate', 'desc'));
    return this.orgaFirestoreService.getDocs<Document>(
      'archive/archive/document',
      ...queryConstraints
    );
  }

  getDocumentsNotProcessedByAccountant() {
    return this.orgaFirestoreService.getDocs<Document>(
      'archive/archive/document',
      where('accounting.relevant', '==', true),
      where('accounting.processed', '==', false),
      orderBy('documentDate', 'desc')
    );
  }

  getDocumentsWithNotReactedComments(userId: string, rows?: number) {
    const queryConstraints: QueryConstraint[] = [
      where('comments.notReactedCount.' + userId, '>', 0),
    ];
    if (rows) {
      queryConstraints.push(limit(rows));
    }
    return this.orgaFirestoreService.getDocs<Document>(
      'archive/archive/document',
      ...queryConstraints
    );
  }

  async updateDocument(documentId: string, document: Partial<Document>): Promise<void> {
    const updateDocument: Partial<Document> = { ...document };
    switch (document.type) {
      case 'expense':
      case 'revenue':
        // @ts-ignore
        updateDocument.paid = !!updateDocument.paid;
        break;
      case 'other':
        // @ts-ignore
        updateDocument.paid = deleteField();
        // @ts-ignore
        updateDocument.amountInCents = deleteField();
        // @ts-ignore
        updateDocument.valueDate = deleteField();
        break;
    }
    return this.orgaFirestoreService.updateDoc<Document>(
      `archive/archive/document/${documentId}`,
      await this.lastEditor.hydrate(updateDocument)
    );
  }

  async uploadDocument(file: File) {
    const docRef = await this.orgaFirestoreService.createDoc(
      'archive/archive/document',
      await this.lastEditor.hydrate({
        type: 'other',
        folder: null,
        accounting: {
          relevant: false,
          processed: false,
        },
        comment: null,
        documentDate: new Date().toISOString(),
        unreadBy: [],
      })
    );

    const metadata: any = {
      customMetadata: {
        documentId: docRef.id,
        docRef: docRef.path,
        documentAiDisabled: false, //!environment.production,
      },
    };
    const plan = await firstValueFrom(this.sessionState.getOrgaSubscriptionPlan());
    if (plan === 'starter') {
      metadata.customMetadata.documentAiDisabled = true;
    }

    const uploadResult = await this.orgaFirestoreService.uploadFile(
      file,
      `archive/archive/document/${docRef.id}`,
      metadata
    );

    await this.rootFirestoreService.updateDoc(
      docRef.path,
      await this.lastEditor.hydrate({
        filePath: uploadResult.metadata.fullPath,
        name: formatFileName(uploadResult.metadata.name),
        fileName: uploadResult.metadata.name,
        documentAiState: !metadata.customMetadata.documentAiDisabled ? 'processing' : 'no result',
      })
    );
  }

  async deleteDocument(document: FirestoreDocument) {
    await this.rootFirestoreService.updateDoc<Document>(
      document.docRef.path,
      // @ts-ignore
      await this.lastEditor.hydrate({})
    );
    await this.rootFirestoreService.deleteDoc(document.docRef.path);
  }

  getDocumentUrl(doc: Document) {
    return this.rootFirestoreService.getDownloadURL(doc.filePath);
  }

  getDocumentMetaData(doc: Document) {
    return this.rootFirestoreService.getMetadata(doc.filePath);
  }

  async downloadDocument(document: FirestoreDocument) {
    saveAs(
      await this.downloadOneDocument(document),
      sanitizeFilename(document.data.name, document.data.fileName)
    );
  }

  private async downloadOneDocument(document: FirestoreDocument) {
    const filePath = document.data.filePath;
    const downloadUrl = await this.rootFirestoreService.getDownloadURL(filePath);
    return firstValueFrom(this.http.get(downloadUrl, { responseType: 'blob' }));
  }

  async downloadAll(documents: FirestoreDocument[]) {
    const downloadBatch = documents.map(async (document) => ({
      blob: await this.downloadOneDocument(document),
      document,
    }));
    const blobs = await Promise.all(downloadBatch);
    const zipFile = new JSZip();

    blobs.forEach((file) => {
      let filename = sanitizeFilename(file.document.data.name, file.document.data.fileName);
      let index = 1;
      let isFileInZip = !!zipFile.file(filename);
      while (isFileInZip) {
        filename =
          '(' +
          index +
          ') ' +
          sanitizeFilename(file.document.data.name, file.document.data.fileName);
        isFileInZip = !!zipFile.file(filename);
        index++;
      }

      zipFile.file(filename, file.blob);
    });
    const zibBlob = await zipFile.generateAsync({ type: 'blob' });
    saveAs(zibBlob, 'jessie-export-' + format(new Date(), 'yyyy-MM-dd-HH:mm:ss'));
  }

  async restartDocumentAi(document: FirestoreDocument) {
    await this.updateDocument(document.id, { documentAiState: 'reprocess' });
  }
}

function sanitizeFilename(documentName: string, filename: string) {
  const fileEnding = filename.substring(filename.lastIndexOf('.'));
  let sanitizedName = sanitize(documentName, '');
  if (!sanitizedName.endsWith(fileEnding)) {
    sanitizedName = sanitizedName + fileEnding;
  }
  return sanitizedName;
}
