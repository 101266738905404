import { Directive, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appTailwindButton]',
  standalone: true,
})
export class TailwindButtonDirective implements OnChanges, OnInit {
  @Input()
  color: ButtonType = 'primary';

  @Input()
  size: ButtonSize = 'm';

  @HostBinding('class') classes = '';

  ngOnChanges(changes: SimpleChanges): void {
    this.bindClasses();
  }

  ngOnInit(): void {
    this.bindClasses();
  }

  bindClasses() {
    var tempClasses = '';

    switch (this.color) {
      case 'primary':
        tempClasses = PRIMARY;
        break;
      case 'secondary':
        tempClasses = SECONDARY;
        break;
      case 'white':
        tempClasses = WHITE;
        break;
      case 'link':
        tempClasses = LINK;
        break;
      case 'circular':
        tempClasses = CIRCULAR;
        break;
      case 'delete':
        tempClasses = DELETE;
        break;
    }

    switch (this.size) {
      case 'xs':
        tempClasses = tempClasses + ' ' + XS;
        break;
      case 'm':
        tempClasses = tempClasses + ' ' + M;
        break;
    }

    this.classes = tempClasses;
  }
}

export type ButtonType = 'primary' | 'secondary' | 'white' | 'link' | 'circular' | 'delete';
export type ButtonSize = 'xs' | 'm';

const PRIMARY =
  'inline-flex items-center border border-transparent font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 disabled:hover:bg-blue-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50';
const SECONDARY =
  'inline-flex items-center border border-transparent font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50';
const WHITE =
  'inline-flex items-center border border-gray-300 shadow-sm font-medium rounded-md text-gray-800 bg-white hover:bg-gray-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50';
const DELETE =
  'text-center border border-transparent shadow-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50';

const LINK =
  'rounded-md font-medium text-blue-600 hover:text-blue-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500';
const CIRCULAR =
  'rounded-full bg-blue-600 p-2 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600';

const XS = 'px-2.5 py-1.5 text-xs';
const M = 'px-4 py-2 text-sm';
