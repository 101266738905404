import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { PositionSlideOverService } from '../positions/position-slide-over/position-slide-over.component';
import { firstValueFrom, Observable, shareReplay } from 'rxjs';
import { FirestoreProject, mapProjectSummary } from 'commons';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContainerComponent } from '../../../../components/container/container.component';
import { HeaderMetaActionComponent } from '../../../../components/container/header-meta-action/header-meta-action.component';
import { FavoriteComponent } from '../../../../components/favorite/favorite.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { TabGroupComponent } from '../../../../components/tabs/routed/tab-group.component';
import { TabDirective } from '../../../../components/tabs/routed/tab.directive';
import { HideWhenNotInPlanDirective } from '../../../../directives/hide-when-not-in-plan.directive';
import { ProjectService } from '../../../../services/project.service';
import { ProjectSlideOverService } from '../../slide-overs/project-slide-over.component';
import { WorkSlideOverService } from '../../slide-overs/work-slide-over.component';
import { Invoice2Service } from '../../../../services/invoice2.service';
import { BadgeComponent } from '../../../../components/badge/badge.component';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  standalone: true,
  imports: [
    NgIf,
    ContainerComponent,
    HeaderMetaActionComponent,
    FavoriteComponent,
    TailwindButtonDirective,
    TabGroupComponent,
    TabDirective,
    RouterLinkActive,
    RouterLink,
    HideWhenNotInPlanDirective,
    RouterOutlet,
    AsyncPipe,
    BadgeComponent,
  ],
})
export class ProjectViewComponent {
  project: Observable<FirestoreProject>;

  constructor(
    public route: ActivatedRoute,
    private projectService: ProjectService,
    private projectSlideOver: ProjectSlideOverService,
    private positionSlideOver: PositionSlideOverService,
    private workSlideOver: WorkSlideOverService,
    private invoiceService: Invoice2Service,
    private router: Router
  ) {
    this.project = this.projectService
      .getProject(this.route.snapshot.params['projectId'])
      .pipe(shareReplay(1));
  }

  async editProject() {
    this.projectSlideOver.edit(await firstValueFrom(this.project));
  }

  async createPosition() {
    const project = await firstValueFrom(this.project);
    this.positionSlideOver.new({
      project,
    });
  }

  async favoriteChanged(activated: boolean) {
    const project = await firstValueFrom(this.project);
    this.projectService.toggleFavorite(project, activated);
  }

  async createInvoice() {
    const invoiceId = await this.invoiceService.createInvoice(await firstValueFrom(this.project));

    this.router.navigate(['invoice', invoiceId], {
      relativeTo: this.route,
    });
  }

  async createWork() {
    const project = await firstValueFrom(this.project);
    this.workSlideOver.new({
      project: mapProjectSummary(project),
      customer: project.data.customerId
        ? { id: project.data.customerId, displayName: project.data.customerName! }
        : undefined,
    });
  }
}
