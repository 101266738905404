<div *ngIf="variant === 'notification'" class="flex flex-col gap-10">
  <div class="rounded-md bg-blue-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <app-ng-icon class="text-xl text-blue-400" name="heroInformationCircleSolid"></app-ng-icon>
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm text-blue-700">
          {{ heading }}
        </p>
        <p class="mt-3 text-sm md:ml-6 md:mt-0">
          <a
            [routerLink]="linkTarget"
            class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
          >
            {{ linkText }}
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="variant === 'alert'" class="rounded-md bg-red-50 p-4 shadow-sm">
  <div class="flex">
    <div class="flex-shrink-0">
      <svg
        class="h-5 w-5 text-red-400"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
        data-slot="icon"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div class="ml-3 flex-1 md:flex md:justify-between">
      <div class="flex flex-col">
        <p class="text-sm font-semibold text-red-700">
          {{ heading }}
        </p>
        <p class="text-sm text-red-700 mt-1" *ngIf="subheading">
          {{ subheading }}
        </p>
      </div>
      <p class="mt-3 text-sm md:ml-6 md:mt-0" *ngIf="linkTarget">
        <a
          [routerLink]="linkTarget"
          class="whitespace-nowrap font-medium text-red-700 hover:text-red-600"
        >
          {{ linkText }}
          <span aria-hidden="true"> &rarr;</span>
        </a>
      </p>
    </div>
  </div>
</div>
