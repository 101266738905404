import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'formatCents',
  standalone: true,
})
export class FormatCents implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(cents: number | null, ...args: unknown[]): string {
    if (!cents) {
      return '';
    }
    return formatNumber(cents * 100, this.locale, '1.2-2');
  }
}
