import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-center-content',
  styles: [
    `
      :host {
        @apply block w-full h-full;
      }
    `,
  ],
  templateUrl: './center-content.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CenterContentComponent {}
