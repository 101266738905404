import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Category, DocumentAiState, EntityReference, UserReference } from 'commons';
import { DatePipe, KeyValue, KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { DropdownItemComponent } from '../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DropdownButtonComponent } from '../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { EntityIconComponent } from '../../../../components/icons/entity-icon.component';
import { CategoryBadgeComponent } from '../../../../components/category-badge/category-badge.component';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: '[app-document-list-item]',
  templateUrl: './document-list-item.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgIconWrapperComponent,
    NgClass,
    CategoryBadgeComponent,
    NgFor,
    EntityIconComponent,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    DropdownItemComponent,
    DatePipe,
    KeyValuePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListItemComponent {
  @Input()
  id!: string;
  @Input()
  name!: string;
  @Input()
  archiveFolder!: EntityReference | null;
  @Input()
  accounting!: boolean;
  @Input()
  accountingProcessed!: boolean;
  @Input()
  documentDate!: string | null;
  @Input()
  internalInvoice = false;
  @Input()
  linkedEntities: Record<string, EntityReference> = {};
  @Input()
  linkedUsers: Record<string, UserReference> = {};

  @Input() selectable = false;

  @Input() selected = false;

  @Input()
  documentAiState: DocumentAiState | null = null;

  @Input()
  category: Category | null = null;

  @Output()
  editDocument = new EventEmitter<void>();

  @Output()
  deleteDocument = new EventEmitter<void>();

  @Output()
  selectedChange = new EventEmitter<boolean>();

  @Output()
  downloadDocument = new EventEmitter<void>();

  @Output()
  restartDocumentAi = new EventEmitter<void>();

  edit() {
    this.editDocument.emit();
  }

  selectionChanged() {
    this.selected = !this.selected;
    this.selectedChange.emit(this.selected);
  }

  sortByEntityType(
    a: KeyValue<string, EntityReference>,
    b: KeyValue<string, EntityReference>
  ): number {
    return a.value.entity.localeCompare(b.value.entity);
  }
}
