import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OrgaUserService } from '../../../../services/orga-user.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrgaUserSlideOverComponent } from '../orga-user-slide-over/orga-user-slide-over.component';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DividerButtonComponent } from '../../../../components/divider-button/divider-button.component';
import { OrgaUserCardComponent } from './orga-user-card/orga-user-card.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { SessionStateService } from '../../../../services/session-state.service';
import { FirestoreOrga } from 'commons';

@Component({
  selector: 'app-orga-user',
  templateUrl: './orga-user.component.html',
  standalone: true,
  imports: [
    NgFor,
    OrgaUserCardComponent,
    NgIf,
    DividerButtonComponent,
    NgIconWrapperComponent,
    OrgaUserSlideOverComponent,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgaUserComponent implements OnInit {
  orga$ = this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga));
  orgaUsers$ = this.orgaUserService.getAllOrgaUsers(false);
  role$ = this.sessionState.getOrgaUserRole();

  newOrgaUserSlideOverOpen = false;

  showAddUserButton!: Observable<boolean>;

  constructor(
    public sessionState: SessionStateService,
    private orgaUserService: OrgaUserService
  ) {}

  addUserToOrganisation() {
    this.newOrgaUserSlideOverOpen = true;
  }

  closeSlideOver() {
    this.newOrgaUserSlideOverOpen = false;
  }

  ngOnInit(): void {
    this.showAddUserButton = combineLatest([this.orgaUsers$, this.orga$, this.role$]).pipe(
      map(([orgaUsers, orga, role]) => {
        const subscription = orga.data.subscription;
        return !!(
          subscription &&
          role === 'owner' &&
          (orgaUsers.filter((orgaUser) => orgaUser.data.role !== 'accountant').length <
            subscription.seats ||
            (subscription.plan === 'business' &&
              orgaUsers.filter((orgaUser) => orgaUser.data.role === 'accountant').length < 1) ||
            (subscription.plan === 'enterprise' &&
              orgaUsers.filter((orgaUser) => orgaUser.data.role === 'accountant').length < 3))
        );
      })
    );
  }
}
