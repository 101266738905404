import { Component, Input } from '@angular/core';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { icons } from '../../icon.config';

@Component({
  selector: 'app-empty-state',
  standalone: true,
  imports: [NgIconWrapperComponent],
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {
  @Input() iconName: keyof typeof icons = 'heroChatBubbleBottomCenter';
  @Input() heading: string = 'Keine Mentions';
  @Input() subheading: string = 'Du hast alles gesehen und erledigt!';
}
