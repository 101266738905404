<div class="lg:col-start-3 lg:row-end-1">
  <div class="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5">
    <div class="flex flex-wrap">
      <div class="flex-auto pl-6 pt-6">
        <div class="flex gap-2">
          <div class="text-xs/6 font-semibold text-gray-500 uppercase tracking-wide">
            {{ position.type === 'effort' ? 'Aufwand' : 'Fixpreis' }}
          </div>
          <div
            *ngIf="position.billable"
            class="sm:hidden inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
          >
            Verrechenbar
          </div>
        </div>
        <dd class="mt-1 text-base font-bold text-gray-900">{{ position.name }}</dd>
      </div>
      <div class="hidden sm:block flex-none px-6 pt-4" *ngIf="position.billable">
        <dt class="sr-only">Status</dt>
        <dd
          class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
        >
          Verrechenbar
        </dd>
      </div>
      <div *ngIf="position.type === 'effort'" class="sm:hidden flex-auto pl-6 pt-6 text-right pr-6">
        <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Stunden geleistet</dt>
        <dd class="w-full flex-none text-xl/8 font-medium tracking-tight text-gray-900">
          {{ position.totalWorkMinutes | formatWork }}
        </dd>
      </div>
    </div>

    <div class="mt-3 grid gap-x-12 border-t border-gray-200 px-6 pt-6 sm:grid-cols-3">
      <div class="text-gray-600">
        <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Beschreibung</dt>
        <dd *ngIf="position.description; else noDescription" class="mt-2 whitespace-pre-line">
          {{ position.description }}
        </dd>
        <ng-template #noDescription>
          <button
            (click)="modifyPosition.emit(position)"
            class="mb-2 text-gray-600 w-full h-full sm:opacity-0 sm:hover:opacity-100 flex sm:hover:bg-gray-50 items-center justify-center rounded-sm"
          >
            <app-ng-icon class="text-[24px] text-gray-400" name="heroPencil"></app-ng-icon>
          </button>
        </ng-template>
      </div>

      <div class="col-span-2 mt-4 sm:mt-0 hidden sm:block">
        <div class="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4">
          <div
            class="flex flex-wrap items-baseline justify-between gap-x-4"
            *ngIf="position.type === 'effort'"
          >
            <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Stundensatz</dt>
            <dd class="w-full flex-none text-xl font-medium tracking-tight text-gray-900">
              {{ position.hourlyRate }}
            </dd>
          </div>
          <div
            class="flex flex-wrap items-baseline justify-between gap-x-4"
            *ngIf="position.type === 'effort'"
          >
            <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Stunden offeriert</dt>
            <dd class="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-900">
              {{ position.estimatedWorkMinutes | formatWork }}
            </dd>
          </div>
          <div
            *ngIf="position.type === 'effort'"
            class="flex flex-wrap items-baseline justify-between gap-x-4"
          >
            <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Stunden geleistet</dt>
            <dd class="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-900">
              {{ position.totalWorkMinutes | formatWork }}
            </dd>
          </div>
          <div
            *ngIf="position.type === 'effort'"
            class="flex flex-wrap items-baseline justify-between gap-x-4"
          >
            <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Stunden verrechnet</dt>
            <dd class="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-900">
              {{ position.billedWorkMinutes | formatWork }}
            </dd>
          </div>
          <div
            *ngIf="position.type === 'fixed'"
            class="lg:col-start-2 flex flex-wrap items-baseline justify-between gap-x-4"
          >
            <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Stunden geleistet</dt>
            <dd class="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-900">
              {{ position.totalWorkMinutes | formatWork }}
            </dd>
          </div>
          <div
            *ngIf="position.type === 'fixed'"
            class="flex flex-wrap items-baseline justify-between gap-x-4"
          >
            <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Fixpreis</dt>
            <dd class="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-900">
              {{ position.amount | formatWork }}
            </dd>
          </div>
          <div
            *ngIf="position.type === 'fixed'"
            class="flex flex-wrap items-baseline justify-between gap-x-4"
          >
            <dt class="min-w-0 truncate text-xs/6 font-medium text-gray-500">Verrechnet</dt>
            <dd class="w-full flex-none text-xl/10 font-medium tracking-tight text-gray-900">
              {{ position.billedAmount | formatWork }}
            </dd>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-center justify-between border-t border-gray-200 px-6 py-5">
      <button (click)="showWork.emit(position)" class="text-sm/6 text-gray-900">
        Stunden <span aria-hidden="true">&rarr;</span>
      </button>
      <div class="flex gap-4">
        <button
          type="button"
          appTailwindButton
          color="white"
          size="xs"
          (click)="createWork.emit(position)"
        >
          Stunden erfassen
        </button>
        <button
          type="button"
          appTailwindButton
          color="secondary"
          size="xs"
          (click)="modifyPosition.emit(position)"
        >
          Bearbeiten
        </button>
      </div>
    </div>
  </div>
</div>
