import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-input-label',
  standalone: true,
  imports: [NgIf],
  templateUrl: './input-label.component.html',
})
export class InputLabelComponent {
  @Input() label?: string;
  @Input() isOptional = true;
}
