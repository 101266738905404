import { Component } from '@angular/core';

@Component({
  selector: 'app-slide-over-content',
  templateUrl: './slide-over-content.component.html',
  standalone: true,
  styles: `
    :host {
      @apply block h-full overflow-auto;
    }
  `,
})
export class SlideOverContentComponent {}
