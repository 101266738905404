import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { defer, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerSummary, FirestoreProject, Project } from 'commons';
import { CustomerService } from '../../../services/customer.service';
import { ProjectService } from '../../../services/project.service';
import { NgIf } from '@angular/common';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { ToggleComponent } from '../../../components/toggle/toggle.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';

export interface ProjectSlideOverInput {
  project?: FirestoreProject;
  customer?: CustomerSummary;
}

@Component({
  selector: 'app-project-slide-over',
  templateUrl: './project-slide-over.component.html',
  standalone: true,
  imports: [
    SlideOverComponent,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    ReactiveFormsModule,
    NgSelectModule,
    ToggleComponent,
    SlideOverFooterComponent,
    TailwindButtonDirective,
    TextInputComponent,
    DropdownComponent,
    NgIf,
  ],
})
export class ProjectSlideOverComponent implements OnInit {
  projectForm!: FormGroup<{
    name: FormControl<string | null>;
    customer: FormControl<CustomerSummary | null>;
    isActive: FormControl<boolean>;
  }>;

  customers = defer(() => this.customerService.getAllCustomerSummaries());

  title!: string;

  constructor(
    private projectService: ProjectService,
    private customerService: CustomerService,
    @Inject(DIALOG_DATA) public data: ProjectSlideOverInput | null,
    @Inject(DialogRef) public dialogRef: DialogRef
  ) {}

  async ngOnInit() {
    this.initForm();

    if (this.data?.project) {
      const project = this.data.project;
      const customer = project.data.customerId
        ? await firstValueFrom(
            this.customerService.getCustomer(project.data.customerId).pipe(
              map((customer) => ({
                id: customer.id,
                displayName: customer.data.displayName,
              }))
            )
          )
        : null;
      this.projectForm.setValue({
        name: project.data.name,
        customer,
        isActive: project.data.isActive,
      });
    }

    this.title = this.data?.project ? 'Projekt bearbeiten' : 'Neues Projekt erstellen';
  }

  initForm() {
    this.projectForm = new FormGroup(
      {
        name: new FormControl<string | null>(null, [Validators.required, Validators.maxLength(60)]),
        customer: new FormControl(this.data?.customer ?? null, []),
        isActive: new FormControl(true, { nonNullable: true }),
      },
      {}
    );
  }

  closeSlideOver(id?: string) {
    this.dialogRef.close(id);
  }

  async save() {
    this.projectForm.markAllAsTouched();
    if (!this.projectForm.invalid) {
      let project: Partial<Project> = {
        name: this.projectForm.value.name!,
        isActive: this.projectForm.value.isActive,
      };

      project = {
        ...project,
        customerId: this.projectForm.value.customer?.id ?? null,
        customerName: this.projectForm.value.customer?.displayName ?? null,
      };

      if (this.data?.project) {
        await this.projectService.updateProject(this.data.project.id, project);
        this.closeSlideOver();
      } else {
        const doc = await this.projectService.createProject(project);
        this.closeSlideOver(doc.id);
      }
    }
  }
}
