import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIconWrapperComponent } from '../../../icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'app-slide-over-alert',
  standalone: true,
  imports: [NgIconWrapperComponent],
  templateUrl: './slide-over-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideOverAlertComponent {
  @Input() heading!: string;
  @Input() subheading!: string;
}
