<app-form-input-wrapper [label]="label" [control]="formControl">
  <div class="relative" #triggerElement>
    <button
      class="text-left pl-4 bg-white"
      appTailwindInput2
      [control]="formControl"
      [attr.disabled]="!isDisabled ? null : true"
      type="button"
      #cdkMenuTriggerFor="cdkMenuTriggerFor"
      [cdkMenuTriggerFor]="dropdownList"
      (cdkMenuOpened)="extractOption()"
      aria-haspopup="listbox"
      aria-expanded="true"
      aria-labelledby="listbox-label"
    >
      <span class="block truncate pr-7">​{{ value?.displayName }}</span>
      <span
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        *ngIf="!isDisabled"
      >
        <svg
          *ngIf="!isLoading; else loading"
          class="h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <!--
        icon is arrow up down, can't find it ...
        -->
          <path
            fill-rule="evenodd"
            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
            clip-rule="evenodd"
          />
        </svg>
        <ng-template #loading>
          <svg
            class="animate-spin -ml-1 h-5 w-5 text-blue-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </ng-template>
      </span>
    </button>
  </div>

  <ng-template #dropdownList>
    <ul
      *ngIf="!isLoading"
      cdkMenu
      [style.width.px]="triggerElement.clientWidth"
      class="absolute py-2 z-10 mt-1 max-h-60 w-80 overflow-y-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      [class.py-1]="_options.length > 1"
      role="listbox"
    >
      <p *ngIf="!_options || _options.length === 0" class="py-2 pl-8">Keine Einträge vorhanden</p>
      <li
        class="text-gray-900 relative cursor-default select-none py-2 pl-8 pr-9 focus-visible:bg-blue-600 focus-visible:text-white hover:bg-gray-100 outline-none group"
        role="option"
        cdkMenuItemRadio
        *ngFor="let item of _options"
        [cdkMenuItemChecked]="item.id === value?.id"
        (cdkMenuItemTriggered)="cdkMenuTriggerFor.close(); valueChanged(item)"
        [cdkMenuitemTypeaheadLabel]="item.displayName"
        cdkTrapFocus
      >
        <span
          class="text-blue-600 absolute inset-y-0 left-0 pl-1.5 flex items-center pr-4 group-focus-visible:text-white"
          [class.hidden]="item.id !== value?.id"
        >
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
        <div class="truncate" [ngClass]="item.id === value?.id ? 'font-semibold' : 'font-normal'">
          {{ item.displayName }}
        </div>
      </li>
    </ul>
  </ng-template>
</app-form-input-wrapper>
