import { Component, Input } from '@angular/core';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { InvoiceState2 } from 'commons/dist/entities/invoice2';

@Component({
  selector: 'app-invoice-state-badge',
  templateUrl: './invoice-state-badge.component.html',
  standalone: true,
  imports: [NgSwitch, NgSwitchCase],
  styles: `
    :host {
      @apply contents;
    }
  `,
})
export class InvoiceStateBadgeComponent {
  @Input()
  invoiceState!: InvoiceState2;
}
