<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img
      style="filter: invert(100%)"
      class="mx-auto h-12 w-auto"
      src="/assets/jessie-logo.png"
      [class.animate-pulse]="!mustConfirmEmail"
      alt="Workflow"
    />
    <ng-container *ngIf="mustConfirmEmail">
      <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
        Bitte E-Mail Adresse bestätigen
      </h2>
      <p class="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
        Du hast den Magic Link nicht in diesem Browser angefordert, daher musst du zu deiner
        Sicherheit deine E-Mail Adresse nochmals bestätigen.
      </p>
    </ng-container>
  </div>

  <div *ngIf="mustConfirmEmail" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form [formGroup]="loginForm">
        <div>
          <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
            Email Adresse
          </label>
          <div class="mt-1 rounded-md shadow-sm">
            <input
              formControlName="mailAddress"
              id="email"
              type="email"
              required
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>

        <div class="mt-6">
          <span class="block w-full rounded-md shadow-sm">
            <button
              (click)="setLoginEmail(mailAddress.value)"
              class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring-blue active:bg-blue-700 transition duration-150 ease-in-out"
            >
              Anmelden
            </button>
          </span>
        </div>
      </form>
    </div>
    <div class="flex justify-center text-xs mt-2 text-gray-500">
      <a [routerLink]="['user', 'logout']" [relativeTo]="null" class="no-underline hover:underline">
        User ausloggen</a
      >
    </div>
  </div>
</div>
