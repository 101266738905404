import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TailwindInputDirective } from '../input/tailwind-input.directive';
import { SelectAllDirective } from '../../directives/selectAll.directive';
import { NgIf } from '@angular/common';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { TailwindInputDirective2 } from '../input/tailwind-input2.directive';

@Component({
  selector: 'app-dropdown-minimal-menu',
  styles: [
    `
      :host {
        @apply flex;
      }
    `,
  ],
  templateUrl: './dropdown-minimal-menu.component.html',
  standalone: true,
  imports: [
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    NgIf,
    SelectAllDirective,
    TailwindInputDirective,
    TailwindInputDirective2,
  ],
})
export class DropdownMinimalMenuComponent {
  @Input()
  dropdownHeader!: string;
  @Input()
  hasSearch = false;

  @Output()
  searchValueChanged = new EventEmitter<string>();

  isOpen = false;

  click(): void {
    this.isOpen = false;
  }

  searchValue(event: any) {
    if (event.target) {
      this.searchValueChanged.emit(event.target.value ?? '');
    }
  }

  menuOpen(event: Event) {
    this.isOpen = true;
    this.searchValueChanged.emit('');
    event.stopPropagation();
    event.preventDefault();
  }
}
