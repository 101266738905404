<app-settings-card
  heading="Profil"
  description="Einstellungen an dieser Stelle sind sofort in in allen Organisationen wo du arbeitest ersichtlich."
  (save)="saveSettings()"
>
  <form [formGroup]="profileForm">
    <div class="mt-6 grid grid-cols-4 gap-6">
      <div class="col-span-4 sm:col-span-2">
        <app-text-input
          label="Vorname"
          [formControl]="$any(profileForm.controls).firstName"
          autocomplete="given-name"
        >
        </app-text-input>
      </div>

      <div class="col-span-4 sm:col-span-2">
        <app-text-input
          label="Nachname"
          [formControl]="$any(profileForm.controls).lastName"
          autocomplete="family-name"
        >
        </app-text-input>
      </div>
    </div>
  </form>
</app-settings-card>
