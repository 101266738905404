import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { firstValueFrom, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { Customer, Document, FirestoreDocument, FirestoreEntity, FirestoreProject } from 'commons';
import { ProjectService } from '../../../services/project.service';
import { CustomerSlideOverService } from '../customer-slide-over/customer-slide-over.component';
import { DocumentService } from '../../../services/document.service';
import { DocumentSlideOverService } from '../../document/document-slide-over/document-slide-over.component';
import { HistoryComponent } from '../../history/history.component';
import { CommentSectionComponent } from '../../comments/comment-section/comment-section.component';
import { HideWhenNotInPlanDirective } from '../../../directives/hide-when-not-in-plan.directive';
import { DocumentListItemComponent } from '../../document/list/document-list-item/document-list-item.component';
import { DocumentListComponent } from '../../document/list/document-list/document-list.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { TabOldDirective } from '../../../components/tabs/content-projected/tab.directive';
import { TabsComponent } from '../../../components/tabs/content-projected/tabs.component';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { HeaderMetaActionComponent } from '../../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../../components/container/container.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { where } from '@angular/fire/firestore';
import { ProjectListEntryComponent } from '../../projects/components/project-list-entry/project-list-entry.component';
import { ProjectSlideOverService } from '../../projects/slide-overs/project-slide-over.component';
import { InvoicesComponent } from '../../finance/invoices/invoices.component';
import { FirestoreInvoice2 } from 'commons/dist/entities/invoice2';
import { Invoice2Service } from '../../../services/invoice2.service';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  standalone: true,
  imports: [
    NgIf,
    ContainerComponent,
    HeaderMetaActionComponent,
    TailwindButtonDirective,
    TabsComponent,
    TabOldDirective,
    NgFor,
    ProjectListEntryComponent,
    NgClass,
    EmptyComponent,
    DocumentListComponent,
    DocumentListItemComponent,
    HideWhenNotInPlanDirective,
    CommentSectionComponent,
    HistoryComponent,
    AsyncPipe,
    InvoicesComponent,
  ],
})
export class CustomerViewComponent {
  customer: Observable<FirestoreEntity<Customer>>;
  projects: Observable<FirestoreProject[]>;
  documents: Observable<FirestoreEntity<Document>[]>;
  invoices: Observable<FirestoreInvoice2[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: Invoice2Service,
    private customerService: CustomerService,
    private projectService: ProjectService,
    private customerSlideOverService: CustomerSlideOverService,
    private projectSlideOver: ProjectSlideOverService,
    private documentService: DocumentService,
    private documentSlideOver: DocumentSlideOverService
  ) {
    this.customer = this.route.params.pipe(
      switchMap((params) => this.customerService.getCustomer(params.customerId))
    );
    this.projects = this.customer.pipe(
      switchMap((customer) => this.projectService.getAllProjectsForCustomer(customer.id))
    );
    this.documents = this.customer.pipe(
      switchMap((customer) =>
        this.documentService.getAllDocuments(
          where(`linkedEntities.${customer.id}.id`, '==', customer.id)
        )
      )
    );
    this.invoices = this.customer.pipe(
      switchMap((customer) => this.invoiceService.getInvoicesForCustomer(customer.id))
    );
  }

  async editCustomer() {
    this.customerSlideOverService.edit(await firstValueFrom(this.customer));
  }

  newProject() {
    this.projectSlideOver.new();
  }

  editDocument(document: FirestoreDocument) {
    this.documentSlideOver.edit([document]);
  }

  async editInvoice(invoice: FirestoreInvoice2) {
    // @ts-ignore
    if (invoice.data._v6) {
      if (invoice.data.documentId) {
        const document = await firstValueFrom(
          this.documentService.getDocument(invoice.data.documentId)
        );
        this.documentSlideOver.edit([document]);
      } else {
        console.log('migrated invoice without data');
      }
    } else {
      this.router.navigate([invoice.id], {
        relativeTo: this.route,
      });
    }
  }
}
