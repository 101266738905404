<app-form-input-wrapper [label]="label" [control]="formControl">
  <fieldset [attr.aria-label]="label">
    <div class="space-y-5">
      <div class="relative flex items-start" *ngFor="let option of options">
        <div class="flex h-6 items-center">
          <input
            aria-describedby="positionType_fix_description"
            class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
            type="radio"
            [value]="option.value"
            [checked]="isSelected(option.value)"
            [disabled]="isDisabled"
            (change)="selectValue(option.value)"
          />
        </div>
        <div class="ml-3 text-sm leading-6">
          <label class="font-medium text-gray-900">{{ option.label }}</label>
          <p class="text-gray-500">{{ option.description }}</p>
        </div>
      </div>
    </div>
  </fieldset>
</app-form-input-wrapper>
