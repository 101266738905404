import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { VatSettingsCardComponent } from './vat-settings-card.component';
import { AsyncPipe } from '@angular/common';
import { VatRateService } from '../../../services/vat-rate.service';

@Component({
  selector: 'app-vat-settings',
  standalone: true,
  imports: [VatSettingsCardComponent, AsyncPipe],
  templateUrl: './vat-settings.component.html',
})
export class VatSettingsComponent {
  vatRates = this.vatRateService
    .getVatRates()
    .pipe(
      map((vatRates) =>
        vatRates
          .map((vatRate) => ({ id: vatRate.id, ...vatRate.data }))
          .sort((a, b) => a.validFrom.localeCompare(b.validFrom))
      )
    );

  constructor(private vatRateService: VatRateService) {}
}
