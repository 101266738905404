import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';
import { NgIconWrapperComponent } from '../../../icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'app-slide-over-header',
  templateUrl: './slide-over-header.component.html',
  styles: [
    `
      :host {
        @apply sticky top-0 z-10;
      }
    `,
  ],
  standalone: true,
  imports: [NgIconWrapperComponent, NgIf],
})
export class SlideOverHeaderComponent {
  @Input()
  title!: string;
  @Input()
  leadText!: string;
  @Input()
  formDirty = false;

  @Output()
  closeSlideOver = new EventEmitter();

  @ViewChild('headerTitle', { read: ElementRef }) headerTitle!: ElementRef;

  showConfirmation = false;

  closeSlideOverClicked() {
    if (this.formDirty) {
      this.showConfirmation = true;
    } else {
      this.closeSlideOver.emit();
    }
  }
}
