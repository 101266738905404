import { EnvironmentInjector, Inject, inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlTree } from '@angular/router';

import { EMPTY, Observable, of, race } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { SystemService } from './system.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { SessionStateService } from './session-state.service';
import { OrgaUserService } from './orga-user.service';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { User } from '@angular/fire/auth';
import { FirestoreOrgaUser } from 'commons';
import { OrgaService } from './orga.service';

export function watchSessionStateFn(
  observedState: () => Observable<boolean | UrlTree>
): (injector: EnvironmentInjector) => Observable<UrlTree> {
  return (injector) => {
    return injector.runInContext(observedState).pipe(
      filter((state): state is UrlTree => state !== true),
      map((urlTree) => urlTree)
    );
  };
}

export type SignInResults =
  | 'NoValidSignInUrl'
  | 'NoEmail'
  | 'InvalidEmail'
  | 'ExpiredLink'
  | 'WrongEmail'
  | 'UserDisabled'
  | 'SignInDone';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private injector = inject(EnvironmentInjector);
  private systemService = inject(SystemService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private sessionState = inject(SessionStateService);
  private orgaUserService = inject(OrgaUserService);
  private userService = inject(UserService);
  private authService = inject(AuthService);
  private orgaService = inject(OrgaService);

  constructor(@Inject(LOCAL_STORAGE) private localStore: StorageService) {
    this.initializeSessionStateWatchers();
    this.initializeSessionStateUpdater();
  }

  private initializeSessionStateWatchers() {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute.root;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route.snapshot.data;
        }),
        switchMap((data): Observable<UrlTree | never> => {
          if (data.sessionStateWatcher) {
            // @ts-ignore
            return race(data.sessionStateWatcher.map((x) => x(this.injector)));
          }
          return EMPTY;
        })
      )
      .subscribe((urlTree) => this.router.navigateByUrl(urlTree.toString()));
  }

  isSystemOpen(): Observable<boolean | UrlTree> {
    return this.systemService
      .isSystemOpen()
      .pipe(
        mergeMap((open) => (open ? of(true) : of(this.router.createUrlTree(['/maintenance']))))
      );
  }

  isLoggedIn(): Observable<boolean | UrlTree> {
    return this.sessionState
      .getUser()
      .pipe(
        mergeMap((user) => (user ? of(true) : of(this.router.createUrlTree(['/user', 'login']))))
      );
  }

  isOrganisationChosen(): Observable<boolean | UrlTree> {
    return this.sessionState
      .getOrga()
      .pipe(
        mergeMap((orgaId) => (orgaId ? of(true) : of(this.router.createUrlTree(['/user', 'orga']))))
      );
  }

  isUserOnboardingComplete(): Observable<boolean | UrlTree> {
    return this.sessionState.getUser().pipe(
      map((user) => {
        if (!user) {
          console.error('isUserOnboardingComplete: user is null');
          return of(false);
        }
        return !!user.data.lastName || !!user.data.firstName;
      }),
      catchError((error) => {
        console.error(error);
        return of(false);
      }),
      mergeMap((open) => (open ? of(true) : of(this.router.createUrlTree(['/user', 'onboarding']))))
    );
  }

  checkSchemaVersion(): Observable<boolean | UrlTree> {
    return this.sessionState.getOrga().pipe(
      switchMap((orga) => {
        if (!orga) {
          console.error('checkSchemaVersion: orga is null');
          return of(false);
        }
        return this.systemService.checkIfSchemaVersionIsCorrect(orga.data.schemaVersion);
      }),
      mergeMap((open) => (open ? of(true) : of(this.router.createUrlTree(['/updateSchema']))))
    );
  }

  checkOrgaSubscriptionState(): Observable<boolean | UrlTree> {
    return this.sessionState.getOrga().pipe(
      map((orga) => {
        if (!orga) {
          console.error('checkOrgaSubscriptionState: orga is null');
          return false;
        }
        const status = orga.data.subscription?.status;
        if (status === 'active' || status === 'trialing') {
          return true;
        } else if (status === 'incomplete' || status === 'unpaid' || status === 'paused') {
          return this.router.createUrlTree(['/subscription-error']);
        } else {
          return this.router.createUrlTree(['/subscription']);
        }
      })
    );
  }

  initializeSessionStateUpdater() {
    const authUser = this.authService
      .getLogggedInUser()
      .pipe(filter((user): user is User => !!user));
    const user = authUser.pipe(switchMap((user) => this.userService.getUserByAuthUserUd(user.uid)));

    user
      .pipe(
        switchMap((user) =>
          this.orgaUserService
            .getOrgaUserByUserId(user.id)
            .pipe(filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser))
        )
      )
      .subscribe((orgaUser) => this.sessionState.setOrgaUser(orgaUser));

    user.subscribe((user) => this.sessionState.setUser(user));
    user
      .pipe(switchMap((user) => this.userService.getPermission(user.id)))
      .subscribe((permission) => this.sessionState.setOrganisations(permission.orgas));

    this.sessionState
      .getOrgaId()
      .pipe(switchMap((orgaId) => this.orgaService.getOrga(orgaId)))
      .subscribe((orga) => this.sessionState.setOrga(orga));
  }
}
