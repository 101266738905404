import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Comment, FirestoreComment, FirestoreEntity, FirestoreUser } from 'commons';
import { CommentService } from '../../../services/comment.service';
import { map, Observable } from 'rxjs';
import { OrgaUserService } from '../../../services/orga-user.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AsyncPipe } from '@angular/common';
import { CommentListComponent } from '../../../components/comment-components/comment-list/comment-list.component';
import { SessionStateService } from '../../../services/session-state.service';
import { filter } from 'rxjs/operators';
import { arrayRemove } from '@angular/fire/firestore';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  standalone: true,
  imports: [CommentListComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentSectionComponent<T> implements OnChanges {
  @Input() parentEntity!: FirestoreEntity<T>;
  @Input() set padded(value: any) {
    this._padded = coerceBooleanProperty(value);
  }

  _padded = false;

  comments!: Observable<FirestoreComment[]>;

  orgaUsers = this.orgaUserService.getAllOrgaUsers();
  loggedInUserId = this.sessionState.getUser().pipe(
    filter((user): user is FirestoreUser => !!user),
    map((user) => user.id)
  );

  constructor(
    private commentService: CommentService,
    private orgaUserService: OrgaUserService,
    private sessionState: SessionStateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentEntity) {
      this.comments = this.commentService.getAllComments<T>(this.parentEntity);
    }
  }

  async createNewComment(comment: Partial<Comment>) {
    await this.commentService.createComment(this.parentEntity, comment);
  }

  deleteComment(comment: FirestoreComment) {
    this.commentService.deleteComment(comment);
  }

  markAsReacted(reaction: { comment: FirestoreComment; userId: string }) {
    this.commentService.updateComment(reaction.comment.docRef.path, {
      // @ts-ignore
      notReactedUserIds: arrayRemove(reaction.userId),
    });
  }
}
