<div class="flex flex-row">
  <div
    class="px-4 py-4 sm:px-6 flex items-center cursor-pointer hover:bg-gray-100"
    *ngIf="selectable"
    (click)="selectionChanged()"
  >
    <app-ng-icon
      class="text-2xl"
      name="heroCheckCircle"
      *ngIf="selected; else notSelected"
    ></app-ng-icon>
    <ng-template #notSelected>
      <svg class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M 21 12 a 9 9 0 1 1 -18 0 a 9 9 0 0 1 18 0 z"
        />
      </svg>
    </ng-template>
  </div>
  <button
    class="relative block hover:bg-gray-50 w-full py-4 min-w-0"
    [ngClass]="selectable ? 'pr-4 sm:pr-6' : 'px-4 sm:px-6'"
    (click)="selectable ? selectionChanged() : edit()"
  >
    <div class="flex text-left justify-between">
      <div class="min-w-0">
        <p class="text-sm font-medium text-blue-600 truncate">
          {{ name }}
          <app-category-badge
            *ngIf="category"
            [color]="category.color"
            [displayName]="category.displayName"
          ></app-category-badge>
        </p>
        <div class="mt-2 sm:flex gap-2">
          <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0" *ngIf="documentDate">
            <app-ng-icon name="heroCalendar"></app-ng-icon>
            <p>
              <time datetime="2020-01-07">{{ documentDate | date : 'dd.MM.yyy' }}</time>
            </p>
          </div>
          <div
            class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0"
            *ngFor="let linked of linkedEntities | keyvalue : sortByEntityType"
          >
            <app-entity-icon [entity]="linked.value.entity"></app-entity-icon>
            <p>
              {{ linked.value.name }}
            </p>
          </div>
          <div
            class="mt-2 flex items-center gap-1.5 text-sm text-gray-500 sm:mt-0"
            *ngIf="(linkedUsers | keyvalue).length > 0"
          >
            <!--            <icon-check-badge-sm class="text-gray-400"></icon-check-badge-sm>-->
            <p
              *ngFor="let linkedUser of linkedUsers | keyvalue; last as last"
              [class.font-bold]="!linkedUser.value.accepted"
            >
              {{ linkedUser.value.displayName }}<span *ngIf="!last">, </span>
            </p>
          </div>
        </div>
      </div>
      <div class="flex gap-4">
        <!--        <div class="text-xs rounded bg-amber-50">-->
        <!--          <div class="p-2">-->
        <!--            <p>1. Dez. 24</p>-->
        <!--            <p>+ 1200.00</p>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="ml-2 flex-shrink-0 flex gap-2 justify-end">
          <p
            class="px-2 h-6 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800 gap-1 items-center"
            *ngIf="accounting"
          >
            <span>Buchhaltung</span>
            <app-ng-icon
              *ngIf="accountingProcessed"
              class="text-sm"
              name="heroCheckCircleMini"
            ></app-ng-icon>
          </p>
          <app-ng-icon
            name="heroSparkles"
            *ngIf="documentAiState === 'ready' || documentAiState === 'processing'"
            [class.animate-pulse]="documentAiState === 'processing'"
            [class.text-gray-600]="documentAiState === 'processing'"
            class="text-blue-600"
          ></app-ng-icon>
        </div>
      </div>
    </div>
  </button>

  <app-dropdown-minimal-menu *ngIf="!selectable">
    <app-dropdown-button>
      <app-ng-icon name="heroEllipsisVerticalSolid" class="text-3xl" strokeWidth="3"></app-ng-icon>
    </app-dropdown-button>
    <app-dropdown-item (menuClick)="edit()"> Dokument bearbeiten </app-dropdown-item>
    <app-dropdown-item
      (menuClick)="deleteDocument.emit()"
      *ngIf="!accountingProcessed && !internalInvoice"
    >
      Dokument löschen
    </app-dropdown-item>
    <app-dropdown-item (menuClick)="downloadDocument.emit()"> Download </app-dropdown-item>
    <app-dropdown-item (menuClick)="restartDocumentAi.emit()"> Dokument-Erkennung starten </app-dropdown-item>
  </app-dropdown-minimal-menu>
</div>
