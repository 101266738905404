import { inject, Injectable } from '@angular/core';
import { collection, doc, Firestore } from '@angular/fire/firestore';
import { formatFileName, RevenueDocument } from 'commons';
import { ORGA_FIRESTORE_SERVICE } from '../../../services/firestore.service';
import { LastEditorService } from '../../../services/last-editor.service';
import { PDFService } from '../../../services/pdf.service';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import {
  addressBlock,
  bankInformationBlock,
  cm,
  DATE_FORMAT_LONG,
  getPositionTable,
  humanDate,
  invoiceFooter,
  invoiceInformationBlock,
} from '../util/pdf-util';
import { Invoice2, InvoiceEntity2 } from 'commons/dist/entities/invoice2';
import { SessionStateService } from '../../../services/session-state.service';
import { Big } from 'big.js';

@Injectable({
  providedIn: 'root',
})
export class InvoicePdfService {
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private lastEditor = inject(LastEditorService);
  private firestore = inject(Firestore);
  private pdfService = inject(PDFService);
  private sessionStateService = inject(SessionStateService);

  async createInvoicePdf(invoice: InvoiceEntity2) {
    const def: TDocumentDefinitions = {
      //watermark: { text: 'Entwurf', color: 'blue', opacity: 0.1, bold: true, italics: false },
      pageSize: 'A4',
      pageMargins: [cm(2.3), cm(3.3), cm(2.5), cm(1.5)],
      header: invoice.logo
        ? {
            image: invoice.logo,
            fit: [cm(5), cm(2.3)],
            margin: [0, cm(1.5), cm(1), 0],
            alignment: 'right',
          }
        : { text: invoice.sender.name ?? '', margin: [0, cm(1.9), cm(2), 0], alignment: 'right' },
      content: [
        addressBlock(invoice.sender.name, invoice.sender.address),
        {
          text: [
            invoice.sender.address.city,
            ', ',
            humanDate(invoice.invoiceDate, DATE_FORMAT_LONG),
          ],
          marginTop: cm(0.5),
        },
        {
          columns: [
            {
              width: '60%',
              text: '',
            },
            {
              width: '40%',
              stack: [addressBlock(invoice.recipient.name, invoice.recipient.address)],
            },
          ],
          marginTop: cm(0.5),
        },
        { ...invoiceInformationBlock(invoice), marginTop: cm(0.5) },
        { text: invoice.texts.intro ?? '', marginTop: cm(0.5) },
        { ...getPositionTable(invoice), marginTop: cm(1) },
        {
          text: `Zahlbar innerhalb von ${invoice.paymentDurationDays} Tagen bis ${humanDate(invoice.dueDate, DATE_FORMAT_LONG)}.`,
          marginTop: cm(1),
        },
        { ...bankInformationBlock(invoice), marginTop: cm(0.5) },
        {
          stack: [invoice.texts.greeting ?? '', invoice.sender.contactPerson.displayName],
          marginTop: cm(0.5),
        },
      ],
      footer: { ...invoiceFooter(invoice) },
      styles: {
        small: { fontSize: 10 },
        bold: { bold: true },
        row: { fontSize: 8, color: 'black', font: 'Raleway', margin: [0, 3] },
      },
      defaultStyle: { font: 'Inter', fontSize: 11, lineHeight: 1.15 },
    };
    const pdfDocument = await this.pdfService.createDoc(def);
    return await this.pdfService.createUint8Array(pdfDocument);
  }

  async uploadDocument(array: Uint8Array, invoice: Invoice2) {
    let docRef;
    if (invoice.documentId) {
      docRef = doc(this.firestore, `archive/archive/document/${invoice.documentId}`);
    } else {
      docRef = doc(collection(this.firestore, 'archive/archive/document'));
    }

    const metadata: any = {
      contentType: 'application/pdf',
      customMetadata: {
        documentId: docRef.id,
        docRef: docRef.path,
        documentAiDisabled: true,
      },
    };

    const uploadResult = await this.orgaFirestoreService.uploadUint8Array(
      array,
      `Rechnung #${invoice.invoiceNumber}`,
      `archive/archive/document/${docRef.id}`,
      metadata
    );

    const invoiceDocument: RevenueDocument = {
      type: 'revenue',
      internalInvoice: true,
      filePath: uploadResult.metadata.fullPath,
      name: formatFileName(uploadResult.metadata.name),
      fileName: uploadResult.metadata.name,
      folder: null,
      accounting: {
        relevant: true,
        processed: false,
      },
      linkedEntities: {
        [invoice.recipient.customer.id]: {
          entity: 'customer-company',
          id: invoice.recipient.customer.id,
          name: invoice.recipient.customer.displayName,
        },
        [invoice.project.id]: {
          entity: 'project',
          id: invoice.project.id,
          name: invoice.project.displayName,
        },
        [invoice.id]: {
          entity: 'invoice',
          id: invoice.id,
          name: 'Rechnung Nr. ' + invoice.invoiceNumber,
        },
      },
      amountInCents: new Big(invoice.invoiceTotals?.totalAmount ?? 0).mul(100).toNumber(),
      valueDate: invoice.dueDate,
      financialYear: new Date(invoice.dueDate).getFullYear(),
      paid: false,
      comment: null,
      documentDate: new Date().toISOString(),
      unreadBy: [],
      documentAiState: 'no result',
    };

    await this.orgaFirestoreService.setDoc(
      docRef.path,
      await this.lastEditor.hydrate(invoiceDocument)
    );
    return docRef.id;
  }
}
