<a
  [routerLink]="routerLink"
  routerLinkActive
  #rla="routerLinkActive"
  [ngClass]="
    rla.isActive
      ? 'group bg-gray-50 rounded-md px-3 py-2 flex items-center text-sm leading-5 font-medium text-orange-600 hover:bg-white focus:outline-none focus:bg-white transition ease-in-out duration-150'
      : 'group rounded-md px-3 py-2 flex items-center text-sm leading-5 font-medium text-gray-900 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition ease-in-out duration-150 print:hidden'
  "
>
  <app-ng-icon
    [name]="iconOpenName"
    class="text-blue-600 text-xl mr-3"
    *ngIf="rla.isActive; else inactive"
  ></app-ng-icon>

  <ng-template #inactive>
    <app-ng-icon
      [name]="iconName"
      class="text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150 text-xl mr-3"
    ></app-ng-icon>
  </ng-template>

  <span class="truncate w-full" [ngClass]="rla.isActive ? ' text-blue-600' : 'text-gray-900'">
    <ng-content></ng-content>
  </span>
</a>
