import { Component, Input, OnChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SettingsCardComponent } from '../settings-card/settings-card.component';
import { DatePipe, NgForOf } from '@angular/common';
import { VatRate } from 'commons';
import { formatISO } from 'date-fns';
import { FormatPercent } from '../../../pipes/format-percent';

@Component({
  selector: 'app-vat-settings-card',
  standalone: true,
  imports: [FormsModule, SettingsCardComponent, NgForOf, DatePipe, FormatPercent],
  templateUrl: './vat-settings-card.component.html',
})
export class VatSettingsCardComponent implements OnChanges {
  @Input() vatRates!: VatRate[];

  activeRateIndex = 0;

  ngOnChanges(): void {
    const today = formatISO(new Date(), { representation: 'date' });
    if (this.vatRates) {
      this.activeRateIndex = this.vatRates.findIndex(
        (vatRate) => vatRate.validFrom <= today && vatRate.validTo >= today
      );
    }
  }
}
