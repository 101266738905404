import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormatCents } from '../../../../../pipes/format-cents';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InvoicePosition2 } from 'commons/dist/entities/invoice2';
import { FormatPercent } from '../../../../../pipes/format-percent';
import { StringToNumber } from '../../../../../pipes/string-to-number';
import { FormatAmount } from '../../../../../pipes/format-amount';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'tr[app-time-material-row]',
  standalone: true,
  imports: [
    FormatCents,
    NgIf,
    FormsModule,
    FormatAmount,
    FormatPercent,
    FormatAmount,
    FormatAmount,
    FormatAmount,
    StringToNumber,
    FormatAmount,
    NgIconWrapperComponent,
  ],
  templateUrl: './time-material-row.component.html',
})
export class TimeMaterialRowComponent {
  @Input() position!: InvoicePosition2;
  @Input() isChecked: boolean = false;
  @Input() showCheckboxes = false;
  @Input() isDisabled = false;
  @Output() positionSelected = new EventEmitter<boolean>();
  @Output() deletePosition = new EventEmitter<InvoicePosition2>();
}
