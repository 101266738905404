import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { FirestoreDocument } from 'commons';
import { DocumentService } from '../../../services/document.service';
import { map, tap } from 'rxjs/operators';
import { DocumentCardComponent } from './document-card/document-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { Dialog } from '@angular/cdk/dialog';
import { DocumentSlideOverComponent } from '../document-slide-over/document-slide-over.component';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  standalone: true,
  imports: [NgIf, DocumentCardComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountingComponent {
  documents$: Observable<FirestoreDocument[]>;

  searchValue = new BehaviorSubject<string | null>(null);
  limitRows = new BehaviorSubject<boolean>(true);

  constructor(
    private documentService: DocumentService,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.documents$ = combineLatest([
      this.documentService.getDocumentsNotProcessedByAccountant(),
      this.searchValue.pipe(
        tap((x) => {
          if (x) {
            this.limitRows.next(false);
          }
        })
      ),
    ]).pipe(
      map(([documents, searchValue]) => {
        return searchValue
          ? documents.filter((document) => {
              return (
                document.data.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                (document.data.documentText
                  ? document.data.documentText.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
                  : false)
              );
            })
          : documents;
      })
    );
  }

  editDocument(documents: FirestoreDocument[]) {
    this.dialog.open(DocumentSlideOverComponent, { data: { documents: documents } });
  }

  async deleteDocument(documents: FirestoreDocument[]) {
    await Promise.all(documents.map((document) => this.documentService.deleteDocument(document)));
  }
}
