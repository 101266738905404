import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Document, FirestoreEntity } from 'commons';
import { orderBy, where } from '@angular/fire/firestore';
import { DocumentService } from '../../../../services/document.service';
import { Dialog } from '@angular/cdk/dialog';
import { DocumentSlideOverComponent } from '../../../document/document-slide-over/document-slide-over.component';

@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  standalone: true,
  imports: [],
})
export class ProjectDocumentsComponent {
  projectId!: string;

  documents$: Observable<FirestoreEntity<Document>[]>;

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.projectId = this.route.parent?.snapshot.params['projectId'];

    this.documents$ = this.documentService.getAllDocuments(
      where(`linkedEntities.${this.projectId}.id`, '==', this.projectId),
      orderBy('documentDate', 'desc')
    );
  }

  editDocument(document: FirestoreEntity<Document>) {
    this.dialog.open(DocumentSlideOverComponent, { data: { documents: [document] } });
  }
}
