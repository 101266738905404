<ng-container *ngIf="rows$ | async as rows">
  <div
    class="bg-white shadow sm:rounded-lg overflow-hidden print:shadow-none"
    *ngIf="rows.length > 0; else noOutstandingPayments"
  >
    <div
      class="grid grid-cols-[minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)_minmax(20px,20px)] sm:grid-cols-[minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)_minmax(20px,20px)] gap-2 py-4 pl-4 group"
    >
      <div>
        <button
          class="pr-4 flex justify-between w-full text-gray-400 group-hover:text-gray-900"
          (click)="sortDateToggle()"
        >
          <div class="text-right text-sm font-semibold">Datum</div>
          <app-ng-icon
            class="text-xl"
            name="heroArrowLongDown"
            [class.rotate-180]="(sortDate | async) === 'asc'"
          ></app-ng-icon>
        </button>
      </div>
      <div class="hidden sm:block"></div>
      <div class="hidden sm:block"></div>

      <div class="text-right text-sm font-semibold text-gray-900">Einnahmen</div>
      <div class="text-right text-sm font-semibold text-gray-900">Ausgaben</div>
      <div></div>
    </div>
    <button
      *ngFor="let row of rows; first as first"
      (click)="edit(row.document)"
      [class.border-t]="first"
      [class.text-red-600]="row.overdue"
      [class.hover:text-red-900]="row.overdue"
      class="w-full border-b border-gray-200 grid grid-cols-[minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)_minmax(20px,20px)] sm:grid-cols-[minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)_minmax(20px,20px)] gap-2 text-sm text-left font-normal text-gray-500 hover:text-gray-800 hover:bg-gray-50 leading-4 group items-center"
    >
      <div class="p-4">
        {{ row.valueDate | date : 'd. MMM yy' }}
        <div
          class="truncate text-gray-600 block sm:hidden text-xs"
          [class.text-red-600]="row.overdue"
          [class.group-hover:text-red-900]="row.overdue"
        >
          {{ row.document.data.name }}
        </div>
        <div class="truncate block sm:hidden text-xs">
          {{ row.legalEntityDisplayName ? row.legalEntityDisplayName : '-' }}
        </div>
      </div>
      <div class="truncate hidden sm:block">
        {{ row.legalEntityDisplayName ? row.legalEntityDisplayName : '-' }}
      </div>
      <div
        class="truncate font-medium text-gray-600 hidden sm:block"
        [class.text-red-600]="row.overdue"
        [class.group-hover:text-red-900]="row.overdue"
      >
        {{ row.document.data.name }}
      </div>

      <div class="text-right font-mono">
        {{ row.revenue | number : '1.2-2' : 'de-CH' }}
      </div>
      <div class="text-right font-mono">
        {{ row.expense | number : '1.2-2' : 'de-CH' }}
      </div>
      <app-dropdown-minimal-menu class="place-self-center h-full">
        <app-dropdown-button>
          <app-ng-icon
            name="heroEllipsisVerticalSolid"
            class="text-3xl"
            strokeWidth="3"
          ></app-ng-icon>
        </app-dropdown-button>
        <app-dropdown-item (menuClick)="markAsPaid(row.document)">
          Als bezahlt markieren
        </app-dropdown-item>
      </app-dropdown-minimal-menu>
    </button>
    <div
      class="grid grid-cols-[minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)_minmax(20px,20px)] sm:grid-cols-[minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)_minmax(20px,20px)] py-4 pl-4 gap-2 bg-gray-50 font-medium"
    >
      <div class="text-sm = text-gray-500">Total</div>
      <div class="hidden sm:block"></div>
      <div class="hidden sm:block"></div>

      <ng-container *ngIf="total$ | async as total">
        <div class="text-right text-sm text-gray-500 font-mono">
          {{ total.revenue | number : '1.2-2' : 'de-CH' }}
          <div
            *ngIf="total.revenue - total.expense > 0"
            class="pt-2 text-right text-sm text-red-600 font-mono"
          >
            {{ total.revenue - total.expense | number : '1.2-2' : 'de-CH' }}
          </div>
        </div>
        <div class="text-right text-sm text-gray-500 font-mono">
          {{ (total$ | async)?.expense | number : '1.2-2' : 'de-CH' }}
          <div
            *ngIf="total.expense - total.revenue > 0"
            class="pt-2 text-right text-sm text-red-600 font-mono"
          >
            {{ total.expense - total.revenue | number : '1.2-2' : 'de-CH' }}
          </div>
        </div>
        <div></div>
      </ng-container>
    </div>
  </div>
  <ng-template #noOutstandingPayments>
    <app-empty message="Keine ausstehenden Zahlungen" disableAction></app-empty>
  </ng-template>
</ng-container>
