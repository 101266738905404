import { Component } from '@angular/core';
import { DocumentCardComponent } from '../../document/list/document-card/document-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { DocumentService } from '../../../services/document.service';
import { filter, switchMap } from 'rxjs/operators';
import { FirestoreOrgaUser } from 'commons';
import { EmptyStateComponent } from '../../../components/empty/empty-state.component';

@Component({
  selector: 'app-my-comment-page',
  standalone: true,
  imports: [DocumentCardComponent, AsyncPipe, NgIf, EmptyStateComponent],
  templateUrl: './my-comments.page.html',
})
export class MyCommentsPage {
  documents = this.sessionService.getOrgaUser().pipe(
    filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
    switchMap((user) => this.documentService.getDocumentsWithNotReactedComments(user?.data.userId))
  );

  constructor(
    private documentService: DocumentService,
    private sessionService: SessionStateService
  ) {}

  async ngOnInit() {}
}
