import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FirestoreWork, IsoDate } from 'commons';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { FormatWorkPipe } from '../../../../pipes/format-work.pipe';
import { TimerRowComponent, TimerRowEntity } from './rows/timer-row.component';
import { WorkRowComponent } from './rows/work-row.component';
import { FormatSecondsPipe } from '../../../../pipes/format-seconds.pipe';
import { SecondsPassedPipe } from '../../../../pipes/seconds-passed.pipe';
import { isToday, parseISO } from 'date-fns';

@Component({
  selector: 'app-timer-work-list',
  standalone: true,
  imports: [
    NgForOf,
    DatePipe,
    TailwindButtonDirective,
    FormatWorkPipe,
    TimerRowComponent,
    WorkRowComponent,
    FormatSecondsPipe,
    SecondsPassedPipe,
    NgIf,
  ],
  templateUrl: './timer-work-list.component.html',
})
export class TimerWorkListComponent {
  @Input({ required: true }) selectedDate: IsoDate = new Date().toISOString();
  @Input({ required: true }) works: FirestoreWork[] | null = null;
  @Input({ required: true }) timerRows: TimerRowEntity[] | null = null;
  @Output() createTimer = new EventEmitter<IsoDate>();
  @Output() showTimer = new EventEmitter<string>();
  @Output() createWorkEntry = new EventEmitter<IsoDate>();
  @Output() start = new EventEmitter<string>();
  @Output() stop = new EventEmitter();
  @Output() editWork = new EventEmitter<FirestoreWork>();

  totalWorkMinutes = 0;
  activeTimerStartTime: IsoDate | null = null;

  isToday = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate) {
      this.isToday = isToday(parseISO(this.selectedDate));
    }
    if (changes.works || changes.timerRows) {
      this.totalWorkMinutes =
        (this.works
          ?.map((fsWork) => fsWork.data)
          .reduce((prev, cur) => prev + cur.workMinutes, 0) ?? 0) +
        (this.timerRows?.reduce((prev, cur) => prev + (cur.workMinutes ?? 0), 0) ?? 0);
    }
    if (changes.timerRows) {
      this.activeTimerStartTime = this.timerRows
        ? (this.timerRows.find((timer) => timer.timerState === 'active')?.startTime ?? null)
        : null;
    }
  }

  trackById(index: number, item: any): any {
    return item.id;
  }
}
