import { ChangeDetectionStrategy, Component, inject, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { ContainerComponent } from '../../../components/container/container.component';
import { SidebarNavigationEntryComponent } from '../../../components/container/sidebar-navigation/sidebar-navigation-entry/sidebar-navigation-entry.component';
import { Router, RouterOutlet } from '@angular/router';
import { SidebarNavigationComponent } from '../../../components/container/sidebar-navigation/sidebar-navigation.component';
import { HeaderMetaActionComponent } from '../../../components/container/header-meta-action/header-meta-action.component';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { TimerService } from '../../../services/timer.service';
import { formatISO } from 'date-fns';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  standalone: true,
  imports: [
    ContainerComponent,
    SidebarNavigationComponent,
    SidebarNavigationEntryComponent,
    RouterOutlet,
    HeaderMetaActionComponent,
    TailwindButtonDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePage {
  timerService = inject(TimerService);
  router = inject(Router);
  // commentService = inject(CommentService);

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  // async ngOnInit() {
  //   console.log(await this.commentService.getDocumentUserMentionCount());
  // }

  today(): string {
    return formatDate(new Date(), 'd. MMMM y', this.locale);
  }

  async startTimer() {
    const today = formatISO(new Date(), { representation: 'date' });
    const timerId = await this.timerService.createTimer(today);
    await this.timerService.startTimer(timerId);
    await this.router.navigate(['calendar', today, 'timer', timerId]);
  }
}
