import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-invoice-preview',
  standalone: true,
  imports: [PdfViewerModule],
  templateUrl: './preview.component.html',
})
export class PreviewComponent implements OnChanges {
  @Input() src!: Uint8Array;

  _src!: Uint8Array;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    if (this.src) {
      this._src = this.src.slice();
    }
  }
}
