import { Component, Input, OnChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InvoiceSettingsFormComponent } from '../../../components/forms/invoice-settings-form/invoice-settings-form.component';
import { SettingsCardComponent } from '../settings-card/settings-card.component';
import { AsyncPipe, DatePipe, DecimalPipe, NgForOf } from '@angular/common';
import { OrgaInvoiceCardComponent } from '../orga-settings/orga-invoice-card.component';
import { VatRate } from 'commons';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { formatISO } from 'date-fns';
import { FormatPercent } from '../../../pipes/format-percent';

@Component({
  selector: 'app-vat-settings-card',
  standalone: true,
  imports: [
    FormsModule,
    InvoiceSettingsFormComponent,
    SettingsCardComponent,
    AsyncPipe,
    OrgaInvoiceCardComponent,
    TailwindInputDirective,
    NgForOf,
    DatePipe,
    DecimalPipe,
    FormatPercent,
  ],
  templateUrl: './vat-settings-card.component.html',
})
export class VatSettingsCardComponent implements OnChanges {
  @Input() vatRates!: VatRate[];

  activeRateIndex = 0;

  ngOnChanges(): void {
    const today = formatISO(new Date(), { representation: 'date' });
    if (this.vatRates) {
      this.activeRateIndex = this.vatRates.findIndex(
        (vatRate) => vatRate.validFrom <= today && vatRate.validTo >= today
      );
    }
  }
}
