import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { add, addMonths, parseISO, startOfMonth } from 'date-fns';
import { Observable } from 'rxjs';
import { FirestoreWork, IsoDate } from 'commons';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { MonthCalendarComponent } from '../month-calendar/month-calendar.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styles: [':host { @apply relative}'],
  standalone: true,
  imports: [MonthCalendarComponent, NgIconWrapperComponent, RouterOutlet],
})
export class CalendarComponent implements OnInit {
  @Input({ required: true }) work: Map<IsoDate, number> = new Map();
  @Input({ required: true }) selectedDate: IsoDate = new Date().toISOString();
  @Input({ required: true }) selectedMonth: IsoDate = new Date().toISOString();
  @Input() warningDays: IsoDate[] = [];

  @Output() selectedDateChange = new EventEmitter<IsoDate>();
  @Output() selectedMonthChange = new EventEmitter<IsoDate>();

  months: IsoDate[] = [];

  selectedDateWorkItems$!: Observable<FirestoreWork[]>;
  selectedDateWorkItemsSum$!: Observable<string>;

  //timers: Observable<FirestoreTimer[]> = this.timerService.getTimer();

  constructor() {} // private timerService: TimerService // private workSlideOver: WorkSlideOverService, // private sessionState: SessionStateService, // private workService: WorkService,

  ngOnChanges(): void {
    this.months = [
      addMonths(startOfMonth(parseISO(this.selectedMonth)), -2).toISOString(),
      addMonths(startOfMonth(parseISO(this.selectedMonth)), -1).toISOString(),
      startOfMonth(parseISO(this.selectedMonth)).toISOString(),
    ];
  }

  ngOnInit(): void {
    // this.months$ = this.currentMonth$.pipe(
    //   map((currentMonth) => [
    //     addMonths(startOfMonth(currentMonth), -2).toISOString(),
    //     addMonths(startOfMonth(currentMonth), -1).toISOString(),
    //     startOfMonth(currentMonth).toISOString(),
    //   ])
    //);
    // this.selectedDateWorkItems$ = combineLatest([
    //   this.sessionState.getOrgaUserId(),
    //   this.selectedDate,
    // ]).pipe(
    //   switchMap(([orgaUserId, date]) => this.workService.getAllWorkForUser(orgaUserId, date, date))
    // );
    // this.selectedDateWorkItemsSum$ = this.selectedDateWorkItems$.pipe(
    //   map((items) => items.reduce((prev, curr) => prev + curr.data.workMinutes, 0)),
    //   map((sum) => (sum / 60).toFixed(2))
    // );
  }

  nextMonth() {
    this.selectedMonthChange.emit(add(parseISO(this.selectedMonth), { months: 1 }).toISOString());
  }

  previousMonth() {
    this.selectedMonthChange.emit(add(parseISO(this.selectedMonth), { months: -1 }).toISOString());
  }

  // async deleteWork(work: FirestoreWork) {
  //   try {
  //     await this.workService.deleteWork(work);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  // modifyWork(work: FirestoreWork) {
  //   this.workSlideOver.edit(work);
  // }
  //
  // createWork(date: Date) {
  //   this.workSlideOver.new({ date: formatISO(date, { representation: 'date' }) });
  // }
  //
  // async startTimer(id: string) {
  //   await this.timerService.startTimer(id);
  // }
  //
  // async stopTimer() {
  //   await this.timerService.stopTimer();
  // }
  //
  // async createTimer(value: Date) {
  //   await this.timerService.createTimer(value.toISOString(), true);
  // }
  //
  // async createAndStartTimer(value: Date) {
  //   const timerId = await this.timerService.createTimer(value.toISOString());
  //   await this.timerService.startTimer(timerId);
  // }
  //
  // collapse(timer: FirestoreTimer) {
  //   this.timerService.collapse(timer);
  // }
  // expand(timer: FirestoreTimer) {
  //   this.timerService.expand(timer);
  // }
  //
  // updateWork(timer: FirestoreTimer, work: NullablePartial<WorkEntity2>) {
  //   this.timerService.updateEmbeddedWork(timer, work);
  // }
  //
  // delete(timer: FirestoreTimer) {
  //   this.timerService.deleteTimer(timer);
  // }
}
