import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { TabOldDirective } from './tab.directive';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tabs.component.html',
  standalone: true,
  imports: [NgIf, NgFor, NgTemplateOutlet],
})
export class TabsComponent {
  @Input() activeTab = 0;

  @ContentChildren(TabOldDirective, { read: TemplateRef }) templateRefChildren!: QueryList<
    TemplateRef<any>
  >;
  @ContentChildren(TabOldDirective) titleChildren!: QueryList<TabOldDirective>;
  @Output() tabChanged = new EventEmitter<number>();

  get titles(): string[] {
    return this.titleChildren.map((templateTitle) => templateTitle.title);
  }

  get templates(): TemplateRef<any>[] {
    return this.templateRefChildren.toArray();
  }

  activateTab(i: number) {
    this.activeTab = i;
    this.tabChanged.emit(this.activeTab);
  }
}
