import { Address, IsoDate } from 'commons';
import { isSameMonth, parseISO } from 'date-fns';
import Big from 'big.js';
import { Alignment, Content } from 'pdfmake/interfaces';
import { InvoiceEntity2 } from 'commons/dist/entities/invoice2';
import { formatIBAN } from 'swissqrbill/utils';

export function cm(cm: number) {
  return cm * 2.835 * 10;
}

export function humanDate(date: IsoDate, format: Intl.DateTimeFormat) {
  return format.format(parseISO(date));
}

export const DATE_FORMAT_LONG = new Intl.DateTimeFormat('de-DE', {
  day: '2-digit',
  month: 'long',
  year: 'numeric',
});
export const DATE_FORMAT_NUMERIC = new Intl.DateTimeFormat('de-DE', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});
export const DATE_FORMAT_MONTH = new Intl.DateTimeFormat('de-DE', {
  month: 'long',
  year: 'numeric',
});

export function formatNumber(
  amount: Big | number,
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
) {
  return new Intl.NumberFormat('de-CH', {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(Big(amount).toNumber());
}

export function addressBlock(name: string, address: Address): Content {
  return {
    stack: [
      name,
      address.addressLine1,
      address.addressLine2,
      { text: [address.zipCode, ' ', address.city] },
    ],
  };
}

export function invoiceFooter(invoice: InvoiceEntity2) {
  return {
    fontSize: 9,
    alignment: 'center' as Alignment,
    stack: [
      {
        text: [
          invoice.sender.name,
          ' ',
          invoice.sender.address.zipCode,
          ' ',
          invoice.sender.address.city,
          ...(invoice.sender.vatUid ? [' • ', invoice.sender.vatUid, ' MWST'] : []),
        ],
      },
      {
        text: [
          invoice.bankDetail.name,
          ...(invoice.bankDetail.bic ? [' • BIC ', invoice.bankDetail.bic] : []),
          ' • IBAN ',
          formatIBAN(invoice.bankDetail.iban),
        ],
      },
    ],
  };
}

export function invoiceInformationBlock(invoice: InvoiceEntity2) {
  return {
    table: {
      body: [
        [
          { text: 'Rechnung', alignment: 'left', marginTop: 1, lineHeight: 1, style: 'small' },
          {
            text: ['#', invoice.invoiceNumber],
            alignment: 'left',
            margin: 0,
            lineHeight: 1,
            bold: true,
          },
        ],
        [
          { text: 'Projekt', alignment: 'left', marginTop: 1, lineHeight: 1, style: 'small' },
          {
            text: invoice.project.displayName,
            alignment: 'left',
            margin: 0,
            lineHeight: 1,
            bold: true,
          },
        ],
        ...(invoice.deliveryPeriod.from && invoice.deliveryPeriod.to
          ? [
              [
                {
                  text: 'Lieferzeitraum',
                  alignment: 'left',
                  marginTop: 1,
                  lineHeight: 1,
                  style: 'small',
                },
                {
                  text: [
                    ...getDeliveryPeriod(invoice.deliveryPeriod.from, invoice.deliveryPeriod.to),
                  ],
                  bold: true,
                  alignment: 'left',
                  margin: 0,
                  lineHeight: 1,
                },
              ],
            ]
          : []),
      ],
    },
    layout: 'noBorders',
  };
}

export function bankInformationBlock(invoice: InvoiceEntity2) {
  return {
    table: {
      body: [
        [
          { text: 'Bank', alignment: 'left', marginTop: 1, lineHeight: 1, style: 'small' },
          {
            text: invoice.bankDetail.name,
            alignment: 'left',
            margin: 0,
            lineHeight: 1,
            bold: true,
          },
        ],
        [
          { text: 'IBAN', alignment: 'left', marginTop: 1, lineHeight: 1, style: 'small' },
          {
            text: formatIBAN(invoice.bankDetail.iban),
            alignment: 'left',
            margin: 0,
            lineHeight: 1,
            bold: true,
          },
        ],
      ],
    },
    layout: 'noBorders',
  };
}

function getPositionUnit(unit: 'hours' | 'piece' | 'fixedPrice' | 'none') {
  switch (unit) {
    case 'hours':
      return 'Stunden';
    case 'fixedPrice':
      return 'Fixpreis';
    case 'none':
      return '';
    case 'piece':
      return 'Stück';
  }
}

export function getPositionTable(invoice: InvoiceEntity2) {
  return {
    table: {
      widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'], // Adjust the widths as necessary
      heights: function (i: number) {
        const positionCount = invoice.positions.positionsSort.length;
        return i > 0 && i < positionCount ? cm(1.1) : 'auto';
      },
      body: [
        // Header row
        [
          { text: 'Beschreibung', style: 'small' },
          { text: 'MwSt.', alignment: 'right', style: 'small' },
          { text: 'Menge', style: 'small' },
          { text: 'Einheit', style: 'small' },
          { text: 'Preis/Einheit', alignment: 'right', style: 'small' },
          { text: 'Summe', alignment: 'right', style: 'small' },
        ],
        // First item row
        ...Object.values(invoice.positions.positions).map((position) => [
          { text: position.displayName, bold: true, marginTop: 8 },
          {
            text: [formatNumber(new Big(position.vatRate).mul(100), 1, 1), '%'],
            marginTop: 8,
            alignment: 'right',
          },
          { text: formatNumber(new Big(position.quantity ?? 0)), alignment: 'right', marginTop: 8 },
          { text: getPositionUnit(position.unit), marginTop: 8 },
          {
            text: formatNumber(new Big(position.unitPrice ?? 0)),
            alignment: 'right',
            marginTop: 8,
          },
          {
            text: formatNumber(new Big(position.totalPrice ?? 0)),
            alignment: 'right',
            marginTop: 8,
          },
        ]),

        // Empty row for spacing
        [{ text: ' ', colSpan: 6 }, {}, {}, {}, {}, {}],
        // Subtotal row
        [
          { text: '', colSpan: 4 },
          {},
          {},
          {},
          { text: 'Zwischentotal', alignment: 'right' },
          {
            text: formatNumber(new Big(invoice.invoiceTotals.netAmount ?? 0)),
            alignment: 'right',
          },
        ],
        // Tax row
        ...invoice.invoiceTotals.vat.map((vat) => [
          { text: '', colSpan: 4 },
          {},
          {},
          {},
          { text: [formatNumber(new Big(vat.rate).mul(100), 1, 1), '% MwSt'], alignment: 'right' },
          { text: formatNumber(new Big(vat.amount ?? 0)), alignment: 'right' },
        ]),
        // Total row
        [
          { text: '', colSpan: 4 },
          {},
          {},
          {},
          { text: 'Total CHF', alignment: 'right', bold: true, marginTop: 8 },
          {
            text: formatNumber(new Big(invoice.invoiceTotals.totalAmount ?? 0)),
            alignment: 'right',
            bold: true,
            marginTop: 8,
          },
        ],
      ],
    },
    layout: {
      hLineWidth: function (i: number, node: unknown) {
        return i === 1 ? 0.5 : 0; // Thin line after the header row
      },
      hLineColor: function (i: number, node: unknown) {
        return 'gray';
      },
      vLineWidth: function (i: number, node: unknown) {
        return 0;
      },
    },
  };
}

function getDeliveryPeriod(from: IsoDate, to: IsoDate) {
  if (isSameMonth(parseISO(from), parseISO(to))) {
    return humanDate(from, DATE_FORMAT_MONTH);
  } else {
    return [humanDate(from, DATE_FORMAT_NUMERIC), ' - ', humanDate(to, DATE_FORMAT_NUMERIC)];
  }
}
