import { Component, OnDestroy } from '@angular/core';
import { groupBy, mapValues } from 'lodash';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { WorkService } from '../../../services/work.service';
import { DateRange, FirestoreWork, WorkEntity2 } from 'commons';
import { FormatMinutesPipe } from '../../../pipes/format-minutes.pipe';
import { PositionStatsRowComponent } from '../components/project-stats-table/position-stats-row/position-stats-row.component';
import { ProjectStatsRowComponent } from '../components/project-stats-table/project-stats-row/project-stats-row.component';
import { AsyncPipe, NgFor } from '@angular/common';
import { ProjectStatsTableComponent } from '../components/project-stats-table/project-stats-table.component';
import { DescriptionListEntryComponent } from '../../../components/two-col-description-list/description-list-entry.component';
import { TwoColDescriptionListComponent } from '../../../components/two-col-description-list/two-col-description-list.component';
import { CardContentComponent } from '../../../components/card/card-content/card-content.component';
import { CardComponent } from '../../../components/card/card.component';
import { DateFilterPillComponent } from '../../../components/filterpills/date-filter-pill.component';

interface PositionStats {
  positionId: string;
  positionName: string;
  billableMinutes: number;
  unbillableMinutes: number;
  totalMinutes: number;
}

interface ProjectStats {
  projectId: string;
  projectName: string;
  billableMinutes: number;
  unbillableMinutes: number;
  totalMinutes: number;
  positionStats: PositionStats[];
}

interface UserStats {
  userId: string;
  displayName: string;
  billableMinutes: number;
  unbillableMinutes: number;
  totalMinutes: number;
  projectsStats: ProjectStats[];
}

@Component({
  selector: 'app-data',
  templateUrl: './data.page.html',
  standalone: true,
  imports: [
    DateFilterPillComponent,
    CardComponent,
    CardContentComponent,
    TwoColDescriptionListComponent,
    DescriptionListEntryComponent,
    ProjectStatsTableComponent,
    NgFor,
    ProjectStatsRowComponent,
    PositionStatsRowComponent,
    AsyncPipe,
    FormatMinutesPipe,
  ],
})
export class DataPage implements OnDestroy {
  allWorks!: WorkEntity2[];

  billableMinutes!: number;
  unbillableMinutes!: number;
  totalMinutes!: number;

  projectStats!: ProjectStats[];
  userStats!: UserStats[];

  dateFilter = new BehaviorSubject<DateRange>({
    type: 'month',
    month: new Date(),
  });

  year = new BehaviorSubject<number>(new Date().getFullYear());

  private subscriptions = new Subscription();

  constructor(private workService: WorkService) {
    this.subscriptions.add(
      this.dateFilter
        .pipe(
          switchMap((date) =>
            this.workService.getWorkByFilter({
              position: null,
              project: null,
              user: null,
              date,
            })
          )
        )
        .subscribe((allWorks) => this.initData(allWorks))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initData(works: FirestoreWork[]) {
    this.allWorks = works.map((fsWork) => fsWork.data);

    this.totalMinutes = this.allWorks.reduce<number>((acc, cur) => acc + cur.workMinutes, 0);
    this.billableMinutes = this.allWorks
      .filter((value) => value.position.billable)
      .reduce<number>((acc, cur) => acc + cur.workMinutes, 0);

    this.unbillableMinutes = this.allWorks
      .filter((value) => !value.position.billable)
      .reduce<number>((acc, cur) => acc + cur.workMinutes, 0);

    this.projectStats = this.calcProjectsStats(this.allWorks);
    this.userStats = this.calcUserStats(this.allWorks);
  }

  private calcProjectsStats(allWorks: WorkEntity2[]) {
    const projects = groupBy(allWorks, (value) => value.project.id);
    return Object.values(
      mapValues(projects, (value, key, object) => ({
        projectId: value[0].project.id,
        projectName: value[0].project.displayName,
        totalMinutes: value.reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
        billableMinutes: value
          .filter((value) => value.position.billable)
          .reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
        unbillableMinutes: value
          .filter((value) => !value.position.billable)
          .reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
        positionStats: this.calcPositionStats(value),
      }))
    ).sort((a, b) => b.totalMinutes - a.totalMinutes);
  }

  private calcPositionStats(allWorks: WorkEntity2[]) {
    const projects = groupBy(allWorks, (value) => value.position.id);
    return Object.values(
      mapValues(projects, (value, key, object) => ({
        positionId: value[0].position.id,
        positionName: value[0].position.displayName,
        totalMinutes: value.reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
        billableMinutes: value
          .filter((value) => value.position.billable)
          .reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
        unbillableMinutes: value
          .filter((value) => !value.position.billable)
          .reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
      }))
    ).sort((a, b) => b.totalMinutes - a.totalMinutes);
  }

  private calcUserStats(allWorks: WorkEntity2[]) {
    const users = groupBy(allWorks, (value) => value.orgaUser.id);
    return Object.values(
      mapValues(users, (value, key, object) => ({
        userId: value[0].orgaUser.id,
        displayName: value[0].orgaUser.displayName,
        totalMinutes: value.reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
        billableMinutes: value
          .filter((value) => value.position.billable)
          .reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
        unbillableMinutes: value
          .filter((value) => !value.position.billable)
          .reduce<number>((acc, cur) => acc + cur.workMinutes, 0),
        projectsStats: this.calcProjectsStats(value),
      }))
    );
  }
}
