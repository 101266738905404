import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CalendarComponent } from '../components/calendar/calendar/calendar.component';
import { BehaviorSubject, combineLatest, map, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { addMonths, formatISO, lastDayOfMonth, parseISO, startOfDay, startOfMonth } from 'date-fns';
import { groupBy } from 'lodash';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { IsoDate } from 'commons';
import { WorkService } from '../../../services/work.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TimerService } from '../../../services/timer.service';

@Component({
  selector: 'app-calendar-page',
  standalone: true,
  imports: [CalendarComponent, AsyncPipe, NgIf],
  templateUrl: './calendar.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarPage {
  sessionState = inject(SessionStateService);
  workService = inject(WorkService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  timerService = inject(TimerService);

  selectedMonth: Subject<IsoDate> = new BehaviorSubject(
    formatISO(new Date(), { representation: 'date' })
  );

  selectedDate = this.activatedRoute.params.pipe(
    map((params) => params.selectedDate),
    tap((selectedDate) => this.selectedMonth.next(selectedDate))
  );

  work = combineLatest([this.sessionState.getOrgaUserId(), this.selectedMonth]).pipe(
    switchMap(([userId, selectedMonth]) =>
      this.workService.getAllWorkForUser(
        userId,
        addMonths(startOfMonth(parseISO(selectedMonth)), -2).toISOString(),
        lastDayOfMonth(parseISO(selectedMonth)).toISOString()
      )
    ),
    map((works) => groupBy(works, (work) => startOfDay(new Date(work.data.date)).toDateString())),
    map((workMap) => {
      const w = new Map<string, number>();
      for (const [key, value] of Object.entries(workMap)) {
        w.set(
          key,
          value.reduce(
            (previousValue, currentValue) => previousValue + currentValue.data.workMinutes,
            0
          ) / 60
        );
      }
      return w;
    })
  );

  warningDays = this.timerService
    .getOutdatedTimers()
    .pipe(map((timers) => timers.map((timer) => timer.data.date)));

  selectedDateChange($event: IsoDate) {
    this.router.navigate(['..', $event], { relativeTo: this.activatedRoute });
  }
}
