import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgIconWrapperComponent],
})
export class InputComponent {
  // todo samuel: duplicate code with currency input component

  @Input()
  label?: string;

  @Input()
  enhanced = false;

  hasErrors = false;

  @Input()
  set errors(errors: ValidationErrors | null | undefined) {}
}
