import { Component, Input, SimpleChanges } from '@angular/core';
import { InputComponent } from '../../input/input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TailwindInputDirective } from '../../input/tailwind-input.directive';
import { FormControlsOf } from '../../../util/form-helpers';
import { InvoiceSettings } from 'commons';
import { NgIf } from '@angular/common';
import { TextInputComponent } from '../../input/text-input/text-input.component';

@Component({
  selector: 'app-invoice-settings-form',
  standalone: true,
  imports: [InputComponent, ReactiveFormsModule, TailwindInputDirective, NgIf, TextInputComponent],
  templateUrl: './invoice-settings-form.component.html',
})
export class InvoiceSettingsFormComponent {
  @Input() invoiceSettingsForm!: FormGroup<InvoiceSettingsForm>;
  @Input() showInvoiceNumber = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showInvoiceNumber) {
      if (this.showInvoiceNumber) {
        this.invoiceSettingsForm.controls.currentInvoiceNumber.enable({ emitEvent: false });
        this.invoiceSettingsForm.controls.currentInvoiceNumber.addValidators(Validators.required);
      } else {
        this.invoiceSettingsForm.controls.currentInvoiceNumber.disable({ emitEvent: false });
        this.invoiceSettingsForm.controls.currentInvoiceNumber.removeValidators(
          Validators.required
        );
      }
    }
  }
}

export type InvoiceSettingsForm = FormControlsOf<InvoiceSettings>;

export function getInvoiceSettingsForm(invoiceSettings: InvoiceSettings | null | undefined) {
  return new FormGroup<InvoiceSettingsForm>({
    vatNumber: new FormControl(invoiceSettings?.vatNumber ?? null, [Validators.required]),
    defaultVatRate: new FormControl(invoiceSettings?.defaultVatRate ?? null, [Validators.required]),
    defaultPaymentDurationDays: new FormControl(
      invoiceSettings?.defaultPaymentDurationDays ?? null,
      [Validators.required]
    ),
    currentInvoiceNumber: new FormControl(invoiceSettings?.currentInvoiceNumber ?? null, [
      Validators.required,
    ]),
  });
}
