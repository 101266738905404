<ng-container *ngIf="history$ | async as history">
  <div *ngIf="history.length > 0">
    <div [ngClass]="_noCard ? 'px-6' : 'overflow-hidden rounded-lg bg-white shadow py-4 px-6'">
      <ul role="list" class="-mb-8">
        <li *ngFor="let historyElement of history; last as last">
          <div class="relative pb-8">
            <span
              *ngIf="!last"
              class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            ></span>
            <div class="relative flex space-x-3 items-center">
              <span
                class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                [class.!bg-blue-400]="loggedInUserId === historyElement.userId"
              >
                <span class="text-sm font-medium leading-none text-white uppercase">{{
                  historyElement.userInitials
                }}</span>
              </span>
              <div class="text-sm flex min-w-0 flex-1 justify-between">
                <div>
                  <span class="text-gray-500">
                    <ng-container [ngSwitch]="historyElement.type">
                      <span *ngSwitchCase="'create'">Erstellt </span>
                      <span *ngSwitchCase="'update'">Geändert </span>
                      <span *ngSwitchCase="'delete'">Gelöscht </span>
                    </ng-container>
                    <span>durch </span>
                  </span>
                  <span class="font-medium text-gray-900"> {{ historyElement.user }}</span>
                </div>
                <div class="whitespace-nowrap text-right text-sm text-gray-500">
                  <time>{{ historyElement.timestamp | date : 'dd.MM.yyy HH:mm:ss' }} </time>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
