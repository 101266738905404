<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img
      style="filter: invert(100%)"
      class="mx-auto h-12 w-auto"
      src="/assets/jessie-logo.png"
      alt="Workflow"
    />
    <h2 class="mt-6 text-center text-xl font-semibold leading-9 text-gray-900">
      Aufgrund von Wartungsarbeiten steht das System zurzeit nicht zur Verfügung.
    </h2>
    <p class="mt-2 text-center text-base leading-9 text-gray-900">
      Sobald die Arbeiten beendet sind, wirst du automatisch weitergeleitet. Vielen Dank für deine
      Geduld.
    </p>
  </div>
</div>
