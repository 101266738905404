<app-settings-card
  heading="Dokumente per E-Mail senden"
  description="Sende Dokumente (PDF oder Bilder) direkt per E-Mail an jessie. Falls die Email Adresse wegen Spam unbrauchbar wird, dann kannst du sie zurücksetzen."
  (save)="saveSettings()"
  [disabled]="disabled"
>
  <form [formGroup]="addressForm">
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <app-toggle
        [disabled]="disabled"
        class="col-span-2 self-center"
        label="Aktivieren"
        [value]="mailDeliveryActivated"
        (valueChange)="activate($event)"
      ></app-toggle>
      <div class="col-span-4">
        <app-input label="E-Mail Adresse">
          <input
            type="text"
            name="name"
            appTailwindInput
            [formControlName]="'uploadEmail'"
            [disabled]="disabled"
          />
        </app-input>
        <button
          class="mt-2"
          appTailwindButton
          (click)="resetMailAddress()"
          color="white"
          [size]="'xs'"
          [disabled]="!mailDeliveryActivated || disabled"
        >
          Neue E-Mail Adresse generieren
        </button>
      </div>
    </div>
  </form>
</app-settings-card>
