<ng-container *ngIf="position">
  <td class="relative px-7 sm:w-12 sm:px-6" *ngIf="showCheckboxes">
    <input
      type="checkbox"
      disabled
      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
    />
  </td>
  <td
    class="whitespace-nowrap py-3 pr-3 text-sm font-medium text-gray-900"
    [class.pl-3]="!showCheckboxes"
  >
    {{ position.displayName }}
    <app-badge class="ml-2" color="green">Coming Soon</app-badge>
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Fixpreis</td>

  <td class="whitespace-nowrap px-3 py-0 text-sm text-gray-500 text-right tabular-nums">
    <app-number-input [isDisabled]="true" class="block max-w-40 ml-auto" variant="currency">
      /></app-number-input
    >
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right"></td>
  <td
    class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-500 text-right tabular-nums"
  ></td>
  <td *ngIf="!showCheckboxes" class="text-center mt-1">
    <button (click)="deletePosition.emit(position)">
      <app-ng-icon name="heroTrash"></app-ng-icon>
    </button>
  </td>
</ng-container>
