import { inject, Injectable } from '@angular/core';

import { formatISO } from 'date-fns';
import { firstValueFrom, map, Observable } from 'rxjs';
import { FirestoreWork, getInterval, IsoDate, Work, WorkEntity2, WorkFilter } from 'commons';
import { LastEditorService } from './last-editor.service';
import { FIRESTORE_SERVICE, ORGA_FIRESTORE_SERVICE } from './firestore.service';
import {
  collection,
  doc,
  Firestore,
  orderBy,
  QueryConstraint,
  runTransaction,
  where,
  writeBatch,
} from '@angular/fire/firestore';
import { SessionStateService } from './session-state.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);

  private sessionState = inject(SessionStateService);
  private lastEditor = inject(LastEditorService);

  firestore = inject(Firestore);

  getWork(projectId: string, positionId: string, workId: string) {
    return this.orgaFirestoreService.getDoc<Work>(`project/${projectId}/work/${workId}`);
  }

  countWork(projectId: string, positionId: string) {
    return this.orgaFirestoreService.count(
      `project/${projectId}/work`,
      where('position.id', '==', positionId)
    );
  }

  getWorkByDocRef(workDocRef: string): Observable<FirestoreWork> {
    return this.rootFirestoreService.getDoc<Work>(workDocRef);
  }

  getAllWorkBetweenDate(from: Date, to: Date): Observable<FirestoreWork[]> {
    return this.sessionState
      .getOrgaId()
      .pipe(
        switchMap((orgaId) =>
          this.orgaFirestoreService.getDocsByCollectionGroup<Work>(
            'work',
            where('orgaId', '==', orgaId),
            where('date', '>=', formatISO(from, { representation: 'date' })),
            where('date', '<=', formatISO(to, { representation: 'date' }))
          )
        )
      );
  }

  getAllWorkForPosition(projectId: string, positionId: string): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${projectId}/work`,
      where('positionId', '==', positionId)
    );
  }

  getAllWorkForPositionBetweenDate(
    projectId: string,
    positionId: string,
    from: Date,
    to: Date
  ): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${projectId}/work`,
      where('positionId', '==', positionId),
      where('date', '>=', formatISO(from, { representation: 'date' })),
      where('date', '<=', formatISO(to, { representation: 'date' }))
    );
  }

  getAllBillableWorkForPositionBetweenDate(
    projectId: string,
    positionId: string,
    from: Date,
    to: Date
  ): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${projectId}/work`,
      where('positionId', '==', positionId),
      where('date', '>=', formatISO(from, { representation: 'date' })),
      where('date', '<=', formatISO(to, { representation: 'date' })),
      where('billed', '==', false)
    );
  }

  getAllWorkForUser(orgaUserId: string, from: Date, to: Date): Observable<FirestoreWork[]> {
    return this.sessionState
      .getOrgaId()
      .pipe(
        switchMap((orgaId) =>
          this.orgaFirestoreService.getDocsByCollectionGroup<Work>(
            'work',
            where('orgaUser.id', '==', orgaUserId),
            where('orgaId', '==', orgaId),
            where('date', '>=', formatISO(from, { representation: 'date' })),
            where('date', '<=', formatISO(to, { representation: 'date' }))
          )
        )
      );
  }

  getAllWorkForProject(underProjectId: string): Observable<FirestoreWork[]> {
    return this.sessionState
      .getOrgaId()
      .pipe(
        switchMap((orgaId) =>
          this.orgaFirestoreService.getDocsByCollectionGroup<Work>(
            'work',
            where('projectId', '==', underProjectId),
            where('orgaId', '==', orgaId)
          )
        )
      );
  }

  getWorkByFilter(filter: WorkFilter): Observable<FirestoreWork[]> {
    return this.sessionState.getOrgaId().pipe(
      map((orgaId) => {
        const filterQuery: QueryConstraint[] = [where('orgaId', '==', orgaId)];
        if (filter.project) {
          filterQuery.push(where('project.id', '==', filter.project.id));
        }
        if (filter.position) {
          filterQuery.push(where('position.id', '==', filter.position.id));
        }
        if (filter.user) {
          filterQuery.push(where('orgaUser.id', '==', filter.user.id));
        }
        if (filter.date.type !== 'notSet') {
          const interval = getInterval(filter.date);
          filterQuery.push(
            where('date', '>=', formatISO(interval.start, { representation: 'date' })),
            where('date', '<=', formatISO(interval.end, { representation: 'date' }))
          );
        }
        filterQuery.push(orderBy('date', 'asc'));
        return filterQuery;
      }),
      switchMap((filterQuery) =>
        this.orgaFirestoreService.getDocsByCollectionGroup<Work>('work', ...filterQuery)
      )
    );
  }

  getAllWorkForProjectBetween(
    underProjectId: string,
    from: Date,
    to: Date
  ): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${underProjectId}/work`,
      where('date', '>=', formatISO(from, { representation: 'date' })),
      where('date', '<=', formatISO(to, { representation: 'date' }))
    );
  }

  getAllBillableWorkForProjectBetweenDate2(
    underProjectId: string,
    from: IsoDate,
    to: IsoDate
  ): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${underProjectId}/work`,
      where('date', '>=', from),
      where('date', '<=', to),
      where('position.billable', '==', true),
      where('position.type', '==', 'effort'),
      where('invoice', '==', null)
    );
  }

  async createWorks(works: WorkEntity2[]) {
    const orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    const batch = writeBatch(this.firestore);
    //add orgaId
    const updatedWorks = works.map((work) => ({ ...work, orgaId }));
    await Promise.all(
      updatedWorks.map(async (work) =>
        batch.set(
          doc(collection(this.firestore, `orga/${orgaId}/project/${work.project.id}/work`)),
          await this.lastEditor.hydrate(work)
        )
      )
    );
    return batch.commit();
  }

  updateWork(work: FirestoreWork, update: Partial<WorkEntity2>): Promise<void> {
    return this.updateWorkInternal(update, work.docRef.path);
  }

  async updateWorkInternal(work: Partial<Work>, docRefPath: string): Promise<void> {
    return this.rootFirestoreService.updateDoc<Work>(
      docRefPath,
      await this.lastEditor.hydrate(work)
    );
  }

  async deleteWork(work: FirestoreWork): Promise<unknown> {
    // do a update to insert lastEditor, else a delete would fail
    await this.updateWorkInternal({}, work.docRef.path);
    return this.rootFirestoreService.deleteDoc(work.docRef.path);
  }

  async bulkUpdateInvoiceState(
    works: Work[],
    invoiceId: string,
    invoiceNumber: string,
    billed: boolean
  ) {
    return runTransaction(this.firestore, async (transaction) => {
      const workDocs = await Promise.all(
        works.map(async (work) => {
          const workDoc = await transaction.get(
            doc(this.firestore, `orga/${work.orgaId}/project/${work.project.id}/work/${work.id}`)
          );
          if (!workDoc.exists()) {
            throw `Work with id ${work.id} does not exist`;
          }
          const workEntity: WorkEntity2 = workDoc.data() as WorkEntity2;

          if (billed && workEntity.invoice) {
            throw `Work with id ${work.id} already billed`;
          }
          if (!billed && workEntity.invoice && workEntity.invoice.id !== invoiceId) {
            throw `Work with id ${work.id} is is not billed with this invoiceId (${workEntity.invoice.id} vs ${invoiceId}`;
          }
          return workDoc;
        })
      );

      await Promise.all(
        workDocs.map(async (workDoc) => {
          transaction.update(
            workDoc.ref,
            await this.lastEditor.hydrate({
              invoice: billed ? { id: invoiceId, invoiceNumber: invoiceNumber } : null,
            })
          );
        })
      );
    });
  }
}
//
// export function createBaseWork(
//   work: {
//     description: string;
//     workMinutes: number;
//   },
//   user: {
//     userId: string;
//     userDisplayName: string;
//   },
//   orgaId: string,
//   project: FirestoreProject,
//   position: Position2
// ): BaseWork {
//   return {
//     orgaId,
//     userId: user.userId,
//     userDisplayName: user.userDisplayName,
//
//     description: work.description,
//     workMinutes: work.workMinutes,
//
//     positionId: position.id,
//     positionDisplayName: position.name,
//     positionBillable: position.billable,
//     positionType: position.type,
//
//     projectId: project.id,
//     projectDisplayName: project.data.name,
//     customerId: project.data.customerId,
//     customerName: project.data.customerName,
//
//     billed: false,
//   };
// }
