<app-slide-over>
  <app-slide-over-header
    #headerComponent
    (closeSlideOver)="closeSlideOver()"
    [formDirty]="projectForm.dirty"
    [title]="title"
  >
  </app-slide-over-header>

  <app-slide-over-content>
    <form [formGroup]="projectForm">
      <div class="px-4 divide-y divide-gray-200 sm:px-6">
        <div class="space-y-6 pt-6 pb-5">
          <div>
            <app-text-input
              label="Name"
              class="mt-4"
              [formControl]="$any(projectForm.controls).name"
            ></app-text-input>
          </div>

          <div>
            <app-input label="Kunde">
              <ng-select
                class="custom"
                [items]="customers$ | async"
                bindLabel="displayName"
                formControlName="customer"
              >
              </ng-select>
            </app-input>
          </div>
          <div class="pt-4">
            <app-toggle label="Aktiv" formControlName="isActive"></app-toggle>
          </div>
        </div>
      </div>
    </form>
  </app-slide-over-content>

  <app-slide-over-footer>
    <span class="inline-flex rounded-md shadow-sm">
      <button
        appTailwindButton
        color="secondary"
        type="button"
        (click)="headerComponent.closeSlideOverClicked()"
      >
        Abbrechen
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm">
      <button appTailwindButton [disabled]="false" (click)="save()">Speichern</button>
    </span>
  </app-slide-over-footer>
</app-slide-over>
