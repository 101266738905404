<app-calendar
  *ngIf="work | async as work"
  [work]="work"
  [selectedDate]="(selectedDate | async)!"
  [selectedMonth]="(selectedMonth | async)!"
  [warningDays]="(warningDays | async)!"
  (selectedDateChange)="selectedDateChange($event)"
  (selectedMonthChange)="selectedMonth.next($event)"
>
</app-calendar>
