import { inject, Injectable } from '@angular/core';
import {
  Comment,
  createUserDisplayName,
  FirestoreComment,
  FirestoreEntity,
  FirestoreUser,
} from 'commons';
import { LastEditorService } from './last-editor.service';
import { filter, firstValueFrom } from 'rxjs';
import { FIRESTORE_SERVICE, ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { orderBy } from '@angular/fire/firestore';
import { SessionStateService } from './session-state.service';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  private lastEditor = inject(LastEditorService);
  private sessionState = inject(SessionStateService);

  getAllComments<T>(parent: FirestoreEntity<T>) {
    return this.rootFirestoreService.getDocs<Comment>(
      parent.docRef.path + '/comment',
      orderBy('creationDate', 'desc')
    );
  }

  async createComment<T>(parent: FirestoreEntity<T>, comment: Partial<Comment>) {
    const user = await firstValueFrom(
      this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user))
    );
    const orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    if (orgaId) {
      comment = {
        ...comment,
        orgaId,
        creationDate: new Date().toISOString(),
        authorDisplayName: createUserDisplayName(user.data.firstName, user.data.lastName),
        authorUserId: user.id,
      };
      return this.rootFirestoreService.createDoc(
        parent.docRef.path + '/comment',
        await this.lastEditor.hydrate(comment)
      );
    } else {
      throw new Error('no orgaId set');
    }
  }

  async deleteComment(comment: FirestoreComment) {
    // first an update to have a last editor set...
    await this.updateComment(comment.docRef.path, {});
    return this.rootFirestoreService.deleteDoc(comment.docRef.path);
  }

  async updateComment(path: string, comment: Partial<Comment>): Promise<void> {
    return this.rootFirestoreService.updateDoc<Comment>(
      path,
      await this.lastEditor.hydrate(comment)
    );
  }
}
