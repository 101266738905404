<div class="grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
  <app-text-input
    label="UID"
    class="sm:col-span-6"
    [formControl]="invoiceSettingsForm.controls.vatNumber"
  >
  </app-text-input>
  <app-text-input
    label="Mehrwertsteuersatz"
    class="sm:col-span-2"
    [formControl]="invoiceSettingsForm.controls.defaultVatRate"
  >
  </app-text-input>
  <app-text-input
    label="Zahlungsdauer in Tage"
    class="sm:col-span-2"
    [formControl]="invoiceSettingsForm.controls.defaultPaymentDurationDays"
  >
  </app-text-input>
  <app-text-input
    *ngIf="showInvoiceNumber"
    label="Aktuelle Rechnungsnummer"
    class="sm:col-span-2"
    [formControl]="invoiceSettingsForm.controls.currentInvoiceNumber"
  >
  </app-text-input>
</div>
