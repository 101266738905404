import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContainerComponent } from '../../../components/container/container.component';
import { AlertComponent } from '../../../components/alert/alert.component';
import { TabsComponent } from '../../../components/tabs/content-projected/tabs.component';
import { TabOldDirective } from '../../../components/tabs/content-projected/tab.directive';
import { ContentComponent } from './content/content.component';
import { PreviewComponent } from './preview/preview.component';
import { CommentSectionComponent } from '../../comments/comment-section/comment-section.component';
import { FormGroup } from '@angular/forms';
import { InvoiceForm } from '../pages/invoice/invoice.page';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { HeaderMetaActionComponent } from '../../../components/container/header-meta-action/header-meta-action.component';
import { Observable } from 'rxjs';
import { CustomerSummary, OrgaUserSummary } from 'commons';
import { FirestoreInvoice2, InvoicePosition2, InvoiceTotals } from 'commons/dist/entities/invoice2';
import { NgIf } from '@angular/common';
import { PositionTableComponent } from './position-table/position-table.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { InvoiceStateBadgeComponent } from './invoice-state-badge/invoice-state-badge.component';

@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [
    ContainerComponent,
    AlertComponent,
    TabsComponent,
    TabOldDirective,
    ContentComponent,
    PreviewComponent,
    CommentSectionComponent,
    TailwindButtonDirective,
    HeaderMetaActionComponent,
    NgIf,
    PositionTableComponent,
    NgIconWrapperComponent,
    InvoiceStateBadgeComponent,
  ],
  templateUrl: './invoice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceComponent {
  @Input() invoice!: FirestoreInvoice2;
  @Input() invoiceForm!: FormGroup<InvoiceForm>;
  @Input() users!: Observable<OrgaUserSummary[]>;
  @Input() customers!: Observable<CustomerSummary[]>;

  @Input() invoicePositions!: InvoicePosition2[];
  @Input() invoiceSummary!: InvoiceTotals;
  @Input() previewPdf: Uint8Array | null = null;

  @Output() openImportDialog = new EventEmitter();
  @Output() saveInvoice = new EventEmitter();
  @Output() deletedPosition = new EventEmitter<InvoicePosition2>();

  @Output() setInvoiceReady = new EventEmitter();
  @Output() setInvoiceDraft = new EventEmitter();
  @Output() setInvoiceSent = new EventEmitter();
  @Output() setInvoicePaid = new EventEmitter();
  @Output() setInvoiceCanceled = new EventEmitter();
  @Output() downloadDocument = new EventEmitter();
}
