<td class="px-2">
  <button
    *ngIf="timer.timerState === 'active'; else inactive"
    (click)="stop.emit(); $event.stopPropagation()"
    class="hover:bg-blue-200 bg-blue-100 text-blue-600 rounded-full w-12 h-12 sm:w-8 sm:h-8 flex justify-center items-center"
  >
    <app-ng-icon strokeWidth="2" name="heroPause" class="text-2xl sm:text-lg"></app-ng-icon>
  </button>
  <ng-template #inactive>
    <button
      *ngIf="timer.timerState === 'inactive' && !timer.outdated; else warning"
      (click)="start.emit(timer.timerId); $event.stopPropagation()"
      class="hover:bg-blue-200 bg-blue-100 text-blue-600 rounded-full w-12 h-12 sm:w-8 sm:h-8 flex justify-center items-center"
    >
      <app-ng-icon strokeWidth="2" name="heroPlay"></app-ng-icon>
    </button>
    <ng-template #warning
      ><app-ng-icon
        name="heroExclamationTriangle"
        class="py-4 text-red-600"
        tp="Ungespeichert"
        tpPlacement="top"
      ></app-ng-icon>
    </ng-template>
  </ng-template>
</td>
<td
  class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate"
  [tp]="timer.projectDisplayName"
  tpOnlyTextOverflow
>
  {{ timer.projectDisplayName || '' }}

  <div class="sm:hidden text-gray-500 text-xs">
    {{ timer.positionDisplayName || '' }}
  </div>
</td>
<td
  class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell truncate"
  [tp]="timer.positionDisplayName"
  tpOnlyTextOverflow
>
  {{ timer.positionDisplayName || '' }}
</td>
<td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
  <div class="line-clamp-3 2xl:line-clamp-none break-all">
    {{ timer.description }}
  </div>
</td>
<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right tabular-nums">
  {{ timer.workMinutes | formatWork }}
</td>
<td
  class="whitespace-nowrap text-gray-500 text-right text-sm flex justify-between items-center py-4"
>
  <span
    *ngIf="timer.timerState === 'active' && timer.startTime; else filler"
    class="tabular-nums text-sm justify-self-start"
    >+ {{ timer.startTime | secondsPassed | formatSeconds }}</span
  >
  <ng-template #filler><span></span></ng-template>
  <app-ng-icon
    name="heroArrowRight"
    class="arrow-button text-lg text-blue-600 col-start-2 justify-self-end w-12 h-12 sm:w-8 sm:h-8 rounded-full flex justify-center items-center"
  ></app-ng-icon>
</td>
