import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent {
  @Input() sidebar = false;
  @Input() subpage = false;

  constructor(private navigationService: NavigationService) {}

  goBack() {
    this.navigationService.back();
  }
}
