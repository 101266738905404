import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TailwindInputDirective2 } from '../tailwind-input2.directive';
import { FormInputWrapperComponent } from '../form-input-wrapper/form-input-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  standalone: true,
  imports: [TailwindInputDirective2, FormInputWrapperComponent, NgIf],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
  styles: `
    :host {
      @apply block;
    }
  `,
})
export class TextInputComponent implements OnChanges, ControlValueAccessor {
  @Input() label?: string;
  @Input() enhanced = false;
  @Input() variant: 'textfield' | 'textarea' = 'textfield';
  @Input() isDisabled = false;
  @Input() autocomplete?: string;

  @Input() formControl!: FormControl;

  errors!: Observable<string[] | null>;

  value: string | null = null;

  onChange: (value: string | null) => void = () => {};
  onTouched: () => void = () => {};

  ngOnChanges() {
    if (this.formControl) {
      this.errors = this.formControl.events.pipe(
        map(() => (this.formControl.touched ? this.formControl.errors : null)),
        map((x) => (x ? Object.keys(x) : null))
      );
    }
  }

  writeValue(value: string | null) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  valueChanged(value: string) {
    this.onChange(value);
    this.onTouched();
  }

  protected readonly Validators = Validators;
}
