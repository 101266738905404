<app-settings-card
  heading="Kategorien"
  description="Kategorien helfen dir bei der Übersicht über deine Ausgaben."
  [showSaveButton]="false"
  [disabled]="disabled"
>
  <div
    class="mt-4 overflow-hidden rounded-md border border-gray-300 bg-white"
    *ngIf="categories?.length"
  >
    <ul role="list" class="divide-y divide-gray-300">
      <li class="flex justify-between items-center" *ngFor="let category of categories">
        <div class="px-6 py-4 w-full flex flex-row justify-between items-center">
          <div>
            <p *ngIf="category.id !== editId">
              <app-category-badge
                [color]="category.color"
                [displayName]="category.displayName"
              ></app-category-badge>
            </p>

            <div *ngIf="category.id === editId">
              <div class="fixed inset-0 z-10" (click)="editCategory(null)"></div>
              <div class="relative flex gap-4 z-20">
                <app-input class="block -m-2.5"
                  ><input
                    #inputElement
                    appTailwindInput
                    class=""
                    type="text"
                    [(ngModel)]="category.displayName"
                    autofocus
                /></app-input>
                <div class="flex gap-2">
                  <app-category-badge
                    *ngFor="let color of allCategoryColors"
                    [class.outline]="category.color === color"
                    [color]="color"
                    [displayName]="color"
                    class="cursor-pointer"
                    (click)="category.color = color"
                  ></app-category-badge>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-dropdown-minimal-menu>
          <app-dropdown-button>
            <app-ng-icon
              name="heroEllipsisVerticalSolid"
              class="text-3xl"
              strokeWidth="3"
            ></app-ng-icon>
          </app-dropdown-button>
          <app-dropdown-item (menuClick)="editCategory(category.id)">
            Bearbeiten
          </app-dropdown-item>
          <app-dropdown-item (menuClick)="deleteCategory(category.id)"> Löschen </app-dropdown-item>
        </app-dropdown-minimal-menu>
      </li>
    </ul>
  </div>
  <button appTailwindButton (click)="addNewCategory()" color="white" [size]="'xs'" class="mt-2">
    Kategorie erstellen
  </button>
</app-settings-card>
