import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { defer, firstValueFrom, Observable, shareReplay, startWith, Subscription } from 'rxjs';
import {
  CustomerSummary,
  FirestoreOrgaUser,
  FirestoreProject,
  FirestoreWork,
  IsoDate,
  mapOrgaUserSummary,
  OrgaUserSummary,
  PositionSummary,
  PositionType2,
  ProjectSummary,
  WorkEntity2,
  WorkPositionSummary,
} from 'commons';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { SlideOverService } from '../../../components/slide-overs/slide-over/slide-over-service';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ToggleComponent } from '../../../components/toggle/toggle.component';
import { InputComponent } from '../../../components/input/input.component';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { DropdownMinimalMenuComponent } from '../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { DropdownButtonComponent } from '../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownItemComponent } from '../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { WorkService } from '../../../services/work.service';
import { SessionStateService } from '../../../services/session-state.service';
import { DateInputComponent } from '../../../components/input/date-input/date-input.component';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';
import { NumberInputComponent } from '../../../components/input/number-input/number-input.component';
import { WorkTimeInputComponent } from '../../../components/input/work-time-input/work-time-input.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { OrgaUserService } from '../../../services/orga-user.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../services/project.service';
import { formatISO } from 'date-fns';
import { SlideOverAlertComponent } from '../../../components/slide-overs/slide-over/slide-over-alert/slide-over-alert.component';

interface WorkForm {
  project: FormControl<ProjectSummary | null>;
  customer: FormControl<CustomerSummary | null>;
  position: FormControl<(PositionSummary & { billable: boolean; type: PositionType2 }) | null>;
  orgaUser: FormControl<OrgaUserSummary>;
  date: FormControl<IsoDate | null>;
  workMinutes: FormControl<number | null>;
  description: FormControl<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class WorkSlideOverService extends SlideOverService<
  FirestoreWork,
  {
    date?: IsoDate;
    customer?: CustomerSummary;
    project?: ProjectSummary;
    position?: WorkPositionSummary;
  },
  void
> {}

@Component({
  selector: 'app-work-slide-over',
  templateUrl: './work-slide-over.component.html',
  standalone: true,
  imports: [
    SlideOverComponent,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    ReactiveFormsModule,
    NgIf,
    ToggleComponent,
    InputComponent,
    TailwindInputDirective,
    SlideOverFooterComponent,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    NgIconWrapperComponent,
    DropdownItemComponent,
    TailwindButtonDirective,
    DateInputComponent,
    TextInputComponent,
    NumberInputComponent,
    WorkTimeInputComponent,
    DropdownComponent,
    AsyncPipe,
    SlideOverAlertComponent,
  ],
})
export class WorkSlideOverComponent implements OnInit, OnDestroy {
  title!: string;

  workForm2!: FormGroup<WorkForm>;
  billed = false;

  loggedInUser = this.sessionState.getOrgaUser().pipe(
    filter((user): user is FirestoreOrgaUser => !!user),
    map((user) => mapOrgaUserSummary(user))
  );
  orgaUsers = this.orgaUserService.getAllOrgaUserSummaries();

  projects = this.projectService.getActiveProjects().pipe(shareReplay(1));

  projectSummaries = this.projects.pipe(
    map((projects) =>
      projects.map((project) => ({
        id: project.id,
        displayName: project.data.name || 'No Displayname',
      }))
    )
  );

  positionSummaries: Observable<WorkPositionSummary[]> = defer(() =>
    this.workForm2.controls.project.valueChanges.pipe(
      startWith(this.workForm2.controls.project.value),
      switchMap((projectSummary) =>
        this.projects.pipe(
          map((projects) => projects.find((project) => project.id === projectSummary?.id) ?? null)
        )
      ),
      filter((project): project is FirestoreProject => !!project),
      map((project) => this.projectService.getWorkPositionSummaries(project))
    )
  );

  private subscriptions = new Subscription();

  constructor(
    private workService: WorkService,
    private sessionState: SessionStateService,
    public workSlideOver: WorkSlideOverService,
    private orgaUserService: OrgaUserService,
    private projectService: ProjectService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async ngOnInit() {
    const loggedInUser = await firstValueFrom(this.loggedInUser);
    let work: WorkEntity2 | null = null;
    if (this.workSlideOver.getMode() == 'edit') {
      work = this.workSlideOver.getEntity()?.data!;
    }
    await this.initForm2(loggedInUser, work);
    this.billed = !!work?.invoice;
    this.title = this.workSlideOver.getMode() === 'new' ? 'Stunden erfassen' : 'Stunden bearbeiten';
  }

  async initForm2(loggedInUser: OrgaUserSummary, work: WorkEntity2 | null) {
    this.workForm2 = new FormGroup<WorkForm>({
      orgaUser: new FormControl(work?.orgaUser ?? loggedInUser, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      project: new FormControl(work?.project ?? this.workSlideOver.getNewMeta().project ?? null, [
        Validators.required,
      ]),
      position: new FormControl(
        work?.position ?? this.workSlideOver.getNewMeta().position ?? null,
        [Validators.required]
      ),
      date: new FormControl(
        work?.date ??
          this.workSlideOver.getNewMeta().date ??
          formatISO(new Date(), { representation: 'date' }),
        [Validators.required]
      ),
      workMinutes: new FormControl(work?.workMinutes ?? null, [Validators.required]),
      description: new FormControl(work?.description ?? null, [Validators.maxLength(240)]),
      customer: new FormControl(
        work?.customer ?? this.workSlideOver.getNewMeta()?.customer ?? null
      ),
    });
    this.disableEnableFields();
    this.subscriptions.add(
      this.workForm2.controls.project.valueChanges.subscribe(async (project) => {
        this.workForm2.controls.customer.patchValue(
          project ? await this.getCustomer(project.id) : null
        );
        this.workForm2.controls.position.patchValue(null);
      })
    );
  }

  disableEnableFields() {
    if (this.workSlideOver.getMode() == 'edit' && this.workSlideOver.getEntity()?.data.invoice) {
      this.workForm2.disable({ emitEvent: false });
    } else {
      this.workForm2.enable({ emitEvent: false });
    }
  }

  async saveWork() {
    this.workForm2.markAllAsTouched();
    if (!this.workForm2.valid) {
      return;
    }
    if (!this.workForm2.dirty) {
      this.closeSlideOver();
      return;
    }
    if (this.workSlideOver.getMode() === 'new') {
      await this.workService.createWorks([
        { ...this.workForm2.getRawValue(), invoice: null } as WorkEntity2,
      ]);
      this.closeSlideOver();
    } else {
      await this.workService.updateWork(
        this.workSlideOver.getEntity()!,
        this.workForm2.getRawValue() as WorkEntity2
      );
      this.closeSlideOver();
    }
  }

  private async getCustomer(projectId: string) {
    const project = await firstValueFrom(
      this.projects.pipe(map((projects) => projects.find((project) => projectId === project.id)))
    );
    return project?.data.customerId
      ? { id: project.data.customerId, displayName: project.data.customerName as string }
      : null;
  }

  async deleteWork() {
    await this.workService.deleteWork(this.workSlideOver.getEntity()!);
    this.closeSlideOver();
  }

  closeSlideOver() {
    this.workSlideOver.closeSlideOver();
  }
}
