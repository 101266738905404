import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { SettingsCardComponent } from '../settings-card/settings-card.component';
import { SessionStateService } from '../../../services/session-state.service';
import { filter } from 'rxjs/operators';
import { FirestoreOrga } from 'commons';

@Component({
  selector: 'app-subscription-settings',
  templateUrl: './subscription-settings.component.html',
  standalone: true,
  imports: [SettingsCardComponent, TailwindButtonDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionSettingsComponent implements OnInit {
  link = environment.customerPortalLink;

  suffix = '';

  constructor(private sessionState: SessionStateService) {}

  async ngOnInit() {
    const orga = await firstValueFrom(
      this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga))
    );
    const emailAddress = orga.data.subscription?.email ?? '';

    this.suffix = emailAddress ? '?prefilled_email=' + emailAddress : '';
  }
}
