import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormControlsOf } from '../../../util/form-helpers';
import { BankConnection } from 'commons';
import { TextInputComponent } from '../../input/text-input/text-input.component';
import { isIBANValid, isQRIBAN } from 'swissqrbill/utils';

@Component({
  selector: 'app-bank-connection-form',
  standalone: true,
  imports: [ReactiveFormsModule, TextInputComponent],
  templateUrl: './bank-connection-form.component.html',
})
export class BankConnectionFormComponent {
  @Input() bankConnectionFormGroup!: FormGroup<BankConnectionForm>;
}

export type BankConnectionForm = FormControlsOf<BankConnection>;

export function getBankConnectionForm(bankConnection: BankConnection | null) {
  return new FormGroup<BankConnectionForm>({
    name: new FormControl<string | null>(bankConnection?.name ?? null, [Validators.required]),
    iban: new FormControl<string | null>(bankConnection?.iban ?? null, [
      Validators.required,
      (control) => {
        return isIBANValid(control.value ?? '') ? null : { ibanInvalid: true };
      },
    ]),
    qrIban: new FormControl<string | null>(bankConnection?.qrIban ?? null, [
      (control) => {
        return control.value ? (isQRIBAN(control.value) ? null : { qrIbanInvalid: true }) : null;
      },
    ]),
    bic: new FormControl<string | null>(bankConnection?.bic ?? null, []),
    city: new FormControl<string | null>(bankConnection?.city ?? null, [Validators.required]),
    zipCode: new FormControl<string | null>(bankConnection?.zipCode ?? null, [Validators.required]),
  });
}
