import { Component, Input } from '@angular/core';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { InputComponent } from '../../input/input.component';
import { TailwindInputDirective } from '../../input/tailwind-input.directive';
import { Address } from 'commons';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormControlsOf } from '../../../util/form-helpers';
import { TextInputComponent } from '../../input/text-input/text-input.component';

@Component({
  selector: 'app-address-form',
  standalone: true,
  imports: [
    DropdownComponent,
    InputComponent,
    TailwindInputDirective,
    ReactiveFormsModule,
    TextInputComponent,
  ],
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent {
  @Input() addressFormGroup!: FormGroup<AddressForm>;

  constructor() {}
}

export type AddressForm = FormControlsOf<Address>;

export function getAddressForm(address: Address | null | undefined): FormGroup<AddressForm> {
  return new FormGroup<AddressForm>({
    addressLine1: new FormControl(address?.addressLine1 ?? null),
    addressLine2: new FormControl(address?.addressLine2 ?? null),
    zipCode: new FormControl(address?.zipCode ?? null),
    city: new FormControl(address?.city ?? null),
    countryCode: new FormControl(address?.countryCode ?? null),
  });
}
