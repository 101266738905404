<div
  style="max-height: 800px; max-width: 1100px"
  class="overflow-y-auto"
  *ngIf="showScreenshotFrame; else normal"
>
  <div class="w-full h-10 bg-gray-50 p-2 flex items-center pl-6">
    <div class="flex items-center">
      <div class="bg-gray-300 rounded-full w-2 h-2 mr-2"></div>
      <div class="bg-gray-300 rounded-full w-2 h-2 mr-2"></div>
      <div class="bg-gray-300 rounded-full w-2 h-2 mr-2"></div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>

<ng-template #normal>
  <div appFileDragDrop (fileDropped)="uploadFile($event)">
    <router-outlet></router-outlet></div
></ng-template>
