import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Orga } from 'commons';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { SettingsCardComponent } from '../settings-card/settings-card.component';
import {
  AddressFormComponent,
  getAddressForm,
} from '../../../components/forms/address-form/address-form.component';

@Component({
  selector: 'app-orga-address-card',
  templateUrl: './orga-address-card.component.html',
  standalone: true,
  imports: [
    SettingsCardComponent,
    ReactiveFormsModule,
    InputComponent,
    TailwindInputDirective,
    AddressFormComponent,
  ],
})
export class OrgaAddressCardComponent implements OnInit {
  @Input()
  orga!: Orga;

  @Input()
  disabled = false;

  @Output()
  addressChanged = new EventEmitter<Partial<Orga>>();

  addressForm!: FormGroup;

  ngOnInit(): void {
    this.addressForm = getAddressForm(this.orga.address);
  }

  saveSettings() {
    if (!this.addressForm.invalid) {
      this.addressChanged.emit({ address: this.addressForm.value });
    }
  }
}
