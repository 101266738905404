import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DecimalPipe, NgIf } from '@angular/common';
import { PositionTypeBadgeComponent } from '../position-type-badge/position-type-badge.component';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TailwindButtonDirective } from '../../../../../components/button/tailwind-button.directive';
import { DropdownMinimalMenuComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { DropdownButtonComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownItemComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { FormatWorkPipe } from '../../../../../pipes/format-work.pipe';
import { Position2 } from 'commons/dist/entities/position2';
import { FormatCents } from '../../../../../pipes/format-cents';

@Component({
  selector: 'app-position-card',
  templateUrl: './position-card.component.html',
  standalone: true,
  imports: [
    PositionTypeBadgeComponent,
    NgIf,
    NgIconWrapperComponent,
    TailwindButtonDirective,
    RouterLink,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    DropdownItemComponent,
    DecimalPipe,
    FormatWorkPipe,
    FormatCents,
  ],
})
export class PositionCardComponent implements OnChanges {
  @Input() position!: Position2;

  @Output() modifyPosition = new EventEmitter<Position2>();

  @Output() createWork = new EventEmitter<Position2>();

  @Output() showWork = new EventEmitter<Position2>();

  ngOnChanges(changes: SimpleChanges) {}
}
