<div class="px-4 sm:px-6 lg:px-8 py-4 lg:py-8">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-base font-semibold text-gray-900">
        {{ selectedDate | date : 'd. MMMM yyyy' }}
      </h1>
    </div>
    <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex gap-2">
      <button
        *ngIf="isToday"
        type="button"
        appTailwindButton
        color="white"
        (click)="createTimer.emit(selectedDate)"
      >
        Neuer Timer
      </button>
      <button
        type="button"
        color="secondary"
        appTailwindButton
        (click)="createWorkEntry.emit(selectedDate)"
      >
        Neue Arbeit
      </button>
    </div>
  </div>
  <div class="-mx-4 mt-8 sm:-mx-0">
    <table class="w-full divide-y divide-gray-300 table-fixed">
      <thead>
        <tr>
          <th
            scope="col"
            class="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0 w-16 sm:w-12"
          ></th>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
          >
            Projekt
          </th>
          <th
            scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
          >
            Position
          </th>
          <th
            scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
          >
            Beschreibung
          </th>
          <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right w-20">
            Stunden
          </th>
          <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0 w-32 md:w-60"></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr
          app-timer-row
          class="bg-blue-100/15"
          *ngFor="let timerRow of timerRows; trackBy: trackById"
          [timer]="timerRow"
          (showTimer)="showTimer.emit($event)"
          (stop)="stop.emit($event)"
          (start)="start.emit($event)"
        ></tr>
        <tr
          app-work-row
          *ngFor="let work of works"
          [work]="work"
          (editWork)="editWork.emit($event)"
        ></tr>
        <tr class="hidden lg:table-row">
          <td
            [colSpan]="4"
            class="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900 text-right"
          >
            Total
          </td>
          <td
            class="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900 text-right tabular-nums"
          >
            {{ totalWorkMinutes | formatWork }}
          </td>
          <td class="whitespace-nowrap py-4 text-sm text-gray-500 text-left tabular-nums">
            <ng-container *ngIf="activeTimerStartTime">
              + {{ activeTimerStartTime | secondsPassed | formatSeconds }}
            </ng-container>
          </td>
        </tr>
        <tr class="hidden sm:table-row lg:hidden">
          <td
            [colSpan]="3"
            class="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900 text-right"
          >
            Total
          </td>
          <td
            class="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900 text-right tabular-nums"
          >
            {{ totalWorkMinutes | formatWork }}
          </td>
          <td class="whitespace-nowrap py-4 text-sm text-gray-500 text-left tabular-nums">
            <ng-container *ngIf="activeTimerStartTime">
              + {{ activeTimerStartTime | secondsPassed | formatSeconds }}
            </ng-container>
          </td>
        </tr>
        <tr class="sm:hidden">
          <td
            [colSpan]="2"
            class="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900 text-right"
          >
            Total
          </td>
          <td
            class="whitespace-nowrap px-3 py-4 text-sm font-semibold text-gray-900 text-right tabular-nums"
          >
            {{ totalWorkMinutes | formatWork }}
          </td>
          <td class="whitespace-nowrap py-4 text-sm text-gray-500 text-left tabular-nums">
            <ng-container *ngIf="activeTimerStartTime">
              + {{ activeTimerStartTime | secondsPassed | formatSeconds }}
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
