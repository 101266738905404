import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DocumentCardComponent } from '../../document/list/document-card/document-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { DocumentService } from '../../../services/document.service';
import { filter, switchMap } from 'rxjs/operators';
import { FirestoreDocument, FirestoreOrgaUser } from 'commons';
import { EmptyStateComponent } from '../../../components/empty/empty-state.component';
import { DocumentSlideOverComponent } from '../../document/document-slide-over/document-slide-over.component';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-my-comment-page',
  standalone: true,
  imports: [DocumentCardComponent, AsyncPipe, NgIf, EmptyStateComponent],
  templateUrl: './my-comments.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyCommentsPage {
  documents = this.sessionService.getOrgaUser().pipe(
    filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
    switchMap((user) => this.documentService.getDocumentsWithNotReactedComments(user?.data.userId))
  );

  constructor(
    private documentService: DocumentService,
    private sessionService: SessionStateService,
    @Inject(Dialog) private dialog: Dialog
  ) {}

  editDocument(documents: FirestoreDocument[]) {
    this.dialog.open(DocumentSlideOverComponent, { data: { documents: documents } });
  }

  async deleteDocument(documents: FirestoreDocument[]) {
    await Promise.all(documents.map((document) => this.documentService.deleteDocument(document)));
  }
}
