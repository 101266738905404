<div
  class="bg-white shadow overflow-hidden sm:rounded-md"
  *ngIf="invoices && invoices.length > 0; else noInvoices"
>
  <ul>
    <li
      app-invoice-list-entry
      *ngFor="let invoice of invoices; first as isFirst"
      [invoice]="invoice"
      [ngClass]="{ 'border-t border-gray-200': !isFirst }"
      (editInvoice)="editInvoice.emit(invoice)"
    ></li>
  </ul>
</div>
<ng-template #noInvoices>
  <app-empty-state
    iconName="heroBanknotes"
    heading="Keine Rechnungen"
    class="flex justify-center mt-[100px]"
  ></app-empty-state>
</ng-template>
