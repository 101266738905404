import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { PositionTableComponent } from '../../components/position-table/position-table.component';
import { AlertComponent } from '../../../../components/alert/alert.component';
import { AsyncPipe, formatDate, NgIf } from '@angular/common';
import { DateInputComponent } from '../../../../components/input/date-input/date-input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InvoicePosition, InvoicePositions } from 'commons/dist/entities/invoice';
import {
  addMonths,
  endOfMonth,
  formatISO,
  getDate,
  isAfter,
  isBefore,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { filter, switchMap, tap } from 'rxjs/operators';
import { combineLatest, map, merge, Observable, of, startWith, take } from 'rxjs';
import { VatRateService } from '../../../../services/vat-rate.service';
import { Position2, ProjectSummary, VatRate } from 'commons';
import { WorkService } from '../../../../services/work.service';
import { getAllWork, groupWorkByPosition, mapInvoicePositions } from '../../util/invoice.util';

interface InvoiceDialogForm {
  deliveryPeriod: FormGroup<{
    from: FormControl<string>;
    to: FormControl<string>;
  }>;
  positions: FormControl<InvoicePositions | null>;
  vatRate: FormControl<VatRate | null>;
}

export type ImportInvoiceItemsDialogComponentReturnType = {
  positions: InvoicePositions;
  vatRate: VatRate | null;
} | null;

export type ImportInvoiceItemsDialogComponentDataType = {
  invoiceId: string;
  invoiceNumber: string;
  project: ProjectSummary;
  positions: Position2[];
  vatRate: VatRate | null;
};

@Component({
  selector: 'app-import-invoice-items-dialog',
  standalone: true,
  imports: [
    NgIconWrapperComponent,
    TailwindButtonDirective,
    PositionTableComponent,
    AlertComponent,
    NgIf,
    DateInputComponent,
    AsyncPipe,
    ReactiveFormsModule,
  ],
  templateUrl: './import-invoice-items-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportInvoiceItemsDialogComponent implements OnInit {
  invoiceDialogForm!: FormGroup<InvoiceDialogForm>;
  invoicePositions!: Observable<InvoicePosition[]>;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private vatRateService: VatRateService,
    private workService: WorkService,
    public dialogRef: DialogRef<
      ImportInvoiceItemsDialogComponentReturnType,
      ImportInvoiceItemsDialogComponent
    >,
    @Inject(DIALOG_DATA)
    public data: ImportInvoiceItemsDialogComponentDataType
  ) {}

  ngOnInit() {
    this.invoiceDialogForm = new FormGroup({
      deliveryPeriod: new FormGroup(
        {
          from: new FormControl<string>(
            formatISO(
              startOfMonth(getDate(new Date()) > 25 ? new Date() : addMonths(new Date(), -1)),
              {
                representation: 'date',
              }
            ),
            {
              nonNullable: true,
              validators: [
                Validators.required,
                (control) => {
                  if (isBefore(parseISO(control.value), parseISO('2018-01-01'))) {
                    return { datePeriod: 'Min Date 1.1.2018' };
                  }
                  if (
                    this.data.vatRate &&
                    isBefore(parseISO(control.value), parseISO(this.data.vatRate.validFrom))
                  ) {
                    return {
                      datePeriod: `Ausserhalb MwSt Periode (${formatDate(
                        new Date(this.data.vatRate.validFrom),
                        'd.M.y',
                        this.locale
                      )} -
                        ${formatDate(new Date(this.data.vatRate.validTo), 'd.M.y', this.locale)})`,
                    };
                  }
                  return null;
                },
              ],
            }
          ),
          to: new FormControl<string>(
            formatISO(
              endOfMonth(getDate(new Date()) > 25 ? new Date() : addMonths(new Date(), -1)),
              {
                representation: 'date',
              }
            ),
            {
              nonNullable: true,
              validators: [
                Validators.required,
                (control) => {
                  if (
                    this.data.vatRate &&
                    isAfter(parseISO(control.value), parseISO(this.data.vatRate.validTo))
                  ) {
                    return {
                      datePeriod: `Ausserhalb MwSt Periode (${formatDate(
                        new Date(this.data.vatRate.validFrom),
                        'd.M.y',
                        this.locale
                      )} -
                        ${formatDate(new Date(this.data.vatRate.validTo), 'd.M.y', this.locale)})`,
                    };
                  }
                  return null;
                },
              ],
            }
          ),
        },
        [Validators.required],
        [
          (control) => {
            if (this.data.vatRate) {
              return of(null);
            }

            return this.vatRateService
              .getVatRatesForPeriod(control.getRawValue().from, control.getRawValue().to)
              .pipe(
                take(1),
                map((rates) => (rates.length !== 1 ? { multipleVatRates: true } : null))
              );
          },
        ]
      ),
      positions: new FormControl<InvoicePositions | null>(null, {
        validators: [Validators.required],
      }),
      vatRate: new FormControl<VatRate | null>(this.data.vatRate),
    });

    const billableHours = merge(
      this.invoiceDialogForm.controls.deliveryPeriod.valueChanges,
      this.invoiceDialogForm.controls.positions.valueChanges
    ).pipe(
      startWith('start'),
      map(() => this.invoiceDialogForm.getRawValue()),

      switchMap((invoice) =>
        this.workService.getAllBillableWorkForProjectBetweenDate2(
          this.data.project.id,
          invoice.deliveryPeriod.from,
          invoice.deliveryPeriod.to
        )
      )
    );

    const groupedWork = billableHours.pipe(map((works) => groupWorkByPosition(works)));

    const vatRates = this.invoiceDialogForm.controls.deliveryPeriod.valueChanges.pipe(
      startWith(this.invoiceDialogForm.controls.deliveryPeriod.value),
      switchMap((period) => {
        if (this.data.vatRate) {
          return of(this.data.vatRate);
        } else {
          return this.vatRateService
            .getVatRatesForDate(period.from!)
            .pipe(map((vatRate) => (vatRate ? { ...vatRate.data, id: vatRate.id } : null)));
        }
      }),
      tap((vatRate) => this.invoiceDialogForm.controls.vatRate.setValue(vatRate)),
      filter((vatRate): vatRate is VatRate => !!vatRate)
    );

    this.invoicePositions = combineLatest([groupedWork, vatRates]).pipe(
      map(([groupedWork, vatRates]) =>
        mapInvoicePositions(this.data.positions, groupedWork, vatRates)
      )
    );
  }

  async importValues() {
    this.invoiceDialogForm.markAllAsTouched();
    if (this.invoiceDialogForm.valid && this.invoiceDialogForm.controls.positions.value) {
      try {
        await this.workService.bulkUpdateInvoiceState(
          getAllWork(Object.values(this.invoiceDialogForm.controls.positions.value.positions)),
          this.data.invoiceId,
          this.data.invoiceNumber,
          true
        );
      } catch (error) {
        console.log(error);
        this.invoiceDialogForm.controls.positions.setValue(null);
        return;
      }
      this.dialogRef.close({
        positions: this.invoiceDialogForm.controls.positions.value,
        vatRate: this.invoiceDialogForm.controls.vatRate.value,
      });
    }
  }

  cancelDialog(): void {
    this.dialogRef.close();
  }

  setDeliveryPeriodThisMonth() {
    this.invoiceDialogForm.controls.deliveryPeriod.patchValue({
      from: formatISO(startOfMonth(new Date()), {
        representation: 'date',
      }),
      to: formatISO(endOfMonth(new Date()), {
        representation: 'date',
      }),
    });
  }

  setDeliveryPeriodLastMonth() {
    this.invoiceDialogForm.controls.deliveryPeriod.patchValue({
      from: formatISO(startOfMonth(addMonths(new Date(), -1)), {
        representation: 'date',
      }),
      to: formatISO(endOfMonth(addMonths(new Date(), -1)), {
        representation: 'date',
      }),
    });
  }
}
