import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomerService } from '../../../services/customer.service';
import { Customer, CustomerType, FirestoreCustomer } from 'commons';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { NgIf } from '@angular/common';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';
import {
  AddressFormComponent,
  getAddressForm,
} from '../../../components/forms/address-form/address-form.component';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export interface CustomerSlideOverInput {
  customer?: FirestoreCustomer;
  customerType?: CustomerType;
}

@Component({
  selector: 'app-customer-slide-over',
  templateUrl: './customer-slide-over.component.html',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    SlideOverComponent,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    SlideOverFooterComponent,
    TailwindButtonDirective,
    TextInputComponent,
    AddressFormComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerSlideOverComponent implements OnInit {
  customerForm!: FormGroup;

  customerType!: CustomerType;
  errors?: any;

  // for the future: use https://brandfetch.com/ to fetch logos

  constructor(
    private customerService: CustomerService,
    @Inject(DIALOG_DATA) public data: CustomerSlideOverInput | null,
    @Inject(DialogRef) public dialogRef: DialogRef
  ) {}

  ngOnInit(): void {
    this.customerType = this.data?.customer
      ? this.data.customer.data.type
      : (this.data?.customerType ?? 'company');
    this.initForm();
  }

  initForm() {
    switch (this.customerType) {
      case 'company':
        this.customerForm = new FormGroup({
          type: new FormControl(this.customerType, [Validators.required]),
          company: new FormGroup({
            displayName: new FormControl<string | null>(
              this.data?.customer?.data.displayName ?? null,
              [Validators.required]
            ),
            legalName: new FormControl<string | null>(
              this.data?.customer?.data.company.legalName ?? null,
              []
            ),
          }),
          address: getAddressForm(this.data?.customer?.data.address ?? null),
        });
        break;
      case 'individual':
        this.customerForm = new FormGroup({
          type: new FormControl(this.customerType, []),
          individual: new FormGroup({
            firstName: new FormControl<string | null>(
              this.data?.customer?.data.individual.firstName ?? null,
              [Validators.required]
            ),
            lastName: new FormControl<string | null>(
              this.data?.customer?.data.individual.lastName ?? null,
              [Validators.required]
            ),
          }),
          address: getAddressForm(this.data?.customer?.data.address ?? null),
        });
        break;
    }
  }

  async createOrUpdateCustomer(value: Customer) {
    this.customerForm.markAllAsTouched();
    if (this.customerForm.invalid) {
      return;
    }

    value = {
      ...value,
      displayName:
        value.type === 'company'
          ? value.company.displayName
          : value.individual.firstName + ' ' + value.individual.lastName,
    };
    if (this.data?.customer) {
      this.customerService.updateCustomer(this.data?.customer?.id, value);
      this.closeSlideOver();
    } else {
      const doc = await this.customerService.createCustomer(value);
      this.closeSlideOver(doc.id);
    }
  }

  closeSlideOver(id?: string) {
    this.dialogRef.close(id);
  }
}
