import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import {
  Category,
  DateRange,
  Document,
  ExpenseDocument,
  FirestoreDocument,
  FirestoreEntity,
  getInterval,
  RevenueDocument,
  Summary,
} from 'commons';
import { map, switchMap, tap } from 'rxjs/operators';
import { formatISO } from 'date-fns';
import { DocumentService } from '../../../services/document.service';
import { groupBy } from 'lodash';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { CategoryBadgeComponent } from '../../../components/category-badge/category-badge.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { AsyncPipe, DatePipe, DecimalPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { DateFilterPillComponent } from '../../../components/filterpills/date-filter-pill.component';
import { SimplePillComponent } from '../../../components/filterpills/simple-pill.component';
import { orderBy, QueryConstraint, where } from '@angular/fire/firestore';
import { Dialog } from '@angular/cdk/dialog';
import { DocumentSlideOverComponent } from '../../document/document-slide-over/document-slide-over.component';

export interface FinanceRow {
  document: FirestoreDocument;
  valueDate?: Date;
  category?: Category;
  legalEntityDisplayName?: string;
  legalEntityId?: string;
  description?: string;
  revenue?: number;
  expense?: number;
  overdue?: boolean;
}

export interface FinanceTotal {
  revenue: number;
  expense: number;
}

@Component({
  selector: 'app-expense-revenue',
  templateUrl: './expense-revenue.component.html',
  standalone: true,
  imports: [
    SimplePillComponent,
    DateFilterPillComponent,
    NgIf,
    NgIconWrapperComponent,
    NgTemplateOutlet,
    NgFor,
    CategoryBadgeComponent,
    EmptyComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseRevenueComponent implements OnDestroy {
  dateFilter = new BehaviorSubject<DateRange>({
    type: 'year',
    year: new Date(),
  });

  legalEntityFilter = new BehaviorSubject<Summary | null>(null);
  legalEntityFilterValues: Array<Summary> | null = null;
  unset = { id: 'unset', displayName: 'Nicht zugeordnet' };

  documents$: Observable<FirestoreEntity<Document>[]>;
  sortDate = new BehaviorSubject<'asc' | 'desc'>('desc');

  rows$: Observable<FinanceRow[]>;
  groupedByCustomer$: Observable<FinanceRow[]>;
  total$: Observable<FinanceTotal>;

  isGroupedByCustomer = false;
  private subscription = new Subscription();

  constructor(
    private documentService: DocumentService,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.documents$ = combineLatest([this.dateFilter, this.legalEntityFilter, this.sortDate]).pipe(
      switchMap(([dateFilter, legalEntityFilter, sortDate]) => {
        const queryConstraints: QueryConstraint[] = [where('paid', '==', true)];
        if (dateFilter.type !== 'notSet' && dateFilter.type === 'month') {
          const interval = getInterval(dateFilter);
          queryConstraints.push(
            where('valueDate', '>=', formatISO(interval.start, { representation: 'date' })),
            where('valueDate', '<=', formatISO(interval.end, { representation: 'date' })),
            orderBy('valueDate', sortDate)
          );
        } else if (dateFilter.type === 'year') {
          queryConstraints.push(
            where('financialYear', '==', dateFilter.year.getFullYear()),
            orderBy('valueDate', sortDate)
          );
        } else {
          queryConstraints.push(where('valueDate', '!=', null), orderBy('valueDate', sortDate));
        }

        return this.documentService.getAllDocuments(...queryConstraints).pipe(
          map((documents) => {
            if (legalEntityFilter) {
              if (legalEntityFilter.id === 'unset') {
                return documents.filter(
                  (document) =>
                    !document.data.linkedEntities ||
                    Object.values(document.data.linkedEntities).filter((entities) =>
                      ['customer-company', 'customer-person'].includes(entities.entity)
                    ).length === 0
                );
              } else {
                return documents.filter(
                  (document) =>
                    legalEntityFilter &&
                    document.data.linkedEntities &&
                    Object.keys(document.data.linkedEntities).includes(legalEntityFilter.id)
                );
              }
            } else {
              return documents;
            }
          })
        );
      }),
      tap((documents) => {
        const allLegalEntities: Array<Summary> = documents
          .map((document) =>
            document.data.linkedEntities
              ? Object.values(document.data.linkedEntities).filter((entities) =>
                  ['customer-company', 'customer-person'].includes(entities.entity)
                )
              : []
          )
          .flatMap(
            (entry): Array<Summary> =>
              entry.length === 0
                ? [this.unset]
                : entry.map((e) => ({ id: e.id, displayName: e.name }))
          );
        const distinctLegalEntities = new Map();
        allLegalEntities.forEach((e) => distinctLegalEntities.set(e.id, e));
        this.legalEntityFilterValues = [...distinctLegalEntities.values()];
      })
    );

    this.rows$ = this.documents$.pipe(
      map((documents) =>
        documents
          .filter(
            (document): document is FirestoreEntity<RevenueDocument | ExpenseDocument> =>
              document.data.type === 'expense' || document.data.type === 'revenue'
          )
          .map((doc) => {
            const document = doc.data;
            return {
              document: doc,
              valueDate: (document.valueDate && new Date(document.valueDate)) || undefined,
              category: document.category,
              legalEntityDisplayName:
                document.linkedEntities &&
                Object.values(document.linkedEntities)
                  .filter((entities) =>
                    ['customer-company', 'customer-person'].includes(entities.entity)
                  )
                  .reduce((prev, cur) => prev + cur.name + ' ', ''),
              description: document.name,
              revenue: document.type === 'revenue' ? document.amountInCents / 100 : undefined,
              expense: document.type === 'expense' ? document.amountInCents / 100 : undefined,
            };
          })
      )
    );

    // @ts-ignore
    this.groupedByCustomer$ = this.documents$.pipe(
      map((documents) => {
        const groupedByCustomer = groupBy(
          documents
            .filter(
              (document): document is FirestoreEntity<RevenueDocument | ExpenseDocument> =>
                document.data.type === 'expense' || document.data.type === 'revenue'
            )
            .map((doc) => {
              const document = doc.data;
              return {
                document: doc,
                valueDate: (document.valueDate && new Date(document.valueDate)) || undefined,

                legalEntityDisplayName:
                  document.linkedEntities &&
                  Object.values(document.linkedEntities)
                    .filter((entitites) =>
                      ['customer-company', 'customer-person'].includes(entitites.entity)
                    )
                    .reduce((prev, cur) => prev + cur.name + ' ', ''),
                legalEntityId:
                  document.linkedEntities &&
                  Object.values(document.linkedEntities)
                    .filter((entitites) =>
                      ['customer-company', 'customer-person'].includes(entitites.entity)
                    )
                    .reduce((prev, cur) => cur.id, ''),

                description: document.name,
                revenue: document.type === 'revenue' ? document.amountInCents / 100 : undefined,
                expense: document.type === 'expense' ? document.amountInCents / 100 : undefined,
              };
            }),
          'legalEntityDisplayName'
        );

        const reduced = Object.values(groupedByCustomer).flatMap((groupedCustomers) =>
          groupedCustomers.reduce((prev, cur) => {
            return {
              ...prev,
              legalEntityDisplayName: cur.legalEntityDisplayName,
              legalEntityId: cur.legalEntityId,
              expense: (prev.expense ?? 0) + (cur.expense ?? 0),
              revenue: (prev.revenue ?? 0) + (cur.revenue ?? 0),
            };
          }, {} as FinanceRow)
        );

        return reduced;
      }),
      map((grouped) =>
        grouped.sort((a, b) =>
          a.legalEntityDisplayName && b.legalEntityDisplayName
            ? a.legalEntityDisplayName.localeCompare(b.legalEntityDisplayName)
            : -1
        )
      )
    );

    this.total$ = this.rows$.pipe(
      map((documents) =>
        documents.reduce<FinanceTotal>(
          (prev, cur) => {
            prev.revenue += cur.revenue ?? 0;
            prev.expense += cur.expense ?? 0;
            return prev;
          },
          {
            revenue: 0,
            expense: 0,
          }
        )
      )
    );

    this.subscription.add(
      this.legalEntityFilter.subscribe((filter) => {
        if (filter) {
          this.isGroupedByCustomer = false;
        }
      })
    );
  }

  sortDateToggle() {
    if (this.sortDate.value === 'desc') {
      this.sortDate.next('asc');
    } else {
      this.sortDate.next('desc');
    }
  }

  openDocument(document: FirestoreDocument) {
    this.dialog.open(DocumentSlideOverComponent, { data: { documents: [document] } });
  }

  isGrouped($event: any) {
    this.isGroupedByCustomer = $event.currentTarget.checked;
    if (this.isGroupedByCustomer) {
      this.legalEntityFilter.next(null);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
