import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BadgeComponent } from '../../../../../components/badge/badge.component';
import { NgIf } from '@angular/common';
import { InvoicePosition } from 'commons/dist/entities/invoice';
import { NumberInputComponent } from '../../../../../components/input/number-input/number-input.component';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'tr[app-fixed-price-row]',
  standalone: true,
  imports: [BadgeComponent, NgIf, NumberInputComponent, NgIconWrapperComponent],
  templateUrl: './fixed-price-row.component.html',
})
export class FixedPriceRowComponent {
  @Input() position!: InvoicePosition;
  @Input() showCheckboxes = false;
  @Input() isDisabled = false;

  @Output() deletePosition = new EventEmitter<InvoicePosition>();

  @HostBinding('class.bg-gray-50') clazz = true;
}
