<app-dark-nav-shell
  [navMenuEntries]="(navMenu$ | async)!"
  [profileMenuEntries]="(profileMenu$ | async)!"
  [userDisplayName]="(userDisplayName$ | async)!"
  [userEmailAddress]="(userEmailAddress$ | async)!"
  [userInitials]="(userInitials$ | async)!"
  [orgaName]="(orgaName$ | async)!"
  [orgaId]="(orgaId$ | async)!"
  [schemaVersion]="(schemaVersion$ | async)!"
  [timerStartTimer]="timerStartTime | async"
  [timerUrl]="timerUrl | async"
>
  <router-outlet></router-outlet>
</app-dark-nav-shell>
