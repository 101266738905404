import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TailwindInputDirective2 } from '../tailwind-input2.directive';
import { FormInputWrapperComponent } from '../form-input-wrapper/form-input-wrapper.component';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, TailwindInputDirective2, FormInputWrapperComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
  styles: `
    :host {
      @apply block;
    }
  `,
})
export class DateInputComponent implements OnChanges, ControlValueAccessor {
  @Input() label?: string;
  @Input() enhanced = false;
  @Input() isDisabled = false;
  @Input() autocomplete?: string;

  @Input() formControl!: FormControl;

  errors!: Observable<string[] | null>;

  value: string | null = null;

  onChange: (value: string | null) => void = () => {};
  onTouched: () => void = () => {};

  ngOnChanges() {
    if (this.formControl) {
      this.errors = this.formControl.events.pipe(
        map(() => (this.formControl.touched ? this.formControl.errors : null)),
        map((x) => (x ? Object.keys(x) : null))
      );
    }
  }

  writeValue(value: string | null) {
    if (value) {
      try {
        const date = new Date(value); // Parse the incoming date string
        this.value = format(date, 'yyyy-MM-dd'); // Format using date-fns
      } catch (error) {
        console.error('Invalid date format:', value);
        this.value = null; // Or handle the error appropriately
      }
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  valueChanged(value: string) {
    this.onChange(value);
    this.onTouched();
  }
}
