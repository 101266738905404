"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapCustomerSummary = void 0;
function mapCustomerSummary(customer) {
    return {
        id: customer.id,
        displayName: customer.data.displayName || 'No Displayname',
    };
}
exports.mapCustomerSummary = mapCustomerSummary;
