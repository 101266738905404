import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CategoryColors } from 'commons';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-category-badge',
  templateUrl: './category-badge.component.html',
  standalone: true,
  imports: [NgSwitch, NgSwitchCase],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      @apply select-none;
    }
  `,
})
export class CategoryBadgeComponent {
  @Input()
  color: CategoryColors = 'grey';
  @Input()
  displayName: string = '';
}
