import { Directive, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appTailwindInput2]',
  standalone: true,
})
export class TailwindInputDirective2 implements OnChanges, OnDestroy {
  @Input() control!: FormControl;

  private error = false;
  private subscription = new Subscription();

  ngOnChanges() {
    if (this.control) {
      this.subscription.add(
        this.control.events
          .pipe(map(() => (this.control.touched ? this.control.errors : null)))
          .subscribe((error) => (this.error = !!error))
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostBinding('class') get classes() {
    const baseClasses =
      'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-50';
    const errorClasses = 'ring-red-400 focus:ring-red-400 focus:ring-2';
    return baseClasses + ' ' + (this.error ? errorClasses : '');
  }
}
