import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { SidebarNavigationEntryComponent } from '../../components/container/sidebar-navigation/sidebar-navigation-entry/sidebar-navigation-entry.component';
import { SidebarNavigationComponent } from '../../components/container/sidebar-navigation/sidebar-navigation.component';
import { TailwindButtonDirective } from '../../components/button/tailwind-button.directive';
import { HeaderMetaActionComponent } from '../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../components/container/container.component';
import { Dialog } from '@angular/cdk/dialog';
import {
  ProjectSlideOverComponent,
  ProjectSlideOverInput,
} from './slide-overs/project-slide-over.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    HeaderMetaActionComponent,
    TailwindButtonDirective,
    SidebarNavigationComponent,
    SidebarNavigationEntryComponent,
    RouterLink,
    RouterOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsComponent {
  constructor(
    @Inject(Dialog) private dialog: Dialog,
    private router: Router
  ) {}

  async createProject() {
    const dialogRef = this.dialog.open<string, ProjectSlideOverInput, ProjectSlideOverComponent>(
      ProjectSlideOverComponent
    );
    const id = await firstValueFrom(dialogRef.closed);
    if (id) {
      this.router.navigate(['/project/details/', id]);
    }
  }
}
