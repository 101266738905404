<div class="px-4 sm:px-6 lg:px-8 py-4 lg:py-8 flex flex-col gap-4 items-start">
  <button (click)="navigateBack.emit()" appTailwindButton color="white" class="inline-flex gap-2">
    <app-ng-icon name="heroArrowLongDown" class="rotate-90"></app-ng-icon>
    <span>Zurück</span>
  </button>

  <div
    class="flex-1 w-full rounded-md border border-gray-200 relative overflow-hidden bg-white shadow sm:rounded-lg"
    [class.!border-blue-600]="timer.state === 'active'"
    [class.!shadow-blue-600]="timer.state === 'active'"
  >
    <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div class="ml-4 mt-2">
          <h3 class="text-base font-semibold text-gray-900 flex gap-4 items-center">
            <div class="text-lg font-semibold text-blue-600 flex-shrink-0 text-right tabular-nums">
              {{
                timer.state === 'active'
                  ? (timer.startTime | secondsPassed | formatSeconds)
                  : (workForm.controls.workMinutes.value ?? 0 | formatWork)
              }}
            </div>
            <div>
              <div>
                {{
                  workForm.controls.project.value ? workForm.controls.project.value.displayName : ''
                }}
              </div>
              <div>
                {{
                  workForm.controls.position.value
                    ? workForm.controls.position.value.displayName
                    : ''
                }}
              </div>
            </div>
          </h3>
        </div>
        <div class="ml-4 mt-2 shrink-0 flex gap-4">
          <app-dropdown-minimal-menu *ngIf="timer.state === 'inactive'">
            <app-dropdown-button><app-ng-icon name="heroTrash"></app-ng-icon></app-dropdown-button>
            <app-dropdown-item (menuClick)="delete.emit()"> Löschen </app-dropdown-item>
          </app-dropdown-minimal-menu>

          <button
            *ngIf="timer.state === 'active'; else inactive"
            type="button"
            appTailwindButton
            (click)="stop.emit()"
          >
            <app-ng-icon class="text-2xl" name="heroPause"></app-ng-icon>
          </button>
          <ng-template #inactive>
            <button type="button" appTailwindButton *ngIf="timer.isToday" (click)="start.emit()">
              <app-ng-icon class="text-2xl" name="heroPlay"></app-ng-icon>
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <form autocomplete="off" novalidate *ngIf="workForm">
      <div class="space-y-6 divide-y pb-4">
        <div class="pt-6 px-4 sm:px-6 grid grid-cols-1 md:grid-cols-2 gap-4">
          <app-dropdown
            label="Projekt"
            [formControl]="workForm.controls.project"
            [options]="projectSummaries"
          ></app-dropdown>
          <app-dropdown
            label="Position"
            [formControl]="workForm.controls.position"
            [options]="positionSummaries"
          ></app-dropdown>
        </div>
        <div class="space-y-4 pt-6 pb-5 px-4 sm:px-6">
          <app-text-input
            label="Beschreibung"
            [formControl]="workForm.controls.description"
            variant="textarea"
          ></app-text-input>

          <app-work-time-input
            label="Arbeitszeit"
            [formControl]="workForm.controls.workMinutes"
          ></app-work-time-input>
        </div>
        <div class="pt-6 px-4 sm:px-6" *ngIf="timer.timeEntries">
          <label class="block text-sm/6 font-medium text-gray-900">Zeiteinträge</label>
          <div
            class="grid grid-cols-3 gap-2 text-gray-500"
            *ngFor="let timeEntry of timer.timeEntries"
          >
            <div class="mt-2 tabular-nums">{{ timeEntry.startTime | date : 'HH:mm' }}</div>
            <div class="mt-2 tabular-nums">{{ timeEntry.endTime | date : 'HH:mm' }}</div>
            <div class="mt-2 tabular-nums">{{ timeEntry.totalMinutes | formatWork }}h</div>
          </div>
        </div>
      </div>
    </form>

    <div
      class="px-4 sm:px-6 py-4 flex w-full justify-end gap-4 rounded-md border-t border-gray-200 bg-white leading-10"
    >
      <button appTailwindButton (click)="saveWork.emit()" [disabled]="timer.state === 'active'">
        Timerwerte als Arbeit speichern
      </button>
    </div>
  </div>
</div>
