<div class="grid grid-cols-1 gap-y-4 gap-x-4 md:grid-cols-6">
  <app-text-input
    label="Adresszeile 1"
    class="md:col-span-6"
    [formControl]="addressFormGroup.controls.addressLine1"
  >
  </app-text-input>
  <app-text-input
    label="Adresszeile 2"
    class="md:col-span-6"
    [formControl]="addressFormGroup.controls.addressLine2"
  >
  </app-text-input>
  <app-text-input
    label="Plz"
    class="md:col-span-2"
    [formControl]="addressFormGroup.controls.zipCode"
  >
  </app-text-input>
  <app-text-input label="Ort" class="md:col-span-4" [formControl]="addressFormGroup.controls.city">
  </app-text-input>
  <app-text-input
    label="Land (Code)"
    class="md:col-span-6"
    [formControl]="addressFormGroup.controls.countryCode"
  >
  </app-text-input>
</div>
