import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../../../../components/input/input.component';
import { TailwindInputDirective } from '../../../../components/input/tailwind-input.directive';
import { DropdownComponent } from '../../../../components/dropdown/dropdown.component';
import { PositionTableComponent } from '../position-table/position-table.component';
import { AddressFormComponent } from '../../../../components/forms/address-form/address-form.component';
import { RouterLink } from '@angular/router';
import { InvoiceForm } from '../../pages/invoice/invoice.page';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { BankConnectionFormComponent } from '../../../../components/forms/bank-connection-form/bank-connection-form.component';
import { InvoiceSettingsFormComponent } from '../../../../components/forms/invoice-settings-form/invoice-settings-form.component';
import { BadgeComponent } from '../../../../components/badge/badge.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { CustomerSummary, OrgaUserSummary } from 'commons';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { AlertComponent } from '../../../../components/alert/alert.component';
import { InvoicePosition2, InvoiceTotals } from 'commons/dist/entities/invoice2';
import { TextInputComponent } from '../../../../components/input/text-input/text-input.component';
import { DateInputComponent } from '../../../../components/input/date-input/date-input.component';
import { InputLabelComponent } from '../../../../components/input/input-label/input-label.component';
import { ErrorComponent } from '../../../error/error.component';
import { InputErrorComponent } from '../../../../components/input/input-error/input-error.component';
import { Observable } from 'rxjs';
import { FormInputWrapperComponent } from '../../../../components/input/form-input-wrapper/form-input-wrapper.component';

@Component({
  selector: 'app-invoice-content',
  standalone: true,
  imports: [
    FormsModule,
    InputComponent,
    ReactiveFormsModule,
    TailwindInputDirective,
    DropdownComponent,
    PositionTableComponent,
    AddressFormComponent,
    RouterLink,
    NgIconWrapperComponent,
    BankConnectionFormComponent,
    InvoiceSettingsFormComponent,
    BadgeComponent,
    TailwindButtonDirective,
    NgIf,
    JsonPipe,
    AlertComponent,
    TextInputComponent,
    DateInputComponent,
    InputLabelComponent,
    ErrorComponent,
    InputErrorComponent,
    FormInputWrapperComponent,
    AsyncPipe,
  ],
  templateUrl: './content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent {
  @Input() invoiceForm!: FormGroup<InvoiceForm>;
  @Input() invoicePositions!: InvoicePosition2[];
  @Input() invoiceSummary!: InvoiceTotals;
  @Input() users!: Observable<OrgaUserSummary[]>;
  @Input() customers!: Observable<CustomerSummary[]>;
  @Input() isDisabled = false;

  @Output() setDeliveryPeriodLastMonth = new EventEmitter();
  @Output() setDeliveryPeriodThisMonth = new EventEmitter();
  @Output() importPositions = new EventEmitter();
  @Output() deletedPosition = new EventEmitter<InvoicePosition2>();
}
