<app-form-input-wrapper [label]="label" [control]="formControl">
  <div class="flex gap-2">
    <input
      [disabled]="isDisabled"
      [(ngModel)]="hourValue"
      (blur)="hourValueChanged()"
      (keydown.enter)="hourValueChanged()"
      placeholder="Stunden"
      #hour
      appTailwindInput2
      [control]="formControl"
      type="text"
    />
    <input
      [class.hidden]="variant === 'hours'"
      [disabled]="isDisabled"
      [(ngModel)]="minuteValue"
      (blur)="minuteValueChanged()"
      (keydown.enter)="minuteValueChanged()"
      placeholder="Minuten"
      #minute
      appTailwindInput2
      [control]="formControl"
      type="text"
    />
  </div>
</app-form-input-wrapper>
