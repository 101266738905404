import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownComponent } from '../../../../components/dropdown/dropdown.component';
import { PositionTableComponent } from '../position-table/position-table.component';
import { AddressFormComponent } from '../../../../components/forms/address-form/address-form.component';
import { RouterLink } from '@angular/router';
import { InvoiceForm } from '../../pages/invoice/invoice.page';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { BankConnectionFormComponent } from '../../../../components/forms/bank-connection-form/bank-connection-form.component';
import { BadgeComponent } from '../../../../components/badge/badge.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { CustomerSummary, OrgaUserSummary } from 'commons';
import { InvoicePosition, InvoiceTotals } from 'commons/dist/entities/invoice';
import { TextInputComponent } from '../../../../components/input/text-input/text-input.component';
import { DateInputComponent } from '../../../../components/input/date-input/date-input.component';
import { Observable } from 'rxjs';
import { FormInputWrapperComponent } from '../../../../components/input/form-input-wrapper/form-input-wrapper.component';

@Component({
  selector: 'app-invoice-content',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DropdownComponent,
    PositionTableComponent,
    AddressFormComponent,
    RouterLink,
    NgIconWrapperComponent,
    BankConnectionFormComponent,
    BadgeComponent,
    TailwindButtonDirective,
    TextInputComponent,
    DateInputComponent,
    FormInputWrapperComponent,
  ],
  templateUrl: './content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent {
  @Input() invoiceForm!: FormGroup<InvoiceForm>;
  @Input() invoicePositions!: InvoicePosition[];
  @Input() invoiceSummary!: InvoiceTotals;
  @Input() users!: Observable<OrgaUserSummary[]>;
  @Input() customers!: Observable<CustomerSummary[]>;
  @Input() isDisabled = false;

  @Output() setDeliveryPeriodLastMonth = new EventEmitter();
  @Output() setDeliveryPeriodThisMonth = new EventEmitter();
  @Output() importPositions = new EventEmitter();
  @Output() deletedPosition = new EventEmitter<InvoicePosition>();
}
