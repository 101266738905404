"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOrgaUserSummary = void 0;
function mapOrgaUserSummary(orgaUser) {
    return {
        id: orgaUser.id,
        displayName: orgaUser.data.userDisplayName || 'No Displayname',
    };
}
exports.mapOrgaUserSummary = mapOrgaUserSummary;
