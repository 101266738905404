<div class="relative flex items-start">
  <div class="flex h-6 items-center">
    <input
      type="checkbox"
      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
      [value]="value"
      [checked]="value"
      [disabled]="isDisabled"
      (change)="selectValue(!value)"
    />
  </div>
  <div class="ml-3 text-sm leading-6">
    <label class="font-medium text-gray-900">{{ label }}</label>
    <p id="comments-description" class="text-gray-500">
      {{ description }}
    </p>
  </div>
</div>
