import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private MAX_HISTORY_LEN = 50; // prevent history from growing indefinitely
  private history: string[] = [];

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        if (this.history.length > this.MAX_HISTORY_LEN) {
          this.history.shift();
        }
      }
    });
  }

  back() {
    if (this.history.length > 0) {
      this.history.pop(); // the current route
      this.router.navigateByUrl(this.history.pop()!); // go back
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
