<div *ngIf="(positions | async)?.length === 0; else weHavePositions">
  <app-empty
    (buttonClick)="createPosition()"
    message="Mit Positionen gibst du deinem Projekt Struktur. Positionen sind einzelne Arbeitspakete um deine Stunden zu erfassen."
  ></app-empty>
</div>

<ng-template #weHavePositions>
  <div class="w-full flex justify-end gap-4">
    <button appTailwindButton color="white" disabled>
      Offerte generieren <app-badge class="ml-2" color="green">Coming Soon</app-badge>
    </button>
    <button appTailwindButton color="secondary" (click)="createPosition()">Neue Position</button>
  </div>
  <div class="pt-4 flex flex-col md:flex-row-reverse gap-4">
    <div class="flex flex-col gap-4 w-full">
      <app-position-card
        *ngFor="let position of positions | async"
        [position]="position"
        (createWork)="createWork(position)"
        (modifyPosition)="modifyPosition(position)"
        (showWork)="showWork(position)"
      >
      </app-position-card>
    </div>
  </div>
</ng-template>
