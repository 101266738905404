import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
  name: 'formatWork',
  standalone: true,
})
export class FormatWorkPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(inputMinutes: number | string | null, ...args: unknown[]): string {
    if (inputMinutes) {
      const hours = Number(inputMinutes) / 60;
      return formatNumber(hours, this.locale, '1.2-2');
    }
    return '0.00';
  }
}
