import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownItemComponent } from '../dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DropdownButtonComponent } from '../dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../dropdown-minimal-menu/dropdown-minimal-menu.component';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Summary } from 'commons';

@Component({
  selector: 'app-simple-pill',
  templateUrl: './simple-pill.component.html',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgIconWrapperComponent,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    NgFor,
    DropdownItemComponent,
  ],
})
export class SimplePillComponent {
  @Input()
  label = '';
  @Input()
  filter: Summary | null = null;

  @Input()
  filterValues: Summary[] = [];

  @Output()
  filterChanged = new EventEmitter<Summary | null>();

  resetFilter() {
    this.filterChanged.emit(null);
  }

  enableFilter(val: Summary) {
    this.filterChanged.emit(val);
  }
}
