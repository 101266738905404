<app-input-label
  [label]="label"
  [isOptional]="!control?.hasValidator(Validators.required)"
></app-input-label>
<div class="relative">
  <ng-content></ng-content>
  <app-input-error [error]="(error | async)!"></app-input-error>
  <div
    class="pointer-events-none absolute inset-y-0 right-0 -mr-[22px] flex items-center text-blue-600"
    *ngIf="enhanced && !error"
  >
    <app-ng-icon class="text-blue-600" name="heroSparkles"></app-ng-icon>
  </div>
</div>
