<form autocomplete="off" novalidate *ngIf="workForm">
  <app-slide-over [backdropBlur]="true">
    <app-slide-over-header
      (closeSlideOver)="closeSlideOver()"
      [title]="title"
      [formDirty]="workForm.dirty"
      #headerComponent
    >
    </app-slide-over-header>

    <app-slide-over-alert
      *ngIf="data?.work?.data?.invoice"
      heading="Diese Stunden wurden bereits verrechnet"
      subheading="Um was zu ändern muss die Rechnung storniert werden."
    ></app-slide-over-alert>

    <app-slide-over-content>
      <div class="space-y-6 divide-y">
        <div class="space-y-4 pt-6 px-4 sm:px-6">
          <app-dropdown
            label="Mitarbeiter"
            [formControl]="workForm.controls.orgaUser"
            [options]="orgaUsers"
          ></app-dropdown>
          <app-dropdown
            label="Projekt"
            [formControl]="workForm.controls.project"
            [options]="projectSummaries"
          ></app-dropdown>
          <app-dropdown
            label="Position"
            [formControl]="workForm.controls.position"
            [options]="positionSummaries"
          ></app-dropdown>
        </div>
        <div class="space-y-4 pt-6 pb-5 px-4 sm:px-6">
          <app-date-input label="Datum" [formControl]="workForm.controls.date"></app-date-input>

          <app-text-input
            label="Beschreibung"
            [formControl]="workForm.controls.description"
            variant="textarea"
          ></app-text-input>

          <app-work-time-input
            label="Arbeitszeit"
            [formControl]="workForm.controls.workMinutes"
          ></app-work-time-input>
        </div>
      </div>
    </app-slide-over-content>

    <app-slide-over-footer>
      <app-dropdown-minimal-menu *ngIf="data?.work?.data?.invoice">
        <app-dropdown-button><app-ng-icon name="heroTrash"></app-ng-icon></app-dropdown-button>
        <app-dropdown-item (menuClick)="deleteWork()"> Löschen </app-dropdown-item>
      </app-dropdown-minimal-menu>
      <span class="inline-flex rounded-md shadow-sm">
        <button
          appTailwindButton
          color="secondary"
          type="button"
          (click)="headerComponent.closeSlideOverClicked()"
        >
          Abbrechen
        </button>
      </span>
      <span class="inline-flex rounded-md shadow-sm">
        <button
          appTailwindButton
          [disabled]="data?.work?.data?.invoice || saving"
          type="button"
          (click)="saveWork()"
          class="grid grid-cols-1"
        >
          <span class="col-start-1 row-start-1">Speichern</span>

          <svg
            *ngIf="saving"
            class="col-start-1 row-start-1 animate-spin -ml-1 h-5 w-5 text-white justify-self-center"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </button>
      </span>
    </app-slide-over-footer>
  </app-slide-over>
</form>
