<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img
      style="filter: invert(100%)"
      class="mx-auto h-12 w-auto"
      src="/assets/jessie-logo.png"
      alt="Workflow"
    />
    <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
      Wir brauchen noch ein paar Informationen von dir
    </h2>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form [formGroup]="userForm" (ngSubmit)="saveUser(userForm.value)" autocomplete="on">
        <div class="space-y-6 pb-5">
          <app-text-input
            label="Vorname"
            [formControl]="$any(userForm.controls).firstName"
            autocomplete="given-name"
          ></app-text-input>
        </div>
        <app-text-input
          label="Nachname"
          [formControl]="$any(userForm.controls).lastName"
          autocomplete="family-name"
          >></app-text-input
        >
        <button
          class="w-full justify-center"
          appTailwindButton
          type="submit"
          [disabled]="userForm.invalid"
        >
          Weiter
        </button>
      </form>
    </div>
    <div class="flex justify-center text-xs mt-2 text-gray-500">
      <a [routerLink]="['user', 'logout']" [relativeTo]="null" class="no-underline hover:underline">
        User {{ loggedInUserEmail$ | async }} ausloggen</a
      >
    </div>
  </div>
</div>
