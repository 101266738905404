<div class="flex flex-col gap-8" *ngIf="orga$ | async as orga">
  <app-document-folder-card
    *ngIf="archive$ | async as archive"
    [archive]="archive"
    [disabled]="!(hasPermission$ | async)"
    (updateArchiveFolders)="updateArchive($event)"
  ></app-document-folder-card>
  <app-document-category-card
    [categories]="(categories$ | async)!"
    (updateCategories)="updateCategories($event)"
  ></app-document-category-card>
  <app-document-mail-card
    *ngIf="
      orga.data.subscription?.plan === 'business' || orga.data.subscription?.plan === 'enterprise'
    "
    [orga]="orga.data"
    [disabled]="!(hasPermission$ | async)"
    (mailAdressChanged)="settingsChanged($event)"
  ></app-document-mail-card>
</div>
