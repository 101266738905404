import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FirestoreProject } from 'commons';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'li[app-project-list-entry]',
  templateUrl: './project-list-entry.component.html',
  standalone: true,
  imports: [NgIconWrapperComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectListEntryComponent {
  @Input() project!: FirestoreProject;
  @Output() navigateTo = new EventEmitter<FirestoreProject>();
}
