import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FirestoreWork } from 'commons';

import { DatePipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownMinimalMenuComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { DropdownButtonComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownItemComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { FormatWorkPipe } from '../../../../../pipes/format-work.pipe';

@Component({
  selector: 'app-work-table',
  templateUrl: './work-table.component.html',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgIconWrapperComponent,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    DropdownItemComponent,
    DatePipe,
    FormatWorkPipe,
    JsonPipe,
  ],
})
export class WorkTableComponent {
  @Input()
  columns: ('project' | 'position' | 'user')[] = ['user', 'position', 'project'];
  @Input()
  works: FirestoreWork[] = [];
  @Input()
  sum: number | null = null;
  @Output()
  modifyWork = new EventEmitter<FirestoreWork>();
  @Output()
  deleteWork = new EventEmitter<FirestoreWork>();

  workId(work: FirestoreWork) {
    return work.id;
  }
}
