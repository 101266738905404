import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TailwindInputDirective } from '../../../../components/input/tailwind-input.directive';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { TimeMaterialRowComponent } from './time-material-row/time-material-row.component';
import { FixedPriceRowComponent } from './fixed-price-row/fixed-price-row.component';
import { InvoicePosition2, InvoicePositions, InvoiceTotals } from 'commons/dist/entities/invoice2';
import { FormatAmount } from '../../../../pipes/format-amount';
import { FormatPercent } from '../../../../pipes/format-percent';
import { isEqual } from 'lodash';
import { TailwindInputDirective2 } from '../../../../components/input/tailwind-input2.directive';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormInputWrapperComponent } from '../../../../components/input/form-input-wrapper/form-input-wrapper.component';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'app-position-table',
  standalone: true,
  imports: [
    TailwindInputDirective,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    TimeMaterialRowComponent,
    FixedPriceRowComponent,
    NgSwitch,
    NgForOf,
    NgSwitchCase,
    FormatAmount,
    FormatPercent,
    NgIf,
    TailwindInputDirective2,
    AsyncPipe,
    NgClass,
    FormInputWrapperComponent,
    NgIconWrapperComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PositionTableComponent),
      multi: true,
    },
  ],
  templateUrl: './position-table.component.html',
})
export class PositionTableComponent implements ControlValueAccessor, OnChanges {
  @Input() invoicePositions!: InvoicePosition2[];
  @Input() invoiceSummary!: InvoiceTotals;
  @Input() mode: 'sorting' | 'selecting' = 'sorting';

  @Input() formControl!: FormControl;

  @Output() deletedPosition = new EventEmitter<InvoicePosition2>();

  private onChange: (value: InvoicePositions | null) => void = () => {};
  private onTouched: () => void = () => {};

  value: InvoicePositions | null = null;
  _invoicePositions!: InvoicePosition2[];
  errors!: Observable<string[] | null>;
  isDisabled = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.invoicePositions &&
      changes.invoicePositions.currentValue &&
      !isEqual(changes.invoicePositions.previousValue, changes.invoicePositions.currentValue)
    ) {
      //const oldPositionSort = [...this.value.positionsSort];
      this.value = null;
      this.onChange(this.value);
      this._invoicePositions = this.invoicePositions;
      // this.invoicePositions
      //   .filter((position) => oldPositionSort.includes(position.positionId))
      //   .forEach((position) => this.positionSelected(position, true));
    }
    if (this.formControl) {
      this.errors = this.formControl.events.pipe(
        map(() => (this.formControl.touched ? this.formControl.errors : null)),
        map((x) => (x ? Object.keys(x) : null))
      );
    }
  }

  writeValue(value: InvoicePositions) {
    if (value) {
      this.value = value;
      if (this.mode === 'sorting') {
        this._invoicePositions = Object.values(this.value.positions);
        this.cdr.markForCheck();
      }
    }
  }

  registerOnChange(fn: (value: InvoicePositions | null) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  positionSelected(position: InvoicePosition2, isSelected: boolean) {
    if (isSelected) {
      const selected = this.value?.positionsSort
        ? [...this.value.positionsSort, position.positionId]
        : [position.positionId];
      this.value = {
        positions: this.value?.positions
          ? { ...this.value.positions, [position.positionId]: position }
          : { [position.positionId]: position },
        positionsSort: this.invoicePositions
          .map((position) => position.positionId)
          .filter((position) => selected.includes(position)),
      };
    } else {
      const newPositions = this.value?.positions ?? {};
      delete newPositions[position.positionId];
      this.value = {
        positions: newPositions,
        positionsSort: this.value?.positionsSort.filter((id) => id !== position.positionId) ?? [],
      };
    }
    this.cdr.markForCheck();
    this.onChange(this.value);
  }

  deleteRow(position: InvoicePosition2) {
    const newPositions = this.value?.positions ?? {};
    delete newPositions[position.positionId];
    this.value = {
      positions: newPositions,
      positionsSort: this.value?.positionsSort.filter((id) => id !== position.positionId) ?? [],
    };
    if (this.mode === 'sorting') {
      this._invoicePositions = Object.values(this.value.positions);
      this.cdr.markForCheck();
    }
    this.onChange(this.value);
    this.deletedPosition.emit(position);
  }
}
