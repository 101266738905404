import { Component } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { InvoicesComponent } from '../../../finance/invoices/invoices.component';
import { Invoice2Service } from '../../../../services/invoice2.service';
import { FirestoreInvoice2 } from 'commons/dist/entities/invoice2';
import { DocumentSlideOverService } from '../../../document/document-slide-over/document-slide-over.component';
import { DocumentService } from '../../../../services/document.service';
import { EmptyStateComponent } from '../../../../components/empty/empty-state.component';

@Component({
  selector: 'app-project-invoice',
  templateUrl: './project-invoice.component.html',
  standalone: true,
  imports: [InvoicesComponent, AsyncPipe, EmptyStateComponent],
})
export class ProjectInvoiceComponent {
  invoices: Observable<FirestoreInvoice2[]>;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: Invoice2Service,
    private documentSlideOver: DocumentSlideOverService,
    private documentService: DocumentService
  ) {
    const projectId = this.route.parent?.snapshot.params['projectId'];
    this.invoices = invoiceService
      .getInvoicesForProject(projectId)
      .pipe(
        map((invoices) =>
          invoices.sort((a, b) => b.data.invoiceNumber.localeCompare(a.data.invoiceNumber))
        )
      );
  }

  async editInvoice(invoice: FirestoreInvoice2) {
    // @ts-ignore
    if (!!invoice.data._v6) {
      if (invoice.data.documentId) {
        const document = await firstValueFrom(
          this.documentService.getDocument(invoice.data.documentId)
        );
        this.documentSlideOver.edit([document]);
      } else {
        console.log('migrated invoice without data');
      }
    } else {
      this.router.navigate([invoice.id], {
        relativeTo: this.route,
      });
    }
  }

  deleteInvoice(invoice: FirestoreInvoice2) {
    //this.invoiceService.deleteInvoice(invoice);
  }

  downloadInvoice(invoice: FirestoreInvoice2) {
    //this.invoiceService.downloadInvoice(invoice);
  }

  createInvoice() {}
}
