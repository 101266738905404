<form class="pl-3" autocomplete="off" novalidate>
  <app-slide-over *ngIf="positionForm">
    <app-slide-over-header
      #headerComponent
      (closeSlideOver)="closeSlideOver()"
      [formDirty]="positionForm.dirty"
      [title]="
        data?.position
          ? positionForm.controls.base.controls.name.value!
          : 'Neue Projektposition erstellen'
      "
      [leadText]="
        data?.position
          ? ''
          : 'Mit Positionen gibst du deinem Projekt Struktur. Positionen sind einzelne Arbeitspakete welche das Projekt unterteilen.'
      "
    >
    </app-slide-over-header>

    <app-slide-over-content>
      <div class="px-4 divide-y divide-gray-200 sm:px-6">
        <div class="space-y-6 pt-6 pb-5">
          <app-text-input
            [formControl]="positionForm.controls.base.controls.name"
            label="Name der Projektposition"
          ></app-text-input>

          <app-text-input
            variant="textarea"
            label="Beschreibung"
            [formControl]="positionForm.controls.base.controls.description"
          ></app-text-input>

          <app-radio-button-group
            label="Art der Projektposition"
            [formControl]="positionForm.controls.type"
            [options]="positionTypeOptions"
          ></app-radio-button-group>

          <app-number-input
            *ngIf="$any(positionForm.controls.specific.controls).hourlyRate"
            label="Stundensatz"
            [fractionDigits]="2"
            currencySymbol="CHF"
            [formControl]="$any(positionForm.controls.specific.controls).hourlyRate"
          ></app-number-input>

          <app-work-time-input
            variant="hours"
            *ngIf="$any(positionForm.controls.specific.controls).estimatedWorkMinutes"
            label="Geschätzter Totalaufwand"
            [formControl]="$any(positionForm.controls.specific.controls).estimatedWorkMinutes"
          ></app-work-time-input>

          <app-number-input
            *ngIf="$any(positionForm.controls.specific.controls).amount"
            label="Festpreis"
            [fractionDigits]="2"
            currencySymbol="CHF"
            [formControl]="$any(positionForm.controls.specific.controls).amount"
          ></app-number-input>

          <app-dropdown
            label="Mehrwertsteuersatz"
            [options]="vatRates"
            [bindIdOnly]="true"
            [formControl]="positionForm.controls.base.controls.vatRateType"
          ></app-dropdown>

          <app-checkbox
            [formControl]="positionForm.controls.base.controls.billable"
            label="Verrechenbar"
            description="Bestimme, ob diese Position dem Kunden in Rechnung gestellt werden kann. Nicht
                  verrechenbare Positionen können z. B. Aufwände für Offerten, Verkauf oder
                  Reisezeit sein."
          ></app-checkbox>
        </div>
      </div>
    </app-slide-over-content>

    <app-slide-over-footer>
      <app-dropdown-minimal-menu *ngIf="data?.position && isDeletionAllowed">
        <app-dropdown-button><app-ng-icon name="heroTrash"></app-ng-icon></app-dropdown-button>
        <app-dropdown-item (menuClick)="deletePosition()"> Löschen </app-dropdown-item>
      </app-dropdown-minimal-menu>
      <button
        appTailwindButton
        color="secondary"
        type="button"
        (click)="headerComponent.closeSlideOverClicked()"
      >
        Abbrechen
      </button>

      <button appTailwindButton type="button" (click)="savePosition()">Speichern</button>
    </app-slide-over-footer>
  </app-slide-over>
</form>
