import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  formatISO,
  getDate,
  isSameDay,
  isSameMonth,
  isToday,
  lastDayOfMonth,
  lastDayOfWeek,
  max,
  min,
  parseISO,
  startOfMonth,
} from 'date-fns';
import { formatDate, NgFor, NgIf } from '@angular/common';
import { IsoDate } from 'commons';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'app-month-calendar',
  templateUrl: './month-calendar.component.html',
  standalone: true,
  imports: [NgFor, NgIf, NgIconWrapperComponent],
})
export class MonthCalendarComponent implements OnInit, OnChanges {
  @Input({ required: true }) month!: IsoDate;
  @Input({ required: true }) selectedDate!: IsoDate;
  @Input({ required: true }) work!: Map<string, number>;
  @Input() warningDays: IsoDate[] = [];

  @Output() selectedDateChange = new EventEmitter<IsoDate>();

  monthTitle!: string;
  totalWorkMonth!: string;
  weeks!: Week[];

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  ngOnInit(): void {
    this.initData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initData();
  }

  initData() {
    this.monthTitle = formatDate(this.month, 'LLLL yyyy', this.locale);

    this.totalWorkMonth = eachDayOfInterval({
      start: startOfMonth(parseISO(this.month)),
      end: lastDayOfMonth(parseISO(this.month)),
    })
      .reduce((acc, cur) => acc + (this.work.get(cur.toDateString()) || 0), 0)
      .toFixed(2);

    this.weeks = eachWeekOfInterval(
      {
        start: startOfMonth(parseISO(this.month)),
        end: endOfMonth(parseISO(this.month)),
      },
      { weekStartsOn: 1 }
    ).map((startOfWeek) => ({
      work: eachDayOfInterval({
        start: max([startOfWeek, parseISO(this.month)]),
        end: min([
          lastDayOfWeek(startOfWeek, { weekStartsOn: 1 }),
          endOfMonth(parseISO(this.month)),
        ]),
      })
        .reduce((acc, cur) => acc + (this.work.get(cur.toDateString()) || 0), 0)
        .toFixed(2),
      days: eachDayOfInterval({
        start: startOfWeek,
        end: lastDayOfWeek(startOfWeek, { weekStartsOn: 1 }),
      }).map((dayOfWeek) => {
        return {
          date: formatISO(dayOfWeek, { representation: 'date' }),
          dayOfMonth: getDate(dayOfWeek),
          isInCurrentMonth: isSameMonth(parseISO(this.month), dayOfWeek),
          isCurrentDate: isToday(dayOfWeek),
          work: this.work.get(dayOfWeek.toDateString()) || 0,
          isSelected: isSameDay(dayOfWeek, parseISO(this.selectedDate)),
        };
      }),
    }));
  }
}

interface Week {
  days: Day[];
  work: string;
}

interface Day {
  date: IsoDate;
  dayOfMonth: number;
  isInCurrentMonth: boolean;
  isCurrentDate: boolean;
  work: number;
  isSelected: boolean;
}
