import { Component, Input } from '@angular/core';
import { CategoryColors } from 'commons';
import { NgClass, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';

export type BadgeColors =
  | 'grey'
  | 'red'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink';

export const allBadgeColors: CategoryColors[] = [
  'grey',
  'red',
  'yellow',
  'green',
  'blue',
  'indigo',
  'purple',
  'pink',
];

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgTemplateOutlet, NgClass],
  styles: `
    :host {
      @apply select-none;
    }
  `,
})
export class BadgeComponent {
  @Input() color: BadgeColors = 'grey';
  @Input() style: 'pill' | 'badge' = 'badge';
  @Input() size: 'small' | 'large' = 'small';
}
