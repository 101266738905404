<app-settings-card
  [showFooter]="false"
  heading="Subscription"
  description="Im Payment Portal kannst du deine Subscription bearbeiten. Für Up- und Downgrades und weitere Anliegen helfen wir dir gerne per Mail weiter."
>
  <div class="flex my-8 gap-4">
    <a [href]="link + suffix" appTailwindButton color="primary">Payment Portal</a>
    <a href="mailto:support@jessie.ai" appTailwindButton color="primary">Support per Mail</a>
  </div>
</app-settings-card>
