import { Component, Input } from '@angular/core';
import { FirestoreProject } from 'commons';
import { Router, RouterLink } from '@angular/router';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'li[app-project-list-entry]',
  templateUrl: './project-list-entry.component.html',
  standalone: true,
  imports: [RouterLink, NgIconWrapperComponent],
})
export class ProjectListEntryComponent {
  @Input()
  project!: FirestoreProject;

  constructor(public router: Router) {}
}
