import { Pipe, PipeTransform } from '@angular/core';
import { NumberString } from 'commons';
import { Big } from 'big.js';

@Pipe({
  name: 'stringToNumber',
  standalone: true,
})
export class StringToNumber implements PipeTransform {
  transform(amount: NumberString | null, ...args: unknown[]): number {
    if (!amount) {
      return 0;
    }
    return new Big(amount).toNumber();
  }
}
