import { inject, Injectable } from '@angular/core';
import {
  FirestoreProject,
  FirestoreUser,
  mapProjectSummary,
  PositionEntity2,
  PositionSummary,
  Project,
  ProjectSummary,
  WorkPositionSummary,
} from 'commons';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { flattenObject } from './helper';
import { LastEditorService } from './last-editor.service';
import { ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { deleteField, where } from '@angular/fire/firestore';
import { SessionStateService } from './session-state.service';
import { Position2 } from 'commons/dist/entities/position2';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private sessionState = inject(SessionStateService);
  private lastEditor = inject(LastEditorService);

  getProject(projectId: string): Observable<FirestoreProject> {
    return this.orgaFirestoreService.getDoc<Project>(`project/${projectId}`);
  }

  getActiveProjects(): Observable<FirestoreProject[]> {
    return this.orgaFirestoreService.getDocs<Project>('project', where('isActive', '==', true));
  }

  getInactiveProjects(): Observable<FirestoreProject[]> {
    return this.orgaFirestoreService.getDocs<Project>('project', where('isActive', '==', false));
  }

  getAllProjects(): Observable<FirestoreProject[]> {
    return this.orgaFirestoreService.getDocs<Project>('project');
  }

  getAllProjectSummaries(): Observable<ProjectSummary[]> {
    return this.orgaFirestoreService
      .getDocs<Project>('project')
      .pipe(map((projects) => projects.map((project) => mapProjectSummary(project))));
  }

  getAllProjectsForCustomer(customerId: string): Observable<FirestoreProject[]> {
    return this.orgaFirestoreService.getDocs<Project>(
      'project',
      where('customerId', '==', customerId)
    );
  }

  async createProject(project: Partial<Project>) {
    return this.orgaFirestoreService.createDoc('project', await this.lastEditor.hydrate(project));
  }

  async updateProject(projectId: string, project: Partial<Project>) {
    return this.orgaFirestoreService.updateDoc<Project>(
      `project/${projectId}`,
      await this.lastEditor.hydrate(project)
    );
  }

  async createPosition(projectId: string, position: PositionEntity2) {
    const id = crypto.randomUUID();
    const positionPath = `positions.${id}`;
    return this.orgaFirestoreService.updateDoc<Project>(
      `project/${projectId}`,
      // @ts-ignore
      await this.lastEditor.hydrate({
        [positionPath]: position,
      })
    );
  }

  async deletePosition(projectId: string, positionId: string) {
    const positionPath = `positions.${positionId}`;
    return this.orgaFirestoreService.updateDoc<Project>(
      `project/${projectId}`,
      // @ts-ignore
      await this.lastEditor.hydrate({
        [positionPath]: deleteField(),
      })
    );
  }

  async updatePosition(
    projectId: string,
    positionId: string,
    position: Partial<PositionEntity2>
  ): Promise<void> {
    const positionPath = `positions.${positionId}`;
    const flattenPosition = flattenObject(position, positionPath);
    return this.orgaFirestoreService.updateDoc<Project>(
      `project/${projectId}`,
      // @ts-ignore
      await this.lastEditor.hydrate(flattenPosition)
    );
  }

  async toggleFavorite(project: FirestoreProject, activated: boolean) {
    const userId = (
      await firstValueFrom(
        this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user))
      )
    ).id;
    // @ts-ignore
    await this.updateProject(project.id, {
      [`favorite.${userId}`]: activated ? true : deleteField(),
    });
  }

  // getAllBillablePositions(projectId: string): Observable<Position2[]> {
  //   return this.orgaFirestoreService.getDoc<Project>(`project/${projectId}`).pipe(
  //     map((project) => (project.data.positions ? Object.values(project.data.positions) : [])),
  //     map((positions) => positions.filter((pos) => pos.billable))
  //   );
  // }

  getPositions(project: FirestoreProject): Position2[] {
    return Object.entries(project.data.positions ?? {}).reduce(
      (prev, cur) => [...prev, { id: cur[0], ...cur[1] }],
      [] as Position2[]
    );
  }

  getBillablePositions(project: FirestoreProject): Position2[] {
    if (!project.data.positions) {
      return [];
    }
    return Object.entries(project.data.positions)
      .reduce((prev, cur) => [...prev, { id: cur[0], ...cur[1] }], [] as Position2[])
      .filter((positions) => positions.billable);
  }

  getPositionSummaries(project: FirestoreProject): PositionSummary[] {
    if (!project.data.positions) {
      return [];
    }
    return Object.entries(project.data.positions).reduce(
      (prev, cur) => [...prev, { id: cur[0], displayName: cur[1].name }],
      [] as PositionSummary[]
    );
  }

  getWorkPositionSummaries(project: FirestoreProject): WorkPositionSummary[] {
    if (!project.data.positions) {
      return [];
    }
    return Object.entries(project.data.positions).reduce(
      (prev, cur) => [
        ...prev,
        {
          id: cur[0],
          displayName: cur[1].name,
          billable: cur[1].billable,
          type: cur[1].type,
        },
      ],
      [] as WorkPositionSummary[]
    );
  }
}
