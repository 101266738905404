import { inject, Injectable } from '@angular/core';

import { map, Observable, shareReplay } from 'rxjs';
import { FirestoreVatRate, IsoDate, VatRate, VatRateDecimal, VatRateType } from 'commons';
import { FIRESTORE_SERVICE } from './firestore.service';
import { isWithinInterval, parseISO } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class VatRateService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);

  vatRates = this.getVatRates().pipe(shareReplay(1));

  getVatRates(): Observable<FirestoreVatRate[]> {
    return this.rootFirestoreService.getDocs<VatRate>('system/vat/vatRates');
  }

  getVatRatesForDate(date: string): Observable<FirestoreVatRate | null> {
    return this.vatRates.pipe(
      map((vatRates) => {
        return (
          vatRates.filter((vatRate) =>
            isWithinInterval(parseISO(date), {
              start: parseISO(vatRate.data.validFrom),
              end: parseISO(vatRate.data.validTo),
            })
          )[0] ?? null
        );
      })
    );
  }

  getVatRatesForPeriod(from: IsoDate, to: IsoDate): Observable<FirestoreVatRate[]> {
    return this.vatRates.pipe(
      map((vatRates) =>
        vatRates.filter(
          (vatRate) =>
            isWithinInterval(parseISO(from), {
              start: parseISO(vatRate.data.validFrom),
              end: parseISO(vatRate.data.validTo),
            }) &&
            isWithinInterval(parseISO(to), {
              start: parseISO(vatRate.data.validFrom),
              end: parseISO(vatRate.data.validTo),
            })
        )
      )
    );
  }

  getVatRateForDate(
    date: IsoDate,
    vatRateType: Exclude<VatRateType, 'none'>
  ): Observable<VatRateDecimal | null> {
    return this.vatRates.pipe(
      map((vatRates) => {
        const validRate = vatRates.find(
          (vatRate) => vatRate.data.validFrom <= date && vatRate.data.validTo >= date
        );
        return validRate?.data[vatRateType] ?? null;
      })
    );
  }
}
