<form
  class="form-container pl-3"
  [formGroup]="customerForm"
  (ngSubmit)="createOrUpdateCustomer(customerForm.value)"
  autocomplete="off"
  novalidate
  *ngIf="customerForm"
>
  <app-slide-over>
    <app-slide-over-header
      #headerComponent
      (closeSlideOver)="closeSlideOver()"
      [formDirty]="customerForm.dirty"
      [title]="customerType === 'individual' ? 'Person' : 'Firma'"
    >
    </app-slide-over-header>

    <app-slide-over-content>
      <div class="px-4 sm:px-6">
        <ng-container *ngIf="customerType == 'company'">
          <div class="space-y-6 pt-6 pb-5">
            <div class="space-y-1">
              <app-text-input
                label="Kurzname"
                class="mt-4"
                [formControl]="$any(customerForm.controls).company.controls.displayName"
              ></app-text-input>
            </div>
            <div class="space-y-1">
              <app-text-input
                label="Firmenname (Rechtsgültig)"
                class="mt-4"
                [formControl]="$any(customerForm.controls).company.controls.legalName"
              ></app-text-input>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="customerType == 'individual'">
          <div class="space-y-6 pt-6 pb-5">
            <div class="space-y-1">
              <app-text-input
                label="Vorname"
                class="mt-4"
                [formControl]="$any(customerForm.controls).individual.controls.firstName"
              ></app-text-input>
            </div>
            <div class="space-y-1">
              <app-text-input
                label="Nachname"
                class="mt-4"
                [formControl]="$any(customerForm.controls).individual.controls.lastName"
              ></app-text-input>
            </div>
          </div>
        </ng-container>

        <div class="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
          <app-address-form
            class="sm:col-span-6"
            [addressFormGroup]="$any(customerForm.controls).address"
          ></app-address-form>
        </div>
      </div>
    </app-slide-over-content>

    <app-slide-over-footer>
      <span class="inline-flex rounded-md shadow-sm">
        <button
          appTailwindButton
          color="secondary"
          type="button"
          (click)="headerComponent.closeSlideOverClicked()"
        >
          Abbrechen
        </button>
      </span>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton type="submit">Speichern</button>
      </span>
    </app-slide-over-footer>
  </app-slide-over>
</form>
