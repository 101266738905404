import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { icons } from '../../icon.config';

@Component({
  selector: 'app-empty-state',
  standalone: true,
  imports: [NgIconWrapperComponent],
  templateUrl: './empty-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent {
  @Input() iconName: keyof typeof icons = 'heroChatBubbleBottomCenter';
  @Input() heading: string = 'Da ist nichts';
  @Input() subheading: string = '';
}
