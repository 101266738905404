import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkViewComponent } from '../work/work-view.component';

@Component({
  selector: 'app-project-activity',
  templateUrl: './project-activity.component.html',
  standalone: true,
  imports: [WorkViewComponent],
})
export class ProjectActivityComponent {
  projectId!: string;
  positionId!: string;

  constructor(private route: ActivatedRoute) {
    this.projectId = this.route.parent?.snapshot.params['projectId'];
    this.positionId = this.route.snapshot.queryParams['positionId'];
  }
}
