import { Component, Inject } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { InvoicesComponent } from '../../../finance/invoices/invoices.component';
import { InvoiceService } from '../../../../services/invoice.service';
import { FirestoreInvoice } from 'commons/dist/entities/invoice';
import { DocumentService } from '../../../../services/document.service';
import { Dialog } from '@angular/cdk/dialog';
import { DocumentSlideOverComponent } from '../../../document/document-slide-over/document-slide-over.component';

@Component({
  selector: 'app-project-invoice',
  templateUrl: './project-invoice.component.html',
  standalone: true,
  imports: [InvoicesComponent, AsyncPipe],
})
export class ProjectInvoiceComponent {
  invoices: Observable<FirestoreInvoice[]>;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private documentService: DocumentService,
    @Inject(Dialog) private dialog: Dialog
  ) {
    const projectId = this.route.parent?.snapshot.params['projectId'];
    this.invoices = this.invoiceService
      .getInvoicesForProject(projectId)
      .pipe(
        map((invoices) =>
          invoices.sort((a, b) => b.data.invoiceNumber.localeCompare(a.data.invoiceNumber))
        )
      );
  }

  async editInvoice(invoice: FirestoreInvoice) {
    // @ts-ignore
    if (!!invoice.data._v6) {
      if (invoice.data.documentId) {
        const document = await firstValueFrom(
          this.documentService.getDocument(invoice.data.documentId)
        );
        this.dialog.open(DocumentSlideOverComponent, { data: { documents: [document] } });
      } else {
        console.log('migrated invoice without data');
      }
    } else {
      this.router.navigate([invoice.id], {
        relativeTo: this.route,
      });
    }
  }
}
