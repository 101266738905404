<a
  class="absolute m-4 right-0"
  appTailwindButton
  type="button"
  [routerLink]="['/user', 'orga']"
  [relativeTo]="null"
  >Organisation wechseln</a
>
<div class="flex items-center justify-center py-24 sm:py-0">
  <app-settings-card
    *ngIf="orga?.subscription; else noSubscription"
    [showFooter]="false"
    heading="Subscription ist nicht ready"
    [description]="
      'Deine Subscription hat den Status ' +
      orga.subscription?.status +
      '. Du kannst entweder selber im Payment Portal schauen was los ist. Oder du fragst via Mail bei uns nach.'
    "
  >
    <div class="flex my-8 gap-4">
      <a [href]="stripeDashboardlink + dashboardSuffix" appTailwindButton color="primary"
        >Payment Portal</a
      >
      <a href="mailto:support@jessie.ai" appTailwindButton color="primary">Support per Mail</a>
    </div>
  </app-settings-card>
</div>

<ng-template #noSubscription>
  <div class="py-26 sm:py-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="mt-6 text-center text-xl font-semibold leading-9 text-gray-900">
        Wähle dein Abonnement
      </h2>
      <p class="mt-2 text-center text-base leading-9 text-gray-900">
        Der erste Monat ist gratis. Du kannst ein Abo jederzeit kündigen.
      </p>
      <div
        class="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3"
      >
        <div
          class="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:mt-8 lg:rounded-r-none"
        >
          <div>
            <div class="flex items-center justify-between gap-x-4">
              <h3 id="tier-freelancer" class="text-lg font-semibold leading-8 text-gray-900">
                Starter
              </h3>
            </div>
            <p class="mt-4 text-sm leading-6 text-gray-600">Einfach mal starten</p>
            <p class="mt-6 flex items-baseline gap-x-1">
              <span class="text-4xl font-bold tracking-tight text-gray-900">9.-</span>
              <span class="text-sm font-semibold leading-6 text-gray-600">/month</span>
            </p>
            <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
              <li app-pricing-list-item>Projektmanagement</li>
              <li app-pricing-list-item>Kundenverwaltung</li>
              <li app-pricing-list-item>Dokumentenverwaltung</li>
              <li app-pricing-list-item>Finanzübersicht</li>
              <li app-pricing-list-item>Für 1 User</li>
              <li app-pricing-list-item>2GB Dokumentenspeicher</li>
            </ul>
          </div>
          <a
            [href]="basicPlanLink"
            aria-describedby="tier-freelancer"
            class="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
            >Einen Monat gratis testen</a
          >
        </div>
        <div
          class="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:z-10 lg:rounded-b-none"
        >
          <div>
            <div class="flex items-center justify-between gap-x-4">
              <h3 id="tier-startup" class="text-lg font-semibold leading-8 text-blue-600">
                Business
              </h3>
              <p
                class="rounded-full bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600"
              >
                Empfehlung
              </p>
            </div>
            <p class="mt-4 text-sm leading-6 text-gray-600">
              Alle Features für den Erfolg deiner Firma
            </p>
            <p class="mt-6 flex items-baseline gap-x-1">
              <span class="text-4xl font-bold tracking-tight text-gray-900"
                >{{ successPlanPrice }}.-</span
              >
              <span class="text-sm font-semibold leading-6 text-gray-600">/month</span>
            </p>

            <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
              <p>alle Features von Starter und zusätzlich</p>
              <li app-pricing-list-item>Mail-Drop für Dokumente</li>
              <li app-pricing-list-item>AI-Dokumentenerkennung (20/Monat)</li>
              <li app-pricing-list-item>1 Buchhaltungszugang</li>
              <li app-pricing-list-item>5GB Dokumentenspeicher</li>
            </ul>
          </div>
          <div>
            <div>
              <label
                for="user-success"
                class="mt-4 block text-sm font-medium leading-6 text-gray-900"
                >User</label
              >
              <select
                [(ngModel)]="successPlanUser"
                (change)="recalculate()"
                id="user-success"
                name="Anzahl User"
                class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
            <a
              [href]="successPlanLink"
              aria-describedby="tier-startup"
              class="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 bg-blue-600 text-white shadow-sm hover:bg-blue-500"
              >Einen Monat gratis testen</a
            >
          </div>
        </div>
        <div
          class="flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 lg:mt-8 lg:rounded-l-none"
        >
          <div>
            <div class="flex items-center justify-between gap-x-4">
              <h3 id="tier-enterprise" class="text-lg font-semibold leading-8 text-gray-900">
                Enterprise
              </h3>
            </div>
            <p class="mt-4 text-sm leading-6 text-gray-600">Für alle die mehr brauchen</p>
            <p class="mt-6 flex items-baseline gap-x-1">
              <span class="text-4xl font-bold tracking-tight text-gray-900"
                >{{ enterprisePlanPrice }}.-</span
              >
              <span class="text-sm font-semibold leading-6 text-gray-600">/month</span>
            </p>
            <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600">
              <p>alle Features von Business und zusätzlich</p>
              <li app-pricing-list-item>AI-Dokumentenerkennung (100/Monat)</li>
              <li app-pricing-list-item>3 Buchhaltungszugänge</li>
              <li app-pricing-list-item>30GB Dokumentenspeicher</li>
            </ul>
          </div>
          <div>
            <label
              for="user-enterprise"
              class="mt-4 block text-sm font-medium leading-6 text-gray-900"
              >User</label
            >
            <select
              [(ngModel)]="enterprisePlanUser"
              (change)="recalculate()"
              id="user-enterprise"
              name="Anzahl User"
              class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
            >
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
          </div>
          <a
            [href]="enterprisePlanLink"
            aria-describedby="tier-enterprise"
            class="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300"
            >Einen Monat gratis testen</a
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>
