"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVatRateForDropdown = exports.VAT_RATE_TEXTS = void 0;
exports.VAT_RATE_TEXTS = {
    standardRate: 'Normalsatz',
    reducedRate: 'Reduzierter Satz',
    specialRate: 'Spezialsatz',
    none: 'Keine Mehrwertsteuer',
};
function getVatRateForDropdown() {
    return Object.entries(exports.VAT_RATE_TEXTS).reduce((prev, cur) => [...prev, { id: cur[0], displayName: cur[1] }], []);
}
exports.getVatRateForDropdown = getVatRateForDropdown;
