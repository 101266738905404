import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';

@Component({
  selector: 'app-toggle-button-group',
  templateUrl: './toggle-button-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleButtonGroupComponent,
    },
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonGroupComponent implements ControlValueAccessor {
  _toggleButtons!: QueryList<ToggleButtonComponent>;
  @ContentChildren(ToggleButtonComponent, { descendants: true })
  set toggleButtons(children: QueryList<ToggleButtonComponent>) {
    this._toggleButtons = children;
    this.syncToggleButtonValues();
  }

  _value!: string | null;
  isDisabled = false;

  set value(value: string | null) {
    this._value = value;
    this.syncToggleButtonValues();
  }

  private onChange!: (value: string | null) => undefined;
  private onTouched!: () => undefined;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  setValue(value: string) {
    this.value = value;
    if (this.onChange) {
      this.onChange(value);
    }
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (this._toggleButtons) {
      this._toggleButtons.forEach((toggleButton) => toggleButton.setDisabled(this.isDisabled));
    }
  }

  private syncToggleButtonValues() {
    if (this._toggleButtons) {
      this._toggleButtons.forEach((toggleButton) => (toggleButton.isChecked = false));
      this._toggleButtons
        .filter((toggleButton) => toggleButton.value === this._value)
        .forEach((toggleButton) => {
          toggleButton.isChecked = true;
        });
      this._toggleButtons.forEach((toggleButton) => toggleButton.markForCheck());
      this._toggleButtons.forEach((toggleButton) => toggleButton.setDisabled(this.isDisabled));
    }
  }
}
