<app-form-input-wrapper [label]="label" [control]="formControl">
  <input
    [attr.disabled]="isDisabled ? true : null"
    [value]="value"
    (input)="valueChanged(inputElement.value)"
    #inputElement
    appTailwindInput2
    [control]="formControl"
    type="date"
  />
  <ng-content></ng-content>
</app-form-input-wrapper>
