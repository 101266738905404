import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FirestoreUser, User } from 'commons';
import { SettingsCardComponent } from '../settings-card/settings-card.component';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';

@Component({
  selector: 'app-user-profile-card',
  templateUrl: './user-profile-card.component.html',
  standalone: true,
  imports: [SettingsCardComponent, ReactiveFormsModule, TextInputComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileCardComponent implements OnInit {
  @Input()
  user!: FirestoreUser;

  @Output()
  profileChanged = new EventEmitter<Partial<User>>();

  profileForm!: FormGroup;

  ngOnInit() {
    this.profileForm = new FormGroup({
      firstName: new FormControl(this.user.data.firstName, [
        Validators.required,
        Validators.maxLength(250),
      ]),
      lastName: new FormControl(this.user.data.lastName, [
        Validators.required,
        Validators.maxLength(250),
      ]),
    });
  }

  saveSettings() {
    if (!this.profileForm.invalid) {
      this.profileChanged.emit(this.profileForm.value);
    }
  }
}
