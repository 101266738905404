import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FirestoreUser, User } from 'commons';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { UserProfileCardComponent } from './user-profile-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  standalone: true,
  imports: [NgIf, UserProfileCardComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsComponent {
  user = this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user));

  constructor(
    private userService: UserService,
    private sessionState: SessionStateService
  ) {}

  async profileChanged(user: Partial<User>) {
    const fsUser = await firstValueFrom(this.user);
    this.userService.update(fsUser.id, user).then(() => console.log('done'));
  }
}
