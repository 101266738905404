<app-timer
  *ngIf="timer | async as timer"
  [timer]="timer"
  [workForm]="workForm"
  [projectSummaries]="projectSummaries"
  [positionSummaries]="positionSummaries"
  (navigateBack)="navigateBack()"
  (start)="start()"
  (stop)="stop()"
  (delete)="delete()"
  (saveWork)="saveWork()"
></app-timer>
