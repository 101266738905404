<app-document-card
  cardTitle="Zur Bearbeitung (Buchhaltung)"
  *ngIf="documents$ | async as documents"
  [documents]="documents"
  [disableFilter]="true"
  [updateAccountingProcessedEnabled]="true"
  (searchValueChanged)="searchValue.next($event)"
  (editDocument)="editDocument($event)"
  (deleteDocument)="deleteDocument($event)"
></app-document-card>
