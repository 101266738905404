import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { firstValueFrom, Observable } from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import { CustomerType, FirestoreCustomer } from 'commons';
import { EmptyComponent } from '../../components/empty/empty.component';
import { CustomerListEntryComponent } from './customer-list-entry/customer-list-entry.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { DropdownItemComponent } from '../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { TailwindButtonDirective } from '../../components/button/tailwind-button.directive';
import { DropdownButtonComponent } from '../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { HeaderMetaActionComponent } from '../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../components/container/container.component';
import { Dialog } from '@angular/cdk/dialog';
import {
  CustomerSlideOverComponent,
  CustomerSlideOverInput,
} from './customer-slide-over/customer-slide-over.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    HeaderMetaActionComponent,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    TailwindButtonDirective,
    DropdownItemComponent,
    NgIf,
    NgFor,
    CustomerListEntryComponent,
    NgClass,
    EmptyComponent,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersComponent {
  customers$: Observable<FirestoreCustomer[]>;

  constructor(
    private customerService: CustomerService,
    private router: Router,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.customers$ = this.customerService.getAllCustomers();
  }

  async createCustomer(type: CustomerType): Promise<void> {
    const dialogRef = this.dialog.open<string, CustomerSlideOverInput, CustomerSlideOverComponent>(
      CustomerSlideOverComponent,
      { data: { customerType: type } }
    );
    const id = await firstValueFrom(dialogRef.closed);
    if (id) {
      this.router.navigate(['/customer/', id]);
    }
  }

  editCustomer(customer: FirestoreCustomer) {
    this.dialog.open<string, CustomerSlideOverInput, CustomerSlideOverComponent>(
      CustomerSlideOverComponent,
      { data: { customer: customer } }
    );
  }
}
