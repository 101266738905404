"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.allCategoryColors = exports.fieldNames = exports.formatFileName = void 0;
function formatFileName(fileName) {
    // Replace '-' and '_' with a space
    let formattedName = fileName.replace(/[-_]/g, ' ');
    // Remove file ending (assumes the file ending starts with a dot)
    const dotIndex = formattedName.lastIndexOf('.');
    if (dotIndex !== -1) {
        formattedName = formattedName.substring(0, dotIndex);
    }
    return formattedName;
}
exports.formatFileName = formatFileName;
exports.fieldNames = [
    'due_date',
    'net_amount',
    'receiver_name',
    'supplier_name',
    'supplier_payment_ref',
    'total_amount',
    'document_date',
    'invoice_id',
    'category',
];
exports.allCategoryColors = [
    'grey',
    'red',
    'yellow',
    'green',
    'blue',
    'indigo',
    'purple',
    'pink',
];
