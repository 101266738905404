<ng-container>
  <td></td>
  <td
    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate"
    [tp]="work.data.project.displayName"
    tpOnlyTextOverflow
  >
    {{ work.data.project.displayName }}
    <div class="sm:hidden text-gray-500 text-xs">{{ work.data.position.displayName }}</div>
  </td>
  <td
    class="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell truncate"
    [tp]="work.data.position.displayName"
    tpOnlyTextOverflow
  >
    {{ work.data.position.displayName }}
  </td>
  <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
    <div class="line-clamp-3 2xl:line-clamp-none break-all">
      {{ work.data.description }}
    </div>
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 text-right tabular-nums">
    {{ work.data.workMinutes | formatWork }}
  </td>
  <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-2">
    <button appTailwindButton size="xs" (click)="editWork.emit(work)" color="white">
      Bearbeiten
    </button>
  </td>
</ng-container>
