<div class="flex flex-col-reverse gap-4 justify-end lg:-mt-[82px] print:hidden sm:flex-row ml-4">
  <div class="relative flex flex-col">
    <app-simple-pill
      label="Firmen / Personen"
      [filter]="legalEntityFilter | async"
      [filterValues]="legalEntityFilterValues ?? []"
      (filterChanged)="legalEntityFilter.next($event)"
    ></app-simple-pill>

    <div class="flex items-center h-5 mt-1 ml-6">
      <input
        (change)="isGrouped($event)"
        [checked]="isGroupedByCustomer"
        id="groupedByCustomer"
        type="checkbox"
        class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded disabled:bg-gray-50 disabled:checked:bg-blue-400"
      />

      <div class="ml-2 text-xs">
        <label for="groupedByCustomer" class="font-medium text-gray-700">Gruppieren</label>
      </div>
    </div>
  </div>
  <app-date-filter-pill
    [filter]="(dateFilter | async)!"
    (filterChanged)="dateFilter.next($event)"
  ></app-date-filter-pill>
</div>

<ng-container *ngIf="!isGroupedByCustomer; else groupedByCustomer">
  <ng-container *ngIf="rows$ | async as rows">
    <div
      class="bg-white mt-4 shadow sm:rounded-lg overflow-hidden print:shadow-none"
      *ngIf="rows.length > 0; else noIncomeExpenses"
    >
      <div
        class="grid grid-cols-[minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)] sm:grid-cols-[minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)] gap-2 p-4 group"
      >
        <div>
          <button
            class="pr-4 flex justify-between w-full text-gray-400 group-hover:text-gray-900"
            (click)="sortDateToggle()"
          >
            <div class="text-right text-sm font-semibold">Datum</div>
            <app-ng-icon
              class="text-xl"
              name="heroArrowLongDown"
              [class.rotate-180]="(sortDate | async) === 'asc'"
            ></app-ng-icon>
          </button>
        </div>
        <div class="hidden sm:block"></div>
        <div class="hidden sm:block"></div>
        <div class="hidden sm:block"></div>

        <div class="text-right text-sm font-semibold text-gray-900">Einnahmen</div>
        <div class="text-right text-sm font-semibold text-gray-900">Ausgaben</div>
      </div>
      <ng-container *ngIf="rows.length > 15">
        <ng-container *ngTemplateOutlet="totalRow"></ng-container>
      </ng-container>
      <button
        *ngFor="let row of rows; first as first"
        (click)="openDocument(row.document)"
        [class.border-t]="first"
        class="w-full border-b border-gray-200 grid grid-cols-[minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)] sm:grid-cols-[minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)] gap-2 px-4 py-2 sm:p-4 text-sm text-left font-normal text-gray-500 hover:text-gray-800 hover:bg-gray-50 leading-4 group items-center"
      >
        <div>
          <app-category-badge
            class="block sm:hidden mb-2"
            *ngIf="row.category"
            [color]="row.category.color"
            [displayName]="row.category.displayName"
          ></app-category-badge>
          {{ row.valueDate | date: 'd. MMM yy' }}
          <div
            class="truncate text-gray-600 block sm:hidden text-xs"
            [class.text-red-600]="row.overdue"
            [class.group-hover:text-red-900]="row.overdue"
          >
            {{ row.document.data.name }}
          </div>
          <div class="truncate block sm:hidden text-xs">
            {{ row.legalEntityDisplayName ? row.legalEntityDisplayName : '-' }}
          </div>
        </div>
        <div class="truncate hidden sm:block">
          <app-category-badge
            *ngIf="row.category"
            [color]="row.category.color"
            [displayName]="row.category.displayName"
          ></app-category-badge>
          <span *ngIf="!row.category">-</span>
        </div>
        <div class="truncate hidden sm:block">
          {{ row.legalEntityDisplayName ? row.legalEntityDisplayName : '-' }}
        </div>
        <div
          class="truncate font-medium text-gray-600 hidden sm:block"
          [class.text-red-600]="row.overdue"
          [class.group-hover:text-red-900]="row.overdue"
        >
          {{ row.document.data.name }}
        </div>

        <div class="text-right font-mono">
          {{ row.revenue | number: '1.2-2' : 'de-CH' }}
        </div>
        <div class="text-right font-mono">
          {{ row.expense | number: '1.2-2' : 'de-CH' }}
        </div>
      </button>
      <ng-container *ngTemplateOutlet="totalRow"></ng-container>
    </div>
    <ng-template #noIncomeExpenses>
      <app-empty
        message="Keine Einnahmen und Ausgaben erfasst für diesen Zeitraum"
        disableAction
      ></app-empty>
    </ng-template>
  </ng-container>
</ng-container>
<ng-template #groupedByCustomer>
  <ng-container *ngIf="groupedByCustomer$ | async as rows">
    <div
      class="bg-white mt-4 shadow sm:rounded-lg overflow-hidden print:shadow-none"
      *ngIf="rows.length > 0; else noOutstandingPayments"
    >
      <div
        class="grid grid-cols-[minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)] gap-2 p-4 group"
      >
        <div class=""></div>

        <div class="text-right text-sm font-semibold text-gray-900">Einnahmen</div>
        <div class="text-right text-sm font-semibold text-gray-900">Ausgaben</div>
      </div>
      <ng-container *ngIf="rows.length > 15">
        <ng-container *ngTemplateOutlet="totalRow"></ng-container>
      </ng-container>
      <button
        *ngFor="let row of rows; first as first"
        (click)="
          legalEntityFilter.next(
            row.legalEntityId && row.legalEntityDisplayName
              ? { id: row.legalEntityId ?? '', displayName: row.legalEntityDisplayName ?? '' }
              : unset
          )
        "
        [class.border-t]="first"
        class="w-full border-b border-gray-200 grid grid-cols-[minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)] gap-2 p-4 text-sm text-left font-normal text-gray-500 hover:text-gray-800 hover:bg-gray-50 leading-4 group items-baseline"
      >
        <div class="truncate">
          {{ row.legalEntityDisplayName ? row.legalEntityDisplayName : '-' }}
        </div>

        <div
          class="text-right font-mono"
          *ngIf="row.revenue; else empty"
          [class.text-gray-400]="row.revenue && row.expense"
        >
          {{ row.revenue | number: '1.2-2' : 'de-CH' }}
          <div
            *ngIf="row.revenue && row.expense && (row.revenue ?? 0) - (row.expense ?? 0) > 0"
            class="pt-2 text-right text-sm font-mono text-gray-500 group-hover:text-gray-800"
          >
            {{ (row.revenue ?? 0) - (row.expense ?? 0) | number: '1.2-2' : 'de-CH' }}
          </div>
        </div>
        <div
          class="text-right font-mono"
          *ngIf="row.expense; else empty"
          [class.text-gray-400]="row.revenue && row.expense"
        >
          {{ row.expense | number: '1.2-2' : 'de-CH' }}
          <div
            *ngIf="row.revenue && row.expense && (row.expense ?? 0) - (row.revenue ?? 0) > 0"
            class="pt-2 text-right text-sm font-mono text-gray-500 group-hover:text-gray-800"
          >
            {{ (row.expense ?? 0) - (row.revenue ?? 0) | number: '1.2-2' : 'de-CH' }}
          </div>
        </div>
        <ng-template #empty><div></div></ng-template>
      </button>
      <ng-container *ngTemplateOutlet="totalRow"></ng-container>
    </div>
    <ng-template #noOutstandingPayments>nichts</ng-template>
  </ng-container>
</ng-template>

<ng-template #totalRow>
  <div
    class="grid grid-cols-[minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)] sm:grid-cols-[minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_1fr)_minmax(50px,_2fr)_minmax(100px,_1fr)_minmax(100px,_1fr)] p-4 gap-2 bg-gray-50 font-medium"
  >
    <div class="text-sm = text-gray-500">Total</div>
    <div class="hidden sm:block"></div>
    <div class="hidden sm:block"></div>
    <div class="hidden sm:block"></div>

    <ng-container *ngIf="total$ | async as total">
      <div class="text-right text-sm text-gray-500 font-mono">
        {{ total.revenue | number: '1.2-2' : 'de-CH' }}
        <div
          *ngIf="total.revenue - total.expense > 0"
          class="pt-2 text-right text-sm text-gray-900 font-mono"
        >
          {{ total.revenue - total.expense | number: '1.2-2' : 'de-CH' }}
        </div>
      </div>
      <div class="text-right text-sm text-gray-500 font-mono">
        {{ (total$ | async)?.expense | number: '1.2-2' : 'de-CH' }}
        <div
          *ngIf="total.expense - total.revenue > 0"
          class="pt-2 text-right text-sm text-red-600 font-mono"
        >
          {{ total.expense - total.revenue | number: '1.2-2' : 'de-CH' }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
