<app-new-comment
  class="ml-5 mb-2 mr-6 mt-4"
  [orgaUsers]="orgaUsers"
  (createNewComment)="createNewComment.emit($event)"
></app-new-comment>

<div class="flex flex-col gap-4 overflow-y-auto p-5 h-full">
  <app-comment
    [class.ml-8]="loggedInUserId === comment.data.authorUserId"
    [class.mr-8]="loggedInUserId !== comment.data.authorUserId"
    *ngFor="let comment of comments; trackBy: trackById; let last = last"
    [comment]="comment"
    [last]="last"
    [loggedInUserId]="loggedInUserId"
    (deleteComment)="deleteComment.emit($event)"
    (markAsReacted)="markAsReacted.emit($event)"
  ></app-comment>
</div>
