import { Component, DestroyRef, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { debounceTime, defer, firstValueFrom, map, Observable, shareReplay, startWith } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStateService } from '../../../services/session-state.service';
import { WorkService } from '../../../services/work.service';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { TimerService } from '../../../services/timer.service';
import {
  FirestoreProject,
  IsoDate,
  ProjectSummary,
  TimeEntry,
  WorkEntity2,
  WorkPositionSummary,
} from 'commons';
import { TimerComponent, TimerData } from '../components/timer/timer.component';
import { ProjectService } from '../../../services/project.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkForm } from '../../projects/slide-overs/work-slide-over.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { addMinutes, isToday, parseISO } from 'date-fns';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-timer-page',
  standalone: true,
  imports: [AsyncPipe, NgIf, TimerComponent],
  templateUrl: './timer.page.html',
})
export class TimerPage {
  sessionState = inject(SessionStateService);
  workService = inject(WorkService);
  timerService = inject(TimerService);
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);

  private projectService = inject(ProjectService);
  private destroyRef = inject(DestroyRef);

  workForm!: FormGroup<WorkForm>;

  projects = this.projectService.getActiveProjects().pipe(shareReplay(1));

  projectSummaries: Observable<ProjectSummary[]> = defer(() =>
    this.projects.pipe(
      map((projects) =>
        projects.map((project) => ({
          id: project.id,
          displayName: project.data.name || 'No Displayname',
        }))
      )
    )
  );

  positionSummaries: Observable<WorkPositionSummary[]> = defer(() =>
    this.workForm.controls.project.valueChanges.pipe(
      startWith(this.workForm.controls.project.value),
      switchMap((projectSummary) =>
        this.projects.pipe(
          map((projects) => projects.find((project) => project.id === projectSummary?.id) ?? null)
        )
      ),
      filter((project): project is FirestoreProject => !!project),
      map((project) => this.projectService.getWorkPositionSummaries(project))
    )
  );

  firestoreTimer = this.activatedRoute.params.pipe(
    map((params) => params.timerId),
    switchMap((timerId) => this.timerService.getTimer(timerId)),
    tap(async (firestoreTimer) => {
      if (this.workForm && !this.workForm.controls.workMinutes.enabled) {
        this.workForm.controls.workMinutes.patchValue(
          firestoreTimer.data.workItemState === 'embedded'
            ? (firestoreTimer.data.work.workMinutes ?? 0)
            : 0,
          { emitEvent: false }
        );
      }
      await this.disableEnableFields();
    })
  );

  timer: Observable<TimerData> = this.firestoreTimer.pipe(
    map((firestoreTimer) => ({
      isToday: isToday(parseISO(firestoreTimer.data.date)),
      state: firestoreTimer.data.timerState,
      date: firestoreTimer.data.date,
      timeEntries:
        firestoreTimer.data.workItemState === 'embedded'
          ? (this.mapTimeEntries(firestoreTimer.data.work.timeEntries) ?? null)
          : null,
      startTime: firestoreTimer.data.timerState === 'active' ? firestoreTimer.data.startTime : null,
    }))
  );

  private mapTimeEntries(
    timeEntries: Record<string, TimeEntry> | null | undefined
  ): { id: string; startTime: IsoDate; endTime: IsoDate; totalMinutes: number }[] | undefined {
    if (!timeEntries) {
      return;
    }
    return Object.entries(timeEntries).map(([key, value]) => ({
      id: key,
      startTime: value.startTime,
      endTime: addMinutes(parseISO(value.startTime), value.totalMinutes).toISOString(),
      totalMinutes: value.totalMinutes,
    }));
  }

  async ngOnInit() {
    const timer = await firstValueFrom(this.firestoreTimer);
    if (timer.data.workItemState === 'embedded') {
      await this.initForm(timer.data.work);
    }
  }

  async navigateBack() {
    await this.router.navigate(['../..'], { relativeTo: this.activatedRoute });
  }

  async start() {
    const timer = await firstValueFrom(this.firestoreTimer);
    await this.timerService.startTimer(timer.id);
  }

  async stop() {
    await this.timerService.stopTimer();
  }

  async delete() {
    const timer = await firstValueFrom(this.firestoreTimer);
    await this.timerService.deleteTimer(timer);
    await this.router.navigate(['../..'], { relativeTo: this.activatedRoute });
  }

  async saveWork() {
    this.workForm.markAllAsTouched();
    if (!this.workForm.valid) {
      return;
    }
    const timer = await firstValueFrom(this.firestoreTimer);
    await Promise.all([
      this.workService.createWorks([
        { ...this.workForm.getRawValue(), invoice: null, timeEntries: null } as WorkEntity2,
      ]),
      this.timerService.deleteTimer(timer),
      this.router.navigate(['../..'], { relativeTo: this.activatedRoute }),
    ]);
  }

  private async initForm(work: Partial<WorkEntity2>) {
    this.workForm = new FormGroup<WorkForm>({
      orgaUser: new FormControl(work.orgaUser!, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      project: new FormControl(work?.project ?? null, [Validators.required]),
      position: new FormControl(work?.position ?? null, [Validators.required]),
      date: new FormControl(work.date!, [Validators.required]),
      workMinutes: new FormControl(work?.workMinutes ?? null, [Validators.required]),
      description: new FormControl(work?.description ?? null, [Validators.maxLength(240)]),
      customer: new FormControl(work?.customer ?? null),
    });

    this.workForm.controls.project.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async (project) => {
        this.workForm.controls.customer.patchValue(
          project ? await this.getCustomer(project.id) : null
        );
        this.workForm.controls.position.patchValue(null);
      });

    this.workForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300), distinctUntilChanged(isEqual))
      .subscribe(() => this.updateWork());

    await this.disableEnableFields();
  }

  private async disableEnableFields() {
    const timer = await firstValueFrom(this.firestoreTimer);
    if (this.workForm && timer.data) {
      if (timer.data.timerState === 'active') {
        this.workForm.controls.workMinutes.disable({ emitEvent: false });
      } else {
        this.workForm.controls.workMinutes.enable({ emitEvent: false });
      }
    }
  }

  private async getCustomer(projectId: string) {
    const project = await firstValueFrom(
      this.projects.pipe(map((projects) => projects.find((project) => projectId === project.id)))
    );
    return project?.data.customerId
      ? { id: project.data.customerId, displayName: project.data.customerName as string }
      : null;
  }

  private async updateWork() {
    const timer = await firstValueFrom(this.firestoreTimer);
    const work = this.workForm.value as WorkEntity2;
    await this.timerService.updateEmbeddedWork(timer, work);
  }
}
