import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { defer, firstValueFrom, Observable, shareReplay, startWith } from 'rxjs';
import {
  CustomerSummary,
  FirestoreOrgaUser,
  FirestoreProject,
  FirestoreWork,
  IsoDate,
  mapOrgaUserSummary,
  OrgaUserSummary,
  PositionSummary,
  PositionType2,
  ProjectSummary,
  WorkEntity2,
  WorkPositionSummary,
} from 'commons';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { NgIf } from '@angular/common';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { DropdownMinimalMenuComponent } from '../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { DropdownButtonComponent } from '../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownItemComponent } from '../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { WorkService } from '../../../services/work.service';
import { SessionStateService } from '../../../services/session-state.service';
import { DateInputComponent } from '../../../components/input/date-input/date-input.component';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';
import { WorkTimeInputComponent } from '../../../components/input/work-time-input/work-time-input.component';
import { DropdownComponent } from '../../../components/dropdown/dropdown.component';
import { OrgaUserService } from '../../../services/orga-user.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../services/project.service';
import { formatISO } from 'date-fns';
import { SlideOverAlertComponent } from '../../../components/slide-overs/slide-over/slide-over-alert/slide-over-alert.component';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface WorkForm {
  project: FormControl<ProjectSummary | null>;
  customer: FormControl<CustomerSummary | null>;
  position: FormControl<(PositionSummary & { billable: boolean; type: PositionType2 }) | null>;
  orgaUser: FormControl<OrgaUserSummary>;
  date: FormControl<IsoDate | null>;
  workMinutes: FormControl<number | null>;
  description: FormControl<string | null>;
}

export interface WorkSlideOverInput {
  work?: FirestoreWork;
  date?: IsoDate;
  customer?: CustomerSummary;
  project?: ProjectSummary;
  position?: WorkPositionSummary;
}

@Component({
  selector: 'app-work-slide-over',
  templateUrl: './work-slide-over.component.html',
  standalone: true,
  imports: [
    SlideOverComponent,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    ReactiveFormsModule,
    NgIf,
    SlideOverFooterComponent,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    NgIconWrapperComponent,
    DropdownItemComponent,
    TailwindButtonDirective,
    DateInputComponent,
    TextInputComponent,
    WorkTimeInputComponent,
    DropdownComponent,
    SlideOverAlertComponent,
  ],
})
export class WorkSlideOverComponent implements OnInit {
  title!: string;

  workForm!: FormGroup<WorkForm>;

  saving = false;

  loggedInUser = this.sessionState.getOrgaUser().pipe(
    filter((user): user is FirestoreOrgaUser => !!user),
    map((user) => mapOrgaUserSummary(user))
  );
  orgaUsers = this.orgaUserService.getAllOrgaUserSummaries();

  projects = this.projectService.getActiveProjects().pipe(shareReplay(1));

  projectSummaries = this.projects.pipe(
    map((projects) =>
      projects.map((project) => ({
        id: project.id,
        displayName: project.data.name || 'No Displayname',
      }))
    )
  );

  positionSummaries: Observable<WorkPositionSummary[]> = defer(() =>
    this.workForm.controls.project.valueChanges.pipe(
      startWith(this.workForm.controls.project.value),
      switchMap((projectSummary) =>
        this.projects.pipe(
          map((projects) => projects.find((project) => project.id === projectSummary?.id) ?? null)
        )
      ),
      filter((project): project is FirestoreProject => !!project),
      map((project) => this.projectService.getWorkPositionSummaries(project))
    )
  );

  constructor(
    private workService: WorkService,
    private sessionState: SessionStateService,
    private orgaUserService: OrgaUserService,
    private projectService: ProjectService,
    @Inject(DIALOG_DATA) public data: WorkSlideOverInput | null,
    @Inject(DialogRef) public dialogRef: DialogRef,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  async ngOnInit() {
    const loggedInUser = await firstValueFrom(this.loggedInUser);
    let work: WorkEntity2 | null = null;
    if (this.data?.work) {
      work = this.data.work.data;
    }
    await this.initForm2(loggedInUser, work);
    this.title = this.data?.work ? 'Stunden bearbeiten' : 'Stunden erfassen';
  }

  async initForm2(loggedInUser: OrgaUserSummary, work: WorkEntity2 | null) {
    this.workForm = new FormGroup<WorkForm>({
      orgaUser: new FormControl(work?.orgaUser ?? loggedInUser, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      project: new FormControl(work?.project ?? this.data?.project ?? null, [Validators.required]),
      position: new FormControl(work?.position ?? this.data?.position ?? null, [
        Validators.required,
      ]),
      date: new FormControl(
        work?.date ?? this.data?.date ?? formatISO(new Date(), { representation: 'date' }),
        [Validators.required]
      ),
      workMinutes: new FormControl(work?.workMinutes ?? null, [Validators.required]),
      description: new FormControl(work?.description ?? null, [Validators.maxLength(240)]),
      customer: new FormControl(work?.customer ?? this.data?.customer ?? null),
    });
    this.disableEnableFields();
    this.workForm.controls.project.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async (project) => {
        this.workForm.controls.customer.patchValue(
          project ? await this.getCustomer(project.id) : null
        );
        this.workForm.controls.position.patchValue(null);
      });
  }

  disableEnableFields() {
    if (this.data?.work && this.data?.work.data.invoice) {
      this.workForm.disable({ emitEvent: false });
    } else {
      this.workForm.enable({ emitEvent: false });
    }
  }

  async saveWork() {
    this.workForm.markAllAsTouched();
    if (!this.workForm.valid) {
      return;
    }
    if (!this.workForm.dirty) {
      this.closeSlideOver();
      return;
    }
    this.saving = true;
    if (this.data?.work) {
      await this.workService.updateWork(this.data.work, this.workForm.getRawValue() as WorkEntity2);
    } else {
      await this.workService.createWorks([
        { ...this.workForm.getRawValue(), invoice: null, timeEntries: null } as WorkEntity2,
      ]);
    }
    this.closeSlideOver();
    this.saving = false;
  }

  private async getCustomer(projectId: string) {
    const project = await firstValueFrom(
      this.projects.pipe(map((projects) => projects.find((project) => projectId === project.id)))
    );
    return project?.data.customerId
      ? { id: project.data.customerId, displayName: project.data.customerName as string }
      : null;
  }

  async deleteWork() {
    if (!this.data?.work) {
      return;
    }
    await this.workService.deleteWork(this.data.work);
    this.closeSlideOver();
  }

  closeSlideOver() {
    this.dialogRef.close();
  }
}
