import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { TailwindButtonDirective } from '../../components/button/tailwind-button.directive';
import { SessionStateService } from '../../services/session-state.service';
import { filter } from 'rxjs/operators';
import { FirestoreOrga } from 'commons';

@Component({
  selector: 'app-subscription-error',
  templateUrl: './subscription-error.component.html',
  standalone: true,
  imports: [TailwindButtonDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionErrorComponent {
  link = environment.customerPortalLink;

  suffix = '';

  constructor(private sessionState: SessionStateService) {}

  async ngOnInit() {
    const orga = await firstValueFrom(
      this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga))
    );
    const emailAdress = orga.data.subscription?.email ?? '';

    this.suffix = emailAdress ? '?prefilled_email=' + emailAdress : '';
  }
}
