import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appSelectAll]',
  standalone: true,
})
export class SelectAllDirective implements OnInit {
  selectText = false;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.element.nativeElement.select();
      this.element.nativeElement.focus();
    }, 10);
  }
}
