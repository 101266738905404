import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Big } from 'big.js';
import { TailwindInputDirective2 } from '../tailwind-input2.directive';
import { FormInputWrapperComponent } from '../form-input-wrapper/form-input-wrapper.component';
import { evaluate } from 'mathjs';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    TailwindInputDirective2,
    FormInputWrapperComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
  styles: `
    :host {
      @apply block;
    }
  `,
})
export class NumberInputComponent implements OnChanges, ControlValueAccessor {
  @Input() label?: string;
  @Input() fractionDigits = 2;
  @Input() enhanced = false;
  @Input() variant: 'plain' | 'currency' = 'plain';
  @Input() currencySymbol = 'CHF';
  @Input() isDisabled = false;
  @Input() placeholder = '';

  @Input() formControl!: FormControl;

  errors!: Observable<ValidationErrors | null>;

  value: string | null = null;
  formattedValue: string | null = null;

  isFocused = false;

  onChange: (value: string | null) => void = () => {};
  onTouched: () => void = () => {};

  ngOnChanges() {
    if (this.formControl) {
      this.errors = this.formControl.events.pipe(
        map(() => (this.formControl.touched ? this.formControl.errors : null))
      );
    }
  }

  writeValue(value: string) {
    if (value) {
      this.value = normalizeNumberString(value, this.fractionDigits);
      this.formatValue();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  valueChanged(value: string) {
    this.value = value;
  }

  blur() {
    this.value = normalizeNumberString(this.value, this.fractionDigits);
    this.formatValue();
    this.isFocused = false;
    this.onChange(this.value);
    this.onTouched();
  }

  private formatValue() {
    if (this.value) {
      this.formattedValue = Number(this.value).toLocaleString('de-CH', {
        maximumFractionDigits: this.fractionDigits,
        minimumFractionDigits: this.fractionDigits,
      });
    } else {
      this.formattedValue = '';
    }
  }

  focus() {
    this.isFocused = true;
  }

  protected readonly Validators = Validators;
}

export function normalizeNumberString(
  potentiallyFormattedNumberString: string | number | null,
  fractionDigits = 2
) {
  if (typeof potentiallyFormattedNumberString === 'number') {
    return new Big(potentiallyFormattedNumberString)
      .toFixed(fractionDigits, Big.roundDown)
      .toString();
  }

  if (!potentiallyFormattedNumberString) {
    return null;
  }

  // Remove any non-numeric characters except for period (.) and comma (,)
  let cleanedString = potentiallyFormattedNumberString.replace(/[^0-9.,+\-()]/g, '');

  // try to evaluate it
  try {
    cleanedString = evaluate(potentiallyFormattedNumberString).toString();
  } catch (e) {}

  // Handle comma as a decimal separator if the period is not present
  if (cleanedString.includes(',') && !cleanedString.includes('.')) {
    cleanedString = cleanedString.replace(',', '.');
  }

  // Remove all commas, since they could be a thousand separators
  cleanedString = cleanedString.replace(/,/g, '');

  // Remove excess periods (.) after the first one
  let periodIndex = cleanedString.indexOf('.');
  if (periodIndex !== -1) {
    cleanedString =
      cleanedString.slice(0, periodIndex + 1) +
      cleanedString.slice(periodIndex + 1).replace(/\./g, '');
  }

  return cleanedString
    ? new Big(cleanedString).toFixed(fractionDigits, Big.roundDown).toString()
    : null;
}
