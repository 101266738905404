import { Component, Inject, Input } from '@angular/core';
import { MenuEntry } from './menuEntry';
import { DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import { asapScheduler, fromEvent, throttleTime } from 'rxjs';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-dark-nav-shell',
  templateUrl: './dark-nav-shell.component.html',
  standalone: true,
  imports: [RouterLink, NgFor, RouterLinkActive, NgClass, NgIf, NgIconWrapperComponent],
})
export class DarkNavShellComponent {
  @Input()
  navMenuEntries!: MenuEntry[];
  @Input()
  profileMenuEntries!: MenuEntry[];
  @Input()
  userEmailAddress!: string;
  @Input()
  userDisplayName!: string;
  @Input()
  userInitials!: string;
  @Input()
  orgaName!: string;
  @Input()
  orgaId!: string;
  @Input()
  schemaVersion!: number;

  @Input()
  timerRunningTime = '';

  profileMenuOpen = false;
  mobileMenuOpen = false;
  isScrolled = false;

  constructor(@Inject(DOCUMENT) private document: Document) {
    fromEvent(window, 'scroll')
      .pipe(throttleTime(100, asapScheduler, { leading: true, trailing: true }))
      .subscribe(() => {
        this.isScrolled = (this.document?.scrollingElement?.scrollTop ?? 0) > 0;
      });
  }

  toggleProfileMenu() {
    this.profileMenuOpen = !this.profileMenuOpen;
  }

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }
}
