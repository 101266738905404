<app-document-card
  *ngIf="documents$ | async as documents"
  [cardTitle]="(archive$ | async)?.name ?? ''"
  [documents]="documents"
  (typeFilterChanged)="typeFilter.next($event)"
  [typeFilter]="typeFilter | async"
  [enableShowAll]="limitRows.value && documents.length >= 10"
  (searchValueChanged)="searchValue.next($event)"
  (showAll)="limitRows.next(false)"
  (editDocument)="editDocument($event)"
  (deleteDocument)="deleteDocument($event)"
></app-document-card>
