<form [formGroup]="projectForm" *ngIf="projectForm">
  <app-slide-over>
    <app-slide-over-header
      #headerComponent
      (closeSlideOver)="closeSlideOver()"
      [formDirty]="projectForm.dirty"
      [title]="title"
    >
    </app-slide-over-header>

    <app-slide-over-content>
      <div class="space-y-6 divide-y">
        <div class="space-y-4 pt-6 px-4 sm:px-6">
          <app-text-input
            label="Name"
            class="mt-4"
            [formControl]="projectForm.controls.name"
          ></app-text-input>
          <app-dropdown
            label="Kunde"
            [formControl]="projectForm.controls.customer"
            [options]="customers"
          ></app-dropdown>
          <app-toggle label="Aktiv" formControlName="isActive"></app-toggle>
        </div>
      </div>
    </app-slide-over-content>

    <app-slide-over-footer>
      <span class="inline-flex rounded-md shadow-sm">
        <button
          appTailwindButton
          color="secondary"
          type="button"
          (click)="headerComponent.closeSlideOverClicked()"
        >
          Abbrechen
        </button>
      </span>
      <span class="inline-flex rounded-md shadow-sm">
        <button type="button" appTailwindButton [disabled]="false" (click)="save()">
          Speichern
        </button>
      </span>
    </app-slide-over-footer>
  </app-slide-over>
</form>
