<ng-container [ngSwitch]="positionType">
  <span
    *ngSwitchCase="'fixed'"
    class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-pink-100 text-pink-800 whitespace-nowrap"
  >
    Fixpreis
  </span>
  <span
    *ngSwitchCase="'effort'"
    class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800 whitespace-nowrap"
  >
    Nach Aufwand
  </span>
</ng-container>
