import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
//import { USE_DEVICE_LANGUAGE } from '@angular/fire/compat/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgOptimizedImage } from '@angular/common';
import { MentionModule } from 'angular-mentions';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { APP_NAME, APP_VERSION } from '@angular/fire/compat/analytics';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import {
  FIRESTORE_SERVICE,
  firestoreServiceFactory,
  ORGA_FIRESTORE_SERVICE,
  orgaFirestoreServiceFactory,
} from './services/firestore.service';
import { SessionStateService } from './services/session-state.service';
import {
  initializeAppCheck,
  provideAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';
import { AuthService } from './services/auth.service';
import { SessionService } from './services/session.service';
import { popperVariation, provideTippyConfig, tooltipVariation } from '@ngneat/helipopper';

export function loginUser(authService: AuthService) {
  return () => authService.bootstrapAlreadyLoggedInUser();
}
export function initSession(sessionService: SessionService) {
  return () => sessionService.initializeSessionStateUpdater();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    importProvidersFrom(
      BrowserModule,
      ReactiveFormsModule,
      NgSelectModule,
      OverlayModule,
      DragDropModule,
      FormsModule,
      NgOptimizedImage,
      MentionModule,
      PdfViewerModule
    ),
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: loginUser,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initSession,
      deps: [SessionService],
      multi: true,
    },

    { provide: LOCALE_ID, useValue: 'de-CH' },

    //{ provide: USE_DEVICE_LANGUAGE, useValue: true },
    { provide: APP_VERSION, useValue: '0.0.0' },
    { provide: APP_NAME, useValue: 'app-jessie' },
    provideHttpClient(withInterceptorsFromDi()),
    // firebase
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      environment.useEmulators ? connectAuthEmulator(auth, 'http://localhost:9099') : undefined;
      return auth;
    }),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    provideAppCheck(() => {
      const provider = new ReCaptchaEnterpriseProvider(environment.appCheckSiteKey);
      return initializeAppCheck(getApp(), {
        provider,
        isTokenAutoRefreshEnabled: true,
      });
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      environment.useEmulators ? connectFirestoreEmulator(firestore, 'localhost', 8080) : undefined;
      return firestore;
    }),
    providePerformance(() => getPerformance()),
    provideStorage(() => {
      const storage = getStorage();
      environment.useEmulators ? connectStorageEmulator(storage, 'localhost', 9199) : undefined;
      return storage;
    }),
    {
      provide: FIRESTORE_SERVICE,
      useFactory: firestoreServiceFactory,
    },
    {
      provide: ORGA_FIRESTORE_SERVICE,
      useFactory: orgaFirestoreServiceFactory,
      deps: [SessionStateService],
    },
  ],
};
