import { inject, Injectable } from '@angular/core';

import { firstValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  FirestoreOrga,
  FirestoreOrgaUser,
  mapOrgaUserSummary,
  OrgaUser,
  OrgaUserSummary,
  User,
} from 'commons';
import { FIRESTORE_SERVICE, ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { where } from '@angular/fire/firestore';
import { SessionStateService } from './session-state.service';

// @ts-ignore
// noinspection JSUnusedLocalSymbols
const EMPTY_USER_SETTINGS: User = {
  firstName: '',
  lastName: '',
  //emailVerified: false,
  //autoChooseOrga: false
};

@Injectable({
  providedIn: 'root',
})
export class OrgaUserService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private sessionState = inject(SessionStateService);

  getAllOrgaUsers(onboardedUserOnly = true): Observable<FirestoreOrgaUser[]> {
    return onboardedUserOnly
      ? this.orgaFirestoreService.getDocs<OrgaUser>(
          'orgaUser',
          where('onboardingCompleted', '==', onboardedUserOnly)
        )
      : this.orgaFirestoreService.getDocs<OrgaUser>('orgaUser');
  }

  getAllOrgaUserSummaries(onboardedUserOnly = true): Observable<OrgaUserSummary[]> {
    const orgaUsers = this.getAllOrgaUsers(onboardedUserOnly);
    return orgaUsers.pipe(
      filter((orgaUsers): orgaUsers is FirestoreOrgaUser[] => !!orgaUsers),
      map((orgaUsers) => orgaUsers.map((orgaUser) => mapOrgaUserSummary(orgaUser)))
    );
  }

  getOrgaUserByUserId(userId: string): Observable<FirestoreOrgaUser> {
    return this.orgaFirestoreService
      .getDocs<OrgaUser>('orgaUser', where('userId', '==', userId))
      .pipe(map((orgas) => orgas[0]));
  }

  async createOrgaUser(orgaUser: OrgaUser) {
    const loggedInOrga = await firstValueFrom(
      this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga))
    );
    const hydratedOrgaUser: OrgaUser = {
      ...orgaUser,
      orgaId: loggedInOrga.id,
      orgaName: loggedInOrga.data.name,
      email: orgaUser.email.toLowerCase(),
    };
    return this.orgaFirestoreService.createDoc<OrgaUser>('orgaUser', hydratedOrgaUser);
  }

  deleteOrgaUser(orgaUser: FirestoreOrgaUser) {
    return this.rootFirestoreService.deleteDoc(orgaUser.docRef.path);
  }
}
