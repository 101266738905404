import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { InvoiceListEntryComponent } from './invoice-list-entry/invoice-list-entry.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FirestoreInvoice2 } from 'commons/dist/entities/invoice2';
import { EmptyStateComponent } from '../../../components/empty/empty-state.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  standalone: true,
  imports: [NgIf, NgFor, InvoiceListEntryComponent, NgClass, EmptyComponent, EmptyStateComponent],
})
export class InvoicesComponent {
  @Input()
  invoices: FirestoreInvoice2[] = [];
  @Output()
  editInvoice = new EventEmitter<FirestoreInvoice2>();
  @Output()
  deleteInvoice = new EventEmitter<FirestoreInvoice2>();
  @Output()
  downloadInvoice = new EventEmitter<FirestoreInvoice2>();
  @Output() createInvoice = new EventEmitter<FirestoreInvoice2>();
}
