import {
  heroArrowDown,
  heroArrowLeft,
  heroArrowLongDown,
  heroArrowLongUp,
  heroArrowRight,
  heroArrowUp,
  heroBanknotes,
  heroBars3,
  heroBell,
  heroBriefcase,
  heroBuildingOffice,
  heroCalendar,
  heroCalendarDays,
  heroChartPie,
  heroChatBubbleBottomCenter,
  heroChatBubbleBottomCenterText,
  heroCheck,
  heroCheckCircle,
  heroChevronDown,
  heroChevronLeft,
  heroChevronRight,
  heroChevronUp,
  heroClock,
  heroCog8Tooth,
  heroDocumentArrowDown,
  heroExclamationCircle,
  heroExclamationTriangle,
  heroFolder,
  heroFolderOpen,
  heroFolderPlus,
  heroFunnel,
  heroLightBulb,
  heroLockClosed,
  heroMagnifyingGlass,
  heroPaperAirplane,
  heroPauseCircle,
  heroPencil,
  heroPlayCircle,
  heroPlus,
  heroQuestionMarkCircle,
  heroSparkles,
  heroStar,
  heroTrash,
  heroUserGroup,
  heroUsers,
  heroXCircle,
  heroXMark,
} from '@ng-icons/heroicons/outline';
import {
  heroCalendarMini,
  heroCheckCircleMini,
  heroClockMini,
  heroCurrencyDollarMini,
  heroUserMini,
} from '@ng-icons/heroicons/mini';
import {
  heroBuildingOfficeSolid,
  heroChartPieSolid,
  heroCheckCircleSolid,
  heroCheckSolid,
  heroClockSolid,
  heroEllipsisVerticalSolid,
  heroExclamationCircleSolid,
  heroExclamationTriangleSolid,
  heroFunnelSolid,
  heroInformationCircleSolid,
  heroStarSolid,
  heroUserSolid,
  heroXCircleSolid,
  heroXMarkSolid,
} from '@ng-icons/heroicons/solid';

export const icons = {
  heroUsers,
  heroArrowLeft,
  heroArrowRight,
  heroXCircle,
  heroChevronUp,
  heroChevronDown,
  heroPencil,
  heroChevronLeft,
  heroChevronRight,
  heroClockMini,
  heroCog8Tooth,
  heroCurrencyDollarMini,
  heroXMark,
  heroBars3,
  heroDocumentArrowDown,
  heroFunnelSolid,
  heroMagnifyingGlass,
  heroPlus,
  heroUserMini,
  heroCheckCircle,
  heroCalendarMini,
  heroBuildingOffice,
  heroUserGroup,
  heroBriefcase,
  heroBanknotes,
  heroCalendar,
  heroCalendarDays,
  heroChatBubbleBottomCenter,
  heroChatBubbleBottomCenterText,
  heroCheckCircleSolid,
  heroCheckCircleMini,
  heroSparkles,
  heroArrowUp,
  heroArrowLongUp,
  heroArrowDown,
  heroArrowLongDown,
  heroInformationCircleSolid,
  heroCheck,
  heroTrash,
  heroFolder,
  heroFolderOpen,
  heroBell,
  heroEllipsisVerticalSolid,
  heroExclamationCircleSolid,
  heroExclamationCircle,
  heroStar,
  heroStarSolid,
  heroQuestionMarkCircle,
  heroXCircleSolid,
  heroExclamationTriangleSolid,
  heroExclamationTriangle,
  heroFolderPlus,
  heroLightBulb,
  heroXMarkSolid,
  heroCheckSolid,
  heroUserSolid,
  heroBuildingOfficeSolid,
  heroLockClosed,
  heroPaperAirplane,
  heroClock,
  heroChartPie,
  heroPlayCircle,
  heroPauseCircle,
  heroFunnel,
  heroChartPieSolid,
  heroClockSolid,
};
