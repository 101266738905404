import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  standalone: true,
  imports: [NgIconWrapperComponent, RouterLink, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() variant: 'alert' | 'notification' = 'alert';
  @Input() heading: string = '';
  @Input() subheading: string | null = null;
  @Input() linkText: string | null = null;
  @Input() linkTarget: string | null = null;
}
