import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { NumberString } from 'commons';
import { Big } from 'big.js';

@Pipe({
  name: 'formatAmount',
  standalone: true,
})
export class FormatAmount implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(amount: NumberString | null, ...args: unknown[]): string {
    if (!amount) {
      return '';
    }
    return formatNumber(new Big(amount).toNumber(), this.locale, '1.2-2');
  }
}
