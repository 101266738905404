import { Component, Input } from '@angular/core';
import { FormatMinutesPipe } from '../../../../../pipes/format-minutes.pipe';

@Component({
  selector: 'tr[app-position-stats-row]',
  templateUrl: './position-stats-row.component.html',
  standalone: true,
  imports: [FormatMinutesPipe],
})
export class PositionStatsRowComponent {
  @Input()
  positionName!: string;

  @Input()
  totalMinutes!: number;

  @Input()
  billableMinutes!: number;

  @Input()
  unbillableMinutes!: number;
}
