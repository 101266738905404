<div
  class="shadow overflow-hidden sm:rounded-md bg-slate-50 pt-4 pl-4 pb-2 pr-2 group rounded relative flex flex-col gap-2"
>
  <span class="flex gap-2 text-xs items-baseline"
    ><span class="font-bold" [class.text-blue-600]="authorIsLoggedIn">{{
      comment.data.authorDisplayName
    }}</span
    ><span class="text-gray-500 text-[10px]">{{
      comment.data.creationDate | date : 'dd.MM.yyy HH:mm:ss'
    }}</span></span
  >
  <div class="flex flex-col gap-4">
    <p class="whitespace-pre-wrap break-words">{{ comment.data.commentText }}</p>
    <div class="flex justify-between">
      <div class="text-xs text-gray-500 self-end">
        <div *ngFor="let user of reactedUser" class="flex gap-0.5 items-center">
          <app-ng-icon name="heroCheckCircleMini"></app-ng-icon>
          {{ user }}
        </div>
      </div>
      <div class="flex text-gray-500 gap-2">
        <button
          type="button"
          *ngIf="logggedInUserHasNotReacted"
          class="disabled:text-gray-300 disabled:cursor-not-allowed h-6 w-6 flex justify-center items-center"
          (click)="doMarkAsReacted()"
        >
          <app-ng-icon name="heroCheck"></app-ng-icon>
        </button>
        <button
          class="disabled:text-gray-300 disabled:cursor-not-allowed h-6 w-6 flex justify-center items-center"
          type="button"
          (click)="deleteComment.emit(comment)"
          [disabled]="!authorIsLoggedIn"
        >
          <app-ng-icon name="heroTrash"></app-ng-icon>
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="logggedInUserHasNotReacted"
    class="absolute top-0 bottom-0 left-0 w-1 bg-blue-600"
  ></div>
</div>
