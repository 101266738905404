import { inject, Injectable } from '@angular/core';
import { FIRESTORE_SERVICE, ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { SessionStateService } from './session-state.service';
import { firstValueFrom } from 'rxjs';
import {
  FirestoreOrgaUser,
  FirestoreTimer,
  IsoDate,
  mapOrgaUserSummary,
  NullablePartial,
  TimeEntry,
  TimerEntity,
  WorkEntity2,
} from 'commons';
import { filter, first, map, switchMap } from 'rxjs/operators';
import {
  collection,
  deleteField,
  doc,
  DocumentSnapshot,
  Firestore,
  getDocsFromServer,
  orderBy,
  runTransaction,
  Transaction,
  where,
} from '@angular/fire/firestore';
import { differenceInMinutes, formatISO, parseISO } from 'date-fns';
import { Big } from 'big.js';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private firestore = inject(Firestore);

  private sessionState = inject(SessionStateService);

  getTimer(timerId: string) {
    return this.sessionState.getOrgaUser().pipe(
      filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
      first(),
      switchMap((orgaUser) =>
        this.rootFirestoreService.getDoc<TimerEntity>(orgaUser.docRef.path + `/timer/${timerId}`)
      )
    );
  }

  getTimers() {
    return this.sessionState.getOrgaUser().pipe(
      filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
      first(),
      switchMap((orgaUser) =>
        this.rootFirestoreService.getDocs<TimerEntity>(orgaUser.docRef.path + '/timer')
      )
    );
  }

  getTimersByDate(date: IsoDate) {
    return this.sessionState.getOrgaUser().pipe(
      filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
      first(),
      switchMap((orgaUser) =>
        this.rootFirestoreService.getDocs<TimerEntity>(
          orgaUser.docRef.path + '/timer',
          where('date', '==', date),
          orderBy('createdAt', 'asc')
        )
      )
    );
  }

  getActiveTimer() {
    return this.sessionState.getOrgaUser().pipe(
      filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
      first(),
      switchMap((orgaUser: FirestoreOrgaUser) =>
        this.rootFirestoreService.getDocs<TimerEntity>(
          `${orgaUser.docRef.path}/timer/`,
          where('timerState', '==', 'active')
        )
      ),
      map((timers) => {
        if (timers.length > 0) {
          return timers[0];
        } else {
          return null;
        }
      })
    );
  }

  getOutdatedTimers() {
    return this.sessionState.getOrgaUser().pipe(
      filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
      first(),
      switchMap((orgaUser: FirestoreOrgaUser) =>
        this.rootFirestoreService.getDocs<TimerEntity>(
          `${orgaUser.docRef.path}/timer/`,
          where('timerState', '==', 'inactive'),
          where('date', '<=', formatISO(new Date(), { representation: 'date' }))
        )
      )
    );
  }

  expand(timer: FirestoreTimer) {
    return this.rootFirestoreService.updateDoc(timer.docRef.path, { isExpanded: true });
  }

  collapse(timer: FirestoreTimer) {
    return this.rootFirestoreService.updateDoc(timer.docRef.path, { isExpanded: false });
  }

  updateEmbeddedWork(timer: FirestoreTimer, work: NullablePartial<WorkEntity2>) {
    const firebaseUpdateObject = Object.entries(work).reduce(
      (prev, cur) => ({ ...prev, [`work.${cur[0]}`]: cur[1] }),
      {}
    );
    return this.rootFirestoreService.updateDoc(timer.docRef.path, firebaseUpdateObject);
  }

  async stopTimer() {
    const orgaUser = await firstValueFrom(
      this.sessionState
        .getOrgaUser()
        .pipe(filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser))
    );

    const timersCollectionRef = collection(this.firestore, `${orgaUser.docRef.path}/timer`);
    const timersCollectionDocs = await getDocsFromServer(timersCollectionRef);

    return await runTransaction(this.firestore, async (transaction) => {
      const allTimers: Promise<DocumentSnapshot>[] = [] as Promise<DocumentSnapshot>[];
      timersCollectionDocs.forEach((doc) => {
        allTimers.push(transaction.get(doc.ref));
      });
      const fetchedTimers = await Promise.all(allTimers);

      await Promise.all(
        fetchedTimers.map((timer) => {
          const timerData = timer.data() as TimerEntity;
          if (timerData.timerState === 'active') {
            return this.transactionalStopTimer(transaction, timer as DocumentSnapshot<TimerEntity>);
          }
          return null;
        })
      );
    });
  }

  async startTimer(timerId: string) {
    const orgaUser = await firstValueFrom(
      this.sessionState
        .getOrgaUser()
        .pipe(filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser))
    );

    const timersCollectionRef = collection(this.firestore, `${orgaUser.docRef.path}/timer`);
    const timersCollectionDocs = await getDocsFromServer(timersCollectionRef);

    return await runTransaction(this.firestore, async (transaction) => {
      const allTimers: Promise<DocumentSnapshot>[] = [] as Promise<DocumentSnapshot>[];
      timersCollectionDocs.forEach((doc) => {
        allTimers.push(transaction.get(doc.ref));
      });
      const fetchedTimers = await Promise.all(allTimers);

      await Promise.all(
        fetchedTimers.map(async (timer) => {
          const timerData = timer.data() as TimerEntity;
          if (timerData.timerState === 'active' && timer.id !== timerId) {
            await this.transactionalStopTimer(transaction, timer as DocumentSnapshot<TimerEntity>);
          }
          if (timerData.timerState === 'inactive' && timer.id === timerId) {
            transaction.update(timer.ref, {
              timerState: 'active',
              startTime: new Date().toISOString(),
            });
          }
        })
      );
    });
  }

  private async transactionalStopTimer(
    transaction: Transaction,
    runningTimer: DocumentSnapshot<TimerEntity>
  ) {
    // stop a running timer
    const runningTimerEntity: TimerEntity = runningTimer.data()!;
    let runningTimerWorkMinutesTotal;
    if (runningTimerEntity.timerState === 'active') {
      if (runningTimerEntity.workItemState === 'embedded') {
        const timeEntry: TimeEntry = {
          startTime: runningTimerEntity.startTime,
          totalMinutes: differenceInMinutes(new Date(), parseISO(runningTimerEntity.startTime)),
        };
        runningTimerWorkMinutesTotal = new Big(runningTimerEntity.work.workMinutes || 0)
          .add(timeEntry.totalMinutes)
          .toNumber();

        transaction.update(runningTimer.ref, {
          timerState: 'inactive',
          ['work.workMinutes']: runningTimerWorkMinutesTotal,
          [`work.timeEntries.${crypto.randomUUID()}`]: timeEntry,
          startTime: deleteField(),
        });
      } else {
        const timeEntry: TimeEntry = {
          startTime: runningTimerEntity.startTime,
          totalMinutes: differenceInMinutes(new Date(), parseISO(runningTimerEntity.startTime)),
        };
        const runningTimerWorkDoc = await transaction.get(
          doc(this.firestore, runningTimerEntity.workPath)
        );
        const runningTimerWorkEntity = runningTimerWorkDoc.data() as WorkEntity2;
        runningTimerWorkMinutesTotal = new Big(runningTimerWorkEntity.workMinutes || 0)
          .add(timeEntry.totalMinutes)
          .toNumber();

        transaction
          .update(runningTimerWorkDoc.ref, {
            workMinutes: runningTimerWorkMinutesTotal,
            [`timeEntries.${crypto.randomUUID()}`]: timeEntry,
          })
          .update(runningTimer.ref, {
            timerState: 'inactive',
            startTime: deleteField(),
          });
      }
    }
  }

  async createTimer(date: IsoDate) {
    const orgaUser = await firstValueFrom(
      this.sessionState
        .getOrgaUser()
        .pipe(filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser))
    );
    const orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    const timer: TimerEntity = {
      createdAt: formatISO(new Date()),
      date: formatISO(parseISO(date), { representation: 'date' }),
      workItemState: 'embedded',
      timerState: 'inactive',
      work: {
        orgaId,
        orgaUser: mapOrgaUserSummary(orgaUser),
        customer: null,
        invoice: null,
        description: '',
        date: formatISO(parseISO(date), { representation: 'date' }),
        workMinutes: 0,
      },
    };
    const timerDoc = await this.rootFirestoreService.createDoc(
      orgaUser?.docRef.path + '/timer',
      timer
    );
    return timerDoc.id;
  }

  deleteTimer(timer: FirestoreTimer) {
    return this.rootFirestoreService.deleteDoc(timer.docRef.path);
  }
}
