import { Component, Inject } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../services/project.service';
import { FirestoreProject } from 'commons';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ProjectListEntryComponent } from '../components/project-list-entry/project-list-entry.component';
import { Dialog } from '@angular/cdk/dialog';
import {
  ProjectSlideOverComponent,
  ProjectSlideOverInput,
} from '../slide-overs/project-slide-over.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.page.html',
  standalone: true,
  imports: [NgIf, NgClass, NgFor, ProjectListEntryComponent, EmptyComponent, AsyncPipe],
})
export class ProjectListPage {
  projects$: Observable<FirestoreProject[]>;

  filter$ = new BehaviorSubject<'all' | 'inactive' | 'active'>('active');

  constructor(
    private projectService: ProjectService,
    private router: Router,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.projects$ = this.filter$.pipe(
      switchMap((filter) => {
        switch (filter) {
          case 'active':
            return this.projectService.getActiveProjects();
          case 'inactive':
            return this.projectService.getInactiveProjects();
          case 'all':
            return this.projectService.getAllProjects();
        }
      }),
      map((projects) => projects.sort((a, b) => a.data.name.localeCompare(b.data.name)))
    );
  }

  async createProject() {
    const dialogRef = this.dialog.open<string, ProjectSlideOverInput, ProjectSlideOverComponent>(
      ProjectSlideOverComponent
    );
    const id = await firstValueFrom(dialogRef.closed);
    if (id) {
      this.router.navigate(['/project/details/', id]);
    }
  }

  navigateTo($event: FirestoreProject) {
    this.router.navigate(['/project/details/', $event.id]);
  }
}
