<ng-container *ngIf="position">
  <td class="relative px-7 sm:w-12 sm:px-6" *ngIf="showCheckboxes">
    <input
      type="checkbox"
      [disabled]="isDisabled || (position.quantity | stringToNumber) === 0"
      [(ngModel)]="isChecked"
      (change)="positionSelected.emit(isChecked)"
      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:border-gray-200 disabled:bg-gray-50"
    />
  </td>
  <td
    class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900"
    [class.!text-gray-600]="(position.quantity | stringToNumber) === 0"
    [class.pl-3]="!showCheckboxes"
  >
    {{ position.displayName }}
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
    {{ position.quantity | stringToNumber }}
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">Stunden</td>
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right tabular-nums">
    {{ position.unitPrice | formatAmount }}
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
    {{ position.vatRate | formatPercent }}%
  </td>
  <td class="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-500 text-right tabular-nums">
    {{ position.totalPrice | formatAmount }}
  </td>
  <td *ngIf="!showCheckboxes" class="text-center">
    <button
      [disabled]="isDisabled"
      class="mt-1 text-gray-400 hover:text-gray-700 disabled:hover:text-gray-400"
      (click)="deletePosition.emit(position)"
    >
      <app-ng-icon name="heroTrash"></app-ng-icon>
    </button>
  </td>
</ng-container>
