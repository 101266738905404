import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ContainerComponent } from '../../../../components/container/container.component';
import { TabDirective } from '../../../../components/tabs/routed/tab.directive';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { TabGroupComponent } from '../../../../components/tabs/routed/tab-group.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { DropdownMinimalMenuComponent } from '../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { HeaderMetaActionComponent } from '../../../../components/container/header-meta-action/header-meta-action.component';
import { DropdownItemComponent } from '../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DropdownButtonComponent } from '../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { CategoryBadgeComponent } from '../../../../components/category-badge/category-badge.component';
import { BadgeComponent } from '../../../../components/badge/badge.component';
import { NgIcon } from '@ng-icons/core';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TabsComponent } from '../../../../components/tabs/content-projected/tabs.component';
import { TabOldDirective } from '../../../../components/tabs/content-projected/tab.directive';
import { ContentComponent } from '../../components/content/content.component';
import { PreviewComponent } from '../../components/preview/preview.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';

import {
  CustomerSummary,
  FirestoreOrgaUser,
  IsoDate,
  mapOrgaUserSummary,
  OrgaUserSummary,
  ProjectSummary,
  VatRate,
} from 'commons';
import {
  AddressForm,
  getAddressForm,
} from '../../../../components/forms/address-form/address-form.component';
import {
  BankConnectionForm,
  getBankConnectionForm,
} from '../../../../components/forms/bank-connection-form/bank-connection-form.component';
import { FormControlsOf } from '../../../../util/form-helpers';
import { SessionStateService } from '../../../../services/session-state.service';
import { OrgaUserService } from '../../../../services/orga-user.service';
import {
  debounceTime,
  defer,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subscription,
} from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { CustomerService } from '../../../../services/customer.service';
import { formatISO } from 'date-fns';
import {
  FirestoreInvoice2,
  InvoiceEntity2,
  InvoicePosition2,
  InvoicePositions,
  InvoiceState2,
  InvoiceTexts,
  InvoiceTotals,
} from 'commons/dist/entities/invoice2';
import { Dialog } from '@angular/cdk/dialog';
import {
  ImportInvoiceItemsDialogComponent,
  ImportInvoiceItemsDialogComponentDataType,
  ImportInvoiceItemsDialogComponentReturnType,
} from '../../dialogs/import-invoice-items-dialog/import-invoice-items-dialog.component';
import {
  calculateInvoicePosition,
  calculateTotals,
  getAllWork,
  getDeliveryPeriod,
  sumWorkMinutes,
} from '../../util/invoice.util';
import { Invoice2Service } from '../../../../services/invoice2.service';
import { WorkTableComponent } from '../../../projects/components/work/work-table/work-table.component';
import { CommentSectionComponent } from '../../../comments/comment-section/comment-section.component';
import { InvoicePdfService } from '../../services/invoice-pdf.service';
import { WorkService } from '../../../../services/work.service';
import { AlertComponent } from '../../../../components/alert/alert.component';
import { InvoiceComponent } from '../../components/invoice.component';
import { ProjectService } from '../../../../services/project.service';
import { DocumentService } from '../../../../services/document.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-invoice-page',
  standalone: true,
  imports: [
    ContainerComponent,
    TabDirective,
    RouterLinkActive,
    RouterLink,
    TabGroupComponent,
    TailwindButtonDirective,
    DropdownMinimalMenuComponent,
    HeaderMetaActionComponent,
    DropdownItemComponent,
    DropdownButtonComponent,
    CategoryBadgeComponent,
    BadgeComponent,
    NgIcon,
    NgIconWrapperComponent,
    TabsComponent,
    TabOldDirective,
    ContentComponent,
    PreviewComponent,
    AsyncPipe,
    NgIf,
    WorkTableComponent,
    CommentSectionComponent,
    JsonPipe,
    AlertComponent,
    InvoiceComponent,
  ],
  templateUrl: './invoice.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicePage implements OnInit, OnDestroy {
  invoice: Observable<FirestoreInvoice2> = this.route.params.pipe(
    map((params) => params['invoiceId']),
    switchMap((invoiceId) => this.invoiceService.getInvoice(invoiceId)),
    shareReplay(1)
  );

  invoiceForm!: FormGroup<InvoiceForm>;

  users!: Observable<OrgaUserSummary[]>;
  customers!: Observable<CustomerSummary[]>;
  invoicePositions!: Observable<InvoicePosition2[]>;
  invoiceSummary!: Observable<InvoiceTotals>;

  subscriptions = new Subscription();

  previewPdf: Observable<Uint8Array | null> = defer(() =>
    this.invoiceForm.valueChanges.pipe(
      startWith(this.invoiceForm.getRawValue()),
      map(() => this.invoiceForm.getRawValue()),
      debounceTime(50),
      switchMap((valueChange) =>
        from(
          this.invoicePdfService.createInvoicePdf(valueChange as unknown as InvoiceEntity2)
        ).pipe(
          catchError((error) => {
            console.log(error);
            return of(null);
          })
        )
      )
    )
  );

  @HostListener('window:visibilitychange', ['$event'])
  async visibilityChangeHandler(event: any) {
    if (event.target.visibilityState === 'hidden') {
      await this.saveInvoice();
    }
  }

  constructor(
    private route: ActivatedRoute,
    private sessionStateService: SessionStateService,
    private projectService: ProjectService,
    private orgaUserService: OrgaUserService,
    private customerService: CustomerService,
    private invoiceService: Invoice2Service,
    private dialog: Dialog,
    private cdr: ChangeDetectorRef,
    private invoicePdfService: InvoicePdfService,
    private workService: WorkService,
    private documentService: DocumentService
  ) {}

  async ngOnInit() {
    this.users = this.orgaUserService.getAllOrgaUsers().pipe(
      filter((orgaUsers): orgaUsers is FirestoreOrgaUser[] => !!orgaUsers),
      map((orgaUsers) => orgaUsers.map((orgaUser) => mapOrgaUserSummary(orgaUser)))
    );

    this.customers = this.customerService.getAllCustomerSummaries();

    await this.initForm((await firstValueFrom(this.invoice)).data);

    this.subscriptions.add(
      this.invoice.subscribe((invoice) => {
        this.invoiceForm.patchValue(invoice.data);
        this.enableDisableForm();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private async initForm(invoice: Partial<InvoiceEntity2> | null) {
    this.invoiceForm = new FormGroup({
      invoiceNumber: new FormControl(invoice?.invoiceNumber ?? '0', {
        nonNullable: true,
      }),
      project: new FormControl<ProjectSummary | null>(invoice?.project ?? null),
      deliveryPeriod: new FormGroup(
        {
          from: new FormControl<IsoDate | null>(invoice?.deliveryPeriod?.from ?? null),
          to: new FormControl<IsoDate | null>(invoice?.deliveryPeriod?.to ?? null),
        },
        [Validators.required]
      ),
      invoiceState: new FormControl<InvoiceState2>(invoice?.invoiceState ?? 'draft', {
        nonNullable: true,
      }),
      invoiceDate: new FormControl<string>(
        invoice?.invoiceDate ?? formatISO(new Date(), { representation: 'date' }),
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      dueDate: new FormControl<string | null>(invoice?.dueDate ?? null, {
        validators: [Validators.required],
      }),
      paymentDurationDays: new FormControl<number>(invoice?.paymentDurationDays ?? 30, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      positions: new FormControl<InvoicePositions>(
        invoice?.positions ?? { positionsSort: [], positions: {} },
        { nonNullable: true }
      ),
      texts: getTextForm(
        invoice?.texts ?? {
          intro: 'Wir erlauben uns folgende Positionen in Rechnung zu stellen',
          greeting: 'Besten Dank und freundliche Grüsse',
        }
      ),
      bankDetail: getBankConnectionForm(
        invoice?.bankDetail ??
          (await firstValueFrom(this.sessionStateService.getOrga()))?.data.bankDetails ??
          null
      ),
      recipient: new FormGroup({
        customer: new FormControl<CustomerSummary | null>(
          invoice?.recipient?.customer ?? null,
          Validators.required
        ),
        name: new FormControl<String | null>(invoice?.recipient?.name ?? null, Validators.required),
        address: getAddressForm(invoice?.recipient?.address ?? null),
      }),
      sender: new FormGroup({
        contactPerson: new FormControl<OrgaUserSummary | null>(
          invoice?.sender?.contactPerson ?? null,
          Validators.required
        ),
        name: new FormControl<String | null>(invoice?.sender?.name ?? null, Validators.required),
        address: getAddressForm(invoice?.sender?.address ?? null),
        vatUid: new FormControl(invoice?.sender?.vatUid ?? null, [Validators.required]),
      }),
      vatRate: new FormControl<VatRate | null>(invoice?.vatRate ?? null),
      invoiceTotals: new FormControl<InvoiceTotals | null>(invoice?.invoiceTotals ?? null),
      documentId: new FormControl<string | null>(invoice?.documentId ?? null),
      logo: new FormControl<string | null>(invoice?.logo ?? null),
    });

    this.enableDisableForm();
    this.cdr.markForCheck();
    this.subscriptions.add(
      this.invoiceForm.controls.recipient.controls.customer.valueChanges.subscribe((customer) =>
        this.invoiceForm.controls.recipient.controls.name.setValue(customer?.displayName ?? null)
      )
    );

    this.invoiceSummary = this.invoiceForm.controls.positions.valueChanges.pipe(
      startWith(this.invoiceForm.controls.positions.value),
      tap((positions) => {
        const deliveryPeriod = getDeliveryPeriod(Object.values(positions.positions));
        if (deliveryPeriod.from && deliveryPeriod.to) {
          this.invoiceForm.controls.deliveryPeriod.setValue(deliveryPeriod);
        }
      }),
      tap((positions) => {
        if (positions.positionsSort.length === 0) {
          this.invoiceForm.controls.vatRate.setValue(null);
        }
      }),
      map((positions) => calculateTotals(Object.values(positions!.positions))),
      tap((summary) => this.invoiceForm.controls.invoiceTotals.setValue(summary))
    );

    this.invoiceForm.markAsDirty();
  }

  async openImportDialog() {
    const invoice = await firstValueFrom(this.invoice);
    const project = await firstValueFrom(this.projectService.getProject(invoice.data.project.id));
    const dialogRef = this.dialog.open<
      ImportInvoiceItemsDialogComponentReturnType,
      ImportInvoiceItemsDialogComponentDataType,
      ImportInvoiceItemsDialogComponent
    >(ImportInvoiceItemsDialogComponent, {
      data: {
        invoiceId: invoice.id,
        invoiceNumber: invoice.data.invoiceNumber,
        project: (await firstValueFrom(this.invoice)).data.project,
        positions: this.projectService.getBillablePositions(project),
        vatRate: this.invoiceForm.controls.vatRate.value,
      },
    });

    // Handle after dialog is closed
    dialogRef.closed.subscribe((result) => {
      if (result) {
        if (result.vatRate) {
          this.invoiceForm.controls.vatRate.setValue(result.vatRate);
        }
        if (result.positions) {
          this.mergePositions(result.positions);
        }
      }
    });
  }

  private mergePositions(toBeMergedBackPositions: InvoicePositions) {
    if (this.invoiceForm.controls.positions.value.positionsSort.length === 0) {
      //empty so just replace
      this.invoiceForm.controls.positions.setValue(toBeMergedBackPositions);
    } else {
      // not empty, so merge into it
      const positions = this.invoiceForm.controls.positions.value;
      Object.values(toBeMergedBackPositions.positions).forEach((toBeMergedBackPosition) => {
        if (positions.positionsSort.includes(toBeMergedBackPosition.positionId)) {
          // already there
          const works = [
            ...positions.positions[toBeMergedBackPosition.positionId].works,
            ...toBeMergedBackPosition.works,
          ];
          positions.positions[toBeMergedBackPosition.positionId] = calculateInvoicePosition(
            toBeMergedBackPosition,
            works,
            sumWorkMinutes(works)
          );
        } else {
          // new one
          positions.positionsSort.push(toBeMergedBackPosition.positionId);
          positions.positions[toBeMergedBackPosition.positionId] = toBeMergedBackPosition;
        }
      });
      this.invoiceForm.controls.positions.setValue(positions);
    }
  }

  async saveInvoice() {
    const invoiceData = this.invoiceForm.getRawValue();
    const invoice = await firstValueFrom(this.invoice);
    if (this.invoiceForm.dirty) {
      await this.invoiceService.updateInvoice(invoice.id, invoiceData as unknown as InvoiceEntity2);
    } else {
      return;
    }
    this.cdr.markForCheck();
  }

  private enableDisableForm() {
    if (this.invoiceForm.controls.invoiceState.value === 'draft') {
      this.invoiceForm.enable();
    } else {
      this.invoiceForm.disable();
    }
  }

  async cleanUpDeletedPosition(position: InvoicePosition2) {
    const invoice = await firstValueFrom(this.invoice);
    await this.workService.bulkUpdateInvoiceState(
      position.works,
      invoice.id,
      invoice.data.invoiceNumber,
      false
    );
  }

  async setInvoiceCanceled() {
    this.invoiceForm.controls.invoiceState.setValue('canceled');
    this.invoiceForm.markAsDirty();
    await this.saveInvoice();
    const invoice = await firstValueFrom(this.invoice);
    await this.workService.bulkUpdateInvoiceState(
      getAllWork(Object.values(invoice.data.positions.positions)),
      invoice.id,
      invoice.data.invoiceNumber,
      false
    );
    if (invoice.data.documentId) {
      await this.documentService.updateDocument(invoice.data.documentId, {
        type: 'other',
        comment: 'Storniert',
      });
    }
    this.enableDisableForm();
  }

  async setInvoicePaid() {
    this.invoiceForm.controls.invoiceState.setValue('paid');
    this.invoiceForm.markAsDirty();
    await this.saveInvoice();
    const invoice = await firstValueFrom(this.invoice);
    if (invoice.data.documentId) {
      await this.documentService.updateDocument(invoice.data.documentId, { paid: true });
    }
    this.enableDisableForm();
  }

  async setInvoiceSent() {
    this.invoiceForm.controls.invoiceState.setValue('sent');
    this.invoiceForm.markAsDirty();
    await this.saveInvoice();
    const invoice = await firstValueFrom(this.invoice);
    const pdf = await firstValueFrom(this.previewPdf);

    if (pdf) {
      const documentId = await this.invoicePdfService.uploadDocument(pdf, {
        ...(await firstValueFrom(this.invoice)).data,
        id: invoice.id,
      });
      this.invoiceForm.controls.documentId.setValue(documentId);
    }
    this.enableDisableForm();
  }

  async setInvoiceDraft() {
    this.invoiceForm.controls.invoiceState.setValue('draft');
    this.invoiceForm.markAsDirty();
    await this.saveInvoice();
    this.enableDisableForm();
  }

  async setInvoiceReady() {
    this.invoiceForm.controls.invoiceState.setValue('ready');
    this.invoiceForm.markAsDirty();
    this.enableDisableForm();

    await this.saveInvoice();
  }

  async downloadDocument() {
    const invoice = await firstValueFrom(this.invoice);
    const pdf = await firstValueFrom(this.previewPdf);
    if (pdf) {
      saveAs(
        new Blob([pdf], { type: 'application/pdf' }),
        `Rechnung #${invoice.data.invoiceNumber}.pdf`
      );
    }
  }
}

export interface InvoiceForm {
  invoiceNumber: FormControl<string>;
  project: FormControl<ProjectSummary | null>;
  deliveryPeriod: FormGroup<{
    from: FormControl<string | null>;
    to: FormControl<string | null>;
  }>;
  invoiceState: FormControl<InvoiceState2>;
  invoiceDate: FormControl<string>;
  dueDate: FormControl<string | null>;
  paymentDurationDays: FormControl<number>;
  positions: FormControl<InvoicePositions>;
  texts: FormGroup<FormControlsOf<InvoiceTexts>>;
  //invoiceSettings: FormGroup<InvoiceSettingsForm>;
  bankDetail: FormGroup<BankConnectionForm>;
  recipient: FormGroup<{
    customer: FormControl<CustomerSummary | null>;
    name: FormControl<String | null>;
    address: FormGroup<AddressForm>;
  }>;
  sender: FormGroup<{
    contactPerson: FormControl<OrgaUserSummary | null>;
    name: FormControl<String | null>;
    address: FormGroup<AddressForm>;
    vatUid: FormControl<string | null>;
  }>;
  vatRate: FormControl<VatRate | null>;
  invoiceTotals: FormControl<InvoiceTotals | null>;
  documentId: FormControl<string | null>;
  logo: FormControl<string | null>;
}

function getTextForm(texts: InvoiceTexts | null) {
  return new FormGroup<FormControlsOf<InvoiceTexts>>({
    intro: new FormControl(texts?.intro ?? null),
    greeting: new FormControl(texts?.greeting ?? null),
  });
}
