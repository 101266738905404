import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FirestoreComment } from 'commons/dist/entities/comment';
import { NgIconWrapperComponent } from '../../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  standalone: true,
  imports: [NgFor, NgIconWrapperComponent, NgIf, DatePipe],
})
export class CommentComponent implements OnChanges, AfterViewInit {
  @Input()
  comment!: FirestoreComment;
  @Input()
  loggedInUserId!: string;
  @Input()
  last = false;
  @Output()
  deleteComment = new EventEmitter<FirestoreComment>();
  @Output()
  markAsReacted = new EventEmitter<{ comment: FirestoreComment; userId: string }>();

  authorIsLoggedIn = false;
  mentionedUsers: string[] = [];
  logggedInUserIsMentioned = false;
  logggedInUserHasNotReacted = false;
  reactedUser: string[] = [];

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    if (this.comment && this.loggedInUserId) {
      this.authorIsLoggedIn = this.comment.data.authorUserId === this.loggedInUserId;
      this.mentionedUsers = Object.values(this.comment.data.mentions).map(
        (userName) => '@' + userName
      );
      this.logggedInUserIsMentioned = Object.keys(this.comment.data.mentions).includes(
        this.loggedInUserId
      );
      this.logggedInUserHasNotReacted = this.comment.data.notReactedUserIds.includes(
        this.loggedInUserId
      );
      this.reactedUser = Object.entries(this.comment.data.mentions)
        .filter(([key]) => !this.comment.data.notReactedUserIds.includes(key))
        .map(([, value]) => value)
        .sort((a, b) => a.localeCompare(b));
    }
  }

  doMarkAsReacted() {
    this.markAsReacted.emit({ comment: this.comment, userId: this.loggedInUserId });
  }

  ngAfterViewInit(): void {
    if (this.last) {
      //this.scrollIntoView();
    }
  }

  private scrollIntoView(): void {
    this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
