<ng-container [ngSwitch]="invoiceState">
  <span
    *ngSwitchCase="'draft'"
    class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-gray-200 text-gray-800 whitespace-nowrap"
  >
    Entwurf
  </span>
  <span
    *ngSwitchCase="'ready'"
    class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800 whitespace-nowrap"
  >
    Bereit
  </span>
  <span
    *ngSwitchCase="'sent'"
    class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-yellow-100 text-yellow-800 whitespace-nowrap"
  >
    Versendet
  </span>
  <span
    *ngSwitchCase="'paid'"
    class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-green-100 text-green-800 whitespace-nowrap"
  >
    Bezahlt
  </span>
  <span
    *ngSwitchCase="'canceled'"
    class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-pink-100 text-pink-800 whitespace-nowrap"
  >
    Storniert
  </span>
</ng-container>
