<ul class="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2">
  <li *ngFor="let orgaUser of orgaUsers$ | async" class="relative col-span-1">
    <app-orga-user-card [orgaUser]="orgaUser" [role]="(role$ | async)!"></app-orga-user-card>
  </li>
</ul>

<ng-container *ngIf="orga$ | async as orga">
  <app-divider-button
    *ngIf="showAddUserButton | async"
    (buttonClick)="addUserToOrganisation()"
    buttonText="Neue Mitarbeiter:in anlegen"
  >
    <app-ng-icon class="text-gray-400" strokeWidth="3" name="heroPlus"></app-ng-icon>
  </app-divider-button>
</ng-container>

<app-orga-user-slide-over
  *ngIf="newOrgaUserSlideOverOpen"
  (cancel)="closeSlideOver()"
></app-orga-user-slide-over>
