<header class="py-4 lg:py-10 bg-gray-100 print:py-2">
  <div class="px-4 sm:px-6 lg:px-8 md:flex md:items-start md:justify-between">
    <div class="flex-1 min-w-0">
      <h2
        class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate flex gap-4 items-center"
      >
        {{ title }}
        <ng-content select="app-invoice-state-badge"></ng-content>
      </h2>
      <a
        [routerLink]="subTitleLink"
        *ngIf="subTitleLink; else plainSubtitle"
        class="text-lg sm:text-xl leading-7 text-gray-500 sm:truncate"
      >
        {{ subTitle }}
      </a>
      <ng-template #plainSubtitle>
        <p class="text-lg sm:text-xl leading-7 text-gray-500 sm:truncate">
          {{ subTitle }}
        </p>
      </ng-template>
    </div>
    <div class="mt-4 flex md:mt-0 gap-2">
      <ng-content></ng-content>
    </div>
  </div>
</header>
