import { Component, Input } from '@angular/core';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { TippyDirective } from '@ngneat/helipopper';

@Component({
  selector: 'app-input-error',
  standalone: true,
  imports: [NgIf, JsonPipe, AsyncPipe, TippyDirective],
  templateUrl: './input-error.component.html',
})
export class InputErrorComponent {
  @Input() error?: string;
}
