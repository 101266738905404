import { Component, forwardRef, Input, OnChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgIconWrapperComponent } from '../../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TailwindInputDirective2 } from '../tailwind-input2.directive';
import { normalizeNumberString } from '../number-input/number-input.component';
import { Big } from 'big.js';
import { InputLabelComponent } from '../input-label/input-label.component';
import { FormInputWrapperComponent } from '../form-input-wrapper/form-input-wrapper.component';

@Component({
  selector: 'app-work-time-input',
  templateUrl: './work-time-input.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgIconWrapperComponent,
    ReactiveFormsModule,
    AsyncPipe,
    FormsModule,
    JsonPipe,
    NgForOf,
    TailwindInputDirective2,
    InputLabelComponent,
    FormInputWrapperComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WorkTimeInputComponent),
      multi: true,
    },
  ],
  styles: `
    :host {
      @apply block;
    }
  `,
})
export class WorkTimeInputComponent implements OnChanges, ControlValueAccessor {
  @Input() label?: string;
  @Input() enhanced = false;
  @Input() variant: 'hoursMinutes' | 'hours' = 'hoursMinutes';
  @Input() isDisabled = false;
  @Input() autocomplete?: string;

  @Input() formControl!: FormControl;

  errors!: Observable<string[] | null>;

  hourValue: string | null = null;
  minuteValue: string | null = null;

  onChange: (value: number | null) => void = () => {};
  onTouched: () => void = () => {};

  ngOnChanges() {
    if (this.formControl) {
      this.errors = this.formControl.events.pipe(
        map(() => (this.formControl.touched ? this.formControl.errors : null)),
        map((x) => (x ? Object.keys(x) : null))
      );
    }
  }

  writeValue(minutes: number | null) {
    this.hourValue = minutes ? Math.floor(minutes / 60).toString() : null;
    this.minuteValue = minutes ? (minutes % 60).toString() : null;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  hourValueChanged() {
    const hourString = normalizeNumberString(this.hourValue);
    if (hourString?.length) {
      const minutes = new Big(hourString)
        .mod(new Big(1))
        .mul(new Big(60))
        .toFixed(0, Big.roundDown)
        .toString();
      this.hourValue = new Big(hourString).toFixed(0, Big.roundDown).toString();
      const newMinuteValue = new Big(this.minuteValue || 0).add(minutes);
      if (newMinuteValue.gt(0)) {
        this.minuteValue = newMinuteValue.toString();
      }
    }
    this.calculateAndWriteValue();
  }

  minuteValueChanged() {
    console.log(this.minuteValue);
    const minuteString = normalizeNumberString(this.minuteValue);
    if (minuteString === null) {
      this.minuteValue = null;
    } else if (minuteString?.length) {
      const hours = new Big(minuteString).div(new Big(60)).toFixed(0, Big.roundDown).toString();
      this.minuteValue = new Big(minuteString).mod(new Big(60)).toString();
      const newHourValue = new Big(this.hourValue || 0).add(hours);
      if (newHourValue.gt(0)) {
        this.hourValue = newHourValue.toString();
      }
    }
    this.calculateAndWriteValue();
  }

  private calculateAndWriteValue() {
    this.onTouched();
    const newValue = new Big(this.hourValue || 0)
      .mul(60)
      .add(this.minuteValue || 0)
      .toNumber();
    this.onChange(newValue || null);
  }

  protected readonly Validators = Validators;
}
