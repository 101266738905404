import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { SessionService, watchSessionStateFn } from './services/session.service';
import { EnvironmentInjector, inject } from '@angular/core';
import { NavigationShellComponent } from './pages/navigation-shell.component';
import { HomePage } from './pages/home/pages/home.page';
import { AccountantGuard } from './services/accountant.guard';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectListPage } from './pages/projects/pages/project-list.page';
import { DataPage } from './pages/projects/pages/data.page';

import { CustomersComponent } from './pages/customers/customers.component';
import { CustomerViewComponent } from './pages/customers/customer-view/customer-view.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { ExpenseRevenueComponent } from './pages/finance/expense-revenue/expense-revenue.component';
import { OutstandingPaymentsComponent } from './pages/finance/outstanding-payments/outstanding-payments.component';
import { DocumentComponent } from './pages/document/document.component';
import { InboxComponent } from './pages/document/list/inbox.component';
import { AccountingComponent } from './pages/document/list/accounting.component';
import { ArchiveComponent } from './pages/document/list/archive.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UserSettingsComponent } from './pages/settings/user-settings/user-settings.component';
import { OrgaSettingsComponent } from './pages/settings/orga-settings/orga-settings.component';
import { OrgaUserComponent } from './pages/settings/orga-settings/orga-user/orga-user.component';
import { DocumentSettingsComponent } from './pages/settings/document-settings/document-settings.component';
import { SubscriptionSettingsComponent } from './pages/settings/subscription-settings/subscription-settings.component';
import { LogoutComponent } from './pages/user/logout/logout.component';
import { EmailLandingPageComponent } from './pages/user/email-landing-page/email-landing-page.component';
import { LoginComponent } from './pages/user/login/login.component';
import { ChooseOrgaComponent } from './pages/user/choose-orga/choose-orga.component';
import { UserOnboardingComponent } from './pages/user/user-onboarding/user-onboarding.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { UpdateSchemaComponent } from './pages/update-schema/update-schema.component';
import { SubscriptionComponent } from './pages/subscription/subscription.component';
import { SubscriptionPendingComponent } from './pages/subscription/subscription-pending.component';
import { SubscriptionErrorComponent } from './pages/subscription/subscription-error.component';
import { ErrorComponent } from './pages/error/error.component';
import { concat, last, Observable, of, takeWhile } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { first } from 'rxjs/operators';
import { CalendarPage } from './pages/home/pages/calendar.page';
import { MyWorkPage } from './pages/home/pages/my-work.page';
import { MyCommentsPage } from './pages/home/pages/my-comments.page';
import { ProjectHistoryComponent } from './pages/projects/components/project-view/project-history.component';
import { ProjectViewComponent } from './pages/projects/components/project-view/project-view.component';
import { ProjectStructureComponent } from './pages/projects/components/project-view/project-structure.component';
import { ProjectActivityComponent } from './pages/projects/components/project-view/project-activity.component';
import { ProjectInvoiceComponent } from './pages/projects/components/project-view/project-invoice.component';
import { ProjectDocumentsComponent } from './pages/projects/components/project-view/project-documents.component';
import { ProjectCommentsComponent } from './pages/projects/components/project-view/project-comments.component';
import { WorkPage } from './pages/projects/pages/work.page';
import { InvoicePage } from './pages/invoice/pages/invoice/invoice.page';
import { VatSettingsComponent } from './pages/settings/vat/vat-settings.component';
import { formatISO } from 'date-fns';
import { TimerWorkListPage } from './pages/home/pages/timer-work-list.page';
import { TimerPage } from './pages/home/pages/timer.page';

function wrapIntoObservable<T>(value: T | Promise<T> | Observable<T>): Observable<T> {
  if (value instanceof Observable) {
    return value;
  } else if (value instanceof Promise) {
    return fromPromise(value);
  } else {
    return of(value);
  }
}

function runSerially(
  guards: CanActivateFn[] | CanActivateChildFn[]
): CanActivateFn | CanActivateChildFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const injector = inject(EnvironmentInjector);
    const observables = guards.map((guard) => {
      const guardResult = injector.runInContext(() => guard(route, state));
      return wrapIntoObservable(guardResult).pipe(first());
    });
    return concat(...observables).pipe(
      takeWhile((v) => v === true, true),
      last()
    );
  };
}

export const routes: Routes = [
  {
    path: '',
    data: {
      sessionStateWatcher: [
        watchSessionStateFn(() => inject(SessionService).isSystemOpen()),
        watchSessionStateFn(() => inject(SessionService).checkSchemaVersion()),
        watchSessionStateFn(() => inject(SessionService).isLoggedIn()),
      ],
    },
    component: NavigationShellComponent,
    canActivateChild: [
      runSerially([
        () => inject(SessionService).isSystemOpen(),
        () => inject(SessionService).isLoggedIn(),
        () => inject(SessionService).isUserOnboardingComplete(),
        () => inject(SessionService).isOrganisationChosen(),
        () => inject(SessionService).checkSchemaVersion(),
        () => inject(SessionService).checkOrgaSubscriptionState(),
      ]),
    ],
    children: [
      {
        path: '',
        component: HomePage,
        canActivate: [AccountantGuard],
        children: [
          {
            path: '',
            redirectTo: 'calendar',
            pathMatch: 'full',
          },
          {
            path: 'calendar',
            children: [
              {
                path: '',
                redirectTo: () => {
                  return `${formatISO(new Date(), { representation: 'date' })}`;
                },
                pathMatch: 'full',
              },
              {
                path: ':selectedDate',
                component: CalendarPage,

                children: [
                  { path: '', component: TimerWorkListPage },
                  { path: 'timer/:timerId', component: TimerPage },
                ],
              },
            ],
          },

          { path: 'work', component: MyWorkPage },
          { path: 'comments', component: MyCommentsPage },
        ],
      },
      {
        path: 'project',
        component: ProjectsComponent,
        children: [
          { path: '', redirectTo: 'all', pathMatch: 'full' },
          {
            path: 'all',
            component: ProjectListPage,
          },
          {
            path: 'work',
            component: WorkPage,
          },
          { path: 'data', component: DataPage },
        ],
      },
      {
        path: 'project/details/:projectId',
        component: ProjectViewComponent,
        children: [
          { path: '', redirectTo: 'structure', pathMatch: 'full' },
          { path: 'structure', component: ProjectStructureComponent },
          { path: 'activity', component: ProjectActivityComponent },
          {
            path: 'invoice',
            component: ProjectInvoiceComponent,
          },
          { path: 'documents', component: ProjectDocumentsComponent },
          { path: 'comments', component: ProjectCommentsComponent },
          { path: 'history', component: ProjectHistoryComponent },
        ],
      },
      {
        path: 'project/details/:projectId/invoice/:invoiceId',
        component: InvoicePage,
        children: [{ path: 'preview', component: InvoicePage }],
        // canDeactivate: [
        //   async (component: InvoicePage) => {
        //     try {
        //       await component.saveInvoice();
        //     } catch (error) {
        //       console.error(error);
        //     }
        //     return true;
        //   },
        // ],
      },
      {
        path: 'customer',
        children: [
          { path: '', component: CustomersComponent },
          {
            path: ':customerId',
            component: CustomerViewComponent,
            data: { showBackButton: true },
          },
        ],
      },
      {
        path: 'customer/:customerId/invoice/:invoiceId',
        component: InvoicePage,
        children: [{ path: 'preview', component: InvoicePage }],
      },
      {
        path: 'customer/:customerId/project/:projectId',
        component: ProjectViewComponent,
        children: [
          { path: '', redirectTo: 'structure', pathMatch: 'full' },
          { path: 'structure', component: ProjectStructureComponent },
          { path: 'activity', component: ProjectActivityComponent },
          {
            path: 'invoice',
            component: ProjectInvoiceComponent,
          },
          { path: 'documents', component: ProjectDocumentsComponent },
          { path: 'comments', component: ProjectCommentsComponent },
          { path: 'history', component: ProjectHistoryComponent },
        ],
      },
      {
        path: 'finance',
        component: FinanceComponent,
        children: [
          { path: '', redirectTo: 'outstandingPayments', pathMatch: 'full' },
          { path: 'expenseRevenue', component: ExpenseRevenueComponent },
          { path: 'outstandingPayments', component: OutstandingPaymentsComponent },
        ],
      },
      {
        path: 'document',
        component: DocumentComponent,
        children: [
          { path: '', redirectTo: 'inbox', pathMatch: 'full' },
          {
            path: 'inbox',
            component: InboxComponent,
            canActivate: [AccountantGuard],
          },
          { path: 'accounting', component: AccountingComponent },
          { path: 'archive/:archiveFolderId', component: ArchiveComponent },
        ],
      },
      {
        path: 'data',
        component: DataPage,
      },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [
          { path: '', redirectTo: 'account', pathMatch: 'full' },
          { path: 'account', component: UserSettingsComponent },
          { path: 'organisation', component: OrgaSettingsComponent },
          { path: 'user', component: OrgaUserComponent },
          { path: 'vat', component: VatSettingsComponent },
          { path: 'document', component: DocumentSettingsComponent },
          { path: 'subscription', component: SubscriptionSettingsComponent },
        ],
      },
    ],
  },
  {
    path: 'user',
    children: [
      {
        path: 'logout',
        component: LogoutComponent,
        // data: {
        //   sessionStateWatcher: [watchSessionStateFn(() => inject(SessionService).isLoggedIn())],
        // },
      },
      {
        path: 'login/emaillandingpage',
        component: EmailLandingPageComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {
          sessionStateWatcher: [watchSessionStateFn(() => inject(SessionService).isLoggedIn())],
        },
      },
      {
        path: 'orga',
        component: ChooseOrgaComponent,
        canActivate: [
          runSerially([
            () => inject(SessionService).isSystemOpen(),
            () => inject(SessionService).isLoggedIn(),
          ]),
        ],
      },
      {
        path: 'onboarding',
        component: UserOnboardingComponent,
        canActivate: [
          runSerially([
            () => inject(SessionService).isSystemOpen(),
            () => inject(SessionService).isLoggedIn(),
          ]),
        ],
      },
    ],
  },
  {
    path: 'maintenance',
    pathMatch: 'full',
    component: MaintenanceComponent,
  },
  {
    path: 'updateSchema',
    component: UpdateSchemaComponent,
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
  },
  {
    path: 'subscription-pending',
    component: SubscriptionPendingComponent,
  },
  {
    path: 'subscription-error',
    component: SubscriptionErrorComponent,
  },
  { path: '** ', component: ErrorComponent },
];
