import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DropdownMinimalMenuComponent } from '../dropdown-minimal-menu.component';

@Component({
  selector: 'app-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemComponent {
  @Output()
  menuClick = new EventEmitter();

  constructor(private dropDownMininal: DropdownMinimalMenuComponent) {}

  click(): void {
    this.menuClick.emit();
    this.dropDownMininal.click();
  }
}
