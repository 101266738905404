import { Component, Inject } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { firstValueFrom, Observable, shareReplay } from 'rxjs';
import { FirestoreProject, FirestoreUser, mapProjectSummary } from 'commons';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContainerComponent } from '../../../../components/container/container.component';
import { HeaderMetaActionComponent } from '../../../../components/container/header-meta-action/header-meta-action.component';
import { FavoriteComponent } from '../../../../components/favorite/favorite.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { TabGroupComponent } from '../../../../components/tabs/routed/tab-group.component';
import { TabDirective } from '../../../../components/tabs/routed/tab.directive';
import { HideWhenNotInPlanDirective } from '../../../../directives/hide-when-not-in-plan.directive';
import { ProjectService } from '../../../../services/project.service';
import { InvoiceService } from '../../../../services/invoice.service';
import { SessionStateService } from '../../../../services/session-state.service';
import { filter, map } from 'rxjs/operators';
import { Dialog } from '@angular/cdk/dialog';
import {
  WorkSlideOverComponent,
  WorkSlideOverInput,
} from '../../slide-overs/work-slide-over.component';
import {
  ProjectSlideOverComponent,
  ProjectSlideOverInput,
} from '../../slide-overs/project-slide-over.component';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  standalone: true,
  imports: [
    NgIf,
    ContainerComponent,
    HeaderMetaActionComponent,
    FavoriteComponent,
    TailwindButtonDirective,
    TabGroupComponent,
    TabDirective,
    RouterLinkActive,
    RouterLink,
    HideWhenNotInPlanDirective,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class ProjectViewComponent {
  project: Observable<FirestoreProject>;
  userId = this.sessionState.getUser().pipe(
    filter((user): user is FirestoreUser => !!user),
    map((user) => user.id)
  );

  constructor(
    public route: ActivatedRoute,
    private projectService: ProjectService,
    private invoiceService: InvoiceService,
    private router: Router,
    private sessionState: SessionStateService,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.project = this.projectService
      .getProject(this.route.snapshot.params['projectId'])
      .pipe(shareReplay(1));
  }

  async editProject() {
    this.dialog.open<never, ProjectSlideOverInput, ProjectSlideOverComponent>(
      ProjectSlideOverComponent,
      {
        data: { project: await firstValueFrom(this.project) },
      }
    );
  }

  async favoriteChanged(activated: boolean) {
    const project = await firstValueFrom(this.project);
    this.projectService.toggleFavorite(project, activated);
  }

  async createInvoice() {
    const invoiceId = await this.invoiceService.createInvoice(await firstValueFrom(this.project));

    this.router.navigate(['invoice', invoiceId], {
      relativeTo: this.route,
    });
  }

  async createWork() {
    const project = await firstValueFrom(this.project);
    this.dialog.open<never, WorkSlideOverInput, WorkSlideOverComponent>(WorkSlideOverComponent, {
      data: {
        project: mapProjectSummary(project),
        customer: project.data.customerId
          ? { id: project.data.customerId, displayName: project.data.customerName! }
          : undefined,
      },
    });
  }
}
