<app-form-input-wrapper [label]="label" [control]="formControl">
  <input
    *ngIf="variant === 'textfield'"
    [attr.disabled]="isDisabled ? true : null"
    [attr.autocomplete]="autocomplete ? autocomplete : null"
    [attr.name]="autocomplete === 'email' ? 'email' : null"
    [value]="value"
    (input)="valueChanged(inputElement.value)"
    #inputElement
    appTailwindInput2
    [control]="formControl"
    type="text"
  />
  <textarea
    *ngIf="variant === 'textarea'"
    [attr.disabled]="isDisabled ? true : null"
    [value]="value"
    (input)="valueChanged(inputElement.value)"
    #inputElement
    appTailwindInput2
    [control]="formControl"
    type="text"
  ></textarea>
</app-form-input-wrapper>
