"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapProjectSummary = void 0;
function mapProjectSummary(project) {
    return {
        id: project.id,
        displayName: project.data.name || 'No Displayname',
    };
}
exports.mapProjectSummary = mapProjectSummary;
