import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, debounceTime, Observable, Subscription } from 'rxjs';
import { ArchiveFolder, DocumentType, FirestoreDocument } from 'commons';
import { DocumentService } from '../../../../services/document.service';
import { AsyncPipe, KeyValue, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { DocumentListComponent } from '../document-list/document-list.component';
import { DropdownMinimalMenuComponent } from '../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { DropdownButtonComponent } from '../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownItemComponent } from '../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DocumentListItemComponent } from '../document-list-item/document-list-item.component';
import { TailwindInputDirective2 } from '../../../../components/input/tailwind-input2.directive';

type DocumentListEntry = FirestoreDocument & { selected: boolean };

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgIconWrapperComponent,
    TailwindButtonDirective,
    DocumentListComponent,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    DropdownItemComponent,
    NgFor,
    DocumentListItemComponent,
    AsyncPipe,
    KeyValuePipe,
    TailwindInputDirective2,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentCardComponent implements OnChanges {
  @Input()
  cardTitle!: string;

  @Input()
  documents!: FirestoreDocument[];

  @Input()
  enableShowAll = false;

  @Input()
  disableFilter = false;

  @Input()
  disableSearch = false;

  @Input()
  typeFilter: DocumentType[] | null = null;

  @Input()
  updateAccountingProcessedEnabled = false;

  @Input()
  updateAccountingRelevantEnabled = false;

  @Output()
  searchValueChanged = new EventEmitter<string | null>();

  @Output()
  typeFilterChanged = new EventEmitter<DocumentType[]>();

  @Output()
  showAll = new EventEmitter<boolean>();

  @Output() editDocument = new EventEmitter<FirestoreDocument[]>();
  @Output() deleteDocument = new EventEmitter<FirestoreDocument[]>();

  isMultiselect = false;
  documentList = new Map<string, DocumentListEntry>();
  atLeastOneSelected!: boolean;
  folder$!: Observable<ArchiveFolder[]>;

  private searchValue = new BehaviorSubject<string | null>(null);

  private subscriptions = new Subscription();

  constructor(private documentService: DocumentService) {
    this.folder$ = this.documentService.getFolders();
    this.subscriptions.add(
      this.searchValue
        .pipe(debounceTime(350))
        .subscribe((searchValue) => this.searchValueChanged.emit(searchValue))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documents && changes.documents.currentValue) {
      const oldDocumentList = new Map(this.documentList);
      this.documentList = new Map();
      this.documents.forEach((document) =>
        this.documentList.set(document.id, {
          ...document,
          selected: oldDocumentList.get(document.id)?.selected || false,
        })
      );
      this.checkAndSetSelectionState();
    }
  }

  toggleMultiselect() {
    this.showAll.emit(true);
    this.isMultiselect = !this.isMultiselect;
    if (this.isMultiselect) {
      this.deselectAll();
    }
  }

  selectionChanged(isSelected: boolean, document: DocumentListEntry) {
    document.selected = isSelected;
    this.checkAndSetSelectionState();
  }

  selectAll() {
    this.documentList.forEach((value) => (value.selected = true));
    this.atLeastOneSelected = true;
  }

  deselectAll() {
    this.documentList.forEach((value) => (value.selected = false));
    this.atLeastOneSelected = false;
  }

  downloadSelectedDocuments() {
    this.downloadDocuments([...this.documentList.values()].filter((document) => document.selected));
  }

  private checkAndSetSelectionState() {
    this.atLeastOneSelected =
      [...this.documentList.values()].filter((document) => document.selected).length > 0;
  }

  updateSelectedDocumentsAsProcessed() {
    this.updateAccountingProcessed(
      [...this.documentList.values()].filter((document) => document.selected)
    );
  }

  updateSelectedDocumentsAsRelevant() {
    this.updateAccountingRelevant(
      [...this.documentList.values()].filter((document) => document.selected)
    );
  }

  updateSelectedDocumentFolder(folder: ArchiveFolder | null) {
    this.updateFolder({
      documents: [...this.documentList.values()].filter((document) => document.selected),
      folder,
    });
  }

  editSelectedDocuments() {
    this.editDocument.emit(
      [...this.documentList.values()]
        .sort((a, b) => b.data?.documentDate?.localeCompare(a.data.documentDate ?? '') ?? 0)
        .filter((document) => document.selected)
    );
  }

  sortByDocumentDate(
    a: KeyValue<string, FirestoreDocument>,
    b: KeyValue<string, FirestoreDocument>
  ): number {
    return b.value.data?.documentDate?.localeCompare(a.value.data.documentDate ?? '') ?? 0;
  }

  async downloadDocuments(documentsToDownload: FirestoreDocument[]) {
    await this.documentService.downloadAll(documentsToDownload);
  }

  async updateAccountingProcessed(documents: FirestoreDocument[]) {
    await Promise.all(
      documents
        .filter((document) => document.data.accounting.relevant)
        .map(
          async (document) =>
            await this.documentService.updateDocument(document.id, {
              accounting: { processed: true, relevant: true },
            })
        )
    );
  }

  downloadDocument(document: FirestoreDocument) {
    this.documentService.downloadDocument(document);
  }

  restartDocumentAi(document: FirestoreDocument) {
    this.documentService.restartDocumentAi(document);
  }

  async updateAccountingRelevant(documents: FirestoreDocument[]) {
    documents.forEach(
      async (document) =>
        await this.documentService.updateDocument(document.id, {
          accounting: { processed: false, relevant: true },
        })
    );
  }

  updateFolder($event: { documents: FirestoreDocument[]; folder: ArchiveFolder | null }) {
    $event.documents.forEach(
      async (document) =>
        await this.documentService.updateDocument(document.id, {
          folder: $event.folder
            ? {
                entity: 'folder',
                id: $event.folder.id,
                name: $event.folder.name,
              }
            : null,
        })
    );
  }

  searchTextChanged($event: Event) {
    const inputElement = $event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    this.searchValue.next(inputValue);
  }

  typeFilterChange(type: DocumentType, $event: any) {
    const isChecked = $event.currentTarget.checked;
    let typeFilter: DocumentType[] = this.typeFilter ?? [];
    if (isChecked) {
      typeFilter?.push(type);
    } else {
      typeFilter = typeFilter?.filter((item) => item !== type) ?? null;
    }
    this.typeFilterChanged.emit(typeFilter);
  }
}
