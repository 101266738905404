import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Summary } from 'commons';
import { CdkMenu, CdkMenuItemRadio, CdkMenuTrigger } from '@angular/cdk/menu';
import { JsonPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { InputLabelComponent } from '../input/input-label/input-label.component';
import { FormInputWrapperComponent } from '../input/form-input-wrapper/form-input-wrapper.component';
import { TailwindInputDirective2 } from '../input/tailwind-input2.directive';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItemRadio,
    NgClass,
    NgFor,
    JsonPipe,
    NgIf,
    InputLabelComponent,
    ReactiveFormsModule,
    FormInputWrapperComponent,
    TailwindInputDirective2,
  ],
  styles: `
    :host {
      @apply block;
    }
  `,
})
export class DropdownComponent implements ControlValueAccessor, OnChanges {
  @Input() label?: string;
  @Input() options: Summary[] | Observable<Summary[]> | null = [];
  @Input() bindIdOnly = false;
  @Input() formControl!: FormControl;
  value!: Summary;

  _options: Summary[] = [];

  onChange: any = () => {};
  onTouched: any = () => {};
  isDisabled = false;
  isLoading = false;

  errors!: Observable<string[] | null>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    if (this.formControl) {
      this.errors = this.formControl.events.pipe(
        map(() => (this.formControl.touched ? this.formControl.errors : null)),
        map((x) => (x ? Object.keys(x) : null))
      );
    }
  }

  async writeValue(obj: any): Promise<void> {
    if (this.bindIdOnly) {
      await this.extractOption();
      this.value = this._options.find((option) => option.id === obj) ?? {
        id: obj,
        displayName: obj,
      };
    } else {
      this.value = obj;
    }
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  valueChanged(value: Summary) {
    this.value = value;
    if (this.bindIdOnly) {
      this.onChange(this.value.id);
    } else {
      this.onChange(this.value);
    }
  }

  async extractOption() {
    this.isLoading = true;
    if (!this.options) {
      this._options = [];
    } else if (Array.isArray(this.options)) {
      this._options = this.options;
    } else {
      this._options = await firstValueFrom(this.options);
    }
    this.isLoading = false;
    this.cdr.markForCheck();
  }
}
