import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import {
  createUserDisplayName,
  createUserInitials,
  FirestoreOrga,
  FirestoreOrgaUser,
  FirestoreUser,
  LastEditor,
  OrgaInfo,
  OrgaUserPermission,
  SubscriptionPlan,
} from 'commons';

@Injectable({
  providedIn: 'root',
})
export class SessionStateService {
  private organisationsFromUser = new BehaviorSubject<Record<string, OrgaInfo> | null>(null);
  private loggedInUser = new BehaviorSubject<FirestoreUser | null>(null);
  private loggedInOrgaId = new BehaviorSubject<string | null>(null);
  private loggedInOrga = new BehaviorSubject<FirestoreOrga | null>(null);
  private loggedInOrgaUser = new BehaviorSubject<FirestoreOrgaUser | null>(null);

  getOrganisations() {
    return this.organisationsFromUser.asObservable();
  }

  setOrganisations(orgas: Record<string, OrgaInfo>) {
    this.organisationsFromUser.next(orgas);
    // console.log(
    //   'session state: updated organisations: ' +
    //     Object.values(orgas)
    //       .map((orga) => `[${orga.name} - ${orga.role}]`)
    //       .join(' ')
    // );
  }

  setUser(user: FirestoreUser) {
    this.loggedInUser.next(user);
    // console.log('session state: updated user: ' + JSON.stringify(user.data));
  }

  getUser() {
    return this.loggedInUser.asObservable();
  }

  setOrgaUser(orgaUser: FirestoreOrgaUser) {
    this.loggedInOrgaUser.next(orgaUser);
    // console.log('session state: updated orga user: ' + JSON.stringify(orgaUser.data));
  }

  getOrgaUser() {
    return this.loggedInOrgaUser.asObservable();
  }

  getOrgaUserId() {
    return this.loggedInOrgaUser.asObservable().pipe(
      filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
      map((orgaUser) => orgaUser.id)
    );
  }

  setOrgaId(orgaId: string) {
    this.loggedInOrgaId.next(orgaId);
  }

  getOrgaId() {
    return this.loggedInOrgaId.asObservable().pipe(filter((orgaId): orgaId is string => !!orgaId));
  }

  setOrga(orga: FirestoreOrga) {
    this.loggedInOrga.next(orga);
    // console.log('session state: updated logged in orga: ' + `${orga.data.name} ${orga.id}`);
  }

  getOrga() {
    return this.loggedInOrga.asObservable();
  }

  getOrgaSubscriptionPlan() {
    return this.loggedInOrga.asObservable().pipe(
      map((orga) => {
        if (orga && orga.data.subscription) {
          return orga.data.subscription.plan;
        } else {
          return null;
        }
      }),
      filter((plan): plan is SubscriptionPlan => !!plan)
    );
  }

  getOrgaUserRole(): Observable<OrgaUserPermission> {
    return this.loggedInOrgaUser.pipe(
      filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
      map((orgaUser) => orgaUser.data.role)
    );
  }

  async getLastEditor(): Promise<LastEditor | null> {
    const user = await firstValueFrom(this.loggedInUser);
    if (user) {
      return {
        callId: crypto.randomUUID(),
        authUserUid: user.data.authUserUid,
        userId: user.id,
        userDisplayName: createUserDisplayName(user.data.firstName, user.data.lastName),
        userInitials: createUserInitials(user.data.firstName, user.data.lastName),
      };
    }
    return null;
  }

  signOut() {
    this.organisationsFromUser.next(null);
    this.loggedInUser.next(null);
    this.loggedInOrga.next(null);
    this.loggedInOrgaUser.next(null);
    this.loggedInOrgaId.next(null);
  }
}
