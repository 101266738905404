<div class="grid grid-cols-1 md:grid-cols-6 gap-x-8 gap-y-4 pt-6">
  <div class="md:col-span-6 border-b border-gray-900/10">
    <div class="pb-12">
      <div class="grid grid-cols-1 gap-y-6 gap-x-4 md:grid-cols-6">
        <app-position-table
          class="md:col-span-6 w-full"
          [invoicePositions]="invoicePositions"
          [formControl]="invoiceForm.controls.positions"
          [invoiceSummary]="invoiceSummary"
          (deletedPosition)="deletedPosition.emit($event)"
        ></app-position-table>

        <div class="flex items-start md:col-span-4">
          <div class="flex items-center h-5">
            <input
              id="report"
              type="checkbox"
              disabled
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 transition duration-150 ease-in-out"
            />
          </div>
          <div class="ml-3 text-sm leading-5">
            <label for="report" class="font-medium text-gray-400"
              >Detaillierter Stundenrapport</label
            >
            <app-badge class="ml-2" color="green">Coming Soon</app-badge>
            <p class="text-gray-400">
              Erstellt eine detaillierte Liste aller verrechneten Stunden und hängt sie an die
              Rechnung an.
            </p>
          </div>
        </div>
        <div class="sm:col-span-2 flex justify-end">
          <button
            class="h-8"
            appTailwindButton
            (click)="importPositions.emit()"
            [disabled]="isDisabled"
          >
            Neue Rechnungsposition
          </button>
        </div>
        <div class="flex items-start md:col-span-6">
          <div class="flex items-center h-5">
            <input
              id="qrCode"
              type="checkbox"
              disabled
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 transition duration-150 ease-in-out"
            />
          </div>
          <div class="ml-3 text-sm leading-5">
            <label for="qrCode" class="font-medium text-gray-400">QR Rechnung</label>
            <app-badge class="ml-2" color="green">Coming Soon</app-badge>
            <p class="text-gray-400">Erstellt eine QR Rechnung und hängt sie an die Rechnung an.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="md:col-span-6 border-b border-gray-900/10">
    <div class="pb-12">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Texte</h2>
      <div class="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 md:grid-cols-6">
        <app-text-input
          label="Einleitung"
          class="md:col-span-6"
          [formControl]="invoiceForm.controls.texts.controls.intro"
        >
        </app-text-input>
        <app-text-input
          label="Grussformel"
          class="md:col-span-6"
          [formControl]="invoiceForm.controls.texts.controls.greeting"
        >
        </app-text-input>
      </div>
    </div>
  </div>

  <div class="md:col-span-6 lg:col-span-3">
    <h2 class="text-base font-semibold leading-7 text-gray-900">Rechnungsadresse</h2>

    <div class="mt-4 grid grid-cols-1 gap-y-4 gap-x-4 md:grid-cols-6">
      <app-dropdown
        label="Kunde"
        class="md:col-span-6"
        [formControl]="invoiceForm.controls.recipient.controls.customer"
        [options]="customers"
      ></app-dropdown>
      <app-text-input
        label="Name"
        class="md:col-span-6"
        [formControl]="invoiceForm.controls.recipient.controls.name"
      >
      </app-text-input>
      <app-address-form
        class="md:col-span-6"
        [addressFormGroup]="invoiceForm.controls.recipient.controls.address"
      ></app-address-form>
    </div>
  </div>
  <div class="md:col-span-6 lg:col-span-3">
    <div class="flex justify-between items-center">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Absender</h2>
      <a routerLink="/settings/organisation" class="text-gray-400"
        ><app-ng-icon name="heroCog8Tooth"></app-ng-icon
      ></a>
    </div>

    <div class="mt-4 grid grid-cols-1 gap-y-4 gap-x-4">
      <app-dropdown
        label="Ansprechpartner"
        [options]="users"
        [formControl]="invoiceForm.controls.sender.controls.contactPerson"
      ></app-dropdown>
      <app-text-input
        label="Name"
        [formControl]="invoiceForm.controls.sender.controls.name"
      ></app-text-input>
      <app-address-form
        [addressFormGroup]="invoiceForm.controls.sender.controls.address"
      ></app-address-form>
    </div>
  </div>
  <div class="mt-4 md:col-span-6 lg:col-span-3">
    <div>
      <div class="flex justify-between items-center">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Zahlungsdetails</h2>
      </div>
      <div class="mt-4 grid grid-cols-1 gap-y-4 gap-x-4">
        <app-date-input label="Rechnungsdatum" [formControl]="invoiceForm.controls.invoiceDate">
        </app-date-input>
        <app-text-input
          label="UID"
          [formControl]="invoiceForm.controls.sender.controls.vatUid"
        ></app-text-input>
        <app-form-input-wrapper label="Lieferdatum">
          <div class="grid grid-cols-2 gap-x-4">
            <app-date-input
              [formControl]="invoiceForm.controls.deliveryPeriod.controls.from"
            ></app-date-input>
            <app-date-input
              [formControl]="invoiceForm.controls.deliveryPeriod.controls.to"
            ></app-date-input>
          </div>
        </app-form-input-wrapper>
        <!--        <app-invoice-settings-form-->
        <!--          [showInvoiceNumber]="false"-->
        <!--          [invoiceSettingsForm]="invoiceForm.controls.invoiceSettings"-->
        <!--        ></app-invoice-settings-form>-->
      </div>
    </div>
  </div>
  <div class="mt-4 md:col-span-6 lg:col-span-3">
    <div class="flex justify-between items-center">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Bankverbindung</h2>
      <a routerLink="/settings/organisation" class="text-gray-400"
        ><app-ng-icon name="heroCog8Tooth"></app-ng-icon
      ></a>
    </div>
    <app-bank-connection-form
      class="block mt-4"
      [bankConnectionFormGroup]="invoiceForm.controls.bankDetail"
    ></app-bank-connection-form>
  </div>
</div>
