import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-header-meta-action',
  templateUrl: './header-meta-action.component.html',
  standalone: true,
  imports: [NgIf, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderMetaActionComponent {
  @Input()
  title!: string;
  @Input()
  subTitle: string | undefined | null = '';
  @Input()
  subTitleLink?: string;
}
