<div class="bg-yellow-50 p-4">
  <div class="flex">
    <div class="flex-shrink-0">
      <app-ng-icon class="text-2xl text-yellow-600" name="heroExclamationTriangle"> </app-ng-icon>
    </div>
    <div class="ml-3">
      <h3 class="text-sm font-medium text-yellow-800">
        {{ heading }}
      </h3>
      <div class="mt-2 text-sm text-yellow-700">
        <p>{{ subheading }}</p>
      </div>
    </div>
  </div>
</div>
