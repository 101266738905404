import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BaseInvoice,
  FirestoreInvoice,
  FirestoreOrga,
  FirestoreProject,
  FirestoreUser,
  Invoice,
  InvoiceState,
} from 'commons';
import { addDays, endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import { firstValueFrom, Subscription } from 'rxjs';
import { InvoiceService } from '../../../../services/invoice.service';
import { SlideOverService } from '../../../../components/slide-overs/slide-over/slide-over-service';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { SlideOverFooterComponent } from '../../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { NgIf } from '@angular/common';
import { TailwindInputDirective } from '../../../../components/input/tailwind-input.directive';
import { ToggleButtonComponent } from '../../../../components/toggle-button-group/toggle-button/toggle-button.component';
import { ToggleButtonGroupComponent } from '../../../../components/toggle-button-group/toggle-button-group.component';
import { InputComponent } from '../../../../components/input/input.component';
import { SlideOverContentComponent } from '../../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { SlideOverHeaderComponent } from '../../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverComponent } from '../../../../components/slide-overs/slide-over/slide-over.component';
import { SessionStateService } from '../../../../services/session-state.service';
import { filter } from 'rxjs/operators';
import { DateInputComponent } from '../../../../components/input/date-input/date-input.component';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSlideOverService extends SlideOverService<
  FirestoreInvoice,
  { project: FirestoreProject },
  void
> {}

@Component({
  selector: 'app-invoice-slide-over',
  templateUrl: './invoice-slide-over.component.html',
  standalone: true,
  imports: [
    SlideOverComponent,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    ReactiveFormsModule,
    InputComponent,
    ToggleButtonGroupComponent,
    ToggleButtonComponent,
    TailwindInputDirective,
    NgIf,
    SlideOverFooterComponent,
    TailwindButtonDirective,
    DateInputComponent,
  ],
})
export class InvoiceSlideOverComponent implements OnInit, OnDestroy {
  invoiceForm!: FormGroup;

  invoice!: FirestoreInvoice;

  private subscriptions = new Subscription();

  constructor(
    private invoiceService: InvoiceService,
    private router: Router,
    public invoiceSlideOver: InvoiceSlideOverService,
    private sessionState: SessionStateService
  ) {}

  ngOnInit() {
    const invoice =
      this.invoiceSlideOver.getMode() === 'edit' ? this.invoiceSlideOver.getEntity()?.data! : null;
    this.initForm(invoice);
    this.invoice = this.invoiceSlideOver.getEntity()!;
  }

  initForm(invoice: Invoice | null) {
    const today = format(new Date(), 'yyyy-MM-dd');
    const sOfMonth = format(startOfMonth(new Date()), 'yyyy-MM-dd');
    const eOfMonth = format(endOfMonth(new Date()), 'yyyy-MM-dd');

    this.invoiceForm = new FormGroup({
      invoiceDate: new FormControl(invoice ? invoice.invoiceDate : today, [Validators.required]),
      startDate: new FormControl(invoice ? invoice.dateFrom : sOfMonth, [Validators.required]),
      endDate: new FormControl(invoice ? invoice.dateTo : eOfMonth, [Validators.required]),
      state: new FormControl(invoice?.state ? invoice.state : 'created', [Validators.required]),
    });
    this.subscriptions.add(
      this.invoiceForm.get('state')?.valueChanges.subscribe((state: InvoiceState) => {
        if (state === 'paid' || state === 'canceled') {
          this.invoiceForm.addControl(
            'valueDate',
            new FormControl(invoice?.valueDate, [Validators.required])
          );
        } else {
          this.invoiceForm.removeControl('valueDate');
        }
      })
    );

    if (this.invoiceForm.value.state === 'paid' || this.invoiceForm.value.state === 'canceled') {
      this.invoiceForm.addControl(
        'valueDate',
        new FormControl(invoice?.valueDate, [Validators.required])
      );
    } else {
      this.invoiceForm.removeControl('valueDate');
    }

    if (this.invoiceSlideOver.getMode() === 'edit') {
      this.invoiceForm.get('invoiceDate')?.disable();
      this.invoiceForm.get('startDate')?.disable();
      this.invoiceForm.get('endDate')?.disable();
    }
  }

  closeSlideOver() {
    this.invoiceSlideOver.closeSlideOver();
  }

  createOrUpdateInvoice(invoice: Invoice) {
    if (this.invoiceSlideOver.getMode() === 'new') {
      this.createInvoice(invoice);
    } else {
      this.updateInvoice(this.invoiceSlideOver.getEntity()?.id!, invoice);
    }
  }

  private async createInvoice(formValue: BaseInvoice) {
    this.invoiceService.createInvoice(await this.hydrateInvoice(formValue)).then(() => {
      this.router.navigate([
        'project',
        'details',
        this.invoiceSlideOver.getNewMeta().project.id,
        'invoice',
      ]);
      this.closeSlideOver();
    });
  }

  private async updateInvoice(invoiceId: string, formValue: any) {
    this.invoiceService
      .updateInvoice(invoiceId, { valueDate: formValue.valueDate, state: formValue.state })
      .then(() => this.closeSlideOver());
  }

  async hydrateInvoice(formValue: any): Promise<BaseInvoice> {
    const orga = await firstValueFrom(
      this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga))
    );
    const user = await firstValueFrom(
      this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user))
    );
    const project = this.invoiceSlideOver.getNewMeta().project;
    const customerId = this.invoiceSlideOver.getNewMeta().project.data.customerId;

    return {
      state: formValue.state,
      orgaId: orga.id,
      customerId: customerId!,
      projectId: project.id,
      userId: user.id,
      // invoiceDate: formValue.invoiceDate,
      invoiceDate: formValue.invoiceDate,
      // dateFrom: formValue.startDate,
      dateFrom: this.invoiceForm.get('startDate')?.value,
      // dateTo: formValue.endDate,
      dateTo: this.invoiceForm.get('endDate')?.value,
      valueDate: addDays(
        parseISO(formValue.invoiceDate),
        orga.data.invoiceDetails.defaultPaymentDurationDays
      ).toISOString(),
      paid: false,
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
