import { Component } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { UiTimer, WorkTimerService } from './work-timer.service';
import { FormatTimestampPipe } from '../../../pipes/format-timestamp.pipe';
import { FormatSecondsPipe } from '../../../pipes/format-seconds.pipe';
import { SecondsPassedPipe } from '../../../pipes/seconds-passed.pipe';
import { EditableLabelComponent } from '../../../components/editable-label/editable-label.component';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-work-timer',
  templateUrl: './work-timer.component.html',
  standalone: true,
  imports: [
    NgIf,
    TailwindButtonDirective,
    NgFor,
    NgClass,
    EditableLabelComponent,
    AsyncPipe,
    SecondsPassedPipe,
    FormatSecondsPipe,
    FormatTimestampPipe,
  ],
})
export class WorkTimerComponent {
  timer$!: Observable<UiTimer[]>;
  isWorkEntryOpen = false;
  workDate!: Date;
  workMinutes!: number;
  workDescription!: string;

  constructor(private workTimerService: WorkTimerService) {
    this.timer$ = this.workTimerService.getAllTimer();
  }

  async addTimer() {
    await this.workTimerService.addTimer();
  }

  async pause() {
    await this.workTimerService.pause();
  }

  async start(index: number) {
    await this.workTimerService.start(index);
  }

  async delete(index: number) {
    await this.workTimerService.delete(index);
  }

  async updateDescription(index: number, description: string) {
    await this.workTimerService.updateDescription(index, description);
  }

  closeWorkEntry() {
    this.isWorkEntryOpen = false;
  }

  async createWorkEntry(i: number) {
    const timer = await firstValueFrom(this.timer$);
    this.workMinutes = Math.ceil(timer[i].cumulatedSeconds / 60);
    this.workDate = new Date();
    this.workDescription =
      timer[i].description === WorkTimerService.emptyDescription ? '' : timer[i].description;
    this.isWorkEntryOpen = true;
  }
}
