import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-description-list-entry',
  templateUrl: './description-list-entry.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionListEntryComponent {
  @Input()
  label!: string;

  @Input()
  value!: string;
}
