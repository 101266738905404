<div class="flex flex-col gap-8" *ngIf="orga$ | async as orga">
  <app-orga-name-card
    [orga]="orga.data"
    [disabled]="!(hasPermission | async)"
    (nameChanged)="settingsChanged($event)"
  ></app-orga-name-card>
  <app-orga-address-card
    [orga]="orga.data"
    [disabled]="!(hasPermission | async)"
    (addressChanged)="settingsChanged($event)"
  ></app-orga-address-card>
  <app-orga-invoice-card
    [orga]="orga.data"
    [disabled]="!(hasPermission | async)"
    (invoiceDetailsChanged)="settingsChanged($event)"
  ></app-orga-invoice-card>
  <app-orga-banking-card
    [orga]="orga.data"
    [disabled]="!(hasPermission | async)"
    (bankDetailsChanged)="settingsChanged($event)"
  ></app-orga-banking-card>
</div>
