import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DecimalPipe, NgIf } from '@angular/common';
import { DropdownItemComponent } from '../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownButtonComponent } from '../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { InvoiceEntity2 } from 'commons/dist/entities/invoice2';
import { FirestoreEntity } from 'commons';
import { InvoiceStateBadgeComponent } from '../../../invoice/components/invoice-state-badge/invoice-state-badge.component';
import { BadgeComponent } from '../../../../components/badge/badge.component';

@Component({
  selector: 'li[app-invoice-list-entry]',
  templateUrl: './invoice-list-entry.component.html',
  standalone: true,
  imports: [
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    NgIconWrapperComponent,
    DropdownItemComponent,
    NgIf,
    DecimalPipe,
    InvoiceStateBadgeComponent,
    BadgeComponent,
  ],
})
export class InvoiceListEntryComponent {
  @Input()
  invoice!: FirestoreEntity<Partial<InvoiceEntity2>>;

  @Output()
  editInvoice = new EventEmitter<FirestoreEntity<Partial<InvoiceEntity2>>>();

  @Output()
  deleteInvoice = new EventEmitter<FirestoreEntity<Partial<InvoiceEntity2>>>();

  @Output()
  downloadInvoice = new EventEmitter<FirestoreEntity<Partial<InvoiceEntity2>>>();
}
