import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SlideOverService } from '../../../components/slide-overs/slide-over/slide-over-service';
import { FirestoreProject, Project } from 'commons';
import { CustomerService } from '../../../services/customer.service';
import { ProjectService } from '../../../services/project.service';
import { AsyncPipe } from '@angular/common';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { ToggleComponent } from '../../../components/toggle/toggle.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectAllDirective } from '../../../directives/selectAll.directive';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { TextInputComponent } from '../../../components/input/text-input/text-input.component';

@Injectable({
  providedIn: 'root',
})
export class ProjectSlideOverService extends SlideOverService<FirestoreProject, void, void> {}

@Component({
  selector: 'app-project-slide-over',
  templateUrl: './project-slide-over.component.html',
  standalone: true,
  imports: [
    SlideOverComponent,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    ReactiveFormsModule,
    InputComponent,
    TailwindInputDirective,
    SelectAllDirective,
    NgSelectModule,
    ToggleComponent,
    SlideOverFooterComponent,
    TailwindButtonDirective,
    AsyncPipe,
    TextInputComponent,
  ],
})
export class ProjectSlideOverComponent implements OnDestroy, OnInit {
  projectForm!: FormGroup;
  customers$!: Observable<{ id: string; displayName: string }[]>;

  private subscription = new Subscription();
  title!: string;

  constructor(
    private projectService: ProjectService,
    private customerService: CustomerService,
    public projectSlideOver: ProjectSlideOverService
  ) {}

  async ngOnInit() {
    this.customers$ = this.customerService.getAllCustomers().pipe(
      map((customers) =>
        customers.map((customer) => ({
          id: customer.id,
          displayName: customer.data.displayName,
        }))
      )
    );
    this.initForm();

    if (this.projectSlideOver.getMode() === 'edit') {
      const project = this.projectSlideOver.getEntity()!;
      const customer = project.data.customerId
        ? await firstValueFrom(
            this.customerService.getCustomer(project.data.customerId).pipe(
              map((customer) => ({
                id: customer.id,
                displayName: customer.data.displayName,
              }))
            )
          )
        : null;
      this.projectForm.setValue({
        name: project.data.name,
        customer,
        isActive: project.data.isActive,
      });
    }

    this.title =
      this.projectSlideOver.getMode() === 'new' ? 'Neues Projekt erstellen' : 'Projekt bearbeiten';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initForm() {
    this.projectForm = new FormGroup(
      {
        name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
        customer: new FormControl('', []),
        isActive: new FormControl(true, []),
      },
      {}
    );
  }

  closeSlideOver() {
    this.projectSlideOver.closeSlideOver();
  }

  async save() {
    this.projectForm.markAllAsTouched();
    if (!this.projectForm.invalid) {
      let project: Partial<Project> = {
        name: this.projectForm.value.name,
        isActive: this.projectForm.value.isActive,
      };

      project = {
        ...project,
        customerId: this.projectForm.value.customer?.id ?? null,
        customerName: this.projectForm.value.customer?.displayName ?? null,
      };

      if (this.projectSlideOver.getMode() === 'edit') {
        await this.projectService.updateProject(this.projectSlideOver.getEntity()!.id, project);
      } else {
        await this.projectService.createProject(project);
      }
      this.closeSlideOver();
    }
  }

  customerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return !control.value ? { noCustomerSet: true } : null;
    };
  }
}
