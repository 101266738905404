import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { customAlphabet } from 'nanoid';
import { Orga } from 'commons';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { ToggleComponent } from '../../../components/toggle/toggle.component';
import { SettingsCardComponent } from '../settings-card/settings-card.component';

const emailCustomChars = '0123456789_abcdefghijklmnopqrstuvwxyz-';

@Component({
  selector: 'app-document-mail-card',
  templateUrl: './document-mail-card.component.html',
  standalone: true,
  imports: [
    SettingsCardComponent,
    ReactiveFormsModule,
    ToggleComponent,
    InputComponent,
    TailwindInputDirective,
    TailwindButtonDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentMailCardComponent implements OnInit {
  @Input()
  orga!: Orga;

  @Input()
  disabled = false;

  @Output()
  mailAdressChanged = new EventEmitter<Partial<Orga>>();

  addressForm!: FormGroup;
  mailDeliveryActivated = false;
  nanoid = customAlphabet(emailCustomChars, 10);

  ngOnInit(): void {
    this.mailDeliveryActivated = !!this.orga.uploadEmail;
    this.addressForm = new FormGroup({
      uploadEmail: new FormControl({
        value: this.orga.uploadEmail ?? null,
        disabled: true,
      }),
    });
  }

  saveSettings() {
    if (!this.addressForm.invalid) {
      this.mailAdressChanged.emit(this.addressForm.value);
    }
  }

  activate(mailDeliveryActivated: boolean) {
    this.mailDeliveryActivated = mailDeliveryActivated;
    if (mailDeliveryActivated) {
      this.addressForm.setValue({
        uploadEmail: this.orga.uploadEmail ?? this.generateMailAdress(),
      });
    } else {
      this.addressForm.setValue({
        uploadEmail: null,
      });
    }
  }

  resetMailAddress() {
    this.addressForm.setValue({
      uploadEmail: this.generateMailAdress(),
    });
  }

  generateMailAdress() {
    return `${this.nanoid(15)}@inbound.jessie.ai`;
  }
}
