import { inject, Injectable } from '@angular/core';
import {
  Address,
  CustomerSummary,
  FirestoreOrgaUser,
  FirestoreProject,
  Invoice,
  InvoiceEntity,
  mapCustomerSummary,
  mapOrgaUserSummary,
  mapProjectSummary,
  OrgaUserSummary,
} from 'commons';

import { firstValueFrom, map } from 'rxjs';
import { ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { where } from '@angular/fire/firestore';
import { LastEditorService } from './last-editor.service';
import { OrgaService } from './orga.service';
import { SessionStateService } from './session-state.service';
import { CustomerService } from './customer.service';
import { addDays, formatISO } from 'date-fns';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);

  private lastEditor = inject(LastEditorService);
  private customerService = inject(CustomerService);
  private orgaService = inject(OrgaService);
  private sessionStateService = inject(SessionStateService);

  getAllInvoices() {
    return this.orgaFirestoreService.getDocs<Invoice>('invoice');
  }

  getInvoicesForProject(projectId: string) {
    return this.orgaFirestoreService.getDocs<Invoice>(
      'invoice',
      where('project.id', '==', projectId)
    );
  }

  getInvoicesForCustomer(customerId: string) {
    return this.orgaFirestoreService.getDocs<Invoice>(
      'invoice',
      where('recipient.customer.id', '==', customerId)
    );
  }

  getInvoice(invoiceId: string) {
    return this.orgaFirestoreService.getDoc<Invoice>(`invoice/${invoiceId}`);
  }

  async createInvoice(project: FirestoreProject) {
    const invoiceId = crypto.randomUUID();
    const invoiceNumber = await this.orgaService.getInvoiceNumber(
      await firstValueFrom(this.sessionStateService.getOrgaId())
    );

    const orga = await firstValueFrom(this.sessionStateService.getOrga());
    const defaultPaymentDurationDays = orga?.data.invoiceDetails.defaultPaymentDurationDays;

    let recipient: {
      customer: CustomerSummary;
      name: string;
      address: Address;
    } | null = null;
    if (project.data.customerId) {
      const customer = await firstValueFrom(
        this.customerService.getCustomer(project.data.customerId)
      );
      recipient = {
        customer: mapCustomerSummary(customer),
        address: customer.data.address,
        name: customer.data.displayName,
      };
    }

    const contactPerson = await firstValueFrom(
      this.sessionStateService.getOrgaUser().pipe(
        filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
        map((orgaUser) => mapOrgaUserSummary(orgaUser))
      )
    );

    const sender: {
      contactPerson: OrgaUserSummary;
      name: string;
      address: Address;
      vatUid: string;
    } = {
      contactPerson,
      name: orga?.data.legalName!,
      address: orga?.data.address!,
      vatUid: orga?.data.invoiceDetails.vatNumber!,
    };

    const invoice: Partial<InvoiceEntity> = {
      logo: orga?.data.logo64 ?? null,
      project: mapProjectSummary(project),
      invoiceState: 'draft',
      invoiceNumber,
      ...(recipient ? { recipient: recipient } : {}),
      paymentDurationDays: defaultPaymentDurationDays,
      dueDate: formatISO(addDays(new Date(), defaultPaymentDurationDays ?? 30)),
      sender,
    };
    await this.orgaFirestoreService.setDoc<Partial<InvoiceEntity>>(
      `invoice/${invoiceId}`,
      await this.lastEditor.hydrate(invoice)
    );
    return invoiceId;
  }

  async updateInvoice(invoiceId: string, invoice: Partial<Invoice>): Promise<void> {
    return this.orgaFirestoreService.updateDoc<Invoice>(
      `invoice/${invoiceId}`,
      await this.lastEditor.hydrate(invoice)
    );
  }
}
