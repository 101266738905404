import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appTab]',
  standalone: true,
})
export class TabOldDirective {
  @Input('appTab')
  title!: string;
}
