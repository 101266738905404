<button
  type="button"
  class="flex w-full justify-center items-center"
  (click)="editInvoice.emit(invoice)"
>
  <div
    class="p-4 sm:py-6 flex-grow block focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
  >
    <div class="flex items-center gap-4">
      <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div class="flex items-center gap-4">
          <div class="text-sm leading-5 font-medium text-blue-600 truncate">
            Rechnung #{{ invoice.data.invoiceNumber }}
          </div>
          <app-badge *ngIf="invoice.data._v6"
            >Migriert {{ !invoice.data.documentId ? ' - Rohdaten unvollständig' : '' }}
          </app-badge>
        </div>

        <div class="flex gap-4">
          <app-invoice-state-badge
            [invoiceState]="invoice.data.invoiceState!"
          ></app-invoice-state-badge>
          <div class="text-sm leading-5 font-medium text-gray-600 truncate">
            {{ invoice.data.invoiceTotals?.totalAmount | number : '1.2-2' : 'de-CH' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <app-dropdown-minimal-menu>-->
  <!--    <app-dropdown-button-->
  <!--      ><app-ng-icon name="heroEllipsisVerticalSolid" class="text-3xl" strokeWidth="3"></app-ng-icon-->
  <!--    ></app-dropdown-button>-->
  <!--    <app-dropdown-item (menuClick)="downloadInvoice.emit(invoice)"-->
  <!--      >Rechnung herunterladen-->
  <!--    </app-dropdown-item>-->
  <!--    <app-dropdown-item (menuClick)="editInvoice.emit(invoice)"-->
  <!--      >Rechnung bearbeiten-->
  <!--    </app-dropdown-item>-->
  <!--    &lt;!&ndash;    <app-dropdown-item&ndash;&gt;-->
  <!--    &lt;!&ndash;      *ngIf="invoice.data.invoiceState === ''"&ndash;&gt;-->
  <!--    &lt;!&ndash;      (menuClick)="deleteInvoice.emit(invoice)"&ndash;&gt;-->
  <!--    &lt;!&ndash;    >&ndash;&gt;-->
  <!--    &lt;!&ndash;      Rechnung löschen</app-dropdown-item&ndash;&gt;-->
  <!--    &lt;!&ndash;    >&ndash;&gt;-->
  <!--  </app-dropdown-minimal-menu>-->
</button>
