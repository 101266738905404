<form
  *ngIf="documentForm"
  [formGroup]="documentForm"
  autocomplete="off"
  novalidate
  (ngSubmit)="update()"
>
  <app-slide-over [backdropBlur]="true" [collabsibleSlideover]="true">
    <app-center-content *ngIf="contentType && downloadUrl">
      <ng-container *ngIf="contentType === 'application/pdf'">
        <pdf-viewer
          class="h-full"
          [src]="downloadUrl"
          [original-size]="false"
          [zoom-scale]="'page-fit'"
          [autoresize]="true"
        ></pdf-viewer>
      </ng-container>
      <ng-container *ngIf="contentType.startsWith('image/')">
        <div class="w-full h-full flex items-center justify-center">
          <img [src]="downloadUrl" alt="Image Preview" />
        </div>
      </ng-container>
    </app-center-content>

    <app-slide-over-header
      #headerComponent
      (closeSlideOver)="closeSlideOver()"
      [title]="document.data.name"
      [leadText]="document.id"
      [formDirty]="documentForm.dirty"
    >
      <div class="flex justify-end gap-4">
        <button
          type="button"
          class="text-blue-200 hover:text-white transition ease-in-out duration-150"
          (click)="downloadDocument()"
        >
          <app-ng-icon class="text-2xl" name="heroDocumentArrowDown"> </app-ng-icon>
        </button>
      </div>
    </app-slide-over-header>

    <app-slide-over-content>
      <app-tab-group class="flex flex-col h-full px-4">
        <ng-template appTab="Dokument">
          <div *ngIf="!downloadUrl" class="rounded-md bg-red-50 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <app-ng-icon class="text-lg text-red-400" name="heroXCircleSolid"></app-ng-icon>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  Dokument konnte nicht geladen werden.
                </h3>
              </div>
            </div>
          </div>

          <div *ngIf="isInternalInvoice" class="rounded-md bg-gray-50 p-4 mt-4 shadow-md">
            <div class="flex">
              <div class="flex-shrink-0">
                <app-ng-icon class="text-lg text-gray-400" name="heroExclamationTriangleSolid">
                </app-ng-icon>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-gray-800">Jessie.ai erzeugte Rechnung</h3>
                <div class="mt-2 text-sm text-gray-700">
                  <p>Dieses Dokument wurde von jessie.ai erzeugt.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="divide-y divide-gray-200 overflow-y-auto h-full py-5">
            <div class="space-y-6 pb-5">
              <app-input class="mt-4">
                <app-toggle-button-group formControlName="type">
                  <app-toggle-button value="revenue">Einnahme</app-toggle-button
                  ><app-toggle-button value="expense">Ausgabe</app-toggle-button
                  ><app-toggle-button value="other"
                    >Andere</app-toggle-button
                  ></app-toggle-button-group
                >
              </app-input>

              <div class="space-y-1">
                <app-text-input
                  label="Name"
                  class="mt-4"
                  [formControl]="$any(documentForm.controls).name"
                ></app-text-input>
              </div>
              <div class="space-y-1">
                <app-text-input
                  label="Notizen"
                  class="mt-4"
                  [formControl]="$any(documentForm.controls).comment"
                >
                </app-text-input>
              </div>

              <app-date-input
                *appHideWhenNotInRole="['owner', 'standard']"
                label="Datum"
                class="mt-4"
                [enhanced]="
                  !document.data.curatedValuesReviewed &&
                  !documentForm.controls['documentDate']?.dirty &&
                  !!document.data.documentAI?.curated?.invoice_date
                "
                [formControl]="$any(documentForm.controls).documentDate"
              >
              </app-date-input>
            </div>
            <div class="flex flex-col space-y-3 pt-6 pb-5" *ngIf="showPaymentFields">
              <app-number-input
                variant="currency"
                label="Betrag"
                [formControl]="$any(documentForm.controls).amount"
                [enhanced]="
                  !document.data.curatedValuesReviewed &&
                  !documentForm.controls['amount']?.dirty &&
                  !!document.data.documentAI?.curated?.total_amount
                "
              >
              </app-number-input>
              <app-date-input
                label="Valuta"
                [enhanced]="
                  !document.data.curatedValuesReviewed &&
                  !documentForm.controls['valueDate']?.dirty &&
                  !!document.data.documentAI?.curated?.due_date
                "
                [formControl]="$any(documentForm.controls).valueDate"
              >
              </app-date-input>
              <app-input
                label="Geschäftsjahr"
                [errors]="
                  documentForm.get('financialYear')?.touched
                    ? documentForm.get('financialYear')?.errors
                    : null
                "
              >
                <input
                  appTailwindInput
                  formControlName="financialYear"
                  type="number"
                  min="2000"
                  max="2099"
                  step="1"
                />
              </app-input>

              <app-input label="Kategorie">
                <ng-select
                  [items]="categories | async"
                  bindLabel="displayName"
                  [clearable]="false"
                  [formControlName]="'category'"
                >
                </ng-select>
              </app-input>

              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="paid"
                    type="checkbox"
                    formControlName="paid"
                    class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded disabled:bg-gray-50 disabled:checked:bg-blue-400"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="paid" class="font-medium text-gray-700">Bezahlt</label>
                </div>
              </div>
            </div>
            <div class="space-y-6 pt-6 pb-5">
              <app-input
                label="Kunde"
                [enhanced]="
                  !document.data.curatedValuesReviewed &&
                  !documentForm.controls['customer']?.dirty &&
                  !!document.data.documentAI?.curated?.supplier_name
                "
              >
                <ng-select
                  [items]="customers$ | async"
                  bindLabel="data.displayName"
                  bindValue="id"
                  formControlName="customer"
                >
                  <ng-template ng-footer-tmp let-searchTerm="searchTerm">
                    <div class="mx-4 my-2 truncate">
                      <button
                        *ngIf="searchTerm"
                        appTailwindButton
                        type="button"
                        size="xs"
                        (click)="createCustomer(searchTerm)"
                        class="truncate"
                      >
                        Kunde
                        {{
                          searchTerm.length > 23 ? searchTerm.substring(0, 20) + '...' : searchTerm
                        }}
                        anlegen
                      </button>
                    </div>
                  </ng-template>
                </ng-select>
              </app-input>

              <div
                class="relative rounded-md bg-yellow-50 p-4 mt-4 shadow-xl"
                *ngIf="recognizedCompanyNotFound"
              >
                <div class="flex">
                  <div class="flex-shrink-0">
                    <app-ng-icon
                      class="text-xl text-yellow-400"
                      name="heroExclamationTriangleSolid"
                    ></app-ng-icon>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">Kundenname nicht gefunden</h3>
                    <div class="mt-2 text-sm text-yellow-700">
                      <p>
                        Es wurde kein Kunde mit den Namen
                        <b>{{ document.data.documentAI?.curated?.supplier_name }}</b> gefunden. Soll
                        ein solcher angelegt werden?
                      </p>
                    </div>
                    <div class="mt-4">
                      <div class="-mx-2 -my-1.5 flex">
                        <button
                          type="button"
                          (click)="
                            createCustomer(document.data.documentAI?.curated?.supplier_name ?? '')
                          "
                          class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-green-50"
                        >
                          Kunde anlegen
                        </button>
                        <button
                          type="button"
                          (click)="recognizedCompanyNotFound = false"
                          class="ml-3 rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-green-50"
                        >
                          Nein Danke
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 -mr-[22px] flex items-center text-blue-600"
                >
                  <app-ng-icon class="text-blue-600" name="heroSparkles"></app-ng-icon>
                </div>
              </div>
            </div>
            <div class="space-y-6 pt-6 pb-5">
              <div class="relative flex items-start" *appHideWhenNotInRole="['owner', 'standard']">
                <div class="flex items-center h-5">
                  <input
                    id="accountRelevant"
                    type="checkbox"
                    formControlName="accountRelevant"
                    class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="accountRelevant" class="font-medium text-gray-700"
                    >Buchhaltungsrelevant</label
                  >
                  <p class="text-gray-500">Dokument wird für die Buchhaltung freigeben.</p>
                </div>
              </div>
              <div
                class="relative flex items-start"
                *ngIf="!!documentForm.get('accountRelevant')?.value"
              >
                <div class="flex items-center h-5">
                  <input
                    id="accountProcessed"
                    type="checkbox"
                    formControlName="accountProcessed"
                    class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="accountProcessed" class="font-medium text-gray-700"
                    >Dokument eingearbeitet</label
                  >
                  <p class="text-gray-500">Dokument wurde durch die Buchhaltung eingearbeitet.</p>
                </div>
              </div>
              <div class="relative flex items-start" *appHideWhenNotInRole="['owner', 'standard']">
                <div class="flex items-center h-5">
                  <input
                    id="archived"
                    type="checkbox"
                    formControlName="archived"
                    class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                  />
                </div>
                <div class="flex flex-row justify-between w-full">
                  <div class="ml-3 text-sm w-1/2">
                    <label for="archived" class="font-medium text-gray-700">Archiviert</label>
                    <p class="text-gray-500">Dokument wird ins Archiv verschoben.</p>
                  </div>

                  <ng-select
                    class="w-1/2"
                    *ngIf="documentForm.get('archived')?.value"
                    [items]="archiveFolder$ | async"
                    bindLabel="name"
                    [clearable]="false"
                    bindValue="id"
                    [formControlName]="'archiveFolder'"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <button
              *ngIf="!isInternalInvoice"
              class="my-10 w-full"
              type="button"
              appTailwindButton
              color="delete"
              (click)="deleteDocument()"
            >
              Dokument löschen
            </button>
          </div>
        </ng-template>
        <ng-container *appHideWhenNotInPlan="['business', 'enterprise']">
          <ng-template appTab="Kommentare" *appHideWhenNotInRole="['owner', 'standard']">
            <app-comment-section [parentEntity]="document" class="block w-full h-full" padded>
            </app-comment-section
          ></ng-template>
        </ng-container>
        <ng-container *appHideWhenNotInPlan="['business', 'enterprise']">
          <ng-template appTab="Änderungen" *appHideWhenNotInRole="['owner', 'standard']">
            <app-history
              [forId]="document.id"
              noCard
              class="block h-full overflow-auto my-5"
            ></app-history
          ></ng-template>
        </ng-container>
      </app-tab-group>
    </app-slide-over-content>

    <app-slide-over-footer>
      <span class="inline-flex rounded-md shadow-sm">
        <button
          appTailwindButton
          color="secondary"
          type="button"
          (click)="headerComponent.closeSlideOverClicked()"
        >
          Abbrechen
        </button>
      </span>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton type="submit">
          {{
            numberOfDocuments > 1 ? currentDocumentIndex + 1 + '/' + numberOfDocuments + ' ' : ''
          }}Speichern
        </button>
      </span>
    </app-slide-over-footer>
  </app-slide-over>
</form>
