<label class="block text-sm font-medium leading-5 text-gray-700 truncate">
  {{ label }}
</label>
<div class="relative mt-1 rounded-md">
  <ng-content></ng-content>
  <!--  <div-->
  <!--    class="pointer-events-none absolute inset-y-0 right-0 -mr-[22px] flex items-center"-->
  <!--    *ngIf="hasErrors">-->
  <!--    <app-ng-icon class="text-red-500 text-xl" name="heroExclamationCircleSolid"></app-ng-icon>-->
  <!--  </div>-->
  <div
    class="pointer-events-none absolute inset-y-0 right-0 -mr-[22px] flex items-center text-blue-600"
    *ngIf="enhanced && !hasErrors"
  >
    <app-ng-icon name="heroSparkles"></app-ng-icon>
  </div>
</div>
