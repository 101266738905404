<div class="bg-white overflow-hidden shadow sm:rounded-lg divide-y divide-gray-200">
  <div class="bg-white p-4 border-b border-gray-200 sm:px-6">
    <div class="flex justify-between flex-wrap sm:flex-nowrap">
      <div class="">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ cardTitle }}
        </h3>
      </div>
      <div class="flex-shrink-0 flex flex-col items-end gap-4">
        <div class="flex flex-row gap-2">
          <div class="relative" *ngIf="!disableSearch">
            <input appTailwindInput2 type="text" (input)="searchTextChanged($event)" />
            <div
              class="absolute inset-y-0 right-0 mr-2 flex items-center pointer-events-none text-gray-600"
            >
              <app-ng-icon class="text-2xl" name="heroMagnifyingGlass"></app-ng-icon>
            </div>
          </div>

          <button
            type="button"
            appTailwindButton
            color="white"
            size="xs"
            [disabled]="!(documents && documents.length > 0)"
            (click)="toggleMultiselect()"
          >
            <app-ng-icon *ngIf="!isMultiselect" class="text-2xl" name="heroPencil"></app-ng-icon>
            <app-ng-icon *ngIf="isMultiselect" class="text-2xl" name="heroChevronUp"></app-ng-icon>
          </button>
        </div>
        <div class="flex flex-row gap-2 text-gray-600" *ngIf="!disableFilter">
          <div class="flex items-center h-5">
            <input
              id="revenue"
              (change)="typeFilterChange('revenue', $event)"
              [checked]="typeFilter?.includes('revenue')"
              type="checkbox"
              class="focus:ring-blue-500 h-4 w-4 text-gray-600 border-gray-300 rounded disabled:bg-gray-50 disabled:checked:bg-blue-400"
            />
            <div class="ml-2 text-xs">
              <label for="revenue" class="font-medium">Einnahmen</label>
            </div>
          </div>
          <div class="flex items-center h-5">
            <input
              id="expense"
              (change)="typeFilterChange('expense', $event)"
              [checked]="typeFilter?.includes('expense')"
              type="checkbox"
              class="focus:ring-blue-500 h-4 w-4 text-gray-600 border-gray-300 rounded disabled:bg-gray-50 disabled:checked:bg-blue-400"
            />
            <div class="ml-2 text-xs">
              <label for="expense" class="font-medium">Ausgaben</label>
            </div>
          </div>
          <div class="flex items-center h-5">
            <input
              id="other"
              (change)="typeFilterChange('other', $event)"
              [checked]="typeFilter?.includes('other')"
              type="checkbox"
              class="focus:ring-blue-500 h-4 w-4 text-gray-600 border-gray-300 rounded disabled:bg-gray-50 disabled:checked:bg-blue-400"
            />
            <div class="ml-2 text-xs">
              <label for="other" class="font-medium">Andere</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <app-document-list [documents]="documents" (editDocument)="editDocument($event)">
      <div class="bg-blue-100 p-4 flex flex-row justify-between" *ngIf="isMultiselect">
        <div class="flex flex-col md:flex-row gap-2">
          <button appTailwindButton color="white" size="xs" (click)="selectAll()">
            Alle auswählen
          </button>
          <button appTailwindButton size="xs" color="white" (click)="deselectAll()">
            Keines auswählen
          </button>
        </div>
        <div class="flex flex-col md:flex-row gap-2">
          <button
            [disabled]="!atLeastOneSelected"
            appTailwindButton
            color="white"
            size="xs"
            (click)="editSelectedDocuments()"
          >
            Bearbeiten
          </button>
          <button
            [disabled]="!atLeastOneSelected"
            appTailwindButton
            color="white"
            size="xs"
            (click)="downloadSelectedDocuments()"
          >
            Download
          </button>
          <button
            *ngIf="updateAccountingProcessedEnabled"
            appTailwindButton
            color="white"
            size="xs"
            [disabled]="!atLeastOneSelected"
            (click)="updateSelectedDocumentsAsProcessed()"
          >
            Verbucht
          </button>
          <button
            *ngIf="updateAccountingRelevantEnabled"
            appTailwindButton
            color="white"
            size="xs"
            [disabled]="!atLeastOneSelected"
            (click)="updateSelectedDocumentsAsRelevant()"
          >
            Buchhaltungsrelevant
          </button>
          <app-dropdown-minimal-menu>
            <app-dropdown-button>
              <button appTailwindButton color="white" size="xs" [disabled]="!atLeastOneSelected">
                Verschieben
              </button>
            </app-dropdown-button>
            <app-dropdown-item (menuClick)="updateSelectedDocumentFolder(null)">
              Inbox
            </app-dropdown-item>
            <app-dropdown-item
              *ngFor="let folder of folder$ | async"
              (menuClick)="updateSelectedDocumentFolder(folder)"
            >
              {{ folder.name }} ({{ folder.documentCount }})
            </app-dropdown-item>
          </app-dropdown-minimal-menu>
        </div>
      </div>
      <li
        *ngFor="let document of documentList | keyvalue: sortByDocumentDate; let last = last"
        app-document-list-item
        [id]="document.value.id"
        [name]="document.value.data.name"
        [documentDate]="document.value.data.documentDate"
        [accounting]="document.value.data.accounting.relevant"
        [accountingProcessed]="document.value.data.accounting.processed"
        [archiveFolder]="document.value.data.folder"
        [linkedEntities]="document.value.data.linkedEntities ?? {}"
        [linkedUsers]="document.value.data.linkedUsers ?? {}"
        [internalInvoice]="
          document.value.data.type === 'revenue' ? document.value.data.internalInvoice : false
        "
        [documentAiState]="
          !document.value.data.curatedValuesReviewed
            ? document.value.data.documentAiState ?? null
            : null
        "
        [category]="
          document.value.data.type !== 'other' ? document.value.data.category ?? null : null
        "
        (editDocument)="editDocument(document.value)"
        (deleteDocument)="deleteDocument(document.value)"
        (downloadDocument)="downloadDocument(document.value)"
        [selectable]="isMultiselect"
        [selected]="document.value.selected"
        (selectedChange)="selectionChanged($event, document.value)"
      ></li>
    </app-document-list>
  </div>
  <div class="w-full flex flex-col p-4 items-center" *ngIf="enableShowAll">
    <div>
      <button appTailwindButton color="white" (click)="showAll.emit(true)">Alle anzeigen</button>
    </div>
  </div>
</div>
