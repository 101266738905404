import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { firstValueFrom, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CustomerService } from '../../../services/customer.service';
import { Customer, Document, FirestoreEntity, FirestoreProject, mapCustomerSummary } from 'commons';
import { ProjectService } from '../../../services/project.service';
import { DocumentService } from '../../../services/document.service';
import { HistoryComponent } from '../../history/history.component';
import { CommentSectionComponent } from '../../comments/comment-section/comment-section.component';
import { HideWhenNotInPlanDirective } from '../../../directives/hide-when-not-in-plan.directive';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { TabOldDirective } from '../../../components/tabs/content-projected/tab.directive';
import { TabsComponent } from '../../../components/tabs/content-projected/tabs.component';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { HeaderMetaActionComponent } from '../../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../../components/container/container.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { where } from '@angular/fire/firestore';
import { ProjectListEntryComponent } from '../../projects/components/project-list-entry/project-list-entry.component';
import { InvoicesComponent } from '../../finance/invoices/invoices.component';
import { FirestoreInvoice } from 'commons/dist/entities/invoice';
import { InvoiceService } from '../../../services/invoice.service';
import { Dialog } from '@angular/cdk/dialog';
import {
  ProjectSlideOverComponent,
  ProjectSlideOverInput,
} from '../../projects/slide-overs/project-slide-over.component';
import {
  CustomerSlideOverComponent,
  CustomerSlideOverInput,
} from '../customer-slide-over/customer-slide-over.component';
import { DocumentSlideOverComponent } from '../../document/document-slide-over/document-slide-over.component';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  standalone: true,
  imports: [
    NgIf,
    ContainerComponent,
    HeaderMetaActionComponent,
    TailwindButtonDirective,
    TabsComponent,
    TabOldDirective,
    NgFor,
    ProjectListEntryComponent,
    NgClass,
    EmptyComponent,
    HideWhenNotInPlanDirective,
    CommentSectionComponent,
    HistoryComponent,
    AsyncPipe,
    InvoicesComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerViewComponent {
  customer: Observable<FirestoreEntity<Customer>>;
  projects: Observable<FirestoreProject[]>;
  documents: Observable<FirestoreEntity<Document>[]>;
  invoices: Observable<FirestoreInvoice[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private customerService: CustomerService,
    private projectService: ProjectService,
    private documentService: DocumentService,
    @Inject(Dialog) private dialog: Dialog
  ) {
    this.customer = this.route.params.pipe(
      switchMap((params) => this.customerService.getCustomer(params.customerId))
    );
    this.projects = this.customer.pipe(
      switchMap((customer) => this.projectService.getAllProjectsForCustomer(customer.id))
    );
    this.documents = this.customer.pipe(
      switchMap((customer) =>
        this.documentService.getAllDocuments(
          where(`linkedEntities.${customer.id}.id`, '==', customer.id)
        )
      )
    );
    this.invoices = this.customer.pipe(
      switchMap((customer) => this.invoiceService.getInvoicesForCustomer(customer.id))
    );
  }

  async editCustomer() {
    this.dialog.open<string, CustomerSlideOverInput, CustomerSlideOverComponent>(
      CustomerSlideOverComponent,
      { data: { customer: await firstValueFrom(this.customer) } }
    );
  }

  async newProject() {
    const dialogRef = this.dialog.open<string, ProjectSlideOverInput, ProjectSlideOverComponent>(
      ProjectSlideOverComponent,
      {
        data: { customer: mapCustomerSummary(await firstValueFrom(this.customer)) },
      }
    );
    const id = await firstValueFrom(dialogRef.closed);
    if (id) {
      this.router.navigate(['project', id], { relativeTo: this.route });
    }
  }

  async editInvoice(invoice: FirestoreInvoice) {
    // @ts-ignore
    if (invoice.data._v6) {
      if (invoice.data.documentId) {
        const document = await firstValueFrom(
          this.documentService.getDocument(invoice.data.documentId)
        );
        this.dialog.open(DocumentSlideOverComponent, { data: { documents: [document] } });
      } else {
        console.log('migrated invoice without data');
      }
    } else {
      this.router.navigate(['invoice', invoice.id], {
        relativeTo: this.route,
      });
    }
  }

  navigateTo(project: FirestoreProject) {
    this.router.navigate(['project', project.id], {
      relativeTo: this.route,
    });
  }
}
