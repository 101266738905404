import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Orga } from 'commons';
import { SettingsCardComponent } from '../settings-card/settings-card.component';
import {
  getInvoiceSettingsForm,
  InvoiceSettingsFormComponent,
} from '../../../components/forms/invoice-settings-form/invoice-settings-form.component';

@Component({
  selector: 'app-orga-invoice-card',
  templateUrl: './orga-invoice-card.component.html',
  standalone: true,
  imports: [SettingsCardComponent, ReactiveFormsModule, InvoiceSettingsFormComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgaInvoiceCardComponent implements OnInit {
  @Input()
  orga!: Orga;

  @Input()
  disabled = false;

  @Output()
  invoiceDetailsChanged = new EventEmitter<Partial<Orga>>();

  invoiceDetailForm!: FormGroup;

  ngOnInit(): void {
    this.invoiceDetailForm = getInvoiceSettingsForm(this.orga.invoiceDetails);
  }

  saveSettings() {
    if (!this.invoiceDetailForm.invalid) {
      this.invoiceDetailsChanged.emit({
        invoiceDetails: this.invoiceDetailForm.value,
        // invoiceDetails: {
        //   ...this.invoiceDetailForm.value,
        //   currentInvoiceNumber: Number(this.invoiceDetailForm.value.currentInvoiceNumber),
        // },
      });
    }
  }
}
