import { Component, Input } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { InputErrorComponent } from '../input-error/input-error.component';
import { InputLabelComponent } from '../input-label/input-label.component';
import { NgIconWrapperComponent } from '../../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TailwindInputDirective2 } from '../tailwind-input2.directive';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-form-input-wrapper',
  standalone: true,
  imports: [
    AsyncPipe,
    InputErrorComponent,
    InputLabelComponent,
    NgIconWrapperComponent,
    NgIf,
    TailwindInputDirective2,
  ],
  templateUrl: './form-input-wrapper.component.html',
})
export class FormInputWrapperComponent {
  @Input() control!: FormControl;
  @Input() label?: string;
  @Input() enhanced = false;

  error!: Observable<string | null>;

  ngOnChanges() {
    if (this.control) {
      this.error = this.control.events.pipe(
        map(() => (this.control.touched ? this.control.errors : null)),
        map((x) => {
          if (x && Object.keys(x).length > 0) {
            switch (Object.keys(x)[0]) {
              case 'required':
                return 'Plichtfeld';
              case 'maxlength':
                return 'Text zu lang';
              case 'datePeriod':
                return Object.values(x)[0] === true
                  ? 'Zeitraum ist nicht gültig'
                  : Object.values(x)[0];
              default:
                return Object.keys(x)[0];
            }
          }
          return null;
        })
      );
    }
  }

  protected readonly Validators = Validators;
}
