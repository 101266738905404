<app-form-input-wrapper [label]="label" [control]="formControl" class="relative">
  <input
    [attr.disabled]="isDisabled ? true : null"
    [value]="this.isFocused ? this.value : this.formattedValue"
    (input)="valueChanged(inputElement.value)"
    (focus)="focus()"
    (blur)="blur()"
    #inputElement
    appTailwindInput2
    [control]="formControl"
    type="text"
    [placeholder]="placeholder"
  />
  <div
    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
    *ngIf="currencySymbol"
  >
    <span class="text-gray-500 sm:text-sm" id="price-currency">{{ currencySymbol }}</span>
  </div>
</app-form-input-wrapper>
