<p class="text-sm text-gray-500" *ngIf="!isEditable" (click)="edit()">
  {{ labelText }}
</p>
<input
  class="text-sm text-black"
  appTailwindInput2
  *ngIf="isEditable"
  [value]="labelText"
  (change)="updateLabelText($event)"
  #focusable
  (blur)="unedit()"
  spellcheck="false"
  autocomplete="false"
  type="text"
  style="margin-top: -9px; margin-left: -13px"
/>
