<app-slide-over>
  <app-slide-over-header
    #headerComponent
    (closeSlideOver)="closeSlideOver()"
    [formDirty]="invoiceForm.dirty"
    [title]="
      this.invoiceSlideOver.getMode() === 'edit'
        ? '#' + invoice.data.invoiceNumber + ' ' + invoice.data.customerDisplayName
        : 'Neue Rechnung erstellen'
    "
    [leadText]="''"
  >
  </app-slide-over-header>

  <app-slide-over-content>
    <form [formGroup]="invoiceForm" autocomplete="off" novalidate>
      <div class="divide-y divide-gray-200">
        <div class="px-4 sm:px-6 flex flex-col gap-4 mt-4">
          <app-input label="Status">
            <app-toggle-button-group formControlName="state">
              <app-toggle-button value="created">Erstellt</app-toggle-button>
              <app-toggle-button value="sent">Versandt</app-toggle-button>
              <app-toggle-button value="paid">Bezahlt</app-toggle-button>
              <app-toggle-button value="canceled"
                >Storniert</app-toggle-button
              ></app-toggle-button-group
            >
          </app-input>

          <app-date-input
            label="Rechnungsdatum"
            [formControl]="$any(invoiceForm.controls).invoiceDate"
          >
          </app-date-input>
          <app-date-input label="Von" [formControl]="$any(invoiceForm.controls).startDate">
          </app-date-input>
          <app-date-input label="Bis" [formControl]="$any(invoiceForm.controls).endDate">
          </app-date-input>
          <app-date-input
            label="Valuta"
            *ngIf="invoiceForm.get('valueDate')"
            [formControl]="$any(invoiceForm.controls).valueDate"
          >
          </app-date-input>
        </div>
      </div>
    </form>
  </app-slide-over-content>

  <app-slide-over-footer>
    <span class="inline-flex rounded-md shadow-sm">
      <button
        appTailwindButton
        color="secondary"
        type="button"
        (click)="headerComponent.closeSlideOverClicked()"
      >
        Abbrechen
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm">
      <button
        appTailwindButton
        [disabled]="!invoiceForm.valid"
        (click)="createOrUpdateInvoice(invoiceForm.value)"
      >
        Speichern
      </button>
    </span>
  </app-slide-over-footer>
</app-slide-over>
