import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Customer, CustomerSummary, FirestoreCustomer, mapCustomerSummary } from 'commons';
import { LastEditorService } from './last-editor.service';
import { ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private lastEditor = inject(LastEditorService);

  getAllCustomers(): Observable<FirestoreCustomer[]> {
    return this.orgaFirestoreService.getDocs<Customer>('customer');
  }

  getAllCustomerSummaries(): Observable<CustomerSummary[]> {
    return this.orgaFirestoreService
      .getDocs<Customer>('customer')
      .pipe(map((customers) => customers.map(mapCustomerSummary)));
  }

  getCustomer(customerId: string) {
    return this.orgaFirestoreService.getDoc<Customer>(`customer/${customerId}`);
  }

  async createCustomer(customer: Partial<Customer>) {
    return this.orgaFirestoreService.createDoc('customer', await this.lastEditor.hydrate(customer));
  }

  async updateCustomer(customerId: string, customer: Partial<Customer>): Promise<void> {
    return this.orgaFirestoreService.updateDoc<Customer>(
      `customer/${customerId}`,
      await this.lastEditor.hydrate(customer)
    );
  }
}
