import { inject, Injectable } from '@angular/core';
import { FirestoreOrga, Orga, OrgaEntity } from 'commons';
import { Observable } from 'rxjs';
import { FIRESTORE_SERVICE } from './firestore.service';
import { doc, Firestore, runTransaction } from '@angular/fire/firestore';
import { Big } from 'big.js';

@Injectable({
  providedIn: 'root',
})
export class OrgaService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  firestore = inject(Firestore);

  async update(id: string, orga: Partial<Orga>) {
    return this.rootFirestoreService.updateDoc<Orga>(`orga/${id}`, orga);
  }

  getOrga(orgaId: string): Observable<FirestoreOrga> {
    return this.rootFirestoreService.getDoc<Orga>(`orga/${orgaId}`);
  }

  async getInvoiceNumber(orgaId: string) {
    return await runTransaction(this.firestore, async (transaction) => {
      const orga = await transaction.get(doc(this.firestore, `orga/${orgaId}`));
      if (!orga.exists()) {
        throw 'Document does not exist!';
      }
      const newInvoiceNumber = new Big(
        (orga.data() as OrgaEntity).invoiceDetails.currentInvoiceNumber
      )
        .add(1)
        .toFixed(0);
      console.log(newInvoiceNumber);
      transaction.update(orga.ref, { 'invoiceDetails.currentInvoiceNumber': newInvoiceNumber });
      return newInvoiceNumber;
    });
  }
}
