<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:p-0">
      <div
        class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-screen-2xl sm:mx-32 sm:p-6"
      >
        <div class="mt-3 text-center sm:mt-0 sm:text-left">
          <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
            Rechnungspositionen importieren
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Wähle den Zeitrahmen und die Positionen aus welche du in deine Rechnung übernehmen
              willst.
            </p>
          </div>
        </div>

        <div
          class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-x-8 gap-y-6 pt-6"
          *ngIf="invoiceDialogForm"
        >
          <app-alert
            *ngIf="invoiceDialogForm.controls.deliveryPeriod.errors?.['multipleVatRates']"
            class="md:col-span-3 lg:col-span-6 w-full"
            heading="Die ausgewählte Leistungsperiode überstreicht eine Mehrwertsteueränderung"
            subheading="Rechnungen dürfen nicht periodenübergreifend sein. Um dies zu verhindern erstelle einfach mehrere Rechnungen die je in einer einzelnen Mehrwersteuerperiode zu liegen kommen."
          ></app-alert>

          <app-date-input
            label="Von"
            [formControl]="invoiceDialogForm.controls.deliveryPeriod.controls.from"
          >
          </app-date-input>
          <app-date-input
            label="Bis"
            [formControl]="invoiceDialogForm.controls.deliveryPeriod.controls.to"
          >
          </app-date-input>
          <div class="flex lg:col-span-4 items-end">
            <button
              appTailwindButton
              color="link"
              type="button"
              size="xs"
              (click)="setDeliveryPeriodLastMonth()"
            >
              Letzter Monat
            </button>
            <button appTailwindButton color="link" size="xs" (click)="setDeliveryPeriodThisMonth()">
              Dieser Monat
            </button>
          </div>
          <app-position-table
            [mode]="'selecting'"
            [formControl]="invoiceDialogForm.controls.positions"
            [invoicePositions]="(invoicePositions | async)!"
            class="md:col-span-3 lg:col-span-6"
          ></app-position-table>
        </div>

        <div class="mt-5 sm:mt-4 gap-2 sm:flex sm:flex-row-reverse">
          <button (click)="importValues()" class="w-full sm:w-auto" type="button" appTailwindButton>
            Importieren
          </button>
          <button
            class="w-full sm:w-auto"
            (click)="cancelDialog()"
            color="secondary"
            type="button"
            appTailwindButton
          >
            Abbrechen
          </button>
        </div>

        <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button
            (click)="cancelDialog()"
            type="button"
            class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span class="sr-only">Close</span>
            <app-ng-icon class="text-2xl" name="heroXMark"></app-ng-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
