import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IsoDate, IsoDateTime, ProjectSummary, TimeEntry, WorkPositionSummary } from 'commons';
import { DatePipe, KeyValue, NgForOf, NgIf } from '@angular/common';
import { FormatWorkPipe } from '../../../../pipes/format-work.pipe';
import { FormatSecondsPipe } from '../../../../pipes/format-seconds.pipe';
import { SecondsPassedPipe } from '../../../../pipes/seconds-passed.pipe';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { WorkForm } from '../../../projects/slide-overs/work-slide-over.component';
import { Observable } from 'rxjs';
import { DropdownComponent } from '../../../../components/dropdown/dropdown.component';
import { TextInputComponent } from '../../../../components/input/text-input/text-input.component';
import { WorkTimeInputComponent } from '../../../../components/input/work-time-input/work-time-input.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { DropdownButtonComponent } from '../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownItemComponent } from '../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DropdownMinimalMenuComponent } from '../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';

export interface TimerData {
  state: 'inactive' | 'active';
  startTime: IsoDateTime | null;
  date: IsoDate;
  timeEntries: { id: string; startTime: IsoDate; endTime: IsoDate; totalMinutes: number }[] | null;
  isToday: boolean;
}

@Component({
  selector: 'app-timer',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    DatePipe,
    FormatWorkPipe,
    FormatSecondsPipe,
    SecondsPassedPipe,
    NgIconWrapperComponent,
    DropdownComponent,
    TextInputComponent,
    WorkTimeInputComponent,
    TailwindButtonDirective,
    ReactiveFormsModule,
    DropdownButtonComponent,
    DropdownItemComponent,
    DropdownMinimalMenuComponent,
  ],
  templateUrl: './timer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      @apply fixed sm:contents inset-0 mt-16 sm:mt-0 z-10 sm:z-0 bg-white overflow-scroll;
    }
  `,
})
export class TimerComponent {
  @Input({ required: true }) timer!: TimerData;
  @Input({ required: true }) workForm!: FormGroup<WorkForm>;
  @Input({ required: true }) positionSummaries!: Observable<WorkPositionSummary[]>;
  @Input({ required: true }) projectSummaries!: Observable<ProjectSummary[]>;

  @Output() start = new EventEmitter();
  @Output() stop = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() saveWork = new EventEmitter();
  @Output() navigateBack = new EventEmitter();

  compareTimeEntries(a: KeyValue<string, TimeEntry>, b: KeyValue<string, TimeEntry>): number {
    return a.value.startTime.localeCompare(b.value.startTime);
  }
}
