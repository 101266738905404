import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormatWorkPipe } from '../../../../../pipes/format-work.pipe';
import { FirestoreWork } from 'commons';
import { TailwindButtonDirective } from '../../../../../components/button/tailwind-button.directive';
import { TippyDirective } from '@ngneat/helipopper';

@Component({
  selector: 'tr[app-work-row]',
  standalone: true,
  imports: [FormatWorkPipe, TailwindButtonDirective, TippyDirective],
  templateUrl: './work-row.component.html',
})
export class WorkRowComponent {
  @Input() work!: FirestoreWork;
  @Output() editWork = new EventEmitter<FirestoreWork>();
}
