import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SidebarNavigationEntryComponent } from '../../components/container/sidebar-navigation/sidebar-navigation-entry/sidebar-navigation-entry.component';
import { SidebarNavigationComponent } from '../../components/container/sidebar-navigation/sidebar-navigation.component';
import { TailwindButtonDirective } from '../../components/button/tailwind-button.directive';
import { HeaderMetaActionComponent } from '../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../components/container/container.component';
import { HideWhenNotInRoleDirective } from '../../directives/hide-when-not-in-role.directive';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    HeaderMetaActionComponent,
    TailwindButtonDirective,
    SidebarNavigationComponent,
    SidebarNavigationEntryComponent,
    RouterLink,
    RouterOutlet,
    HideWhenNotInRoleDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent {
  protected readonly TailwindButtonDirective = TailwindButtonDirective;
}
